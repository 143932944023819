import { memo } from 'react';
import { TreeSelect } from 'antd';
import _ from 'lodash';

import OriginAreaData from './OriginAreaData';

const toTreeData = ({ name, children, code }) => {
  if (!_.isEmpty(children)) {
    children = _.map(children, toTreeData);
  }
  return {
    value: code,
    title: name,
    children,
  };
};

// TODO: antd 4.17 后可以使用 fieldNames 处理
const TREE_SELECT_DATA = OriginAreaData.map(toTreeData);

function AreaTreeSelect(props) {
  return (
    <TreeSelect
      treeCheckable={true}
      showCheckedStrategy={TreeSelect.SHOW_PARENT}
      {...props}
      treeData={TREE_SELECT_DATA}
    />
  );
}

AreaTreeSelect.propTypes = {
  ...TreeSelect.propTypes,
};

export default memo(AreaTreeSelect);
