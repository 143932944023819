/**
 * Created by qiuyuzhou on 2017/5/10.
 */

import { decodeGraphQLGlobalID } from './graphQLGlobalID';
import PhoneNumberFormatter from '../components/PhoneNumberFormatter';
import LocalDateTime from '../components/LocalDateTime';
import AuthLink from '../components/AuthLink';

export function createLocalDateTimeRender(format = 'l HH:mm') {
  function localDateTimeRender(cell, row, rowIndex) {
    return <LocalDateTime format={format} date={cell} />;
  }

  return localDateTimeRender;
}

export function createNodeLinkRender(urlFormatter, type = 'default') {
  return function (cell, row, rowIdx) {
    const detailURL = urlFormatter(row.id);
    let title = null;

    if (type === 'id') {
      title = decodeGraphQLGlobalID(row.id);
    } else if (type === 'mobile') {
      title = <PhoneNumberFormatter value={cell} />;
    } else {
      title = cell;
    }
    return <AuthLink to={detailURL}>{title}</AuthLink>;
  };
}
