import { Form, Input, Radio } from 'antd';

import { TaxiCompanyRoleList } from '../../common/roles';
import {
  createCustomValidator,
  getAccountValidator,
  getPasswdValidator,
} from '../../utils/formFieldValidator';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};
const StaffCreateForm = props => {
  const confirmPassword = [
    {
      required: true,
      message: '请输入名称',
    },
    createCustomValidator(value => {
      const { getFieldValue } = props.form;
      if (value === getFieldValue('password')) {
        return true;
      }
    }, '两次密码输入不一致！'),
  ];

  return (
    <Form {...formItemLayout} {...props}>
      <Form.Item
        label="账号名称"
        name="username"
        rules={[
          {
            required: true,
            message: '请输入账号名称',
          },
          getAccountValidator(),
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="角色"
        name="role"
        rules={[
          {
            required: true,
            message: '角色不能为空',
          },
        ]}
      >
        <RadioGroup>
          {TaxiCompanyRoleList.map(item => (
            <RadioButton key={item.value} value={item.value}>
              {item.label}
            </RadioButton>
          ))}
        </RadioGroup>
      </Form.Item>
      <Form.Item
        label="密码"
        name="password"
        rules={[
          {
            required: true,
            message: '请输入密码',
          },
          getPasswdValidator(),
        ]}
      >
        <Input type="password" />
      </Form.Item>
      <Form.Item
        label="确认密码"
        name="confirmPassword"
        rules={confirmPassword}
      >
        <Input type="password" />
      </Form.Item>
    </Form>
  );
};

export default StaffCreateForm;
