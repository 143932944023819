import { memo } from 'react';
import { Cascader } from 'antd';

import { provinces } from './OriginAreaData';

const fieldNames = { label: 'name', value: 'code' };
function ProvinceSelect(props) {
  return (
    <Cascader
      expandTrigger="hover"
      placeholder="请选择省份"
      showSearch={true}
      {...props}
      options={provinces}
      fieldNames={fieldNames}
    />
  );
}

ProvinceSelect.propTypes = {
  ...Cascader.propTypes,
};

export default memo(ProvinceSelect);
