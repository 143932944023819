import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Tabs, PageHeader, Row, Skeleton } from 'antd';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import Breadcrumbs from '../Breadcrumbs';
import styles from './index.module.less';
import _ from 'lodash';

const BreadcrumbsWithRouter = withRouter(Breadcrumbs);

const Content = ({ children, extraContent }) => (
  <Row className="content" type="flex">
    <div style={{ flex: 1 }}>{children}</div>
    {extraContent && (
      <div
        style={{
          marginLeft: 80,
        }}
      >
        {extraContent}
      </div>
    )}
  </Row>
);

class CustomPageHeader extends PureComponent {
  static contextTypes = {
    breadcrumbNameMap: PropTypes.object,
  };
  onChange = key => {
    if (this.props.onTabChange) {
      this.props.onTabChange(key);
    }
  };

  render() {
    const {
      // title,
      logo,
      action,
      content,
      extraContent,
      tabList,
      className,
      activeTabKey,
      router,
      location,
      match,
      history,
      loading,
      ...pageHeaderProps
    } = this.props;
    const clsString = classNames(styles['page-header'], className);

    let tabDefaultValue;
    if (activeTabKey !== undefined && tabList) {
      tabDefaultValue = tabList.filter(item => item.default)[0] || tabList[0];
    }

    const tabItems = _.map(tabList, item => ({
      label: item.tab,
      key: item.key,
    }));

    return (
      <PageHeader
        onBack={() => history.goBack()}
        avatar={logo}
        extra={action}
        loading={loading}
        {...pageHeaderProps}
        className={clsString}
        footer={
          tabList &&
          tabList.length > 0 && (
            <Tabs
              className={styles.tabs}
              defaultActiveKey={tabDefaultValue && tabDefaultValue.key}
              activeKey={activeTabKey}
              onChange={this.onChange}
              items={tabItems}
            />
          )
        }
      >
        <BreadcrumbsWithRouter className={styles.breadcrumb} />
        <Skeleton loading={loading}>
          <Content extraContent={extraContent}>{content}</Content>
        </Skeleton>
      </PageHeader>
    );
  }
}

export default withRouter(CustomPageHeader);
