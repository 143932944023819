import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PageHeader from '../components/PageHeader';
import styles from './PageHeaderLayout.module.less';

export default ({ children, wrapperClassName, top, ...restProps }) => (
  <div className={classNames(styles['page-header-layout'], wrapperClassName)}>
    {top}
    <PageHeader {...restProps} linkElement={Link} />
    {children ? <div className={styles.content}>{children}</div> : null}
  </div>
);
