import { Suspense } from 'react';
import { Modal, Skeleton } from 'antd';

import ErrorBoundary from '../ErrorBoundary';

const AsyncModal = ({ children, ...modalProps }) => (
  <Modal {...modalProps}>
    <ErrorBoundary>
      <Suspense fallback={<Skeleton />}>{children}</Suspense>
    </ErrorBoundary>
  </Modal>
);

AsyncModal.propTypes = {
  ...Modal.propTypes,
};

export default AsyncModal;
