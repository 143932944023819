import PropTypes from 'prop-types';
import { Link, useLocation, matchPath } from 'react-router-dom';

import isAuthorizedRoute from '../../auth/isAuthorizedRoute';
import useCurClientRoles from '../../hooks/useCurClientRoles';

import router from '../../common/router';

// 获取最终匹配的 route
const getMatchRoute = (pathname, routes = []) => {
  for (let route of routes) {
    if (matchPath(pathname, route)) {
      return getMatchRoute(pathname, route?.routes) ?? route;
    }
  }
  return null;
};

const getToPathname = to => {
  if (typeof to === 'object' && to !== null) {
    return to?.pathname;
  }
  return to;
};

const defaultProps = {
  unauthorizedRender: true,
};

const AuthLink = ({ to, children, unauthorizedRender, ...linkProps }) => {
  const location = useLocation();
  const roles = useCurClientRoles();

  const toPathname = getToPathname(
    typeof to === 'function' ? to(location) : to,
  );

  const matchRoute = getMatchRoute(toPathname, router);

  if (!matchRoute || !isAuthorizedRoute(matchRoute, roles)) {
    return unauthorizedRender ? children : null;
  }

  return (
    <Link {...linkProps} to={to}>
      {children}
    </Link>
  );
};

AuthLink.defaultProps = defaultProps;
AuthLink.propTypes = {
  unauthorizedRender: PropTypes.bool,
};

export default AuthLink;
