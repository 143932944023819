/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type TaxiStaffList_data$ref = any;
export type TaxiStaffListQueryVariables = {|
  count: number,
  cursor?: ?string,
  q?: ?any,
|};
export type TaxiStaffListQueryResponse = {|
  +data: {|
    +$fragmentRefs: TaxiStaffList_data$ref
  |}
|};
export type TaxiStaffListQuery = {|
  variables: TaxiStaffListQueryVariables,
  response: TaxiStaffListQueryResponse,
|};
*/


/*
query TaxiStaffListQuery(
  $count: Int!
  $cursor: String
  $q: Json
) {
  data: relay {
    ...TaxiStaffList_data
  }
}

fragment TaxiStaffList_data on Relay {
  entities: taxi_company_staffs(first: $count, after: $cursor, q: $q) {
    edges {
      node {
        id
        username
        phone_number
        created_at
        updated_at
        blocked_at
        roles {
          role
        }
        company {
          id
          name
        }
        __typename
      }
      cursor
    }
    pageInfo {
      hasNextPage
      endCursor
    }
    totalCount
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "q"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Variable",
    "name": "q",
    "variableName": "q"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TaxiStaffListQuery",
    "selections": [
      {
        "alias": "data",
        "args": null,
        "concreteType": "Relay",
        "kind": "LinkedField",
        "name": "relay",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TaxiStaffList_data"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TaxiStaffListQuery",
    "selections": [
      {
        "alias": "data",
        "args": null,
        "concreteType": "Relay",
        "kind": "LinkedField",
        "name": "relay",
        "plural": false,
        "selections": [
          {
            "alias": "entities",
            "args": (v1/*: any*/),
            "concreteType": "TaxiCompanyStaffConnection",
            "kind": "LinkedField",
            "name": "taxi_company_staffs",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TaxiCompanyStaffEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TaxiCompanyStaff",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "username",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "phone_number",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "created_at",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "updated_at",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "blocked_at",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RoleUser",
                        "kind": "LinkedField",
                        "name": "roles",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "role",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Company",
                        "kind": "LinkedField",
                        "name": "company",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCount",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "entities",
            "args": (v1/*: any*/),
            "filters": [
              "q"
            ],
            "handle": "connection",
            "key": "TaxiStaffList_entities",
            "kind": "LinkedHandle",
            "name": "taxi_company_staffs"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f8ad3657e4eb07c0408f3d49cb6c1cf0",
    "id": null,
    "metadata": {},
    "name": "TaxiStaffListQuery",
    "operationKind": "query",
    "text": "query TaxiStaffListQuery(\n  $count: Int!\n  $cursor: String\n  $q: Json\n) {\n  data: relay {\n    ...TaxiStaffList_data\n  }\n}\n\nfragment TaxiStaffList_data on Relay {\n  entities: taxi_company_staffs(first: $count, after: $cursor, q: $q) {\n    edges {\n      node {\n        id\n        username\n        phone_number\n        created_at\n        updated_at\n        blocked_at\n        roles {\n          role\n        }\n        company {\n          id\n          name\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    totalCount\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e7aa00a8ffb9ce44cffc44548cb99b27';

module.exports = node;
