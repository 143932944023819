import propTypes from 'prop-types';
import { insertDivider } from './dividerUtil';

import LinkButton from '../components/LinkButton';

export default function descriptionListTitleRender(title, actions) {
  let values = [title];
  if (actions) {
    actions.map((item, index) => {
      if (item.permission) {
        if (item.isDangerous) {
          const edit = (
            <LinkButton
              onClick={item.onClick}
              key={index}
              style={{ fontSize: '14px', color: '#f5222d' }}
            >
              {item.name}
            </LinkButton>
          );
          values.push(edit);
        } else {
          const edit = (
            <LinkButton
              onClick={item.onClick}
              key={index}
              style={{ fontSize: '14px' }}
            >
              {item.name}
            </LinkButton>
          );
          values.push(edit);
        }
      }
      return null;
    });
  }
  return insertDivider(values);
}

descriptionListTitleRender.propTypes = {
  actions: propTypes.arrayOf({
    permission: propTypes.bool.isRequired,
    name: propTypes.string.isRequired,
    onClick: propTypes.func.isRequired,
    isDangerous: propTypes.bool,
  }),
};
