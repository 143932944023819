import { useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { CreditCardOutlined } from '@ant-design/icons';
import _ from 'lodash';

import ModalContainer, {
  safelyOpenModalByRef,
} from '../../components/ModalContainer';
import ModalForm from '../../components/ModalForm';
import WithdrawalConfigDescription from './WithdrawalConfigDescription';
import WithdrawalConfigEditForm from './WithdrawalConfigEditForm';

import { fenToYuan, yuanToFen } from '../../utils/transformUtils';
import updateWithdrawalConfigMutation from './updateWithdrawalConfigMutation';
import { bankConfigKeyMapping } from './mapping';

const BankConfigTitle = (
  <>
    <CreditCardOutlined /> 银行卡提现配置
  </>
);

const WithdrawalConfigBankCard = ({ data, relay, loading }) => {
  const $EditBankConfigModal = useRef();

  const { environment } = relay;

  // reusable data for description and form
  const bankConfigData = useMemo(
    () => ({
      isActive: data?.bank_payout,
      isAutoProcess: data?.auto_process_bank_payout,
      title: data?.bank_title,
      minAmount: fenToYuan(data?.bank_minimum_payout_amount ?? 0),
      maxAmount: fenToYuan(data?.bank_maximum_payout_amount ?? 0),
    }),
    [data],
  );

  const handleEditBtnClick = () => safelyOpenModalByRef($EditBankConfigModal);

  const $renderBankConfigEditForm = useCallback(
    ({ visible, loading, hide, setLoading }) => {
      return (
        <ModalForm
          title="修改银行卡提现配置"
          open={visible}
          onCancel={hide}
          confirmLoading={loading}
          onSubmit={async formValues => {
            const diffValues = _.omitBy(
              formValues,
              (val, key) => val === bankConfigData[key],
            );

            if (_.isEmpty(diffValues)) {
              hide();
              return;
            }

            if (_.hasIn(diffValues, 'minAmount')) {
              diffValues.minAmount = yuanToFen(diffValues.minAmount);
            }
            if (_.hasIn(diffValues, 'maxAmount')) {
              diffValues.maxAmount = yuanToFen(diffValues.maxAmount);
            }

            setLoading(true);
            try {
              await updateWithdrawalConfigMutation(environment, {
                input: bankConfigKeyMapping(diffValues),
              });
              message.success('修改银行卡提现配置成功');
              hide();
            } catch (error) {
              message.error(error.message);
            } finally {
              setLoading(false);
            }
          }}
        >
          <WithdrawalConfigEditForm initialValues={bankConfigData} />
        </ModalForm>
      );
    },
    [environment, bankConfigData],
  );

  return (
    <>
      <WithdrawalConfigDescription
        title={BankConfigTitle}
        data={bankConfigData}
        loading={loading}
        handleEditConfig={handleEditBtnClick}
      />
      <ModalContainer ref={$EditBankConfigModal}>
        {$renderBankConfigEditForm}
      </ModalContainer>
    </>
  );
};

WithdrawalConfigBankCard.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
};
export default WithdrawalConfigBankCard;
