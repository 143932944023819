import { Children, isValidElement } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

import './Toolbar.module.less';

const ToolBarLeft = ({ children }) => children;
const ToolBarCenter = ({ children }) => children;
const ToolBarRight = ({ children }) => children;

const Toolbar = ({ children, ...otherProps }) => {
  const left = [];
  const center = [];
  const right = [];
  Children.forEach(children, (child, index) => {
    if (!isValidElement(child)) {
      return;
    }
    if (child.type === ToolBarLeft) {
      left.push(child);
    } else if (child.type === ToolBarCenter) {
      center.push(child);
    } else if (child.type === ToolBarRight) {
      right.push(child);
    }
  });
  return (
    <Row
      {...otherProps}
      type="flex"
      justify="space-between"
      styleName="smart-table-toolbar"
    >
      {left.length > 0 && (
        <Col styleName="smart-table-toolbar-left">{left}</Col>
      )}
      {center.length > 0 && <Col>{center}</Col>}
      {right.length > 0 && <Col>{right}</Col>}
    </Row>
  );
};

Toolbar.propTypes = {
  left: PropTypes.element,
  center: PropTypes.element,
  right: PropTypes.element,
};

Toolbar.Left = ToolBarLeft;
Toolbar.Center = ToolBarCenter;
Toolbar.Right = ToolBarRight;

export default Toolbar;
