import { PureComponent } from 'react';
import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;

let warning = false;
if (!warning && process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line no-console
  console.warn(
    '该组件实现方式是一种 trick 行为，建议不再使用该组件，日期时间的处理建议在最终提交的地方进行处理',
  );
  warning = true;
}

export default class MyRangePicker extends PureComponent {
  state = {
    start: null,
    end: null,
  };

  static getDerivedStateFromProps(nextProps, curState) {
    const [start = null, end = null] = nextProps.value || [];
    return {
      start,
      end,
    };
  }

  handleRangePickerChange = moments => {
    const [newStart, newEnd] = moments || [];
    this.setState({
      start: newStart,
      end: newEnd,
    });
    this.triggerChange(moments);
  };

  /**
   * 如果之前没有选中时间范围，
   * 通过日历组件选择时间范围为 A 日期开始时间 和 B 日结束时间
   *
   * 原先通过 onChange 的实现，
   * 在设置 quick range 时
   * 如果之前没有选中时间范围，直接选择 quick range 內时间，会导致选择时间错误。
   *
   * 通过 in-place 操作临时解决该 BUG
   *
   * Ant V4 RangePick 交互发生根本性的改变。
   * RangePick 中的 handleCalendarChange 只有在点击开始时间或结束时间变化时触发，不再是点击某个日期时触发（change by quick range 也会触发该函数）
   * 如果，MyRangePicker 支持选择时间，那么当前实现可能会导致用户选择的时间失效。
   * 从而导致问题： https://jira.shundaojia.com/browse/WF-221
   *
   * MyRangePicker 应该考虑被废弃。
   * 由于某些地方使用了 MyRangePick，且不需要选择时间，此时仍可使用该 trick
   * 因此，如果组件允许选择具体时间时，不保留该行为。
   *
   */
  handleCalendarChange = (moments, dateStrings, info) => {
    const { range } = info;
    const { onCalendarChange, showTime } = this.props;
    const [newStart, newEnd] = moments || [];
    const { start, end } = this.state;

    if (!showTime) {
      if (range === 'start' && !start) {
        newStart && newStart.startOf('day');
      } else if (range === 'end' && !end) {
        newEnd && newEnd.startOf('day');
      }
    }

    onCalendarChange && onCalendarChange(moments, dateStrings, info);
  };

  triggerChange = newVals => {
    const { onChange } = this.props;
    onChange && onChange(newVals);
  };

  render() {
    const { onChange, onCalendarChange, ...otherProps } = this.props;
    return (
      <RangePicker
        {...otherProps}
        onChange={this.handleRangePickerChange}
        onCalendarChange={this.handleCalendarChange}
      />
    );
  }
}
