/**
 * Created by qiuyuzhou on 2017/6/7.
 */
import { Component } from 'react';
import _ from 'lodash';

import LocalDateTime from '../components/LocalDateTime';
import Config from '../config';
import { FullScreenPanel } from '../layouts/BasicLayout';
import './Home.module.less';

export default function Home(props) {
  const commitRefName = process.env.REACT_APP_COMMIT_REF_NAME
    ? process.env.REACT_APP_COMMIT_REF_NAME
    : 'Development';
  const commitSHA =
    process.env.REACT_APP_COMMIT_SHA &&
    process.env.REACT_APP_COMMIT_SHA.slice(0, 8);
  return (
    <FullScreenPanel>
      <div styleName="jumbotron">
        <h1 styleName="display-4">顺道出行管理后台</h1>
        <p styleName="lead">
          {commitRefName}
          {commitSHA && `.${commitSHA}`}
        </p>
        {process.env.NODE_ENV === 'production' &&
          Config.deploymentEnv !== 'PROD' && <GitMessage />}
      </div>
    </FullScreenPanel>
  );
}

class GitMessage extends Component {
  state = {
    commits: [],
  };

  componentDidMount() {
    if (process.env.NODE_ENV === 'production') {
      getCommitMessage().then(data => {
        this.setState({
          commits: data,
        });
      });
    }
  }

  render() {
    const { commits } = this.state;
    return (
      <ul style={{ listStyleType: 'disc' }}>
        {commits.map((item, index) => (
          <li key={index} style={{ padding: 5 }}>
            <h3>{_.unescape(item.title)}</h3>
            <h4>
              {/* 使用 new Date 把 git log 的 date 转换成 moment 可识别的格式 
                e.g. "Thu Jan 7 15:35:14 2021 +0800" -> Thu Jan 07 2021 15:35:14 GMT+0800 (中国标准时间)
              */}
              {item.author} -- <LocalDateTime date={new Date(item.date)} />
            </h4>
            <br />
            <pre style={{ whiteSpace: 'pre-line' }}>{item.message}</pre>
          </li>
        ))}
      </ul>
    );
  }
}

function getCommitMessage() {
  return fetch('/commits.json', {
    method: 'get',
    headers: {},
  }).then(response => {
    if (response.ok && !response.redirected) {
      return response.json().catch(e => {
        // 本地开发时，如果没有commits.json文件（缺省没有），服务器会返回首页html的内容。
        // 因此如果json解析失败表示，commits.json文件不存在
        return Promise.reject(response);
      });
    } else {
      return Promise.reject(response);
    }
  });
}
