import { Children, isValidElement, PureComponent } from 'react';
import classNames from 'classnames';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import { Row, Col, Button, Space } from 'antd';

import LinkButton from '../LinkButton';
import AdvancedSearchFormPanel from './AdvancedSearchFormPanel';
import styles from './AdvancedSearchForm.module.less';

const FormItem = Form.Item;

let desertedaWarnning = true;

class AdvancedSearchForm extends PureComponent {
  static defaultProps = {
    collapsesize: 3,
  };

  state = {
    expand: false,
  };

  componentDidMount() {
    if (desertedaWarnning) {
      // eslint-disable-next-line no-console
      console.warn(
        '`AdvancedSearchForm` only support `Form` of Antd v3. Should migrate `Form` to v4 of Ant-Design and replace `AdvancedSearchForm` by `SmartTableSearchForm` as soon as possible,',
      );
      desertedaWarnning = false;
    }
  }

  handleSearch = e => {
    const { onSubmit, form } = this.props;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        onSubmit && onSubmit(values);
      }
    });
  };

  handleFormReset = () => {
    const { onReset } = this.props;
    onReset && onReset();
  };

  toggleCollapse = () => {
    this.setState(({ expand }) => ({ expand: !expand }));
  };

  render() {
    const { collapsesize, children, className, form, loading, ...otherProps } =
      this.props;
    const { expand } = this.state;
    const childTotal = Children.count(children);
    return (
      <AdvancedSearchFormPanel>
        <Form
          {...otherProps}
          loading={loading}
          onSubmit={this.handleSearch}
          layout="inline"
          className={classNames(styles['advanced-search-form'], className)}
        >
          <Row gutter={{ md: 8, lg: 16, xl: 24 }} type="flex">
            {Children.map(children, (child, index) => {
              return (
                isValidElement(child) && (
                  <Col
                    md={8}
                    sm={24}
                    key={index}
                    style={{
                      display:
                        expand || index < collapsesize ? 'block' : 'none',
                    }}
                  >
                    {child}
                  </Col>
                )
              );
            })}
          </Row>
          <Row gutter={{ md: 8, lg: 16, xl: 24 }} type="flex" justify="end">
            <Col>
              <FormItem>
                <Space>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    disabled={loading}
                  >
                    查询
                  </Button>
                  <Button
                    loading={loading}
                    disabled={loading}
                    onClick={this.handleFormReset}
                  >
                    重置
                  </Button>
                  {childTotal > collapsesize && (
                    <LinkButton onClick={this.toggleCollapse}>
                      {expand ? (
                        <>
                          收起&nbsp;
                          <UpOutlined />
                        </>
                      ) : (
                        <>
                          展开&nbsp;
                          <DownOutlined />
                        </>
                      )}
                    </LinkButton>
                  )}
                </Space>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </AdvancedSearchFormPanel>
    );
  }
}

export default AdvancedSearchForm;
