import { lazy } from 'react';
import { WalletOutlined } from '@ant-design/icons';

import Section from '../../components/Section';
import { authorizedRouteWrapper } from '../../auth/authorization';

export default {
  path: 'asset',
  component: Section,
  name: '运力管理',
  icon: <WalletOutlined />,
  resource_id: '/asset',
  leftNav: true,
  routes: [
    {
      path: 'drivers',
      name: '司机列表',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "AssetDriverListPage" */ '../../views/AssetDriverListPage'
            ),
        ),
      ),
      resource_id: '/asset/drivers',
      exact: true,
      leftNav: true,
    },
    {
      path: 'drivers/:id',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "AssetDriverDetailsPage" */ '../../views/AssetDriverDetailsPage'
            ),
        ),
      ),
      resource_id: '/asset/drivers/:id',
      exact: true,
      leftNav: false,
    },
    {
      path: 'vehicles',
      name: '车辆列表',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "AssetVehicleListPage" */ '../../views/AssetVehicleListPage'
            ),
        ),
      ),
      resource_id: '/asset/vehicles',
      exact: true,
      leftNav: true,
    },
    {
      path: 'vehicle-switches',
      name: '车辆更换审核',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "AssetVehicleSwitchListPage" */ '../../views/AssetVehicleSwitchListPage'
            ),
        ),
      ),
      resource_id: '/asset/vehicle-switches',
      exact: true,
      leftNav: true,
    },
    {
      path: 'vehicle-switches/:id',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "AssetVehicleSwitchDetailsPage" */ '../../views/AssetVehicleSwitchDetailsPage'
            ),
        ),
      ),
      resource_id: '/asset/vehicle-switches/:id',
      exact: true,
    },
    {
      path: 'paper-updates',
      name: '资料更新审核',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "AssetPaperUpdateListPage" */ '../../views/AssetPaperUpdateListPage'
            ),
        ),
      ),
      resource_id: '/asset/paper-updates',
      exact: true,
      leftNav: true,
    },

    {
      path: 'paper-updates/:id',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "AssetPaperUpdateDetailsPage" */ '../../views/AssetPaperUpdateDetailsPage'
            ),
        ),
      ),
      resource_id: '/asset/paper-updates/:id',
      exact: true,
    },
    {
      path: 'vehicles/:id',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "AssetVehicleDetailsPage" */ '../../views/AssetVehicleDetailsPage'
            ),
        ),
      ),
      resource_id: '/asset/vehicles/:id',
      exact: true,
      leftNav: false,
    },
    {
      path: 'integrity-rules',
      name: '资料完整性规则',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "AssetIntegrityRuleListPage" */ '../../views/AssetIntegrityRuleListPage'
            ),
        ),
      ),
      resource_id: '/asset/integrity-rules',
      exact: true,
      leftNav: true,
    },
    {
      path: 'integrity-rules/:id',
      breadcrumbName: () => '详情',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "AssetIntegrityRuleDetailsPage" */ '../../views/AssetIntegrityRuleDetailsPage'
            ),
        ),
      ),
      resource_id: '/asset/integrity-rules/:id',
      exact: true,
      leftNav: false,
    },
    {
      path: 'integrity-rules/:id/editing',
      breadcrumbName: () => '编辑',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "AssetIntegrityRuleEditingPage" */ '../../views/AssetIntegrityRuleEditingPage'
            ),
        ),
      ),
      resource_id: '/asset/integrity-rules/:id/editing',
      exact: true,
      leftNav: false,
    },
    {
      path: 'driver-certificates-query',
      name: '司机证件状态查询',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "CertificatesQueryPage" */ '../../views/DriverCertificatesQueryPage'
            ),
        ),
      ),
      resource_id: '/asset/driver-certificates-query',
      exact: true,
      leftNav: true,
    },
    {
      path: 'vehicle-certificates-query',
      name: '车辆证件状态查询',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "VehicleCertificatesQueryPage" */ '../../views/VehicleCertificatesQueryPage'
            ),
        ),
      ),
      resource_id: '/asset/vehicle-certificates-query',
      exact: true,
      leftNav: true,
    },
  ],
};
