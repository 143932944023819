import _ from 'lodash';

import RolePermissionMapping from './permission.config';
import createPermissionChecker from './createPermissionChecker';
import { transformPermissionRegExp } from './utils';

export const permitMatcher = (policy, permissionKey) =>
  _.startsWith(policy, permissionKey) ||
  transformPermissionRegExp(policy).test(permissionKey);

export const denyMatcher = (policy, permissionKey) =>
  _.startsWith(permissionKey, _.trimEnd(policy, '_*')) ||
  transformPermissionRegExp(policy).test(permissionKey);

const { hasPermission } = createPermissionChecker(
  RolePermissionMapping,
  permitMatcher,
  denyMatcher,
);

export const hasRoutePermission = hasPermission;
/**
 * 路由是否有权限
 * @param {Route} route
 * @param {Array<string>} roles
 */
function isAuthorizedRoute(route, roles = []) {
  if (_.isEmpty(roles)) {
    return false;
  }

  const { resource_id } = route; // TODO resource key 生成规则调整

  if (_.isString(resource_id)) {
    // 所有角色均不包含该权限返回 false
    return hasRoutePermission(roles, resource_id);
  } else if (Array.isArray(resource_id)) {
    // authorize 为数组时 取 and 模式
    // 所有角色均不包含该角色 返回 false
    return _.every(resource_id, rId => hasRoutePermission(roles, rId));
  }

  return true; // 未配置 resource_id 时
}

export default isAuthorizedRoute;
