import { lazy } from 'react';

import Section from '../../components/Section';
import { authorizedRouteWrapper } from '../../auth/authorization';

export default {
  path: 'carpool',
  name: '拼车',
  icon: 'sd-ride-o',
  component: Section,
  resource_id: 'carpool',
  leftNav: true,
  routes: [
    {
      path: 'scheduling',
      name: '调度中心',
      resource_id: 'carpool_scheduling',
      exact: true,
      leftNav: true,
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "Scheduling" */ '../../views/scheduling/Scheduling'
            ),
        ),
      ),
    },
    {
      path: 'intercity_ride_groups',
      name: '预约拼车订单组',
      resource_id: 'carpool_intercityRideGroups',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "IntercityRideGrounps" */ '../../views/intercityRideGroups'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'intercity_rides',
      name: '预约拼车订单',
      resource_id: 'carpool_intercityRides',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "IntercityRideListPage" */ '../../views/IntercityRideListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'zones',
      name: '站点',
      resource_id: '/carpool/zones',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "CarpoolZoneListPage" */ '../../views/CarpoolZoneListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'routes',
      name: '线路',
      resource_id: 'carpool_routes',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "RouteListPage" */ '../../views/RouteListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'penalty-rules',
      name: '轻度违约罚款规则',
      resource_id: '/carpool/penalty-rules',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "PenaltyRuleListPage" */ '../../views/PenaltyRuleListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'zones/:zoneId/:aliasId/:recommendationId',
      resource_id: '/carpool/zones/:zoneId/:aliasId/:recommendationId',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "CarpoolZoneRecommendationDetailPage" */ '../../views/CarpoolZoneRecommendationDetailPage'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'zones/:zoneId/:aliasId',
      resource_id: '/carpool/zones/:zoneId/:aliasId',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "CarpoolZoneAliasDetailPage" */ '../../views/CarpoolZoneAliasDetailPage'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'zones/:id',
      resource_id: '/carpool/zones/:id',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "CarpoolZoneDetailPage" */ '../../views/CarpoolZoneDetailPage'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'routes/:id',
      resource_id: 'carpool_routes_view_details',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "RouteDetailsPage" */ '../../views/RouteDetailsPage'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'routes/:routeId/driver_groups/:id',
      name: '线路',
      resource_id: 'carpool_routes_view_driverGroupDetails',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "RouteDriverGroupDetails" */ '../../views/RouteDetailsPage/RouteDriverGroupDetails'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'intercity_rides/:id',
      resource_id: 'carpool_intercityRides_view_details',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "IntercityRideDetailsPage" */ '../../views/IntercityRideDetailsPage'
            ),
        ),
      ),
      exact: true,
    },
  ],
};
