/**
 * Created by qiuyuzhou on 2017/5/7.
 */
import { combineReducers } from 'redux';
import _ from 'lodash';
import {
  SIGNED_IN,
  SIGNED_OUT,
  FETCH_VIEWER,
  FETCH_VIEWER_FULFILLED,
  FETCH_VIEWER_FAILED,
  GENERATE_WEB_HOOK,
  // REFRESH_TOKENS_FULFILLED,
} from '../actions';

const DEFAULT_VIEWER_STATE = { entry: null, error: null, fetching: false };

const viewerReducer = (state = DEFAULT_VIEWER_STATE, action) => {
  if (action.type === SIGNED_IN || action.type === SIGNED_OUT) {
    return DEFAULT_VIEWER_STATE;
  }

  if (action.type === FETCH_VIEWER) {
    return {
      ...state,
      fetching: true,
    };
  }
  if (action.type === FETCH_VIEWER_FULFILLED) {
    return {
      ...state,
      fetching: false,
      error: null,
      entry: action.payload,
    };
  }
  if (action.type === FETCH_VIEWER_FAILED) {
    return {
      ...state,
      fetching: false,
      error: action.payload.message,
    };
  }

  if (action.type === GENERATE_WEB_HOOK) {
    return _.merge(state, {
      entry: {
        webhook_token: action.payload,
      },
    });
  }
  return state;
};

// const tokensReducer = (state = null, action) => {
//   switch (action.type) {
//     case SIGNED_OUT:
//       return null;
//     case SIGNED_IN:
//     case REFRESH_TOKENS_FULFILLED:
//       return action.payload;
//     default:
//       return state;
//   }
// };

export default combineReducers({
  viewer: viewerReducer,
  // tokens: tokensReducer,
});
