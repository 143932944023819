import PropTypes from 'prop-types';
import { Form, Input, Switch, InputNumber } from 'antd';

import { getFormRequiredValidator } from '../../utils/formFieldValidator';

const FORM_LAYOUT = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const WithdrawalConfigWechatPayEditForm = ({
  initialValues = {},
  form,
  onFinish,
  onlyShowAmount = false,
}) => {
  const comparedValidator = ({ getFieldValue }) => ({
    validator(rule, value) {
      if (value < getFieldValue('minAmount')) {
        return Promise.reject(new Error('最大提现额度不能小于最小提现额度'));
      }
      return Promise.resolve();
    },
  });

  return (
    <Form
      {...FORM_LAYOUT}
      form={form}
      initialValues={initialValues}
      onFinish={onFinish}
    >
      {!onlyShowAmount && (
        <>
          <Form.Item label="启用状态" name="isActive" valuePropName="checked">
            <Switch />
          </Form.Item>
          <Form.Item
            label="自动转账"
            name="isAutoProcess"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item label="提现标题" name="title">
            <Input />
          </Form.Item>
        </>
      )}

      <Form.Item
        label="最小提现额度(元)"
        name="minAmount"
        rules={[getFormRequiredValidator('请输入最小提现额度', 'number')]}
      >
        <InputNumber min={0} precision={2} />
      </Form.Item>
      <Form.Item
        label="最大提现额度(元)"
        name="maxAmount"
        rules={[
          getFormRequiredValidator('请输入最大提现额度', 'number'),
          comparedValidator,
        ]}
      >
        <InputNumber min={0} precision={2} />
      </Form.Item>
    </Form>
  );
};

WithdrawalConfigWechatPayEditForm.propTypes = {
  initialValues: PropTypes.shape({
    isActive: PropTypes.bool,
    isAutoProcess: PropTypes.bool,
    title: PropTypes.string,
    minAmount: PropTypes.number,
    maxAmount: PropTypes.number,
  }),
  onFinish: PropTypes.func,
  onlyShowAmount: PropTypes.bool,
};

export default WithdrawalConfigWechatPayEditForm;
