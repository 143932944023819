/**
 * Created by qiuyuzhou on 2017/5/20.
 */
import PropTypes from 'prop-types';
import { Badge } from 'antd';

const defaultProps = {
  errorText: '已禁用',
  successText: '已启用',
};

const ActiveState = ({ isActive, successText, errorText }) => {
  return isActive ? (
    <Badge status="success" text={successText} />
  ) : (
    <Badge status="error" text={errorText} />
  );
};

ActiveState.propTypes = {
  isActive: PropTypes.bool.isRequired,
  errorText: PropTypes.string,
};
ActiveState.defaultProps = defaultProps;

export default ActiveState;
