import { useRef } from 'react';
import _ from 'lodash';

const useMemorizedObject = obj => {
  const preVariables = useRef(obj);
  if (!_.isEqual(preVariables.current, obj)) {
    preVariables.current = obj;
  }
  return preVariables.current;
};

export default useMemorizedObject;
