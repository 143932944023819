/**
 * Created by qiuyuzhou on 2017/5/6.
 */
import _ from 'lodash';

import { safelyJSONParse } from './utils/transformUtils';

const ENV_PREFIX = 'REACT_APP_';

function init() {
  const injectConfig = safelyJSONParse(
    global.document.getElementById('__SD_DATA__')?.textContent,
  );

  // Get current deployment environment.
  const deploymentEnv = _.upperCase(
    injectConfig?.ENV ?? process.env[`${ENV_PREFIX}DEPLOYMENT_ENV`],
  );

  const getter = key => {
    return injectConfig?.[key] ?? process.env[`${ENV_PREFIX}${key}`];
  };

  return {
    deploymentEnv: deploymentEnv,
    serverURL: getter('SERVER_URL'),
    wsServerURL: getter('WS_SERVER_URL'),
    clientID: getter('CLIENT_ID'),
    hawkEyeServiceID: getter('HAWK_EYE_SERVICE_ID'),
    amapJsApiUrl: getter('AMAP_JS_API_URL'),
    amapWebServiceKey: getter('AMAP_WEB_SERVICE_KEY'),
    amapJsApiKey: getter('AMAP_JS_API_KEY'),
    BaiduMapAK: getter('BAIDU_MAP_AK'),
    PromotionBaseUrl: getter('PROMOTION_BASE_URL'),
    sentryDSN: getter('SENTRY_DSN'),
    keycloakServerURL: getter('KEYCLOAK_SERVER_URL'),
    keycloakRealm: getter('KEYCLOAK_REALM'),
    keycloakClientId: getter('KEYCLOAK_CLIENT_ID'),
    keycloakClientSecret: getter('KEYCLOAK_CLIENT_SECRET'),
    keycloakIdOfClient: getter('KEYCLOAK_ID_OF_CLIENT'),
    jiraDidiWorkOrderUrl: getter('JIRA_DIDI_WORK_ORDER_URL'),
  };
}

const Config = init();

export default Config;
