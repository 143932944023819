import { Form, Input } from 'antd';

import {
  getPasswdValidator,
  createCustomValidator,
} from '../../utils/formFieldValidator';

const StaffUpdateForm = props => {
  const confirmPasswordRules = [
    {
      required: true,
      message: '请再次输入密码',
    },
    createCustomValidator(value => {
      const { getFieldValue } = props.form;
      if (value === getFieldValue('password')) {
        return true;
      }
    }, '两次密码输入不一致！'),
  ];

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    },
  };

  return (
    <Form {...formItemLayout} {...props}>
      <Form.Item
        label="新密码"
        name="password"
        rules={[
          {
            required: true,
            message: '请输入新密码',
          },
          getPasswdValidator(),
        ]}
      >
        <Input type="password" />
      </Form.Item>
      <Form.Item
        label="确认密码"
        name="confirmPassword"
        rules={confirmPasswordRules}
      >
        <Input type="password" />
      </Form.Item>
    </Form>
  );
};

export default StaffUpdateForm;
