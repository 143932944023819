import moment from 'moment';
import _ from 'lodash';

export function isMoment(val) {
  return _.isObject(val) && moment.isMoment(val);
}

export function getValueType(value) {
  if (isMoment(value)) {
    return 'Date';
  }

  if (_.isArray(value)) {
    for (let val of value) {
      if (!isMoment(val)) {
        return 'Array';
      }
    }
    return 'Dates';
  }

  return typeof value;
}

export function transformFiltersValForLocationState(filters) {
  if (_.isEmpty(filters)) {
    return filters;
  }
  function transformVal(value, valueType) {
    if (valueType === 'Dates') {
      return _.map(value, item => item.format());
    }
    if (valueType === 'Date') {
      return value.format();
    }
    return value;
  }
  return _.mapValues(filters, (filters, key) => {
    const valueType = getValueType(filters.value);
    return {
      ...filters,
      valueType,
      value: transformVal(filters.value, valueType),
    };
  });
}

export function transformValueForForm(filterValue, valueType) {
  if (!_.isNil(filterValue) && valueType === 'Dates') {
    return _.map(filterValue, item => moment(item));
  }
  if (!_.isNil(filterValue) && valueType === 'Date') {
    return moment(filterValue);
  }
  if (filterValue === null) {
    return undefined;
  }

  return filterValue;
}

export function transformFilterValuesForForm(filters) {
  return _.mapValues(filters, ({ value, valueType }, key) =>
    transformValueForForm(value, valueType),
  );
}

export function filterValuesToFormFieldData(filters, selector) {
  const fieldsVals = _.assign(transformFilterValuesForForm(filters), selector);
  return _.map(fieldsVals, (value, key) => ({ name: key, value: value }));
}
