/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateCompanyInput = {|
  clientMutationId?: ?string,
  id: string,
  name?: ?string,
  province?: ?string,
  city?: ?string,
  phone_number?: ?string,
|};
export type TaxiCompanyDetailsUpdateBaseInfoMutationVariables = {|
  input: UpdateCompanyInput
|};
export type TaxiCompanyDetailsUpdateBaseInfoMutationResponse = {|
  +updateCompany: ?{|
    +error: ?{|
      +errors: $ReadOnlyArray<?string>
    |},
    +company: ?{|
      +id: string,
      +city: ?string,
      +name: ?string,
      +province: ?string,
      +phone_number: ?string,
    |},
  |}
|};
export type TaxiCompanyDetailsUpdateBaseInfoMutation = {|
  variables: TaxiCompanyDetailsUpdateBaseInfoMutationVariables,
  response: TaxiCompanyDetailsUpdateBaseInfoMutationResponse,
|};
*/


/*
mutation TaxiCompanyDetailsUpdateBaseInfoMutation(
  $input: UpdateCompanyInput!
) {
  updateCompany(input: $input) {
    error {
      errors
    }
    company {
      id
      city
      name
      province
      phone_number
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateCompanyPayload",
    "kind": "LinkedField",
    "name": "updateCompany",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Error",
        "kind": "LinkedField",
        "name": "error",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "errors",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Company",
        "kind": "LinkedField",
        "name": "company",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "city",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "province",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phone_number",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TaxiCompanyDetailsUpdateBaseInfoMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TaxiCompanyDetailsUpdateBaseInfoMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4894fdad0260f1b1c7d31b02a4d982a0",
    "id": null,
    "metadata": {},
    "name": "TaxiCompanyDetailsUpdateBaseInfoMutation",
    "operationKind": "mutation",
    "text": "mutation TaxiCompanyDetailsUpdateBaseInfoMutation(\n  $input: UpdateCompanyInput!\n) {\n  updateCompany(input: $input) {\n    error {\n      errors\n    }\n    company {\n      id\n      city\n      name\n      province\n      phone_number\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cf3d5f4020cc955c8035c9a147f6b626';

module.exports = node;
