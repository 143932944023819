import { graphql, useFragment } from 'react-relay/hooks';
import { Table } from 'antd';
import BigNumber from 'bignumber.js';

import AmountFormatter from '../../components/AmountFormatter';
import LocalDateTime from '../../components/LocalDateTime';
import { decodeGraphQLGlobalID } from '../../utils/graphQLGlobalID';
import PaymentMethod from '../../components/PaymentMethod';
import { fenToYuan } from '../../utils/transformUtils';
import { fillKeyByDataIndex } from '../../utils/tableColumnHelper';

const fragment = graphql`
  fragment TaxiOrderPaymentList_data on Taxi @relay(plural: true) {
    payments {
      id
      paymentMethod: payment_method
      amount
      state
      promotionAmount: promotion_amount
      rideBalance: ride_balance
    }
  }
`;

const methodRender = value => <PaymentMethod method={value} />;
const amountRender = (value = 0) => (
  <AmountFormatter value={fenToYuan(value ?? 0)} />
);
const realAmountRender = (value, row, index) => {
  const { amount, promotionAmount, rideBalance } = row;
  const realPay = new BigNumber(amount)
    .minus(promotionAmount || 0)
    .minus(rideBalance || 0)
    .dividedBy(100)
    .toNumber();
  return <AmountFormatter value={realPay} />;
};
const stateRender = value => {
  const paymentStateNames = {
    checkout: 'checkout',
    void: 'void',
    failure: '失败',
    processing: '处理中',
    completed: '完成',
  };
  return paymentStateNames[value];
};

const timeRender = value => {
  return <LocalDateTime date={value} />;
};

const TaxiOrderPaymentList = ({ payments }) => {
  const data = useFragment(fragment, payments);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 50,
      render: decodeGraphQLGlobalID,
    },
    {
      title: '支付方式',
      dataIndex: 'paymentMethod',
      width: 80,
      render: methodRender,
    },
    {
      title: '订单金额',
      dataIndex: 'amount',
      width: 80,
      render: amountRender,
    },
    {
      title: '券抵扣',
      width: 80,
      dataIndex: 'promotionAmount',
      render: amountRender,
    },
    {
      title: '余额抵扣',
      dataIndex: 'rideBalance',
      width: 80,
      render: amountRender,
    },
    {
      title: '应付金额',
      dataIndex: 'real_amount',
      width: 80,
      render: realAmountRender,
    },
    {
      title: '状态',
      dataIndex: 'state',
      width: 60,
      render: stateRender,
    },
    {
      title: '时间',
      dataIndex: 'updatedAt',
      render: timeRender,
    },
  ];

  const finalColumns = fillKeyByDataIndex(columns);
  return (
    <Table
      columns={finalColumns}
      dataSource={data}
      rowKey="id"
      pagination={false}
      size="small"
    />
  );
};

export default TaxiOrderPaymentList;
