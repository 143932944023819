/**
 * Created by qiuyuzhou on 2017/5/8.
 */

import { createSelector } from 'reselect';

// export const tokensRowSelector = state => _.get(state, 'account.tokens', null);

export const viewerRawSelector = state => state?.account?.viewer;

export const viewerSelector = createSelector(
  viewerRawSelector,
  viewerRaw => viewerRaw?.entry ?? null,
);

export const viewerRolesSelector = createSelector(
  viewerSelector,
  viewer => viewer?.roles,
);

// export const loginedSelector = createSelector(
//   tokensRowSelector,
//   tokens => !_.isEmpty(tokens)
// );

export const viewerOperationCenterSelector = createSelector(
  viewerSelector,
  viewer => viewer?.operationCenter,
);

export const viewerCapacitySupplierSelector = createSelector(
  viewerSelector,
  viewer => viewer?.capacitySupplier,
);
