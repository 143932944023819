import { memo } from 'react';
import { Cascader } from 'antd';

import { provincesAndCities } from './OriginAreaData';

const fieldNames = { label: 'name', value: 'code' };

function CitySelect(props) {
  return (
    <Cascader
      expandTrigger="hover"
      placeholder="请选择城市"
      showSearch={true}
      {...props}
      options={provincesAndCities}
      fieldNames={fieldNames}
    />
  );
}

CitySelect.propTypes = {
  ...Cascader.propTypes,
};

export default memo(CitySelect);
