import {
  defaultIntegrations,
  init,
  // reactRouterV5Instrumentation,
} from '@sentry/react';
// import { matchPath } from 'react-router-dom';
// import { Integrations } from '@sentry/tracing';
import Config from './config';
// import history from './history';
// import routes from './common/router';

// 临时性解决 antd 导致的 `ResizeObserver loop limit exceeded` 错误上报过多的问题
class EventRateLimiter {
  _recorder;
  _report_interval;

  /***
   * @param {function} eventMatcher
   * @param {number} [reportInterval=60]
   */
  constructor(eventMatcher, reportInterval = 60) {
    this.eventMatcher = eventMatcher;
    this._report_interval = reportInterval;
  }

  // 记录每个 url 的上次上报时间
  get recorder() {
    // lazy init
    if (!this._recorder) {
      this._recorder = new Map();
    }
    return this._recorder;
  }

  // 记录每个 url 的上次上报时间
  get reportInterval() {
    return this._report_interval;
  }

  isWatchingEvent = (event, hint) => {
    return this.eventMatcher(event, hint);
  };

  shouldReport = event => {
    const url = event?.request?.url;
    const newTime = event?.timestamp;
    // 该 URL 未上报过
    if (!this.recorder.has(url)) {
      this.recorder.set(url, newTime);
      return true;
    }

    const lastReportTime = this.recorder.get(url);

    // 距离上次上报大于 60s 需要上报
    if (newTime - lastReportTime > this.reportInterval) {
      this.recorder.set(url, newTime);
      return true;
    }

    return false;
  };
}

const eventRateLimiters = [
  new EventRateLimiter(
    // hint.originalException 可能为 null
    event =>
      !!event?.exception?.values?.some(({ value }) =>
        value.includes('ResizeObserver loop limit exceeded'),
      ),
  ),
];

const initSentry = () => {
  const commitSHA =
    process.env.REACT_APP_COMMIT_SHA &&
    process.env.REACT_APP_COMMIT_SHA.slice(0, 8);
  init({
    dsn: Config.sentryDSN,
    environment: Config.deploymentEnv,
    release: commitSHA,
    defaultIntegrations,
    integrations: [
      // new Integrations.BrowserTracing({
      //   // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      //   routingInstrumentation: reactRouterV5Instrumentation(
      //     history,
      //     routes,
      //     matchPath,
      //   ),
      // }),
    ],
    ignoreErrors: [/Loading (CSS ){0,}chunk .*? failed/],
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/#normalization-depth
    normalizeDepth: 10,

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    // https://docs.sentry.io/platforms/javascript/guides/react/performance/#verify
    // tracesSampleRate: 0.1,

    // 临时性解决 antd 导致的 `ResizeObserver loop limit exceeded` 错误上报过多的问题
    beforeSend(event, hint) {
      const limiter = eventRateLimiters.find(limiter =>
        limiter.isWatchingEvent(event, hint),
      );

      if (limiter && !limiter.shouldReport(event)) {
        // return null 表示不上传
        return null;
      }

      return event;
    },
  });
};

export default initSentry;
