import {
  createNumberRangeValidator,
  validateEMail,
  validateAccount,
  isUseablePhoneNumber,
  validateURL,
  validateBankCardNumber,
  validatePasswd,
  validateIdentity,
} from './validator';
// import OriginAreaData from '@/components/AreaSelect/OriginAreaData';

/**
 * 创建自定义 form item validator
 *
 * @param {function} validateFn
 * @param {string} message
 */
export function createCustomValidator(validateFn, message) {
  return {
    validator(rule, value, callback) {
      if (value !== undefined && value !== null && !validateFn(value)) {
        callback(message);
        return;
      }
      callback();
    },
  };
}

export function getEMailValidator(
  message = '请输入正确的邮箱，例如：guest@shundaojia.com',
) {
  return createCustomValidator(validateEMail, message);
}

function checkSameAsOtherFiled(value, otherFiledName, context) {
  const self = context || this;
  return self.getFieldValue(otherFiledName) === value;
}

/**
 * 获取符合antd filed validator 规则的 函数
 * @param {*} otherFiledName
 * @param {*} message
 * @param {*} context 上下文
 */
export function getSameAsOtherFiledValidator(
  otherFiledName,
  message = `not same as ${otherFiledName}`,
  context,
) {
  const validateValue = value =>
    checkSameAsOtherFiled.call(this, value, otherFiledName, context);

  return createCustomValidator(validateValue, message);
}

/**
 * 获取 form Field item 校验器
 * @param {*} message 返回的校验失败返回的消息
 * @param {*} type 内建校验类型
 * @param {*} transform 校验前转换字段值
 */
export function getFormRequiredValidator(message, type, transform) {
  return {
    required: true,
    message,
    type,
    transform,
  };
}

export function getPhoneNumberValidator(message = '手机号格式不正确') {
  return createCustomValidator(isUseablePhoneNumber, message);
}

export function maxValidator(max = 10000, message = `最大值：${max}`) {
  const validateValue = value => parseFloat(value, 10) <= max;
  return createCustomValidator(validateValue, message);
}

export function getNumberRangeValidator(
  min,
  max,
  message = '错误的范围',
  equalMin = false,
  equalMax = false,
) {
  const validateRange = createNumberRangeValidator(
    min,
    max,
    equalMin,
    equalMax,
  );
  return createCustomValidator(validateRange, message);
}

/**
 * 获取输入长度校验器
 * @param {number} max
 * @param {string} message
 * @param {string} type
 */
export function getMaxValidator(max, message, type) {
  let _message;
  if (!message) {
    if (type === 'number') {
      _message = `不能大于${max}`;
    } else {
      _message = `长度不能大于${max}`;
    }
  } else {
    _message = message.replace(/\[max\]/g, max);
  }
  return {
    max,
    message: _message,
    type,
  };
}

export function getBankCardValidator(message = '请输入正确的银行卡号') {
  return createCustomValidator(validateBankCardNumber, message);
}

export function getPasswdValidator(
  message = '密码为8-30位，必须同时包含数字，大小写字母',
) {
  return createCustomValidator(validatePasswd, message);
}

export function getUrlValidator(
  message = '请输入正确的url链接.例如：https://www.shundaojia.com',
) {
  return createCustomValidator(validateURL, message);
}

export function getAccountValidator(
  message = '4-20位字符，字母开头，只能包含数字、字母、下划线 “_”',
) {
  return createCustomValidator(validateAccount, message);
}

export function getIdentityValidator(message = '身份证格号式有误') {
  return createCustomValidator(validateIdentity, message);
}
