import { useState, useCallback } from 'react';
import { Alert } from 'antd';

import LinkButton from '../LinkButton';

const ErrorInfo = ({ error }) => {
  const [showStack, toggleShowStack] = useState(false);
  const message = error?.message || '未知错误';

  const toggleViewStackBtnClick = useCallback(() => {
    toggleShowStack(showStack => !showStack);
  }, []);

  return (
    <Alert
      type="error"
      message={message}
      description={showStack && <pre>{error.stack.toString()}</pre>}
      action={
        <LinkButton onClick={toggleViewStackBtnClick}>
          {showStack ? '收起' : '查看详情'}
        </LinkButton>
      }
      showIcon
    />
  );
};

export default ErrorInfo;
