import _ from 'lodash';

const formatQueryKey = field => {
  return _.snakeCase(field);
};

const formatQueryOperation = op => {
  return _.toLower(op);
};

/**
 * More doc see https://github.com/activerecord-hackery/ransack#search-matchers
 */
export function formatQ(state, default_q = {}) {
  if (!state) {
    return;
  }
  const { sorter, filters } = state;
  const newQ = {};

  const formatKeyAndOp = filters =>
    _.map(filters, ([key, { _op, ...others }]) => [
      formatQueryKey(key),
      {
        ...others,
        _op: formatQueryOperation(_op),
      },
    ]);

  const ignoreEmptyFilter = filters =>
    _.filter(
      filters,
      ([, { _op, value }]) =>
        !(_.isNil(value) || (_op === 'in' && _.isEmpty(value))),
    );
  const flatMapToQ = filters =>
    _.flatMap(filters, item => {
      const [key, { _op, value }] = item;

      // closed-closed-interval => [start, end]
      if (_op === 'range' || _op === 'closed-closed-interval') {
        return [
          [`${key}_gteq`, value[0]],
          [`${key}_lteq`, value[1]],
        ];
      }

      // closed-open-interval => [start, end)
      if (_op === 'closed-open-interval') {
        return [
          [`${key}_gteq`, value[0]],
          [`${key}_lt`, value[1]],
        ];
      }

      return [[`${key}_${_op}`, value]];
    });

  const filtersQ = _.flow([
    _.toPairs,
    formatKeyAndOp,
    ignoreEmptyFilter,
    flatMapToQ,
    _.fromPairs,
  ])(filters);

  _.merge(newQ, default_q, filtersQ);

  if (!_.isEmpty(sorter)) {
    _.merge(newQ, {
      s: formatSorter(sorter),
    });
  }

  return newQ;
}

function formatSorter(sorter) {
  if (_.isEmpty(sorter)) return null;

  const orderMapping = {
    descend: 'desc',
    ascend: 'asc',
  };

  return `${formatQueryKey(sorter.field)} ${orderMapping[sorter.order]}`;
}

export default formatQ;
