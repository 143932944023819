import { useCallback } from 'react';

import usePromiseMutation from './usePromiseMutation';

import { checkMutationPayload } from '../utils/checkMutationPayload';

export const checkMutations = response => {
  for (let mutationName in response) {
    checkMutationPayload(response[mutationName]);
  }
  return response;
};

const useSDMutation = (fragmentSpec, checkResponse = checkMutations) => {
  const [commit, ...rest] = usePromiseMutation(fragmentSpec);
  const commitMutation = useCallback(
    async config => {
      const response = await commit(config);
      checkResponse(response);
      return response;
    },
    [checkResponse, commit],
  );
  return [commitMutation, ...rest];
};

export default useSDMutation;
