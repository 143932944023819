export default // Generated automatically by nearley, version unknown
// http://github.com/Hardmath123/nearley
(function () {
function id(x) { return x[0]; }

    const opMapping = {
        "=": "In",
        "==": "In",
        "in": "In",
        "!=": "NotIn",
        "notin": "NotIn",
    }
var grammar = {
    Lexer: undefined,
    ParserRules: [
    {"name": "expressions$ebnf$1", "symbols": []},
    {"name": "expressions$ebnf$1", "symbols": ["expressions$ebnf$1", "expressionTrailer"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "expressions", "symbols": ["expression", "expressions$ebnf$1"], "postprocess": ([e, t]) => [e].concat(t)},
    {"name": "expressionTrailer", "symbols": ["_", {"literal":","}, "_", "expression"], "postprocess": (v) => v[3]},
    {"name": "expression$subexpression$1", "symbols": ["exist"]},
    {"name": "expression$subexpression$1", "symbols": ["doesNotExist"]},
    {"name": "expression$subexpression$1$macrocall$2$string$1", "symbols": [{"literal":"i"}, {"literal":"n"}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "expression$subexpression$1$macrocall$2", "symbols": ["expression$subexpression$1$macrocall$2$string$1"]},
    {"name": "expression$subexpression$1$macrocall$1", "symbols": ["label", "__", "expression$subexpression$1$macrocall$2", "__", "set"], "postprocess": 
        ([l, w1, op, w2, r]) => {
            return {
                key: l,
                operator: opMapping[op],
                values: r
            }
        }
        },
    {"name": "expression$subexpression$1", "symbols": ["expression$subexpression$1$macrocall$1"]},
    {"name": "expression$subexpression$1$macrocall$4$string$1", "symbols": [{"literal":"n"}, {"literal":"o"}, {"literal":"t"}, {"literal":"i"}, {"literal":"n"}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "expression$subexpression$1$macrocall$4", "symbols": ["expression$subexpression$1$macrocall$4$string$1"]},
    {"name": "expression$subexpression$1$macrocall$3", "symbols": ["label", "__", "expression$subexpression$1$macrocall$4", "__", "set"], "postprocess": 
        ([l, w1, op, w2, r]) => {
            return {
                key: l,
                operator: opMapping[op],
                values: r
            }
        }
        },
    {"name": "expression$subexpression$1", "symbols": ["expression$subexpression$1$macrocall$3"]},
    {"name": "expression$subexpression$1$macrocall$6$string$1", "symbols": [{"literal":"="}, {"literal":"="}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "expression$subexpression$1$macrocall$6", "symbols": ["expression$subexpression$1$macrocall$6$string$1"]},
    {"name": "expression$subexpression$1$macrocall$5", "symbols": ["label", "_", "expression$subexpression$1$macrocall$6", "_", "value"], "postprocess": 
        ([label, w1, op, w2, value]) => {
            return {
                key: label,
                operator: opMapping[op],
                values: [value]
            }
        }
        },
    {"name": "expression$subexpression$1", "symbols": ["expression$subexpression$1$macrocall$5"]},
    {"name": "expression$subexpression$1$macrocall$8", "symbols": [{"literal":"="}]},
    {"name": "expression$subexpression$1$macrocall$7", "symbols": ["label", "_", "expression$subexpression$1$macrocall$8", "_", "value"], "postprocess": 
        ([label, w1, op, w2, value]) => {
            return {
                key: label,
                operator: opMapping[op],
                values: [value]
            }
        }
        },
    {"name": "expression$subexpression$1", "symbols": ["expression$subexpression$1$macrocall$7"]},
    {"name": "expression$subexpression$1$macrocall$10$string$1", "symbols": [{"literal":"!"}, {"literal":"="}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "expression$subexpression$1$macrocall$10", "symbols": ["expression$subexpression$1$macrocall$10$string$1"]},
    {"name": "expression$subexpression$1$macrocall$9", "symbols": ["label", "_", "expression$subexpression$1$macrocall$10", "_", "value"], "postprocess": 
        ([label, w1, op, w2, value]) => {
            return {
                key: label,
                operator: opMapping[op],
                values: [value]
            }
        }
        },
    {"name": "expression$subexpression$1", "symbols": ["expression$subexpression$1$macrocall$9"]},
    {"name": "expression", "symbols": ["expression$subexpression$1"], "postprocess": (data) => data[0][0]},
    {"name": "exist", "symbols": ["label"], "postprocess": 
        (v) => {
            return {
                key: v[0],
                operator: "Exists"
            }
        }
        },
    {"name": "doesNotExist", "symbols": [{"literal":"!"}, "label"], "postprocess": 
        (v) => {
            return {
                key: v[1],
                operator: "DoesNotExist"
            }
        }
        },
    {"name": "set$ebnf$1", "symbols": []},
    {"name": "set$ebnf$1", "symbols": ["set$ebnf$1", "setTrailer"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "set", "symbols": [{"literal":"("}, "_", "value", "set$ebnf$1", "_", {"literal":")"}], "postprocess": (i) => [i[2]].concat(i[3])},
    {"name": "setTrailer", "symbols": [{"literal":","}, "_", "value"], "postprocess": (v) => v[2]},
    {"name": "eq_op", "symbols": [{"literal":"="}]},
    {"name": "eq_op$string$1", "symbols": [{"literal":"="}, {"literal":"="}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "eq_op", "symbols": ["eq_op$string$1"]},
    {"name": "label_prefix$subexpression$1$ebnf$1$subexpression$1", "symbols": [/[a-z0-9A-Z]/]},
    {"name": "label_prefix$subexpression$1$ebnf$1$subexpression$1", "symbols": [{"literal":"."}]},
    {"name": "label_prefix$subexpression$1$ebnf$1$subexpression$1", "symbols": [{"literal":"-"}]},
    {"name": "label_prefix$subexpression$1$ebnf$1", "symbols": ["label_prefix$subexpression$1$ebnf$1$subexpression$1"]},
    {"name": "label_prefix$subexpression$1$ebnf$1$subexpression$2", "symbols": [/[a-z0-9A-Z]/]},
    {"name": "label_prefix$subexpression$1$ebnf$1$subexpression$2", "symbols": [{"literal":"."}]},
    {"name": "label_prefix$subexpression$1$ebnf$1$subexpression$2", "symbols": [{"literal":"-"}]},
    {"name": "label_prefix$subexpression$1$ebnf$1", "symbols": ["label_prefix$subexpression$1$ebnf$1", "label_prefix$subexpression$1$ebnf$1$subexpression$2"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "label_prefix$subexpression$1", "symbols": ["label_prefix$subexpression$1$ebnf$1"]},
    {"name": "label_prefix", "symbols": ["label_prefix$subexpression$1", {"literal":"/"}], "postprocess": 
        function(data) {
            return data[0][0].join("") + "/"
        }
        },
    {"name": "label_name$ebnf$1$subexpression$1", "symbols": [/[a-z0-9A-Z]/]},
    {"name": "label_name$ebnf$1$subexpression$1", "symbols": [{"literal":"_"}]},
    {"name": "label_name$ebnf$1$subexpression$1", "symbols": [{"literal":"-"}]},
    {"name": "label_name$ebnf$1$subexpression$1", "symbols": [{"literal":"."}]},
    {"name": "label_name$ebnf$1", "symbols": ["label_name$ebnf$1$subexpression$1"]},
    {"name": "label_name$ebnf$1$subexpression$2", "symbols": [/[a-z0-9A-Z]/]},
    {"name": "label_name$ebnf$1$subexpression$2", "symbols": [{"literal":"_"}]},
    {"name": "label_name$ebnf$1$subexpression$2", "symbols": [{"literal":"-"}]},
    {"name": "label_name$ebnf$1$subexpression$2", "symbols": [{"literal":"."}]},
    {"name": "label_name$ebnf$1", "symbols": ["label_name$ebnf$1", "label_name$ebnf$1$subexpression$2"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "label_name", "symbols": ["label_name$ebnf$1"], "postprocess": 
        function(d) {
            return d[0].join('')
        }
        },
    {"name": "label$ebnf$1", "symbols": ["label_prefix"], "postprocess": id},
    {"name": "label$ebnf$1", "symbols": [], "postprocess": function(d) {return null;}},
    {"name": "label", "symbols": ["label$ebnf$1", "label_name"], "postprocess": 
        (data) => {
            return data.join('')
        }
        },
    {"name": "value", "symbols": ["unquoted_value"], "postprocess": (d) => d[0]},
    {"name": "value", "symbols": [{"literal":"\""}, "quoted_str", {"literal":"\""}], "postprocess": (d) => d[1]},
    {"name": "unquoted_value$ebnf$1$subexpression$1", "symbols": [/[a-z0-9A-Z]/]},
    {"name": "unquoted_value$ebnf$1$subexpression$1", "symbols": [{"literal":"_"}]},
    {"name": "unquoted_value$ebnf$1$subexpression$1", "symbols": [{"literal":"-"}]},
    {"name": "unquoted_value$ebnf$1$subexpression$1", "symbols": [{"literal":"."}]},
    {"name": "unquoted_value$ebnf$1$subexpression$1", "symbols": [{"literal":"/"}]},
    {"name": "unquoted_value$ebnf$1", "symbols": ["unquoted_value$ebnf$1$subexpression$1"]},
    {"name": "unquoted_value$ebnf$1$subexpression$2", "symbols": [/[a-z0-9A-Z]/]},
    {"name": "unquoted_value$ebnf$1$subexpression$2", "symbols": [{"literal":"_"}]},
    {"name": "unquoted_value$ebnf$1$subexpression$2", "symbols": [{"literal":"-"}]},
    {"name": "unquoted_value$ebnf$1$subexpression$2", "symbols": [{"literal":"."}]},
    {"name": "unquoted_value$ebnf$1$subexpression$2", "symbols": [{"literal":"/"}]},
    {"name": "unquoted_value$ebnf$1", "symbols": ["unquoted_value$ebnf$1", "unquoted_value$ebnf$1$subexpression$2"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "unquoted_value", "symbols": ["unquoted_value$ebnf$1"], "postprocess": (d) => d[0].join('')},
    {"name": "quoted_str", "symbols": [], "postprocess": () => ""},
    {"name": "quoted_str", "symbols": ["quoted_str", "quoted_str_char"], "postprocess": (d) => d[0].concat(d[1])},
    {"name": "quoted_str_char", "symbols": [/[^"\r\n]/], "postprocess": id},
    {"name": "quoted_str_char", "symbols": [{"literal":"\\"}, {"literal":"\""}], "postprocess": () => "\""},
    {"name": "_$ebnf$1", "symbols": []},
    {"name": "_$ebnf$1", "symbols": ["_$ebnf$1", "wschar"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "_", "symbols": ["_$ebnf$1"], "postprocess": function(d) {return null;}},
    {"name": "__$ebnf$1", "symbols": ["wschar"]},
    {"name": "__$ebnf$1", "symbols": ["__$ebnf$1", "wschar"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "__", "symbols": ["__$ebnf$1"], "postprocess": function(d) {return null;}},
    {"name": "wschar", "symbols": [/[ \t]/], "postprocess": id}
]
  , ParserStart: "expressions"
}
 return grammar ;})();