/**
 * Created by qiuyuzhou on 2017/5/7.
 */

import { createAction } from 'redux-actions';

export const SIGNED_IN = 'SIGNED_IN';
export const SIGNED_OUT = 'SIGNED_OUT';

export const signedIn = createAction(SIGNED_IN);
export const signedOut = createAction(SIGNED_OUT);

export const FETCH_VIEWER = 'FETCH_VIEWER';
export const FETCH_VIEWER_FULFILLED = 'FETCH_VIEWER_FULFILLED';
export const FETCH_VIEWER_FAILED = 'FETCH_VIEWER_FAILED';

export const fetchViewer = createAction(FETCH_VIEWER);
export const fetchViewerFulfilled = createAction(FETCH_VIEWER_FULFILLED);
export const fetchFailed = createAction(FETCH_VIEWER_FAILED);

// export const REFRESH_TOKENS_FULFILLED = 'REFRESH_TOKENS_FULFILLED';
// export const refreshTokenFulfilled = createAction(REFRESH_TOKENS_FULFILLED);

export const GENERATE_WEB_HOOK = 'GENERATE_WEB_HOOK';
export const generateWebHookAction = createAction(GENERATE_WEB_HOOK);

// export const CREATE_CALL_IN_SESSION_ACTION = 'CREATE_CALL_IN_SESSION_ACTION';
// export const RM_CALL_IN_SESSION_ACTION = 'RM_CALL_IN_SESSION_ACTION';
// export const UPDATE_PHONE_LINE_STATUS_ACTION =
//   'UPDATE_PHONE_LINE_STATUS_ACTION';
// export const createCallInSession = createAction(CREATE_CALL_IN_SESSION_ACTION);
// export const rmCallInSession = createAction(RM_CALL_IN_SESSION_ACTION);
// export const updatePhoneLineStatus = createAction(
//   UPDATE_PHONE_LINE_STATUS_ACTION
// );

export const SCHEDULING_CHANGE_SELECTED_ORDER_ACTION =
  'SCHEDULING_CHANGE_SELECTED_ORDER_ACTION';
export const SCHEDULING_CHANGE_SELECTED_ROUTE_ACTION =
  'SCHEDULING_CHANGE_SELECTED_ROUTE_ACTION';
export const SCHEDULING_CHANGE_INTERCITY_RIDE_LIST_STATUS_ACTION =
  'SCHEDULING_CHANGE_INTERCITY_RIDE_LIST_STATUS_ACTION'; // 筛选的虚报拼车订单列表状态
export const SCHEDULING_CHANGE_RIDE_LIST_STATUS_ACTION =
  'SCHEDULING_CHANGE_RIDE_LIST_STATUS_ACTION'; // 筛选的拼车订单列表状态
export const SCHEDULING_CHANGE_EXPRESS_LIST_STATUS_ACTION =
  'SCHEDULING_CHANGE_EXPRESS_LIST_STATUS_ACTION'; // 快车列表状态
export const SCHEDULING_CLEAR_ALL_DATA_ACTION =
  'SCHEDULING_CLEAR_ALL_DATA_ACTION';
export const chgSelectedRouteInScheduling = createAction(
  SCHEDULING_CHANGE_SELECTED_ROUTE_ACTION,
);
export const chgIntercityRideListStatusInScheduling = createAction(
  SCHEDULING_CHANGE_INTERCITY_RIDE_LIST_STATUS_ACTION,
);
export const chgExpressListStatisInScheduling = createAction(
  SCHEDULING_CHANGE_EXPRESS_LIST_STATUS_ACTION,
);
export const clearSchdulingData = createAction(
  SCHEDULING_CLEAR_ALL_DATA_ACTION,
);

export const CHANGE_SIDE_MENU_COLLAPSED = 'CHANGE_SIDE_MENU_COLLAPSED';
export const changeSideMenuCollapsed = createAction(CHANGE_SIDE_MENU_COLLAPSED);
