import passwordValidator from 'password-validator';

const passwordRule = new passwordValidator();

passwordRule
  .is()
  .min(8) // Minimum length 8
  .is()
  .max(20)
  .has()
  .uppercase() // Must have uppercase
  .has()
  .lowercase()
  .has()
  .digits();
// .is().not().oneOf(['Passw0rd', 'Password123'])

export function validatePasswd(value) {
  return passwordRule.validate(value);
}
