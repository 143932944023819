import { Component } from 'react';
import { Input, Select } from 'antd';
import _ from 'lodash';

const Option = Select.Option;

const select_options = [
  { value: '京', label: '京' },
  { value: '津', label: '津' },
  { value: '沪', label: '沪' },
  { value: '渝', label: '渝' },
  { value: '蒙', label: '蒙' },
  { value: '新', label: '新' },
  { value: '藏', label: '藏' },
  { value: '宁', label: '宁' },
  { value: '桂', label: '桂' },
  { value: '港', label: '港' },
  { value: '澳', label: '澳' },
  { value: '黑', label: '黑' },
  { value: '吉', label: '吉' },
  { value: '辽', label: '辽' },
  { value: '晋', label: '晋' },
  { value: '冀', label: '冀' },
  { value: '青', label: '青' },
  { value: '鲁', label: '鲁' },
  { value: '豫', label: '豫' },
  { value: '苏', label: '苏' },
  { value: '皖', label: '皖' },
  { value: '浙', label: '浙' },
  { value: '云', label: '云' },
  { value: '闽', label: '闽' },
  { value: '赣', label: '赣' },
  { value: '湘', label: '湘' },
  { value: '鄂', label: '鄂' },
  { value: '粤', label: '粤' },
  { value: '琼', label: '琼' },
  { value: '甘', label: '甘' },
  { value: '陕', label: '陕' },
  { value: '黔', label: '黔' },
  { value: '滇', label: '滇' },
  { value: '川', label: '川' },
  { value: '贵', label: '贵' },
];

export default class InputPlateNumber extends Component {
  state = {
    province: null,
    number: null,
  };

  static getDerivedStateFromProps(nextProps) {
    return {
      number: nextProps?.value?.number,
      province: nextProps?.value?.province,
    };
  }

  handleNumberChange = e => {
    const number = _.toUpper(_.trim(e.target.value));
    this.setState({ number });
    this.triggerChange({ number });
  };

  handleProvinceChange = province => {
    this.setState({ province });
    this.triggerChange({ province });
  };

  triggerChange = changedValue => {
    // Should provide an event to pass value to Form.
    const { onChange } = this.props;
    if (onChange) {
      onChange(_.assign({}, this.state, changedValue));
    }
  };

  render() {
    const { province, number } = this.state;
    return (
      <span>
        <Select
          value={province}
          style={{ width: '32%' }}
          onChange={this.handleProvinceChange}
        >
          {select_options.map(node => (
            <Option key={node.value}>{node.label}</Option>
          ))}
        </Select>
        <Input
          type="text"
          value={number}
          onChange={this.handleNumberChange}
          style={{ width: '65%', marginLeft: '3%' }}
        />
      </span>
    );
  }
}
