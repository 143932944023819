/**
 * Do something like AdvancedSearchForm
 * But support for antd v4
 */
import { Children, isValidElement, memo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import { Form, Row, Col, Button, Space } from 'antd';
import _ from 'lodash';

import LinkButton from '../LinkButton';

import AdvancedSearchFormPanel from './AdvancedSearchFormPanel';
import styles from './SmartTableSearchForm.module.less';

const COL_GUTTER = { md: 8, lg: 16, xl: 24 };

const SmartTableSearchForm = ({
  collapsesize,
  children,
  className,
  loading,
  onReset,
  fields,
  form: propsForm,
  ...otherProps
}) => {
  const [expand, setExpand] = useState(false);
  const [form] = Form.useForm(propsForm);
  const handleFormReset = useCallback(() => {
    form.resetFields();
    onReset && onReset();
  }, [form, onReset]);

  const handleExpandBtnClick = useCallback(() => {
    setExpand(expand => !expand);
  }, []);

  const childTotal = Children.count(children);

  const initialFields = Children.map(children, child => ({
    name: child.props.name,
    value: undefined,
  }));

  const finalFields = _.uniqBy([...(fields || []), ...initialFields], 'name');

  return (
    <AdvancedSearchFormPanel>
      <Form
        {...otherProps}
        form={form}
        fields={finalFields}
        // layout="inline"
        className={classNames(styles['smart-table-search-form'], className)}
      >
        <Row gutter={COL_GUTTER}>
          {Children.map(children, (child, index) => {
            return (
              isValidElement(child) && (
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={8}
                  xl={8}
                  xxl={8}
                  key={index}
                  style={{
                    display: expand || index < collapsesize ? 'block' : 'none',
                  }}
                >
                  {child}
                </Col>
              )
            );
          })}
        </Row>
        <Row gutter={COL_GUTTER} type="flex" justify="end">
          <Col>
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit" disabled={loading}>
                  查询
                </Button>
                <Button
                  // htmlType="reset"
                  disabled={loading}
                  onClick={handleFormReset}
                >
                  重置
                </Button>
                {childTotal > collapsesize && (
                  <LinkButton onClick={handleExpandBtnClick}>
                    {expand ? (
                      <>
                        收起&nbsp;
                        <UpOutlined />
                      </>
                    ) : (
                      <>
                        展开&nbsp;
                        <DownOutlined />
                      </>
                    )}
                  </LinkButton>
                )}
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </AdvancedSearchFormPanel>
  );
};

SmartTableSearchForm.propTypes = {
  onReset: PropTypes.func,
  collapsesize: PropTypes.number,
};

SmartTableSearchForm.defaultProps = {
  collapsesize: 3,
};

export default memo(SmartTableSearchForm);
