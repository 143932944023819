/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type TaxiVehicleTable_data$ref = any;
export type UpdateTaxiVehicleDetailInput = {|
  clientMutationId?: ?string,
  id: string,
  plate_number?: ?string,
  trading_certificate?: ?string,
  trade_expire_date?: ?string,
  registration_date?: ?string,
  vin?: ?string,
  company_id?: ?number,
|};
export type TaxiVehicleTableMutationVariables = {|
  input: UpdateTaxiVehicleDetailInput
|};
export type TaxiVehicleTableMutationResponse = {|
  +updateTaxiVehicleDetail: ?{|
    +error: ?{|
      +errors: $ReadOnlyArray<?string>
    |},
    +vehicleDetail: ?{|
      +$fragmentRefs: TaxiVehicleTable_data$ref
    |},
  |}
|};
export type TaxiVehicleTableMutation = {|
  variables: TaxiVehicleTableMutationVariables,
  response: TaxiVehicleTableMutationResponse,
|};
*/


/*
mutation TaxiVehicleTableMutation(
  $input: UpdateTaxiVehicleDetailInput!
) {
  updateTaxiVehicleDetail(input: $input) {
    error {
      errors
    }
    vehicleDetail: vehicle_detail {
      ...TaxiVehicleTable_data
      id
    }
  }
}

fragment TaxiVehicleTable_data on TaxiVehicleDetail {
  id
  plateNumber: plate_number
  registrationDate: registration_date
  tradingCertificate: trading_certificate
  tradeExpireDate: trade_expire_date
  vin
  company {
    id
    name
  }
  driverDetails: driver_details {
    id
    driverId: driver_id
    realName: real_name
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Error",
  "kind": "LinkedField",
  "name": "error",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "errors",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TaxiVehicleTableMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateTaxiVehicleDetailPayload",
        "kind": "LinkedField",
        "name": "updateTaxiVehicleDetail",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": "vehicleDetail",
            "args": null,
            "concreteType": "TaxiVehicleDetail",
            "kind": "LinkedField",
            "name": "vehicle_detail",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "TaxiVehicleTable_data"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TaxiVehicleTableMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateTaxiVehicleDetailPayload",
        "kind": "LinkedField",
        "name": "updateTaxiVehicleDetail",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": "vehicleDetail",
            "args": null,
            "concreteType": "TaxiVehicleDetail",
            "kind": "LinkedField",
            "name": "vehicle_detail",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": "plateNumber",
                "args": null,
                "kind": "ScalarField",
                "name": "plate_number",
                "storageKey": null
              },
              {
                "alias": "registrationDate",
                "args": null,
                "kind": "ScalarField",
                "name": "registration_date",
                "storageKey": null
              },
              {
                "alias": "tradingCertificate",
                "args": null,
                "kind": "ScalarField",
                "name": "trading_certificate",
                "storageKey": null
              },
              {
                "alias": "tradeExpireDate",
                "args": null,
                "kind": "ScalarField",
                "name": "trade_expire_date",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "vin",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "company",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": "driverDetails",
                "args": null,
                "concreteType": "TaxiDriverDetail",
                "kind": "LinkedField",
                "name": "driver_details",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": "driverId",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "driver_id",
                    "storageKey": null
                  },
                  {
                    "alias": "realName",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "real_name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bc600e56e5f828357d0d093e5326eadf",
    "id": null,
    "metadata": {},
    "name": "TaxiVehicleTableMutation",
    "operationKind": "mutation",
    "text": "mutation TaxiVehicleTableMutation(\n  $input: UpdateTaxiVehicleDetailInput!\n) {\n  updateTaxiVehicleDetail(input: $input) {\n    error {\n      errors\n    }\n    vehicleDetail: vehicle_detail {\n      ...TaxiVehicleTable_data\n      id\n    }\n  }\n}\n\nfragment TaxiVehicleTable_data on TaxiVehicleDetail {\n  id\n  plateNumber: plate_number\n  registrationDate: registration_date\n  tradingCertificate: trading_certificate\n  tradeExpireDate: trade_expire_date\n  vin\n  company {\n    id\n    name\n  }\n  driverDetails: driver_details {\n    id\n    driverId: driver_id\n    realName: real_name\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2976250d6cee4820b70f527c9e396b70';

module.exports = node;
