import { lazy } from 'react';

import Section from '../../components/Section';
import { authorizedRouteWrapper } from '../../auth/authorization';

export default {
  path: 'taxi',
  name: '出租车',
  icon: 'sd-taxi',
  component: Section,
  resource_id: 'taxi',
  leftNav: true,
  routes: [
    {
      path: 'taxi-orders',
      name: '出租车订单',
      resource_id: '/taxi/taxi-orders',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "TaxiOrderList" */ '../../views/TaxiOrderListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'taxi-drivers',
      name: '出租车司机',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "TaxiDriverList" */ '../../views/taxiDrivers/TaxiDriverList'
            ),
        ),
      ),
      resource_id: '/taxi/taxi-drivers',
      exact: true,
      leftNav: true,
    },
    {
      path: 'taxi-drivers/:id',
      resource_id: '/taxi/taxi-drivers/:id',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "TaxiDriverDetails" */ '../../views/taxiDrivers/TaxiDriverDetails'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'taxi-vehicles',
      name: '出租车车辆',
      resource_id: '/taxi/taxi-vehicles',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "TaxiVehicleList" */ '../../views/TaxiVehicleListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'taxi-areas',
      name: '出租车区域',
      resource_id: '/taxi/taxi-areas',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "TaxiAreaList" */ '../../views/TaxiAreaListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'hot-cities',
      name: '热门城市',
      resource_id: '/taxi/hot-cities',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "TaxiHotCityListPage" */ '../../views/TaxiHotCityListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'hot-places',
      name: '热门地点',
      resource_id: '/taxi/hot-places',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "TaxiHotPlaceListPage" */ '../../views/TaxiHotPlaceListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'online-drivers',
      name: '在线司机',
      resource_id: '/taxi/online-drivers',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "TaxiOnlineLocationPage" */ '../../views/TaxiOnlineLocationPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'hot-places/:id',
      resource_id: '/taxi/hot-places/:id',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "TaxiHotPlaceDetailPage" */ '../../views/TaxiHotPlaceDetailPage'
            ),
        ),
      ),
      exact: true,
    },
  ],
};
