/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type TaxiVehicleTable_data$ref: FragmentReference;
declare export opaque type TaxiVehicleTable_data$fragmentType: TaxiVehicleTable_data$ref;
export type TaxiVehicleTable_data = $ReadOnlyArray<{|
  +id: string,
  +plateNumber: string,
  +registrationDate: ?string,
  +tradingCertificate: ?string,
  +tradeExpireDate: ?string,
  +vin: ?string,
  +company: {|
    +id: string,
    +name: ?string,
  |},
  +driverDetails: $ReadOnlyArray<?{|
    +id: string,
    +driverId: ?number,
    +realName: ?string,
  |}>,
  +$refType: TaxiVehicleTable_data$ref,
|}>;
export type TaxiVehicleTable_data$data = TaxiVehicleTable_data;
export type TaxiVehicleTable_data$key = $ReadOnlyArray<{
  +$data?: TaxiVehicleTable_data$data,
  +$fragmentRefs: TaxiVehicleTable_data$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "TaxiVehicleTable_data",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "plateNumber",
      "args": null,
      "kind": "ScalarField",
      "name": "plate_number",
      "storageKey": null
    },
    {
      "alias": "registrationDate",
      "args": null,
      "kind": "ScalarField",
      "name": "registration_date",
      "storageKey": null
    },
    {
      "alias": "tradingCertificate",
      "args": null,
      "kind": "ScalarField",
      "name": "trading_certificate",
      "storageKey": null
    },
    {
      "alias": "tradeExpireDate",
      "args": null,
      "kind": "ScalarField",
      "name": "trade_expire_date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "driverDetails",
      "args": null,
      "concreteType": "TaxiDriverDetail",
      "kind": "LinkedField",
      "name": "driver_details",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": "driverId",
          "args": null,
          "kind": "ScalarField",
          "name": "driver_id",
          "storageKey": null
        },
        {
          "alias": "realName",
          "args": null,
          "kind": "ScalarField",
          "name": "real_name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TaxiVehicleDetail",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '202adc7bb74e5723abbc7deb2ebb11a0';

module.exports = node;
