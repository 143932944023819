/**
 * 统一维护的路由配置
 *
 * 路由设计建议遵循以下格式：
 *
 * `/wordA-wordB/wordC-wordD?word_e=1`
 *
 * 由于当前路由格式与用户权限相关联，加上历史设计未遵循以上规范，
 * 维护历史路由页面时可以考虑同时对路由进行变更。
 *
 * 路由设计以及规范进一步优化
 *
 */
import Home from '../../views/Home';

// 布局
import BasicLayout from '../../layouts/BasicLayout';

/**
 * 以下模块进行 code splitting 后会导致 build 时内存溢出，未查出原因
 *
 * bundle 整体大小上涨接近 400%，(从 18mb 会增长至 64mb)
 *
 * TODO 通用方案解决 bundle 激增的问题
 */

import STATISTICS_ROUTE from './statisticsRoute';
import USER_ROUTE from './userRoute';
import CARPOOL_ROUTE from './carpoolRoute';
import ROUTE_CARPOOL_V2_ROUTE from './routeCarpoolV2Route';
import EXPRESS_ROUTE from './expressRoute';
import EXPRESS_ROUTE_V2 from './expressRouteV2';
import XIAOJIAN_ROUTE from './xiaojianRoute';
import MOVE_CAR_CODE_ROUTE from './moveCarCodeRoute';
import TAXI_BIZ_ROUTE from './taxiBizRoute';
import OPERATION_ROUTE from './operationRoute';
import SYSTEM_SETTING_ROUTE from './systemSettingRoute';
import STORE_ROUTE from './storeRoute';
import ACCOUNTING_ROUTE from './accountingRoute';
import TOOL_ROUTE from './toolRoute';
import RECRUITMENT_ROUTE from './recruitmentRoute';
import ASSET_ROUTE from './assetRoute';
import BI_ROUTE from './biRoute';
import INSURANCE_ROUTE from './insuranceRoute';

import SUPERVISOR_ROUTE from './supervisorRoute';
import OC_SETTING_ROUTE from './ocSettingRoute';
import CS_PLUTUS_ROUTE from './csPlutusRoute';

import { transformRoutes } from './utils';

const COMMON_ROUTE = {
  path: '/',
  component: BasicLayout,
  routes: [
    {
      path: 'home',
      name: '首页',
      component: Home,
      exact: true,
    },
    STATISTICS_ROUTE,
    USER_ROUTE,
    ASSET_ROUTE,
    RECRUITMENT_ROUTE,
    CARPOOL_ROUTE,
    ROUTE_CARPOOL_V2_ROUTE,
    EXPRESS_ROUTE_V2,
    EXPRESS_ROUTE,
    XIAOJIAN_ROUTE,
    MOVE_CAR_CODE_ROUTE,
    TAXI_BIZ_ROUTE,
    OPERATION_ROUTE,
    BI_ROUTE,
    STORE_ROUTE,
    ACCOUNTING_ROUTE,
    CS_PLUTUS_ROUTE,
    INSURANCE_ROUTE,
    TOOL_ROUTE,
    SYSTEM_SETTING_ROUTE,
    OC_SETTING_ROUTE,
    SUPERVISOR_ROUTE,
  ],
};

export default transformRoutes([COMMON_ROUTE]);
