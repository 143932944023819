/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type TaxiOrderPaymentList_data$ref = any;
type TaxiTimeline_data$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type TaxiOrderDetail_data$ref: FragmentReference;
declare export opaque type TaxiOrderDetail_data$fragmentType: TaxiOrderDetail_data$ref;
export type TaxiOrderDetail_data = {|
  +id: string,
  +destination: ?string,
  +origin: ?string,
  +rating: ?number,
  +feedback: ?string,
  +$fragmentRefs: TaxiTimeline_data$ref & TaxiOrderPaymentList_data$ref,
  +$refType: TaxiOrderDetail_data$ref,
|};
export type TaxiOrderDetail_data$data = TaxiOrderDetail_data;
export type TaxiOrderDetail_data$key = {
  +$data?: TaxiOrderDetail_data$data,
  +$fragmentRefs: TaxiOrderDetail_data$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaxiOrderDetail_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "destination",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "origin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rating",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "feedback",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TaxiTimeline_data"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TaxiOrderPaymentList_data"
    }
  ],
  "type": "Taxi",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '94b34e4ed870d8933cdd5942ae8ea055';

module.exports = node;
