import './LogoLoading.module.less';

const LogoLoading = props => (
  <div styleName="logo-loading">
    <div styleName="logo-loading-logo" />
    <div styleName="logo-loading-shadown" />
  </div>
);

export default LogoLoading;
