import { lazy } from 'react';
import { SettingOutlined } from '@ant-design/icons';

import { authorizedRouteWrapper } from '../../auth/authorization';

export default {
  path: '/operation-center-console/setting',
  name: '运营中心设置',
  icon: <SettingOutlined />,
  resource_id: '/operation-center-console/setting',
  component: authorizedRouteWrapper(
    lazy(
      () =>
        import(
          /* webpackChunkName: "OCSettingPage" */ '../../views/OCSettingPage'
        ),
    ),
  ),
  leftNav: true,
};
