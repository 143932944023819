import { isSDNetworkError, SDNetworkError } from './SDNetworkError';

const emptyFn = () => {};

export const optionToRequestInstanceMiddleware = (req, next) => {
  let newReq = req;
  if (!(req instanceof Request)) {
    const { url, ...rest } = req;
    const method = req?.method?.toUpperCase() || 'GET';
    // Request with GET/HEAD method cannot have body.
    if (rest.body && (method === 'GET' || method === 'HEAD')) {
      rest.body = undefined;
    }
    newReq = new Request(url, rest);
  }
  return next(newReq);
};

export const checkResponseStatusMiddleware = async (req, next) => {
  const response = await next(req);
  if (!response.ok) {
    const error = new SDNetworkError(
      `${response.status} - ${response.statusText}`,
    );
    error.request = req;
    error.response = response;
    throw error;
  }

  return response;
};

export const authMiddleware = ({
  getToken = emptyFn,
  refreshToken = emptyFn,
}) => {
  let tokenRefreshInProgress = null; // null or promise
  return async (req, next) => {
    let token;

    if (tokenRefreshInProgress) {
      token = await tokenRefreshInProgress;
    } else {
      token = await getToken(req);
    }

    req.headers.set('authorization', `Bearer ${token}`);

    try {
      return await next(req);
    } catch (error) {
      if (!isSDNetworkError(error)) {
        throw error;
      }
      const lastRes = error.response;

      if (lastRes.status !== 401) {
        throw error;
      }

      if (!tokenRefreshInProgress) {
        tokenRefreshInProgress = refreshToken(req, lastRes);
      }

      const newToken = await tokenRefreshInProgress;
      const newReq = error.request.clone();
      newReq.headers.set('authorization', `Bearer ${newToken}`);

      return next(newReq);
    }
  };
};

export const loggerMiddleware = async (req, next) => {
  const start = new Date().getTime();
  const res = await next(req);
  const end = new Date().getTime();
  // eslint-disable-next-line no-console
  console.log('[SD-NETWORK]', `[${end - start}ms]`, req, res);
  return res;
};

export const retryMiddleware = (options = {}) => {
  const {
    retryTimes = 2,
    // timeout = 1000 * 10, // TODO 支持设置超时时间 默认十秒超时
    retryDelays = attempt => Math.pow(2, attempt + 4) * 100,
    statusCodes = (statusCode, req, res) =>
      statusCode < 200 || statusCode === 408,
  } = options;
  return async (req, next) => {
    let retried = 0;
    const core = async req => {
      try {
        return await next(req);
      } catch (error) {
        if (!isSDNetworkError(error)) {
          throw error;
        }
        const lastRes = error.response;
        retried++;
        if (
          !statusCodes(lastRes.status, req, lastRes) ||
          retried > retryTimes
        ) {
          throw error;
        }

        // sleep
        await new Promise(resolve => setTimeout(resolve, retryDelays(retried)));
        const newReq = error.request.clone();
        return core(newReq);
      }
    };

    return core(req);
  };
};
