import _ from 'lodash';
import moment from 'moment';

// for day granularity
const QuickRangesList = [
  {
    label: '今天',
    value: () => {
      const start = moment().startOf('days');
      const end = start;
      return [start, end];
    },
  },
  {
    label: '昨天',
    value: () => {
      const start = moment().subtract(1, 'days');
      const end = start;
      return [start, end];
    },
  },
  {
    label: '前天',
    value: () => {
      const start = moment().subtract(2, 'days');
      const end = start;
      return [start, end];
    },
  },
  {
    label: '本周',
    value: () => {
      const thisWeekStart = moment().startOf('weeks');
      const thisWeekEnd = moment().endOf('weeks');
      return [thisWeekStart, thisWeekEnd];
    },
  },
  {
    label: '上周',
    value: () => {
      const lastWeekStart = moment().startOf('week').subtract(1, 'weeks');
      const lastWeekEnd = moment().endOf('week').subtract(1, 'weeks');
      return [lastWeekStart, lastWeekEnd];
    },
  },
  {
    label: '本月',
    value: () => {
      const thisMonthStart = moment().startOf('months');
      const thisMonthEnd = moment().endOf('months');
      return [thisMonthStart, thisMonthEnd];
    },
  },
  {
    label: '上个月',
    value: () => {
      const lastMonthStart = moment().startOf('month').subtract(1, 'months');
      const lastMonthEnd = moment().endOf('month').subtract(1, 'months');
      return [lastMonthStart, lastMonthEnd];
    },
  },
  ...[2, 7, 30].map(day => ({
    label: `最后 ${day} 天`,
    value: () => {
      const today = moment().startOf('day');
      return [moment(today).subtract(day - 1, 'day'), today];
    },
  })),
];

export default _.fromPairs(
  _.map(QuickRangesList, ({ label, value }) => [label, value]),
);
