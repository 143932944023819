import { Descriptions } from 'antd';
import PropTypes from 'prop-types';
import { Button, Card, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import ActiveState from '../../components/ActiveState';

const { confirm } = Modal;

const WithdrawalConfigDescription = ({
  data,
  title,
  loading,
  handleEditConfig,
  onlyShowAmount = false,
}) => {
  const handleConfirmEditConfig = () => {
    confirm({
      title: '危险操作！',
      icon: <ExclamationCircleOutlined />,
      content: '确认修改提现配置吗？',
      onOk() {
        handleEditConfig?.();
      },
    });
  };

  return (
    <Card
      title={title}
      loading={loading}
      extra={
        <Button danger onClick={handleConfirmEditConfig}>
          修改配置
        </Button>
      }
    >
      <Descriptions>
        {!onlyShowAmount && (
          <>
            <Descriptions.Item label="状态">
              <ActiveState isActive={data?.isActive ?? false} />
            </Descriptions.Item>
            <Descriptions.Item label="自动转账">
              <ActiveState isActive={data?.isAutoProcess ?? false} />
            </Descriptions.Item>
            <Descriptions.Item label="提现标题">
              {data?.title}
            </Descriptions.Item>
          </>
        )}
        <Descriptions.Item label="最小提现额度">
          {`${data?.minAmount} 元`}
        </Descriptions.Item>
        <Descriptions.Item label="最大提现额度">
          {`${data?.maxAmount} 元`}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

WithdrawalConfigDescription.propTypes = {
  data: PropTypes.shape({
    isActive: PropTypes.bool,
    isAutoProcess: PropTypes.bool,
    title: PropTypes.string,
    minAmount: PropTypes.number,
    maxAmount: PropTypes.number,
  }),
  onlyShowAmount: PropTypes.bool,
  title: PropTypes.element,
  handleEditConfig: PropTypes.func,
  loading: PropTypes.bool,
};

export default WithdrawalConfigDescription;
