/**
 * 参考 https://github.com/airbnb/react-create-hoc
 */
import hoistNonReactStatics from 'hoist-non-react-statics';

import getComponentName from './getComponentName';

const createHOC = (hocName, getWrappedComp) => {
  return ComponentToWrap => {
    const WrappedComp = getWrappedComp(ComponentToWrap);
    WrappedComp.displayName = `${hocName}(${getComponentName(
      ComponentToWrap,
    )})`;
    hoistNonReactStatics(WrappedComp, ComponentToWrap);
    return WrappedComp;
  };
};

export default createHOC;
