import { commitMutation } from 'react-relay';

import { checkMutationPayload } from './checkMutationPayload';

export function commitMutationWithPromise(env, config) {
  return new Promise((resolve, reject) => {
    commitMutation(env, {
      ...config,
      onCompleted: resolve,
      onError: e => reject(e),
    });
  });
}

/**
 * check the mutation response
 * @param {Response} response mutation response
 * @param {String[]} fields mutation fields
 */
export function checkMutationResponse(response, fields = []) {
  for (let field of fields) {
    checkMutationPayload(response[field]);
  }
  return response;
}

/**
 * check the mutation response for neo end-point
 *
 * @param {Object} mutationPayload mutation payload
 */
export { checkMutationForNeo } from './checkMutationPayload'; // 兼容现有使用，TODO: 后续需废弃

export default commitMutationWithPromise;
