/**
 * Created by qiuyuzhou on 2017/5/6.
 */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import { matchRoutes } from 'react-router-config';
import _ from 'lodash';

import AuthLink from '../components/AuthLink';
import routers from '../common/router';

export default class Breadcrumbs extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  renderItem = (route, match, curpath) => {
    const { url, params } = match;
    if (route.path === '/') {
      return (
        <Breadcrumb.Item key="/">
          <AuthLink to="/">
            <HomeOutlined />
            首页
          </AuthLink>
        </Breadcrumb.Item>
      );
    }
    const getRouteName = route =>
      _.isFunction(route.breadcrumbName)
        ? route.breadcrumbName(match)
        : route.breadcrumbName || route.name;

    // _.last(_.values(params)) 为了兼容详情页未配置 name 以及 breadcrumbName
    const breadcrumbName =
      getRouteName(route) ||
      global.decodeURIComponent(_.last(_.values(params)));

    const linkWrapper = element =>
      url === curpath ? element : <AuthLink to={url}>{element}</AuthLink>;

    return (
      <Breadcrumb.Item key={url}>{linkWrapper(breadcrumbName)}</Breadcrumb.Item>
    );
  };

  getMatchRoutes(pathname) {
    const snippets = pathname.split('/');
    const allPath = snippets.map((item, index) => {
      if (index > 0) {
        return snippets.slice(0, index + 1).join('/');
      }
      return item;
    });
    const matchs = _.filter(
      _.flatMap(allPath, item => matchRoutes(routers, item)),
      { match: { isExact: true } },
    );
    return matchs;
  }
  render() {
    const {
      location: { pathname },
      history,
      match,
      staticContext,
      ...otherProps
    } = this.props;
    const matchs = this.getMatchRoutes(pathname);

    return (
      <Breadcrumb {...otherProps}>
        {matchs.map(({ route, match }) =>
          this.renderItem(route, match, pathname),
        )}
      </Breadcrumb>
    );
  }
}
