/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type TaxiDriverList_data$ref = any;
export type TaxiDriverListQueryVariables = {|
  count: number,
  cursor?: ?string,
  q?: ?any,
|};
export type TaxiDriverListQueryResponse = {|
  +data: {|
    +$fragmentRefs: TaxiDriverList_data$ref
  |}
|};
export type TaxiDriverListQuery = {|
  variables: TaxiDriverListQueryVariables,
  response: TaxiDriverListQueryResponse,
|};
*/


/*
query TaxiDriverListQuery(
  $count: Int!
  $cursor: String
  $q: Json
) {
  data: relay {
    ...TaxiDriverList_data
  }
}

fragment TaxiDriverList_data on Relay {
  entities: taxi_drivers(first: $count, after: $cursor, q: $q) {
    edges {
      node {
        id
        company {
          id
          name
        }
        phone_number
        created_at
        current_sign_in_at
        taxi_driver_detail {
          real_name
          rating
          id
        }
        avatar {
          thumbnail
          id
        }
        __typename
      }
      cursor
    }
    pageInfo {
      hasNextPage
      endCursor
    }
    totalCount
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "q"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Variable",
    "name": "q",
    "variableName": "q"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TaxiDriverListQuery",
    "selections": [
      {
        "alias": "data",
        "args": null,
        "concreteType": "Relay",
        "kind": "LinkedField",
        "name": "relay",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TaxiDriverList_data"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TaxiDriverListQuery",
    "selections": [
      {
        "alias": "data",
        "args": null,
        "concreteType": "Relay",
        "kind": "LinkedField",
        "name": "relay",
        "plural": false,
        "selections": [
          {
            "alias": "entities",
            "args": (v1/*: any*/),
            "concreteType": "TaxiDriverConnection",
            "kind": "LinkedField",
            "name": "taxi_drivers",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TaxiDriverEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TaxiDriver",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Company",
                        "kind": "LinkedField",
                        "name": "company",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "phone_number",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "created_at",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "current_sign_in_at",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TaxiDriverDetail",
                        "kind": "LinkedField",
                        "name": "taxi_driver_detail",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "real_name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "rating",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Picture",
                        "kind": "LinkedField",
                        "name": "avatar",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "thumbnail",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCount",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "entities",
            "args": (v1/*: any*/),
            "filters": [
              "q"
            ],
            "handle": "connection",
            "key": "TaxiDriverList_entities",
            "kind": "LinkedHandle",
            "name": "taxi_drivers"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "22e3d097f4a89a707a42a5f9f01e6feb",
    "id": null,
    "metadata": {},
    "name": "TaxiDriverListQuery",
    "operationKind": "query",
    "text": "query TaxiDriverListQuery(\n  $count: Int!\n  $cursor: String\n  $q: Json\n) {\n  data: relay {\n    ...TaxiDriverList_data\n  }\n}\n\nfragment TaxiDriverList_data on Relay {\n  entities: taxi_drivers(first: $count, after: $cursor, q: $q) {\n    edges {\n      node {\n        id\n        company {\n          id\n          name\n        }\n        phone_number\n        created_at\n        current_sign_in_at\n        taxi_driver_detail {\n          real_name\n          rating\n          id\n        }\n        avatar {\n          thumbnail\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    totalCount\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '13cd391ba0de5cf296ff0b0483a15a3d';

module.exports = node;
