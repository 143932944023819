import PropTypes from 'prop-types';
import { Form, Input } from 'antd';

import ProvinceSelect from '../../components/ProvinceSelect';
import PhoneNumberInput from '../../components/PhoneNumberInput';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const TaxiCompanyForm = props => {
  const { initialValues } = props;

  return (
    <Form {...formItemLayout} {...props} initialValues={initialValues}>
      <Form.Item
        label="名称"
        name="name"
        rules={[
          {
            required: true,
            message: '请输入名称',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="省份"
        name="province"
        rules={[
          {
            required: true,
            message: '请输入省份',
          },
        ]}
      >
        <ProvinceSelect />
      </Form.Item>
      <Form.Item
        label="城市"
        name="city"
        rules={[
          {
            required: true,
            message: '请输入城市',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="客服电话"
        name="phone_number"
        rules={[
          {
            required: true,
            message: '请输入客服电话',
          },
        ]}
      >
        <PhoneNumberInput />
      </Form.Item>
    </Form>
  );
};

TaxiCompanyForm.defaultProps = {
  initialValues: {},
};

TaxiCompanyForm.propTypes = {
  initialValues: PropTypes.object,
};

export default TaxiCompanyForm;
