import PropTypes from 'prop-types';
import moment from 'moment';
import { Form, Input } from 'antd';

import InputPlateNumber from '../../components/InputPlateNumber';
import InputSearchTaxiCompany from '../../components/InputSearchTaxiCompany';
import {
  createCustomValidator,
  getFormRequiredValidator,
} from '../../utils/formFieldValidator';
import MyDatePicker from '../../components/MyDatePicker';
import { decodeGraphQLGlobalID } from '../../utils/graphQLGlobalID';

const FORM_ITEM_LAYOUT = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};
const DATE_PICKER_STYLE = { width: '100%' };
const PLATE_NUMBER_REGEXP = /^[A-Z](([A-Z]|\d){5,5})$/;

const companyRules = [
  getFormRequiredValidator('请选择出租车公司', 'object'),
  createCustomValidator(value => {
    if (value && value.id) {
      return true;
    }
  }, '请选择出租车公司'),
];

const disableDate = curDate => {
  moment().startOf('day').add(1, 'd').isSameOrBefore(curDate);
};

const initiaPlateNumberHandler = plateNumber => {
  if (plateNumber) {
    return {
      province: plateNumber.slice(0, 1),
      number: plateNumber.slice(1),
    };
  } else {
    return {
      province: '闽',
      number: null,
    };
  }
};

const extraMessage = (
  <span style={{ color: '#f04134' }}>
    <i>修改车辆所属公司为危险操作</i>
  </span>
);

const TaxiVehicleForm = props => {
  const { initialValues } = props;
  const isModify = !!initialValues;

  const _initialValues = {
    ...initialValues,
    plateNumber: initiaPlateNumberHandler(initialValues?.plateNumber),
    registrationDate:
      initialValues?.registrationDate &&
      moment(initialValues.registrationDate, 'YYYY-MM-DD'),
    tradeExpireDate:
      initialValues?.tradeExpireDate &&
      moment(initialValues.tradeExpireDate, 'YYYY-MM-DD'),
  };

  return (
    <Form {...FORM_ITEM_LAYOUT} {...props} initialValues={_initialValues}>
      <Form.Item
        label="请选择出租车公司"
        name="company"
        rules={companyRules}
        help={isModify && extraMessage}
      >
        <InputSearchTaxiCompany
          q={JSON.stringify({
            id_not_in: parseInt(
              decodeGraphQLGlobalID(initialValues?.company?.id),
            ),
          })}
        />
      </Form.Item>
      <Form.Item label="车牌号">
        <Form.Item
          noStyle
          name="plateNumber"
          rules={[
            { required: true, message: 'Province is required' },
            ({ getFieldValue }) => ({
              async validator(rule, value, callback) {
                if (!value) {
                  throw new Error('请选择省份, 并输入车牌号');
                } else {
                  const { province, number } = value;
                  if (!province) {
                    throw new Error('请选择省份');
                  }
                  if (!number) {
                    throw new Error('请输入车牌号');
                  }
                  if (PLATE_NUMBER_REGEXP.test(number)) {
                    return callback();
                  } else {
                    throw new Error(
                      '请输入正确的车牌号，大写字母开头+5位数字或大写字母，例如：A123B5',
                    );
                  }
                }
              },
            }),
          ]}
        >
          <InputPlateNumber />
        </Form.Item>
      </Form.Item>
      <Form.Item
        label="车辆识别代码"
        name="vin"
        rules={[
          {
            required: true,
            message: '请输入正确的车辆识别代码（17位字母数字组合）',
            pattern: /^(([A-Z]|[a-z]|\d){17})$/,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="车辆注册日期"
        name="registrationDate"
        rules={[{ required: true, message: '请输入车辆注册日期' }]}
      >
        <MyDatePicker style={DATE_PICKER_STYLE} disabledDate={disableDate} />
      </Form.Item>
      <Form.Item label="出租车运输证号" name="tradingCertificate">
        <Input />
      </Form.Item>
      <Form.Item label="运营资格截止日期" name="tradeExpireDate">
        <MyDatePicker style={DATE_PICKER_STYLE} />
      </Form.Item>
    </Form>
  );
};

TaxiVehicleForm.defaultProps = {
  initialValues: {},
};

TaxiVehicleForm.propTypes = {
  initialValues: PropTypes.object,
};

export default TaxiVehicleForm;
