/**
 * Created by qiuyuzhou on 2017/5/7.
 */
import { useCallback, useRef } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { connect } from 'react-redux';
import { Alert, Skeleton, Space, Row, Col, Avatar, Dropdown } from 'antd';
import _ from 'lodash';

import { viewerRawSelector } from '../redux/selectors';
import useCurClientRoles from '../hooks/useCurClientRoles';

import RoleList from '../common/roles';

import LinkButton from './LinkButton';
import { RightOutlined } from '@ant-design/icons';

const ROLE_LIST = RoleList.sort((a, b) => a.order - b.order);

const getViewerInfoTitle = (viewerInfo, roles) => {
  if (viewerInfo.company) {
    return `出租车公司：${viewerInfo.company.name}`;
  }
  if (viewerInfo.operationCenter) {
    // 监管人员帐号登录，不展示线路运营中心信息
    if (_.includes(roles, 'operation_center_supervisor')) {
      return `监管人员`;
    }
    return `线路运营中心：${viewerInfo.operationCenter.name}`;
  }
  if (viewerInfo.operationCenterGroup) {
    return `区域管理中心：${viewerInfo.operationCenterGroup.name}`;
  }
  if (viewerInfo.capacitySupplier) {
    return `运力提供商：${viewerInfo.capacitySupplier.name}`;
  }

  for (let role of ROLE_LIST) {
    if (_.includes(roles, role.value)) {
      return role.label;
    }
  }
};

function ViewerMenu({ viewer, collapsed }) {
  const { keycloak } = useKeycloak();

  const roles = useCurClientRoles();

  const handleLoginBtnClick = useCallback(() => {
    keycloak.login();
  }, [keycloak]);

  const handleSignOutBtnClick = useCallback(() => {
    keycloak.logout();
  }, [keycloak]);

  const handleMenuClick = useCallback(
    ({ key }) => {
      const menuItemClickHandler = {
        accountInfo: () => {
          keycloak && keycloak.accountManagement();
        },
        signOut: () => {
          keycloak && keycloak.logout();
        },
      };

      const handler = menuItemClickHandler[key];
      handler && handler();
    },
    [keycloak],
  );

  const $content = useRef(null);

  if (!keycloak.authenticated) {
    return <LinkButton onClick={handleLoginBtnClick}>登录</LinkButton>;
  }

  if (viewer.error) {
    return (
      <Alert
        style={{
          fontSize: '14px',
          display: 'inline-block',
        }}
        message={
          <Space direction="vertical">
            <span>{viewer.error}</span>
            <LinkButton onClick={handleSignOutBtnClick}>退出登录</LinkButton>
          </Space>
        }
        type="error"
      />
    );
  }

  if (!viewer.entry || viewer.fetching) {
    return (
      <Space>
        <Skeleton.Avatar />
        <Skeleton.Input block />
      </Space>
    );
  }

  const { entry } = viewer;

  return (
    <Dropdown
      menu={{
        items: [
          {
            label: <span>帐号中心</span>,
            key: 'accountInfo',
          },
          {
            label: <span>退出登录</span>,
            key: 'signOut',
          },
        ],
        onClick: handleMenuClick,
        style: { width: 256 },
      }}
      trigger={['click']}
      getPopupContainer={() => $content.current}
    >
      <Row
        ref={$content}
        gutter={8}
        align="middle"
        wrap={false}
        style={{ cursor: 'pointer' }}
      >
        <Col>
          <Avatar size="large">{entry.username}</Avatar>
        </Col>
        <Col
          flex="1"
          style={{
            // 避免 collapsed 过程抖动
            maxHeight: 22,
            visibility: collapsed ? 'hidden' : null,
            overflow: 'hidden',
          }}
        >
          <Row justify="space-between" align="middle" wrap={false}>
            <Col>{getViewerInfoTitle(entry, roles)}</Col>
            <Col>
              <RightOutlined />
            </Col>
          </Row>
        </Col>
      </Row>
    </Dropdown>
  );
}

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  viewer: viewerRawSelector(state),
});

export default connect(mapStateToProps)(ViewerMenu);
