import _ from 'lodash';

/**
 * 是否是否定策略
 *
 * @param {string} policy
 */
const isDenyPolicy = policy => _.startsWith(policy, '!');

/**
 * 获取否定策略列表
 *
 * @param {Array<string>} policies
 */
const getDenyPolicies = policies =>
  policies.filter(isDenyPolicy).map(item => item.slice(1));

/**
 * 是否是肯定策略
 *
 * @param {string} policy
 */
const isPermitPolicy = policy => !isDenyPolicy(policy);

/**
 * 获取肯定策略列表
 *
 * @param {Array<string>} policies
 */
const getPermitPolicies = policies => policies.filter(isPermitPolicy);

/**
 *
 * @param {Object} permissionConf
 * @param {(policy, permissionKey) => boolean} permitMatcher
 * @param {(policy, permissionKey) => boolean} denyMatcher
 */
const createPermissionChecker = (
  permissionConf,
  permitMatcher,
  denyMatcher = permitMatcher,
) => {
  if (_.isArray(permissionConf) || !_.isObject(permissionConf)) {
    throw Error('Invalid config');
  }
  if (!_.isFunction(permitMatcher)) throw Error('Invalid matcher');
  if (!_.isFunction(denyMatcher)) throw Error('Invalid matcher');

  /**
   * 获取配置策略
   *
   * @param {string} role
   */
  const getPermissionPoliciesByRole = role => {
    return permissionConf?.[role] || [];
  };

  /**
   * 某个角色是否包含权限位
   *
   * @param {string} role
   * @param {string} permissionKey
   */
  const isRoleHasPermission = (
    role,
    permissionKey,
    permitMatcher,
    denyMatcher,
  ) => {
    const allPolicies = getPermissionPoliciesByRole(role); // 当前角色的所有权限配置策略
    const denyPolicies = getDenyPolicies(allPolicies); // 非权限 !permission
    const permitPolicies = getPermitPolicies(allPolicies); // 拥有的权限

    const matchDenyPolicy = _.some(denyPolicies, policy =>
      denyMatcher(policy, permissionKey),
    );

    if (matchDenyPolicy) {
      return false;
    }

    const matchPermitPolicy = _.some(permitPolicies, permission =>
      permitMatcher(permission, permissionKey),
    );

    return matchPermitPolicy;
  };

  /**
   * 是否拥有权限
   *
   * @param {Array<string>} roles
   * @param {string} permissionKey
   */
  const hasPermission = (roles, permissionKey) =>
    _.some(roles, role =>
      isRoleHasPermission(role, permissionKey, permitMatcher, denyMatcher),
    );

  return {
    isRoleHasPermission,
    hasPermission,
  };
};

export default createPermissionChecker;
