import PropTypes from 'prop-types';
import { graphql, useFragment } from 'react-relay/hooks';

import { Timeline } from 'antd';

import _ from 'lodash';
import LocalDateTime from '../../components/LocalDateTime';
import PhoneNumberFormatter from '../../components/PhoneNumberFormatter';

import { statusNames } from './SmartTaxiOrdersTable/TaxiStatus';

const fragment = graphql`
  fragment TaxiTimeline_data on Taxi {
    driver {
      id
      phoneNumber: phone_number
    }
    status
    createdAt: created_at
    logStateChanges: log_state_changes {
      id
      createdAt: created_at
      event
      fromState: from_state
      toState: to_state
    }
    orderReassignReasons: order_reassign_reasons {
      driver {
        phoneNumber: phone_number
      }
      reassignReason: reassign_reason {
        reason
      }
    }
    cancelReason: cancel_reason {
      cancelType: cancel_type
      reason
    }
  }
`;

const eventNames = {
  'accept!': '接单',
  'progress!': '乘客上车',
  'arrive!': '到达目的地',
  'complete!': '完成',
  'expire!': '调度超时',
  'passenger_cancel!': '乘客取消',
  'driver_cancel!': '司机取消',
};

const TaxiTimeline = ({ ride }) => {
  const data = useFragment(fragment, ride);
  const cookieEventMessage = (entry, cancelReason) => {
    if (entry.event === 'reassign!') {
      return (
        <span>
          改派 - 因为
          <span style={{ color: '#108ee9' }}>
            {entry.reassignReason.reason}
          </span>
          ，订单被改派 - 司机:&nbsp;
          <PhoneNumberFormatter value={entry.driver.phoneNumber} />
        </span>
      );
    } else if (
      _.includes(['passenger_cancel!', 'driver_cancel!'], entry.event)
    ) {
      return (
        <span>
          {eventNames[entry.event]} -&nbsp;
          <span style={{ color: '#108ee9' }}>{cancelReason}</span>
        </span>
      );
    }
    return <span>{eventNames[entry.event]}</span>;
  };

  const cookieEntries = ride => {
    if (ride) {
      const { logStateChanges, orderReassignReasons } = ride;
      const reassignEventIndexes = logStateChanges
        .map((e, index) => {
          return {
            ...e,
            index: index,
          };
        })
        .filter(e => e.event === 'reassign!')
        .map(e => e.index);
      const result = _.clone(logStateChanges);
      orderReassignReasons &&
        orderReassignReasons.forEach((e, i) => {
          const index = reassignEventIndexes[i];
          result[index] = {
            ...result[index],
            ...e,
          };
        });
      const cancelReason = ride?.cancelReason?.reason;

      return result.map(entry => {
        const statusModel = statusNames.find(
          item => item.value === entry.toState,
        );
        return {
          key: entry.id,
          color: statusModel.color,
          status: statusModel.text,
          time: entry.createdAt,
          event: cookieEventMessage(entry, cancelReason),
        };
      });
    }
    return [];
  };

  const entries = cookieEntries(data);
  const items = entries.map(entry => {
    return (
      <Timeline.Item key={entry.key} color={entry.color}>
        <LocalDateTime date={entry.time} format="l HH:mm:ss" />
        &nbsp;
        <span>{entry.status}</span> - {entry.event}
      </Timeline.Item>
    );
  });
  items.reverse();
  items.unshift(
    <Timeline.Item key="initial" color={'gray'}>
      <LocalDateTime date={data.createdAt} format="l HH:mm:ss" />
      &nbsp;待接单 - 下单
    </Timeline.Item>,
  );
  return <Timeline style={{ marginTop: '20px' }}>{items}</Timeline>;
};

TaxiTimeline.propTypes = {
  ride: PropTypes.object.isRequired,
};

export default TaxiTimeline;
