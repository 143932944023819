import { useMemo } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import _ from 'lodash';

import Config from '../config';
import { updateTokenWithPromise } from '../utils/keycloakUtils';
import {
  createNetworkLayer,
  authMiddleware,
  loggerMiddleware,
  retryMiddleware,
} from '../utils/networklayer';

const isJsonContent = contentType => contentType?.includes('application/json');

const useSDClient = () => {
  const { keycloak } = useKeycloak();

  // TODO: 多个组件调用此 hook 共享同一个 network layer
  const request = useMemo(() => {
    const middleware = [
      async (req, next) => {
        const response = await next(req);
        if (isJsonContent(response.headers.get('content-type'))) {
          return response.json();
        }
        return response.blob();
      },
      retryMiddleware(),
      process.env.NODE_ENV === 'development' && loggerMiddleware,
      authMiddleware({
        getToken: async () => keycloak?.token,
        refreshToken: async () => {
          try {
            return await updateTokenWithPromise(keycloak);
          } catch (error) {
            keycloak.login();
          }
        },
      }),
    ].filter(Boolean);

    const network = createNetworkLayer(middleware);

    return async (url, options) => {
      const { query, ...otherFetchOptions } = options || {};

      const method = options?.method?.toUpperCase() || 'GET';

      url = new URL(url, Config.serverURL);

      if (method === 'GET') {
        _.forEach(query, (val, key) => {
          url.searchParams.set(key, val);
        });
      }

      const headers = new Headers({
        'Content-Type': 'application/json;charset=utf-8',
      });
      _.forEach(options?.headers, (val, key) => {
        headers.set(key, val);
      });

      const finalReqOptions = _.assign(otherFetchOptions, {
        url,
        method,
        headers,
      });

      if (finalReqOptions.body && isJsonContent(headers.get('Content-Type'))) {
        finalReqOptions.body = JSON.stringify(options?.body);
      }

      return network(finalReqOptions);
    };
  }, [keycloak]);

  const sdClient = useMemo(
    () => ({
      request,
    }),
    [request],
  );

  return sdClient;
};

export default useSDClient;
