import { useMemo } from 'react';
import { isAuthorized } from '../auth/authorization';
import _ from 'lodash';

import useMemorizedObject from './useMemorizedObject';
import useCurClientRoles from './useCurClientRoles';

function usePermission(permissionMap = {}) {
  const roles = useCurClientRoles();

  const mermorizedPermissionMap = useMemorizedObject(permissionMap);

  const result = useMemo(
    () =>
      _.mapValues(mermorizedPermissionMap, permissionKey =>
        isAuthorized(permissionKey, roles),
      ),
    [mermorizedPermissionMap, roles],
  );

  return result;
}

export default usePermission;
