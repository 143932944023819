import PropTypes from 'prop-types';

import { Badge } from 'antd';

export const statusNames = [
  {
    value: 'pending',
    text: '待接单',
    color: 'gray',
    badgeStatus: 'default',
  },
  {
    value: 'accepted',
    text: '已接单',
    color: '#108ee9',
    badgeStatus: 'processing',
  },
  {
    value: 'in_progress',
    text: '行程中',
    color: '#108ee9',
    badgeStatus: 'processing',
  },
  {
    value: 'completed',
    text: '完成',
    color: '#00a854',
    badgeStatus: 'success',
  },
  {
    value: 'expired',
    text: '过期',
    color: '#f04134',
    badgeStatus: 'error',
  },
  {
    value: 'passenger_canceled',
    text: '取消 - 乘客',
    color: '#ffbf00',
    badgeStatus: 'warning',
  },
  {
    value: 'driver_canceled',
    text: '取消 - 司机',
    color: '#ffbf00',
    badgeStatus: 'warning',
  },
];
export const TaxiStatus = props => {
  const { status } = props;
  if (status) {
    const stateModel = statusNames.find(item => item.value === status);
    if (stateModel) {
      return <Badge status={stateModel.badgeStatus} text={stateModel.text} />;
    }
  }
};

const allValue = statusNames.map(item => item.value);

TaxiStatus.propTypes = {
  status: PropTypes.oneOf(allValue).isRequired,
};
