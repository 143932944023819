import { useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import createHOC from '../../utils/createHOC';

import withInfiniteLoadMore from './withInfiniteLoadMore';

const withInfiniteConnection = _.flow(
  withInfiniteLoadMore,
  createHOC('withInfiniteConnection', Comp => {
    const InfiniteConnectionHOC = props => {
      const { relay } = props;
      const loadMore = useCallback(
        callback => {
          relay.loadMore(25, error => {
            callback();
          });
        },
        [relay],
      );

      return <Comp {...props} loadMore={loadMore} hasMore={relay.hasMore} />;
    };
    InfiniteConnectionHOC.propTypes = {
      relay: PropTypes.shape({
        hasMore: PropTypes.func.isRequired,
        isLoading: PropTypes.func.isRequired,
        loadMore: PropTypes.func.isRequired,
      }).isRequired,
    };
    return InfiniteConnectionHOC;
  }),
);

export default withInfiniteConnection;
