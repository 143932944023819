/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type RideStatus = "accepted" | "arrived" | "at_dropoff" | "canceled" | "closed" | "completed" | "driver_canceled" | "expired" | "in_progress" | "origin_arrived" | "passenger_canceled" | "pending" | "requesting" | "suspended" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type TaxiTimeline_data$ref: FragmentReference;
declare export opaque type TaxiTimeline_data$fragmentType: TaxiTimeline_data$ref;
export type TaxiTimeline_data = {|
  +driver: ?{|
    +id: string,
    +phoneNumber: ?string,
  |},
  +status: ?RideStatus,
  +createdAt: ?any,
  +logStateChanges: ?$ReadOnlyArray<?{|
    +id: string,
    +createdAt: any,
    +event: ?string,
    +fromState: ?string,
    +toState: string,
  |}>,
  +orderReassignReasons: ?$ReadOnlyArray<?{|
    +driver: {|
      +phoneNumber: ?string
    |},
    +reassignReason: {|
      +reason: string
    |},
  |}>,
  +cancelReason: ?{|
    +cancelType: ?string,
    +reason: string,
  |},
  +$refType: TaxiTimeline_data$ref,
|};
export type TaxiTimeline_data$data = TaxiTimeline_data;
export type TaxiTimeline_data$key = {
  +$data?: TaxiTimeline_data$data,
  +$fragmentRefs: TaxiTimeline_data$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": "phoneNumber",
  "args": null,
  "kind": "ScalarField",
  "name": "phone_number",
  "storageKey": null
},
v2 = {
  "alias": "createdAt",
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reason",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaxiTimeline_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TaxiDriver",
      "kind": "LinkedField",
      "name": "driver",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": "logStateChanges",
      "args": null,
      "concreteType": "LogStateChange",
      "kind": "LinkedField",
      "name": "log_state_changes",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "event",
          "storageKey": null
        },
        {
          "alias": "fromState",
          "args": null,
          "kind": "ScalarField",
          "name": "from_state",
          "storageKey": null
        },
        {
          "alias": "toState",
          "args": null,
          "kind": "ScalarField",
          "name": "to_state",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "orderReassignReasons",
      "args": null,
      "concreteType": "OrderReassignReason",
      "kind": "LinkedField",
      "name": "order_reassign_reasons",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Driver",
          "kind": "LinkedField",
          "name": "driver",
          "plural": false,
          "selections": [
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": "reassignReason",
          "args": null,
          "concreteType": "ReassignReason",
          "kind": "LinkedField",
          "name": "reassign_reason",
          "plural": false,
          "selections": [
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "cancelReason",
      "args": null,
      "concreteType": "CancelReason",
      "kind": "LinkedField",
      "name": "cancel_reason",
      "plural": false,
      "selections": [
        {
          "alias": "cancelType",
          "args": null,
          "kind": "ScalarField",
          "name": "cancel_type",
          "storageKey": null
        },
        (v3/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Taxi",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '9519cbca3770d422c983019b41274359';

module.exports = node;
