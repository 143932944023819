import PropTypes from 'prop-types';

export function formatNumber(num, prefix = '', suffix = '') {
  const formatedNum = `${num}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return `${prefix}${formatedNum}${suffix}`;
}

function NumberFormatter(props) {
  const { value, prefix, suffix, ...otherProps } = props;

  const _val = global.parseFloat(value, 10);

  return (
    <span {...otherProps}>
      {_val || _val === 0 ? formatNumber(value, prefix, suffix) : '--.--'}
    </span>
  );
}

NumberFormatter.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  prefix: PropTypes.any,
  suffix: PropTypes.any,
};

export default NumberFormatter;
