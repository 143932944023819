/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type PaymentMethods = "AlipayMethod" | "Cash" | "Didi" | "RideBalance" | "WechatPayment" | "Withdraw" | "%future added value";
export type PaymentStates = "checkout" | "completed" | "failure" | "free_charged" | "processing" | "refunded" | "void" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type TaxiOrderPaymentList_data$ref: FragmentReference;
declare export opaque type TaxiOrderPaymentList_data$fragmentType: TaxiOrderPaymentList_data$ref;
export type TaxiOrderPaymentList_data = $ReadOnlyArray<{|
  +payments: ?$ReadOnlyArray<?{|
    +id: string,
    +paymentMethod: ?PaymentMethods,
    +amount: number,
    +state: PaymentStates,
    +promotionAmount: ?number,
    +rideBalance: ?number,
  |}>,
  +$refType: TaxiOrderPaymentList_data$ref,
|}>;
export type TaxiOrderPaymentList_data$data = TaxiOrderPaymentList_data;
export type TaxiOrderPaymentList_data$key = $ReadOnlyArray<{
  +$data?: TaxiOrderPaymentList_data$data,
  +$fragmentRefs: TaxiOrderPaymentList_data$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "TaxiOrderPaymentList_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Payment",
      "kind": "LinkedField",
      "name": "payments",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": "paymentMethod",
          "args": null,
          "kind": "ScalarField",
          "name": "payment_method",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "amount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "state",
          "storageKey": null
        },
        {
          "alias": "promotionAmount",
          "args": null,
          "kind": "ScalarField",
          "name": "promotion_amount",
          "storageKey": null
        },
        {
          "alias": "rideBalance",
          "args": null,
          "kind": "ScalarField",
          "name": "ride_balance",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Taxi",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '805084729dc6b5792013d09e6d6c40a2';

module.exports = node;
