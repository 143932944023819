/*
 * File Created: Monday, 22nd October 2018 2:25:28 pm
 * Author: xyy94813 (xyy94813@sina.com)
 * -----
 * Last Modified: Wednesday, 8th December 2021 3:54:11 pm
 * Modified By: xyy94813 (xyy94813@sina.com>)
 */

import { memo } from 'react';
import { useRelayEnvironment } from 'react-relay/hooks';
import { QueryRenderer } from 'react-relay';
import _ from 'lodash';

import RelayError from '../components/RelayError';
import createHOC from './createHOC';

const DEFAULT_OPTIONS = {
  defaultVariables: {},
  dataMapping: [['data', props => props.data]],
  shouldQuery: () => true,
};

/**
 * if get error, render `RelayError`
 * else render the callback values
 * @param {function(props, function)} callback
 */
export const QueryRendererWrapper =
  callback =>
  ({ error, props, retry }) => {
    if (error) {
      return <RelayError error={error} />;
    }
    return callback(props, retry);
  };

const createRelayRoot = (options = {}) =>
  createHOC('withRelayRoot', WrappedComp => {
    const {
      defaultVariables,
      dataMapping,
      query,
      shouldQuery: factoryShouldQuery,
    } = {
      ...DEFAULT_OPTIONS,
      ...options,
    };

    const RelayRoot = ({
      variables: propsVars = {},
      shouldQuery: propsShouldQuery,
      ...otherProps
    }) => {
      const relayEnv = useRelayEnvironment();
      const variables = {
        ...defaultVariables,
        ...propsVars,
      };
      const shouldQuery = propsShouldQuery ?? factoryShouldQuery;

      return (
        <QueryRenderer
          environment={relayEnv}
          query={shouldQuery(variables) ? query : null}
          variables={variables}
          render={QueryRendererWrapper(props => {
            const wrappedCompProps = _.assign(
              {},
              otherProps,
              {
                variables,
                loading: !props,
              },
              _.fromPairs(
                _.map(dataMapping, ([propsName, transformValFromProps]) => [
                  propsName,
                  props ? transformValFromProps(props) : null,
                ]),
              ),
            );
            return <WrappedComp {...wrappedCompProps} />;
          })}
        />
      );
    };

    RelayRoot.defaultProps = {
      variables: {},
    };

    return memo(RelayRoot);
  });

export default createRelayRoot;
