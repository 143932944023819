import _ from 'lodash';

// the fields of config in the server are different both of four withdrawal channel.
// it is hard to reuse these fields without mapping.

export const wechatPayConfigKeyMapping = value => {
  const MAPPING = {
    isActive: 'wechat_payout',
    isAutoProcess: 'auto_process_wechat_payout',
    title: 'wechat_title',
    minAmount: 'wechat_minimum_payout_amount',
    maxAmount: 'wechat_maximum_payout_amount',
  };

  return _.mapKeys(value, (value, key) => {
    return MAPPING[key];
  });
};

export const alipayConfigKeyMapping = value => {
  const MAPPING = {
    isActive: 'alipay_payout',
    isAutoProcess: 'auto_process_alipay_payout',
    title: 'alipay_title',
    minAmount: 'alipay_minimum_payout_amount',
    maxAmount: 'alipay_maximum_payout_amount',
  };

  return _.mapKeys(value, (value, key) => {
    return MAPPING[key];
  });
};

export const bankConfigKeyMapping = value => {
  const MAPPING = {
    isActive: 'bank_payout',
    isAutoProcess: 'auto_process_bank_payout',
    title: 'bank_title',
    minAmount: 'bank_minimum_payout_amount',
    maxAmount: 'bank_maximum_payout_amount',
  };

  return _.mapKeys(value, (value, key) => {
    return MAPPING[key];
  });
};

export const rewardConfigKeyMapping = value => {
  const MAPPING = {
    minAmount: 'reward_wechat_minimum_payout_amount',
    maxAmount: 'reward_wechat_maximum_payout_amount',
  };

  return _.mapKeys(value, (value, key) => {
    return MAPPING[key];
  });
};
