/**
 * Created by qiuyuzhou on 2017/5/7.
 */
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import schedulingReducer from './schedulingReducer';
import accountReduce from './account';
import collapsedReducer from './collapsed';

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    collapsed: collapsedReducer,
    account: accountReduce,
    scheduling: schedulingReducer,
  });

export default createRootReducer;
