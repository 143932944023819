import BigNumber from 'bignumber.js';
import _ from 'lodash';

// 使用 React.Children.map/toArray/xx 等方法来处理 children 时，
// 导致实际得到的 key 值会带有一个或多个 `.$` 前缀
// eg: <div key="divA" /> => { key: ".$divA", ... }
// export function transformKay(key) {
//   return key.replace(/^(\.\$)+/, '');
// }

export function transformFormattedPhoneNumber(formattedPhoneNum) {
  return _.trim(formattedPhoneNum).replace(/(-|——|\s)/g, '');
}

/**
 * ¥ 1,234.123 -> 1234.123
 *
 * Note!!!:
 * 123. -> 123.
 * ¥ 1,234.123.123 -> 1234.123
 *
 * @param {string} value
 * @return {string}
 */
export const numberParser = value => {
  // safari 不支持 反向肯定预查 和 反向否定预查
  // return _.replace(value, /[^\d.]|((?<=\..*?)\..*)/g, ''); // 不保留第二个小数点往后的值以及非数字字符（除第一个小数点）

  const removeLatterDecimalPoint = String(value)
    .split('.')
    .slice(0, 2)
    .join('.');
  return removeLatterDecimalPoint.replace(/[^\d.]/g, '');
};

export function amountFormatter(value) {
  value = numberParser(value);
  return `¥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function amountParser(value) {
  return numberParser(value);
}

export const percentFormatter = value => {
  value = value ?? null;
  if (value === null) return value;

  return `${numberParser(value)}%`;
};

export const percentParser = value => {
  value = value ?? null;
  if (value === null) return value;
  return amountParser(value);
};

/**
 * Safely deserialize serialized json String
 * when `JSON.parse` throw an error, return a empty object
 * @param {string} serializedString
 */
export function safelyJSONParse(serializedString) {
  try {
    return JSON.parse(serializedString);
  } catch (error) {
    return {};
  }
}

/**
 * 分转换成元
 * @param {number} fen
 */
export const fenToYuan = fen => {
  return new BigNumber(fen).dividedBy(100).toNumber();
};

/**
 * 元转换成分
 * @param {number} yuan
 */
export const yuanToFen = yuan => {
  return new BigNumber(yuan).multipliedBy(100).toNumber();
};

/**
 * 百分比转换成小数
 * @param {number} percent
 */
export const percentToDecimal = percent => {
  return new BigNumber(percent).dividedBy(100).toNumber();
};

/**
 * 小数转换成百分比
 * @param {number} decimal
 */
export const decimalToPercent = decimal => {
  return new BigNumber(decimal).multipliedBy(100).toNumber();
};
