import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'intersection-observer'; // core-js 中未包含 intersection-observer polyfill
import 'moment/locale/zh-cn';
import ReactDOM from 'react-dom';
import moment from 'moment';
// 推荐在入口文件全局设置 locale
import initSentry from './initSentry';

import Config from './config';
import App from './App';

import * as serviceWorker from './serviceWorker';

if (Config.sentryDSN) {
  initSentry();
}
// for https://lbs.amap.com/api/javascript-api/guide/abc/prepare
// TODO: lazy init
window._AMapSecurityConfig = {
  serviceHost: Config.amapJsApiUrl,
};

moment.locale('zh-cn');
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
