import { createElement } from 'react';
import { Button, Result } from 'antd';

import config from './typeConfig';

export default ({ linkElement = 'a', type, title, desc, actions, ...rest }) => {
  const pageType = type in config ? type : 500;

  return (
    <Result
      {...rest}
      status={pageType}
      title={title || config[pageType]?.title}
      subTitle={desc || config[pageType]?.desc}
      extra={
        actions ||
        createElement(
          linkElement,
          {
            to: '/',
            href: '/',
          },
          <Button type="primary">返回首页</Button>,
        )
      }
    />
  );
};
