/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type TaxiOrderList_data$ref = any;
export type TaxiOrderListQueryVariables = {|
  count: number,
  cursor?: ?string,
  q?: ?any,
|};
export type TaxiOrderListQueryResponse = {|
  +data: {|
    +$fragmentRefs: TaxiOrderList_data$ref
  |}
|};
export type TaxiOrderListQuery = {|
  variables: TaxiOrderListQueryVariables,
  response: TaxiOrderListQueryResponse,
|};
*/


/*
query TaxiOrderListQuery(
  $count: Int!
  $cursor: String
  $q: Json
) {
  data: relay {
    ...TaxiOrderList_data
  }
}

fragment TaxiOrderList_data on Relay {
  entities: taxis(first: $count, after: $cursor, q: $q) {
    edges {
      node {
        id
        passenger {
          id
          phoneNumber: phone_number
        }
        driver {
          id
          phoneNumber: phone_number
          taxiDriverDetail: taxi_driver_detail {
            realName: real_name
            id
          }
        }
        vehicle {
          plateNumber: plate_number
          id
        }
        status
        otherFee: other_fee
        taxiFee: taxi_fee
        tip
        rating
        createdAt: created_at
        payments {
          paymentMethod: payment_method
          id
        }
        zone {
          id
          province
          city
          district
        }
        ...TaxiOrderTable_data
        __typename
      }
      cursor
    }
    pageInfo {
      hasNextPage
      endCursor
    }
    totalCount
  }
}

fragment TaxiOrderTable_data on Taxi {
  id
  passenger {
    id
    phoneNumber: phone_number
  }
  driver {
    id
    phoneNumber: phone_number
    taxiDriverDetail: taxi_driver_detail {
      realName: real_name
      id
    }
  }
  vehicle {
    plateNumber: plate_number
    id
  }
  status
  otherFee: other_fee
  taxiFee: taxi_fee
  tip
  rating
  createdAt: created_at
  payments {
    paymentMethod: payment_method
    id
  }
  zone {
    id
    province
    city
    district
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "q"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Variable",
    "name": "q",
    "variableName": "q"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": "phoneNumber",
  "args": null,
  "kind": "ScalarField",
  "name": "phone_number",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TaxiOrderListQuery",
    "selections": [
      {
        "alias": "data",
        "args": null,
        "concreteType": "Relay",
        "kind": "LinkedField",
        "name": "relay",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TaxiOrderList_data"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TaxiOrderListQuery",
    "selections": [
      {
        "alias": "data",
        "args": null,
        "concreteType": "Relay",
        "kind": "LinkedField",
        "name": "relay",
        "plural": false,
        "selections": [
          {
            "alias": "entities",
            "args": (v1/*: any*/),
            "concreteType": "TaxiConnection",
            "kind": "LinkedField",
            "name": "taxis",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TaxiEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Taxi",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "passenger",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TaxiDriver",
                        "kind": "LinkedField",
                        "name": "driver",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": "taxiDriverDetail",
                            "args": null,
                            "concreteType": "TaxiDriverDetail",
                            "kind": "LinkedField",
                            "name": "taxi_driver_detail",
                            "plural": false,
                            "selections": [
                              {
                                "alias": "realName",
                                "args": null,
                                "kind": "ScalarField",
                                "name": "real_name",
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TaxiVehicleDetail",
                        "kind": "LinkedField",
                        "name": "vehicle",
                        "plural": false,
                        "selections": [
                          {
                            "alias": "plateNumber",
                            "args": null,
                            "kind": "ScalarField",
                            "name": "plate_number",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      {
                        "alias": "otherFee",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "other_fee",
                        "storageKey": null
                      },
                      {
                        "alias": "taxiFee",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "taxi_fee",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "tip",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "rating",
                        "storageKey": null
                      },
                      {
                        "alias": "createdAt",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "created_at",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Payment",
                        "kind": "LinkedField",
                        "name": "payments",
                        "plural": true,
                        "selections": [
                          {
                            "alias": "paymentMethod",
                            "args": null,
                            "kind": "ScalarField",
                            "name": "payment_method",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Zone",
                        "kind": "LinkedField",
                        "name": "zone",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "province",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "city",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "district",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCount",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "entities",
            "args": (v1/*: any*/),
            "filters": [
              "q"
            ],
            "handle": "connection",
            "key": "TaxiOrderList_entities",
            "kind": "LinkedHandle",
            "name": "taxis"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "688276618848e6dec2eff3c360f5b8f3",
    "id": null,
    "metadata": {},
    "name": "TaxiOrderListQuery",
    "operationKind": "query",
    "text": "query TaxiOrderListQuery(\n  $count: Int!\n  $cursor: String\n  $q: Json\n) {\n  data: relay {\n    ...TaxiOrderList_data\n  }\n}\n\nfragment TaxiOrderList_data on Relay {\n  entities: taxis(first: $count, after: $cursor, q: $q) {\n    edges {\n      node {\n        id\n        passenger {\n          id\n          phoneNumber: phone_number\n        }\n        driver {\n          id\n          phoneNumber: phone_number\n          taxiDriverDetail: taxi_driver_detail {\n            realName: real_name\n            id\n          }\n        }\n        vehicle {\n          plateNumber: plate_number\n          id\n        }\n        status\n        otherFee: other_fee\n        taxiFee: taxi_fee\n        tip\n        rating\n        createdAt: created_at\n        payments {\n          paymentMethod: payment_method\n          id\n        }\n        zone {\n          id\n          province\n          city\n          district\n        }\n        ...TaxiOrderTable_data\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    totalCount\n  }\n}\n\nfragment TaxiOrderTable_data on Taxi {\n  id\n  passenger {\n    id\n    phoneNumber: phone_number\n  }\n  driver {\n    id\n    phoneNumber: phone_number\n    taxiDriverDetail: taxi_driver_detail {\n      realName: real_name\n      id\n    }\n  }\n  vehicle {\n    plateNumber: plate_number\n    id\n  }\n  status\n  otherFee: other_fee\n  taxiFee: taxi_fee\n  tip\n  rating\n  createdAt: created_at\n  payments {\n    paymentMethod: payment_method\n    id\n  }\n  zone {\n    id\n    province\n    city\n    district\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e5cf46c14d30f2c6e2922ea77eb90649';

module.exports = node;
