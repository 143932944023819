import { decodeBase64Str, encodeBase64 } from './base64';

export const decodeGraphQLGlobalID = input => {
  try {
    // Note: 部分模型 raw id 不是数字符串
    const str = decodeBase64Str(input);
    const regexp = /.+?[-_]([^-_]+?)$/g;
    if (!regexp.test(str)) {
      throw Error('Incorrect graphql id');
    }
    return str.replace(regexp, '$1');
  } catch (error) {
    return input;
  }
};

export const encodeGraphQLGlobalID = (typeName, originId) => {
  return encodeBase64(`${typeName}-${originId}`);
};
