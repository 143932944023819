import Keycloak from 'keycloak-js';

import Config from './config';

const keycloakInstance = new Keycloak({
  realm: Config.keycloakRealm,
  url: Config.keycloakServerURL,
  clientId: Config.keycloakClientId,
  verifyTokenAudience: true,
  sslRequired: 'external',
  publicClient: true,
  useResourceRoleMappings: true,
  confidentialPort: 0,
  policyEnforcer: {},
  onLoad: 'check-sso',
  promiseType: 'native',
  enableLogging: process.env.NODE_ENV === 'development',
});

export default keycloakInstance;
