/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type WithdrawalConfigPage_data$ref = any;
export type GlobalConfigInput = {|
  clientMutationId?: ?string,
  bank_title?: ?string,
  wechat_title?: ?string,
  alipay_title?: ?string,
  wechat_minimum_payout_amount?: ?any,
  wechat_maximum_payout_amount?: ?any,
  alipay_minimum_payout_amount?: ?any,
  alipay_maximum_payout_amount?: ?any,
  bank_minimum_payout_amount?: ?any,
  bank_maximum_payout_amount?: ?any,
  wechat_payout?: ?boolean,
  alipay_payout?: ?boolean,
  bank_payout?: ?boolean,
  auto_process_wechat_payout?: ?boolean,
  auto_process_alipay_payout?: ?boolean,
  auto_process_bank_payout?: ?boolean,
  driver_bonus_count?: ?number,
  reward_wechat_minimum_payout_amount?: ?any,
  reward_wechat_maximum_payout_amount?: ?any,
  autoProcessInvoicing?: ?boolean,
  autoProcessInvoicingDelay?: ?number,
|};
export type updateWithdrawalConfigMutationVariables = {|
  input: GlobalConfigInput
|};
export type updateWithdrawalConfigMutationResponse = {|
  +setGlobalConfig: ?{|
    +global_config: ?{|
      +id: string,
      +$fragmentRefs: WithdrawalConfigPage_data$ref,
    |},
    +error: ?{|
      +errors: $ReadOnlyArray<?string>
    |},
  |}
|};
export type updateWithdrawalConfigMutation = {|
  variables: updateWithdrawalConfigMutationVariables,
  response: updateWithdrawalConfigMutationResponse,
|};
*/


/*
mutation updateWithdrawalConfigMutation(
  $input: GlobalConfigInput!
) {
  setGlobalConfig(input: $input) {
    global_config {
      id
      ...WithdrawalConfigPage_data
    }
    error {
      errors
    }
  }
}

fragment WithdrawalConfigPage_data on GlobalConfig {
  id
  wechat_payout
  auto_process_wechat_payout
  wechat_title
  wechat_minimum_payout_amount
  wechat_maximum_payout_amount
  alipay_payout
  auto_process_alipay_payout
  alipay_title
  alipay_minimum_payout_amount
  alipay_maximum_payout_amount
  bank_payout
  auto_process_bank_payout
  bank_title
  bank_minimum_payout_amount
  bank_maximum_payout_amount
  reward_wechat_minimum_payout_amount
  reward_wechat_maximum_payout_amount
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Error",
  "kind": "LinkedField",
  "name": "error",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "errors",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updateWithdrawalConfigMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GlobalConfigPayload",
        "kind": "LinkedField",
        "name": "setGlobalConfig",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "GlobalConfig",
            "kind": "LinkedField",
            "name": "global_config",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "WithdrawalConfigPage_data"
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updateWithdrawalConfigMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GlobalConfigPayload",
        "kind": "LinkedField",
        "name": "setGlobalConfig",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "GlobalConfig",
            "kind": "LinkedField",
            "name": "global_config",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "wechat_payout",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "auto_process_wechat_payout",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "wechat_title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "wechat_minimum_payout_amount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "wechat_maximum_payout_amount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "alipay_payout",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "auto_process_alipay_payout",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "alipay_title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "alipay_minimum_payout_amount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "alipay_maximum_payout_amount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bank_payout",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "auto_process_bank_payout",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bank_title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bank_minimum_payout_amount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bank_maximum_payout_amount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "reward_wechat_minimum_payout_amount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "reward_wechat_maximum_payout_amount",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f3c675d5ee7f2a526d285e009819f67b",
    "id": null,
    "metadata": {},
    "name": "updateWithdrawalConfigMutation",
    "operationKind": "mutation",
    "text": "mutation updateWithdrawalConfigMutation(\n  $input: GlobalConfigInput!\n) {\n  setGlobalConfig(input: $input) {\n    global_config {\n      id\n      ...WithdrawalConfigPage_data\n    }\n    error {\n      errors\n    }\n  }\n}\n\nfragment WithdrawalConfigPage_data on GlobalConfig {\n  id\n  wechat_payout\n  auto_process_wechat_payout\n  wechat_title\n  wechat_minimum_payout_amount\n  wechat_maximum_payout_amount\n  alipay_payout\n  auto_process_alipay_payout\n  alipay_title\n  alipay_minimum_payout_amount\n  alipay_maximum_payout_amount\n  bank_payout\n  auto_process_bank_payout\n  bank_title\n  bank_minimum_payout_amount\n  bank_maximum_payout_amount\n  reward_wechat_minimum_payout_amount\n  reward_wechat_maximum_payout_amount\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'abf08db592c1e550f947687485408db7';

module.exports = node;
