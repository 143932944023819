/**
 * Like `FormModal`, for support Antd V4
 */
import { Children, isValidElement, cloneElement, memo } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal } from 'antd';

import AsyncModal from '../AsyncModal';

function ModalForm({ children, onSubmit, afterClose, ...modalProps }) {
  const [form] = Form.useForm();

  let newChildren;

  if (typeof children === 'function') {
    newChildren = children(form);
  } else {
    newChildren = Children.map(children, item =>
      isValidElement(item)
        ? cloneElement(item, { form, onFinish: onSubmit })
        : item,
    );
  }

  return (
    <AsyncModal
      maskClosable={false}
      {...modalProps}
      afterClose={(...args) => {
        form.resetFields();
        afterClose && afterClose(...args);
      }}
      onOk={() => {
        form.submit();
      }}
    >
      {newChildren}
    </AsyncModal>
  );
}

ModalForm.propTypes = {
  ...Modal.propTypes,
  onSubmit: PropTypes.func,
  afterClose: PropTypes.func,
};

export default memo(ModalForm);
