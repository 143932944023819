import { memo, useMemo } from 'react';
import { createPaginationContainer, graphql } from 'react-relay';
import _ from 'lodash';

import { withInfiniteConnection } from '../../components/InfiniteLoadMore';
import { useLocationQ } from '../../components/SmartTable2';
import createRelayRoot from '../../utils/createRelayRoot';

import SmartTaxiVehicleTable from './SmartTaxiVehicleTable';

const HISTORY_STATE_KEY = 'SmartTaxiVehicleTable';

const query = graphql`
  query TaxiVehicleListQuery($count: Int!, $cursor: String, $q: Json) {
    data: relay {
      ...TaxiVehicleList_data
    }
  }
`;

const TaxiVehicleList = createPaginationContainer(
  withInfiniteConnection(SmartTaxiVehicleTable),
  {
    data: graphql`
      fragment TaxiVehicleList_data on Relay {
        entities: taxi_vehicles(first: $count, after: $cursor, q: $q)
          @connection(key: "TaxiVehicleList_entities") {
          edges {
            node {
              ...TaxiVehicleTable_data
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
          totalCount
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.data?.entities;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      const { variables = {} } = props;
      return {
        ...fragmentVariables,
        count,
        ...variables,
        cursor,
      };
    },
    query,
  },
);

const TaxiVehicleListRoot = createRelayRoot({
  defaultVariables: {
    count: 25,
  },
  query,
})(TaxiVehicleList);

const DEFAULT_Q = {
  s: 'id desc',
};

export default memo(function ({
  historyStateKey: propHistoryStateKey,
  taxiDriverDetailAuth,
  editAuth,
  addAuth,
}) {
  const historyStateKey = propHistoryStateKey || HISTORY_STATE_KEY;
  const locationQ = useLocationQ(historyStateKey, DEFAULT_Q);
  const variables = useMemo(
    () => _.merge({ q: JSON.stringify(locationQ) }),
    [locationQ],
  );
  return (
    <TaxiVehicleListRoot
      variables={variables}
      taxiDriverDetailAuth={taxiDriverDetailAuth}
      addAuth={addAuth}
      editAuth={editAuth}
    />
  );
});
