import { Link } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';

import Exception from '../components/Exception';
import { isNotLoggedIn } from '../utils/keycloakUtils';

import { useCurClientRoles } from '../hooks';
import isAuthorizedRoute from './isAuthorizedRoute';

const withAuthRoute = WrappedComponent => {
  const AuthRouteWrapper = props => {
    const { route } = props;
    const { keycloak } = useKeycloak();
    const roles = useCurClientRoles();

    if (isNotLoggedIn(keycloak)) {
      return null;
    }

    if (!isAuthorizedRoute(route, roles)) {
      return <Exception type={403} linkElement={Link} />;
    }

    return <WrappedComponent {...props} />;
  };

  return AuthRouteWrapper;
};

export default withAuthRoute;
