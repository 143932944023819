import _ from 'lodash';

import {
  useLocationFilter,
  useLocationSorter,
} from '../../components/SmartTable2';
import { getNameByAddressCode } from '../../components/AreaSelect';

export const useLocationQ = historyStateKey => {
  const filtersState = useLocationFilter(historyStateKey);
  const sorterState = useLocationSorter(historyStateKey);
  const locationQ = {};

  if (_.isEmpty(sorterState)) {
    locationQ.s = 'id desc';
  } else if (sorterState?.field === 'taxiFee') {
    locationQ.s = `taxi_fee ${sorterState?.order}`;
  } else if (sorterState?.field === 'otherFee') {
    locationQ.s = `other_fee ${sorterState?.order}`;
  } else if (sorterState?.field === 'createdAt') {
    locationQ.s = `created_at ${sorterState?.order}`;
  } else {
    locationQ.s = `${sorterState?.field} ${sorterState?.order}`;
  }

  locationQ.id_eq = filtersState?.id?.value;
  locationQ.passenger_phone_number_eq =
    filtersState?.passengerPhoneNumber?.value;
  locationQ.driver_phone_number_eq = filtersState?.driverPhoneNumber?.value;

  locationQ.created_at_gteq = filtersState?.registrationDate?.value?.[0];
  locationQ.created_at_lteq = filtersState?.registrationDate?.value?.[1];

  if (
    !_.isEmpty(filtersState?.paymentMethod?.value) &&
    filtersState?.paymentMethod?.value?.length === 1
  ) {
    locationQ.payments_payment_method_name_eq =
      filtersState?.paymentMethod?.value[0];
    locationQ.payments_state_eq = 'completed';
  } else if (
    !_.isEmpty(filtersState?.paymentMethod?.value) &&
    filtersState?.paymentMethod?.value?.length !== 1
  ) {
    locationQ.payments_payment_method_name_in =
      filtersState?.paymentMethod?.value;
    locationQ.payments_state_eq = 'completed';
  } else {
    locationQ.payments_state_eq = null;
  }

  const zoneValue = filtersState?.zone?.value;
  if (zoneValue?.length === 1) {
    const areaName = getNameByAddressCode(zoneValue[0]);
    locationQ.zone_province_cont = _.replace(areaName, '市', ''); // 特殊处理直辖市，北京市的 province 为 "北京"
  } else if (zoneValue?.length === 2) {
    let areaName = getNameByAddressCode(zoneValue[1]);
    if (
      ['北京城区', '天津城区', '上海城区', '重庆城区', '重庆郊县'].includes(
        areaName,
      )
    ) {
      areaName = _.replace(areaName, /(城区|郊县)/, '市'); // 这部分数据server数据为 “北京市”
    }
    locationQ.zone_city_cont = areaName;
  } else if (zoneValue?.length === 3) {
    const areaName = getNameByAddressCode(zoneValue[2]);
    locationQ.zone_district_cont = areaName;
  }

  if (
    !_.isEmpty(filtersState?.status?.value) &&
    filtersState?.status?.value?.length === 1
  ) {
    locationQ.status_eq = filtersState?.status?.value[0];
  } else if (
    !_.isEmpty(filtersState?.status?.value) &&
    filtersState?.status?.value?.length !== 1
  ) {
    locationQ.status_in = filtersState?.status?.value;
  }

  return locationQ;
};
