import React, { useCallback, useState, useRef } from 'react';
import { compile } from 'path-to-regexp';
import { graphql, useFragment } from 'react-relay/hooks';
import { Table, message } from 'antd';
import _ from 'lodash';

import { encodeGraphQLGlobalID } from '../../../utils/graphQLGlobalID';
import AuthLink from '../../../components/AuthLink';
import ModalContainer, {
  safelyOpenModalByRef,
} from '../../../components/ModalContainer';
import { decodeGraphQLGlobalID } from '../../../utils/graphQLGlobalID';
import LinkButton from '../../../components/LinkButton';
import LocalDateTime from '../../../components/LocalDateTime';
import { insertDivider } from '../../../utils/dividerUtil';
import {
  fillKeyByDataIndex,
  getColumnFilter,
  getColumnMerger,
} from '../../../utils/tableColumnHelper';

import TaxiVehicleForm from '../TaxiVehicleForm';
import ModalForm from '../../../components/ModalForm';
import { useSDMutation } from '../../../hooks';

const DateOnlyISOFormat = 'YYYY-MM-DD';

const fragment = graphql`
  fragment TaxiVehicleTable_data on TaxiVehicleDetail @relay(plural: true) {
    id
    plateNumber: plate_number
    registrationDate: registration_date
    tradingCertificate: trading_certificate
    tradeExpireDate: trade_expire_date
    vin
    company {
      id
      name
    }
    driverDetails: driver_details {
      id
      driverId: driver_id
      realName: real_name
    }
  }
`;
const editTaxiVehicleMutation = graphql`
  mutation TaxiVehicleTableMutation($input: UpdateTaxiVehicleDetailInput!) {
    updateTaxiVehicleDetail(input: $input) {
      error {
        errors
      }
      vehicleDetail: vehicle_detail {
        ...TaxiVehicleTable_data
      }
    }
  }
`;

function transformEditValue(formValues) {
  if (!_.isEmpty(formValues.plateNumber)) {
    const _new_plate_number =
      formValues.plateNumber.province + formValues.plateNumber.number;

    formValues.plateNumber = _new_plate_number;
  }

  if (!_.isEmpty(formValues.registrationDate)) {
    const _new_registration_date =
      formValues.registrationDate.format(DateOnlyISOFormat);
    formValues.registrationDate = _new_registration_date;
  }

  if (!_.isEmpty(formValues.tradeExpireDate)) {
    const _new_trade_expire_date = !!formValues.tradeExpireDate
      ? formValues.tradeExpireDate.format(DateOnlyISOFormat)
      : null;
    formValues.tradeExpireDate = _new_trade_expire_date;
  }

  return formValues;
}

const dateRender = value => (
  <LocalDateTime date={value} format={DateOnlyISOFormat} />
);

const idNodeLinkRender = id => decodeGraphQLGlobalID(id);
const getTaxiDriverDetailsPagePath = compile('/taxi/taxi-drivers/:id', {
  encode: encodeURIComponent,
});

const TaxiVehicleTable = ({
  loading,
  entities,
  onChange,
  taxiDriverDetailAuth,
  editAuth,
  hideColumnKeys = [],
  extendsColProps = {},
}) => {
  const data = useFragment(fragment, entities);
  const [editRecord, setEditedRecord] = useState(null);
  const $editTaxiVehicle = useRef(null);

  const [editTaxiVehicle] = useSDMutation(editTaxiVehicleMutation);

  const handleEditBtnClick = useCallback(editRecord => {
    safelyOpenModalByRef($editTaxiVehicle);
    setEditedRecord(editRecord);
  }, []);

  const actionsRender = (id, editRecord, index) => {
    return (
      editAuth && (
        <LinkButton
          key="editButton"
          onClick={() => handleEditBtnClick(editRecord)}
        >
          编辑
        </LinkButton>
      )
    );
  };

  const driversRender = (value, r) => {
    const driversWithLink = value
      ?.filter(item => !!(item && item.driverId))
      ?.map(({ driverId, realName }) => {
        const taxiDriverId = encodeGraphQLGlobalID('TaxiDriver', driverId);
        if (taxiDriverDetailAuth) {
          return (
            <AuthLink
              key={taxiDriverId}
              to={getTaxiDriverDetailsPagePath({ id: taxiDriverId })}
            >
              {realName}
            </AuthLink>
          );
        }
        return `${taxiDriverId}-${realName}`;
      });

    return insertDivider(driversWithLink);
  };

  const renderEditedModal = useCallback(
    ({ visible, hide }) => {
      const handleEditVehicle = async values => {
        transformEditValue(values);
        let specificValue = _.omitBy(
          values,
          (val, key) => val === editRecord[key],
        );
        if (_.isEmpty(specificValue)) {
          return hide();
        }

        const inputValues = {
          company_id: !!specificValue?.company
            ? decodeGraphQLGlobalID(specificValue.company?.id)
            : undefined,
          plate_number: !!specificValue?.plateNumber
            ? specificValue?.plateNumber
            : undefined,
          vin: !!specificValue?.vin ? specificValue?.vin : undefined,
          registration_date: !!specificValue?.registrationDate
            ? specificValue?.registrationDate
            : undefined,
          trading_certificate: !!specificValue?.tradingCertificate
            ? specificValue?.tradingCertificate
            : undefined,
          trade_expire_date: !!specificValue?.tradeExpireDate
            ? specificValue?.tradeExpireDate
            : undefined,
        };

        const variables = {
          input: {
            id: editRecord?.id,
            ...inputValues,
          },
        };

        try {
          await editTaxiVehicle({ variables });
          message.success('成功修改车辆！');
          hide();
          setEditedRecord(null);
        } catch (error) {
          message.error(error.message);
        }
      };

      return (
        <ModalForm
          key={editRecord?.id}
          title="修改车辆信息"
          open={visible}
          onCancel={hide}
        >
          {form => {
            return (
              <TaxiVehicleForm
                form={form}
                onFinish={handleEditVehicle}
                initialValues={editRecord}
              />
            );
          }}
        </ModalForm>
      );
    },
    [editRecord, editTaxiVehicle],
  );

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 90,
      render: idNodeLinkRender,
    },
    {
      title: '车牌',
      dataIndex: 'plateNumber',
      width: 100,
    },
    {
      title: '出租车运输证号',
      dataIndex: 'tradingCertificate',
      width: 150,
    },
    {
      title: '关联司机',
      dataIndex: 'driverDetails',
      width: 80,
      render: driversRender,
    },
    {
      title: '所属公司',
      dataIndex: ['company', 'name'],
      width: 120,
    },
    {
      title: '运营资格截止日期',
      dataIndex: 'tradeExpireDate',
      width: 180,
      render: dateRender,
    },
    {
      title: '注册日期',
      dataIndex: 'registrationDate',
      width: 120,
      render: dateRender,
    },
    {
      title: '操作',
      dataIndex: 'id',
      key: 'actions',
      render: actionsRender,
    },
  ];

  const _hideColumnKeys = [...hideColumnKeys];

  if (!editAuth) {
    _hideColumnKeys.push('actions');
  }
  const finalColumns = _.flow([
    fillKeyByDataIndex,
    getColumnFilter(_hideColumnKeys),
    getColumnMerger(extendsColProps),
  ])(columns);

  return (
    <>
      <Table
        rowKey="id"
        size="middle"
        columns={finalColumns}
        loading={loading}
        dataSource={data}
        pagination={false}
        onChange={onChange}
      />
      {editAuth && (
        <ModalContainer ref={$editTaxiVehicle}>
          {renderEditedModal}
        </ModalContainer>
      )}
    </>
  );
};

export default TaxiVehicleTable;
