/**
 * Created by qiuyuzhou on 2017/5/7.
 */

import { of, from } from 'rxjs';
import {
  map,
  catchError,
  mergeMap,
  mapTo,
  ignoreElements,
} from 'rxjs/operators';
import { combineEpics } from 'redux-observable';
import { fetchQuery, graphql } from 'relay-runtime';

import {
  SIGNED_IN,
  SIGNED_OUT,
  FETCH_VIEWER,
  fetchViewer,
  fetchViewerFulfilled,
  fetchFailed,
} from '../actions';
import { environment as relayEnv } from '../../configureRelay';
import keycloak from '../../configureKeycloak';
import { getCurClientRolesFromKeycloak } from '../../utils/keycloakUtils';

const signedInEpic = action$ =>
  action$.ofType(SIGNED_IN).pipe(mapTo(fetchViewer()));

const fetchViewerFromServer = () =>
  fetchQuery(
    relayEnv,
    graphql`
      query accountQuery {
        viewer: adminViewer {
          id
          username
          capacitySupplier {
            id
            name
          }
          operationCenter {
            id
            name
          }
          operationCenterGroup {
            id
            name
          }
        }
      }
    `,
    {},
  );

// Error handle see: https://redux-observable.js.org/docs/recipes/ErrorHandling.html
const fetchViewerEpic = action$ =>
  action$.ofType(FETCH_VIEWER).pipe(
    mergeMap(action =>
      from(
        fetchViewerFromServer().then(data => ({
          ...data.viewer,
          // 临时兼容方案，待 `isAuthorized` 相关页面改进完成后需要去掉，
          roles: getCurClientRolesFromKeycloak(keycloak),
        })),
      ).pipe(
        map(viewer => fetchViewerFulfilled(viewer)),
        catchError(error => of(fetchFailed(error))),
      ),
    ),
  );

const signOutEpic = action$ =>
  action$
    .ofType(SIGNED_OUT)
    .pipe(map(action => action))
    .pipe(ignoreElements());

//----------------------------------------------------------------------

const accountEpic = combineEpics(signedInEpic, fetchViewerEpic, signOutEpic);

export default accountEpic;
