import _ from 'lodash';

const ACTION_TYPE = Object.freeze({
  ACTION: 'action',
  VIEW: 'view',
});

/**
 * 有效的类型
 *
 * @param {string} type
 */
const isValidType = type => _.includes(Object.values(ACTION_TYPE), type);

/**
 *
 * @param {string} resourceId page path or '**'
 * @param {string} type action or 'view'
 * @param {string} funcKey actual function key
 */
const createPermissionKey = (resourceId, type, funcKey) => {
  if (typeof resourceId !== 'string') {
    throw Error('Invalid resource id');
  }

  if (!isValidType(type)) {
    throw Error(
      `Invalid type, it should be one of '${Object.values(
        ACTION_TYPE,
      ).toString()}'`,
    );
  }

  if (typeof funcKey !== 'string') {
    throw Error('Invalid function key');
  }
  return [resourceId, type, funcKey].join('_');
};

createPermissionKey.ACTION_TYPE = ACTION_TYPE;

export default createPermissionKey;
