const getSDErrorMsg = error => error?.errors?.[0];
const getNeoErrorMsg = error => error?.message;

/**
 *
 * 此处同时处理了 shundao backend  和 neo 的 error 格式
 * 不确定这中实现方式是否合理
 *
 * @param {Object} error
 * @returns
 */
const getErrorMsg = error => getNeoErrorMsg(error) ?? getSDErrorMsg(error);

export const checkMutationPayload = payload => {
  const { error } = payload;
  if (error) {
    const errMsg = getErrorMsg(error) ?? 'Unknown error';
    const err = new Error(errMsg);
    err.data = payload;
    throw err;
  }
  return payload;
};

/**
 * check the mutation response for neo end-point
 *
 * @param {Object} mutationPayload mutation payload
 *
 */
export function checkMutationForNeo(mutationPayload) {
  return checkMutationPayload(mutationPayload);
}
