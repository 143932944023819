import { lazy } from 'react';
import { UserAddOutlined } from '@ant-design/icons';

import Section from '../../components/Section';

import { authorizedRouteWrapper } from '../../auth/authorization';

export default {
  path: 'recruitment',
  name: '司机招募',
  icon: <UserAddOutlined />,
  component: Section,
  resource_id: '/recruitment',
  leftNav: true,
  routes: [
    {
      path: 'applications',
      name: '审核工作台',
      resource_id: '/recruitment/applications',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "RecruitApplicationListPage" */ '../../views/RecruitApplicationListPage'
            ),
        ),
      ),
      leftNav: true,
      exact: true,
    },
    {
      path: 'applications/:id',
      resource_id: '/recruitment/applications/:id',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "RecruitApplicationDetailsPage" */ '../../views/RecruitApplicationDetailsPage'
            ),
        ),
      ),
      leftNav: false,
      exact: true,
    },
    {
      path: 'driver-posts/create-post',
      name: '创建招募',
      resource_id: '/recruitment/driver-posts/create-post',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "DriverPostCreatingPage" */ '../../views/DriverPostCreatingPage'
            ),
        ),
      ),
      leftNav: false,
      exact: true,
    },
    {
      path: 'driver-posts',
      name: '职位列表',
      resource_id: '/recruitment/driver-posts',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "DriverRecruitmentListPage" */ '../../views/DriverPostListPage'
            ),
        ),
      ),
      leftNav: true,
      exact: true,
    },
    {
      path: 'driver-posts/:id/edit',
      name: '编辑职位',
      resource_id: '/recruitment/driver-posts/:id/edit',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "DriverPostEditingPage" */ '../../views/DriverPostEditingPage'
            ),
        ),
      ),
      exact: true,
    },
  ],
};
