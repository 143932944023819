/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateCompanyInput = {|
  clientMutationId?: ?string,
  name: string,
  province: string,
  city: string,
  phone_number: string,
|};
export type TaxiCompanyListCreateCompanyMutationVariables = {|
  input: CreateCompanyInput
|};
export type TaxiCompanyListCreateCompanyMutationResponse = {|
  +createCompany: ?{|
    +error: ?{|
      +errors: $ReadOnlyArray<?string>
    |},
    +company: ?{|
      +id: string,
      +name: ?string,
      +city: ?string,
      +province: ?string,
      +phone_number: ?string,
    |},
  |}
|};
export type TaxiCompanyListCreateCompanyMutation = {|
  variables: TaxiCompanyListCreateCompanyMutationVariables,
  response: TaxiCompanyListCreateCompanyMutationResponse,
|};
*/


/*
mutation TaxiCompanyListCreateCompanyMutation(
  $input: CreateCompanyInput!
) {
  createCompany(input: $input) {
    error {
      errors
    }
    company {
      id
      name
      city
      province
      phone_number
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateCompanyPayload",
    "kind": "LinkedField",
    "name": "createCompany",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Error",
        "kind": "LinkedField",
        "name": "error",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "errors",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Company",
        "kind": "LinkedField",
        "name": "company",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "city",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "province",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phone_number",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TaxiCompanyListCreateCompanyMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TaxiCompanyListCreateCompanyMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "516edc2fb2de932299f1da3d35498fdf",
    "id": null,
    "metadata": {},
    "name": "TaxiCompanyListCreateCompanyMutation",
    "operationKind": "mutation",
    "text": "mutation TaxiCompanyListCreateCompanyMutation(\n  $input: CreateCompanyInput!\n) {\n  createCompany(input: $input) {\n    error {\n      errors\n    }\n    company {\n      id\n      name\n      city\n      province\n      phone_number\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0871fff53e3c331c071a271c56f06d68';

module.exports = node;
