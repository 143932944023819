import { withErrorBoundary } from '../../components/ErrorBoundary';

export const getFullPath = (path, parentPath = '') => {
  if (!path) {
    return parentPath;
  } else if (path.startsWith('/')) {
    return path;
  } else if (parentPath.endsWith('/')) {
    return `${parentPath}${path}`;
  } else {
    return `${parentPath}/${path}`;
  }
};

export const transformRoutes = (routes, parentPath) =>
  routes.map(({ routes = [], path, component, ...otherConfig }) => {
    // 补全路径
    const fullPath = getFullPath(path, parentPath);

    return {
      ...otherConfig,
      path: fullPath,
      component: withErrorBoundary(component),
      routes: transformRoutes(routes, fullPath),
    };
  });
