/**
 * 参考 https://github.com/airbnb/react-create-hoc
 */
import { isForwardRef, Element, isMemo } from 'react-is';

const getComponentName = Component => {
  if (typeof Component === 'string') {
    return Component;
  }
  if (typeof Component === 'function') {
    return Component.displayName || Component.name || 'Anonymous';
  }
  const mockRCElement = { type: Component, $$typeof: Element };
  if (isForwardRef(mockRCElement)) {
    return Component.displayName;
  }
  if (isMemo(mockRCElement)) {
    return getComponentName(Component.type);
  }
  return null;
};

export default getComponentName;
