import { Environment, RecordSource, Store, Observable } from 'relay-runtime';
import {
  RelayNetworkLayer,
  urlMiddleware,
  loggerMiddleware,
  errorMiddleware,
  perfMiddleware,
  retryMiddleware,
  authMiddleware,
  cacheMiddleware,
} from 'react-relay-network-modern/es';
import { createConsumer } from '@rails/actioncable';
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';
import {
  createLegacyRelaySubscriptionHandler as createHandler, // for Relay v11 need to use createRelaySubscriptionHandler
} from 'graphql-ruby-client/subscriptions/createRelaySubscriptionHandler';
import { getCurrentHub } from '@sentry/react';
import sentryMiddleware from 'rrnl-sentry-middleware';
import {
  createRequestLimiterMiddleware,
  TokenBucketRateLimiter,
} from 'rrnl-request-limiter-middleware';

import keycloak from './configureKeycloak';

import Config from './config';

const __DEV__ = process.env.NODE_ENV === 'development';
const MAX_RETRY_TIMES = 2;

const subscriptionClientHub = new Map();

function getSubscriptionClient(token) {
  if (!token) {
    throw Error('Need to login');
  }

  if (subscriptionClientHub.has(token)) {
    return subscriptionClientHub.get(token);
  }

  const WSS_SERVER_URL = new URL(
    `${Config.serverURL.replace(/^http/, 'ws')}/cable`,
  );

  WSS_SERVER_URL.search = `?token=${token}`; // 更好的兼容性

  /**
   * More option usage see:
   * https://graphql-ruby.org/javascript_client/relay_subscriptions#actioncable
   */
  const subscriptionClient = createConsumer(WSS_SERVER_URL);
  new ActionCableLink({
    cable: subscriptionClient,
    options: {
      reconnect: true,
    },
  });

  subscriptionClientHub.set(token, subscriptionClient);

  return subscriptionClientHub.get(token);
}

/**
 * Base on react-relay-network-modern
 * see https://github.com/relay-tools/react-relay-network-modern
 */
const network = new RelayNetworkLayer(
  [
    cacheMiddleware({
      size: 20, // max 20 requests
      ttl: 3_000, // 3s
    }),
    createRequestLimiterMiddleware(
      new TokenBucketRateLimiter(20, 3_000),
      'wait',
    ),
    urlMiddleware({
      url: () => `${Config.serverURL}/graphql`,
    }),
    __DEV__ ? loggerMiddleware() : null,
    __DEV__ ? errorMiddleware() : null,
    __DEV__ ? perfMiddleware() : null,
    authMiddleware({
      token: () => {
        // why return NULL
        // https://github.com/relay-tools/react-relay-network-modern/blob/47806aa905702436307c00a192354540629ac5b6/src/middlewares/auth.js#L51
        return keycloak ? keycloak.token : null;
      },
      tokenRefreshPromise: (req, res) =>
        new Promise((resolve, reject) => {
          keycloak
            .updateToken(5)
            .success(refreshed => {
              if (refreshed) {
                return resolve(keycloak.token);
              }
            })
            .error(reject);
        }).catch(err => {
          keycloak.login(); // refresh 失败重新登录
          throw err;
        }),
    }),

    retryMiddleware({
      allowMutations: false,
      allowFormData: true,
      /**
       * [5000 ,10000, 30000, 30000, ...],
       * dp[0] = 5; dp[n] = dp[n - 1] * (n + 1), max is 30 * 1000;
       * fetchTimeout default is 15 * 1000
       * @param {number} attempt
       * @returns number
       */
      fetchTimeout: function getTimeout(attempt) {
        if (attempt >= 2) return 30 * 1000;
        else if (attempt === 1) return 10 * 1000;
        else return 5 * 1000;
      },
      retryDelays: attempt => Math.pow(2, attempt + 4) * 100, // or simple array [3200, 6400, 12800, 25600, 51200, 102400, 204800, 409600],
      beforeRetry: ({ forceRetry, abort, delay, attempt, lastError, req }) => {
        if (attempt > MAX_RETRY_TIMES) {
          abort('网络异常，请求超出重试次数');
        }
      },
      statusCodes: (statusCode, req, res) =>
        statusCode < 200 || statusCode === 408,
    }),
    Config.sentryDSN
      ? sentryMiddleware({
          hub: getCurrentHub,
        })
      : null,
    /**
     * Handle server response 500 with a html document
     * Change the error message to easy to read for user
     */
    next => async req => {
      const res = await next(req).catch(error => {
        if (
          error.res &&
          error.res.status === 500 &&
          /<html>/.test(error.message)
        ) {
          error.message = ` 服务器异常 (500): {${req.operation.name}}`;
        }
        throw error;
      });
      return res;
    },
  ],
  {
    // noThrow: true,
    subscribeFn: (config, variables, cacheConfig) => {
      return Observable.create(sink => {
        createHandler({
          cable: getSubscriptionClient(keycloak.token),
        })(config, variables, cacheConfig, {
          onNext: sink.next,
          onError: sink.error,
          onCompleted: sink.complete,
        });
      });
    },
  },
); // as second arg you may pass advanced options for RRNL

const source = new RecordSource();
const store = new Store(source);
export const environment = new Environment({ network, store });
