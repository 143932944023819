import _ from 'lodash';

export const fillKeyByDataIndex = columns =>
  _.map(columns, colConf => {
    if (colConf?.children) {
      return {
        ...colConf,
        children: fillKeyByDataIndex(colConf?.children),
      };
    }

    return {
      ...colConf,
      key: colConf?.key || colConf?.dataIndex.toString(),
    };
  });

const returnInput = input => input;

export const getColumnFilter = hideColumnKeys => {
  if (_.isEmpty(hideColumnKeys)) return returnInput;

  const columnFilter = columns =>
    _.reduce(
      columns,
      (newColumns, column) => {
        const { key, children } = column;
        if (children) {
          newColumns.push({
            ...column,
            children: columnFilter(children),
          });
        } else if (!_.includes(hideColumnKeys, key)) {
          newColumns.push(column);
        }
        return newColumns;
      },
      [],
    );

  return columnFilter;
};

export const getColumnMerger = extendsColProps => {
  if (_.isEmpty(extendsColProps)) return returnInput;

  const columnMerger = columns =>
    _.map(columns, colProps =>
      colProps.children
        ? { ...colProps, children: columnMerger(colProps.children) }
        : _.merge(colProps, extendsColProps[colProps.key]),
    );

  return columnMerger;
};
