import { combineReducers } from 'redux';
import {
  SCHEDULING_CHANGE_SELECTED_ROUTE_ACTION,
  SCHEDULING_CLEAR_ALL_DATA_ACTION,
  SCHEDULING_CHANGE_INTERCITY_RIDE_LIST_STATUS_ACTION,
  SCHEDULING_CHANGE_EXPRESS_LIST_STATUS_ACTION,
} from '../actions';

function selectedRouteReducer(state = null, action) {
  const { type, payload } = action;
  if (type === SCHEDULING_CHANGE_SELECTED_ROUTE_ACTION) {
    state = payload;
  }
  return state;
}

function intercityRideListStatusReducer(state = 'pending', action) {
  const { type, payload } = action;
  if (type === SCHEDULING_CHANGE_INTERCITY_RIDE_LIST_STATUS_ACTION) {
    state = payload;
  }
  return state;
}

function expressListStatusReducer(state = 'pending', action) {
  const { type, payload } = action;
  if (type === SCHEDULING_CHANGE_EXPRESS_LIST_STATUS_ACTION) {
    state = payload;
  }
  return state;
}

const combine = combineReducers({
  selectedRoute: selectedRouteReducer,
  intercityRideListStatus: intercityRideListStatusReducer,
  expressListStatus: expressListStatusReducer,
});

export default function (state = {}, action) {
  const { type } = action;
  if (type === SCHEDULING_CLEAR_ALL_DATA_ACTION) {
    return {};
  }
  return combine(state, action);
}
