import classNames from 'classnames';

import styles from './AdvancedSearchFormPanel.module.less';

export default ({ className, ...otherProps }) => (
  <div
    {...otherProps}
    className={classNames(styles['advanced-search-form-panel'], className)}
  />
);
