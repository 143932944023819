import { useMemo, useState } from 'react';
import { ReloadOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import _ from 'lodash';

import {
  TotalCount,
  Toolbar,
  useFilterValues,
  useSearchFormSubmit,
  useLocationFilter,
  useLocationQueryHandler,
  useLocationStateClearer,
  useLocationSorter,
} from '../../../components/SmartTable2';
import { getValueType } from '../../../components/SmartTable2/transform';
import { paymentMethod } from '../../../common/PaymentMethod';

import { statusNames } from './TaxiStatus';
import TaxiOrderTable from './TaxiOrderTable';
import SearchForm from './SearchForm';

const PAYMENT_FILTER_OPTIONS = _.toPairs(paymentMethod).map(item => {
  const [key, val] = item;
  return {
    text: val,
    value: key,
  };
});
const HISTORY_STATE_KEY = 'SmartTaxiOrdersTable';

const SmartTaxiOrdersTable = ({
  relay,
  historyStateKey: propHistoryStateKey,
  loading,
  loadingMore,
  entities,
  totalCount,
  _inDetails,
  primaryActions,
  ...otherProps
}) => {
  const historyStateKey = propHistoryStateKey ?? HISTORY_STATE_KEY;

  const sorterState = useLocationSorter(historyStateKey);
  const filtersState = useLocationFilter(historyStateKey);
  const searchFormValues = useFilterValues(historyStateKey);
  const locationsStateHandler = useLocationQueryHandler(historyStateKey);

  const [refetching, setRefetching] = useState(false);

  const handleSearchFormFinish = useSearchFormSubmit(historyStateKey);
  const handleSearchFormReset = useLocationStateClearer(historyStateKey);

  const searchFormFields = useMemo(
    () => _.map(searchFormValues, (value, key) => ({ name: key, value })),
    [searchFormValues],
  );
  const handleRefetchBtnClick = () => {
    if (refetching || loadingMore) {
      return;
    }
    setRefetching(true);
    relay.refetchConnection(25, error => {
      setRefetching(false);
      error && message.error(error.message);
    });
  };

  const handleTableChange = useMemo(() => {
    const getTableChangeHandler = (options = {}) => {
      return (pagination, filters, sorter) => {
        const newFilters = _.mapValues(filters, (value, field) => ({
          value,
          valueType: getValueType(value),
        }));
        locationsStateHandler(
          newFilters,
          sorter?.order
            ? {
                field: sorter?.columnKey,
                order: sorter?.order,
              }
            : {},
        );
      };
    };
    return getTableChangeHandler();
  }, [locationsStateHandler]);

  const getColumnSorterOrder = field => {
    if (_.isEqual(field, sorterState?.field)) {
      return sorterState?.order;
    }
    return false;
  };

  const getColumnFilters = field => {
    const filtersOpt = filtersState?.[field];
    const filteredValue = filtersOpt?.value || null;
    return {
      filteredValue,
      filtered: !!filteredValue,
    };
  };

  const idSortOrder = getColumnSorterOrder('id');
  const statusFilter = getColumnFilters('status');
  const taxiFeeSortOrder = getColumnSorterOrder('taxiFee');
  const tipSortOrder = getColumnSorterOrder('tip');
  const otherFeeSortOrder = getColumnSorterOrder('otherFee');
  const paymentMethodFilter = getColumnFilters('paymentMethod');
  const createdAtSortOrder = getColumnSorterOrder('createdAt');

  const statusFilterOptions = _inDetails
    ? _.filter(statusNames, item => {
        return item.value !== 'pending' && item.value !== 'expired';
      })
    : statusNames;
  const paymentsFilterOptions = PAYMENT_FILTER_OPTIONS.filter(
    item => item.value !== 'Withdraw',
  );
  const extendsColProps = useMemo(() => {
    return {
      id: {
        sorter: true,
        sortOrder: idSortOrder,
      },
      status: {
        filters: statusFilterOptions,
        ...statusFilter,
      },
      taxiFee: {
        sorter: true,
        sortOrder: taxiFeeSortOrder,
      },
      tip: {
        sorter: true,
        sortOrder: tipSortOrder,
      },
      otherFee: {
        sorter: true,
        sortOrder: otherFeeSortOrder,
      },
      paymentMethod: {
        filters: paymentsFilterOptions,
        ...paymentMethodFilter,
      },
      createdAt: {
        sorter: true,
        sortOrder: createdAtSortOrder,
      },
    };
  }, [
    statusFilter,
    idSortOrder,
    taxiFeeSortOrder,
    tipSortOrder,
    otherFeeSortOrder,
    paymentMethodFilter,
    statusFilterOptions,
    createdAtSortOrder,
    paymentsFilterOptions,
  ]);

  return (
    <>
      <SearchForm
        onFinish={handleSearchFormFinish}
        fields={searchFormFields}
        onReset={handleSearchFormReset}
      />
      <Toolbar>
        <Toolbar.Left>
          <Button
            icon={<ReloadOutlined />}
            loading={loading}
            disabled={loadingMore}
            onClick={handleRefetchBtnClick}
          >
            重新加载
          </Button>
          <Button
            disabled={loading || loadingMore}
            onClick={handleSearchFormReset}
          >
            清除过滤器及排序
          </Button>
          {primaryActions}
        </Toolbar.Left>
        <Toolbar.Right>
          <TotalCount value={totalCount} />
        </Toolbar.Right>
      </Toolbar>
      <TaxiOrderTable
        {...otherProps}
        loading={loading}
        entities={entities}
        extendsColProps={extendsColProps}
        onChange={handleTableChange}
      />
    </>
  );
};

SmartTaxiOrdersTable.propTypes = {
  ...TaxiOrderTable.propTypes,
};

export default SmartTaxiOrdersTable;
