/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type TaxiOrderTable_data$ref = any;
export type PaymentMethods = "AlipayMethod" | "Cash" | "Didi" | "RideBalance" | "WechatPayment" | "Withdraw" | "%future added value";
export type RideStatus = "accepted" | "arrived" | "at_dropoff" | "canceled" | "closed" | "completed" | "driver_canceled" | "expired" | "in_progress" | "origin_arrived" | "passenger_canceled" | "pending" | "requesting" | "suspended" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type TaxiOrderList_data$ref: FragmentReference;
declare export opaque type TaxiOrderList_data$fragmentType: TaxiOrderList_data$ref;
export type TaxiOrderList_data = {|
  +entities: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +passenger: ?{|
          +id: string,
          +phoneNumber: ?string,
        |},
        +driver: ?{|
          +id: string,
          +phoneNumber: ?string,
          +taxiDriverDetail: ?{|
            +realName: ?string
          |},
        |},
        +vehicle: ?{|
          +plateNumber: string
        |},
        +status: ?RideStatus,
        +otherFee: ?number,
        +taxiFee: ?number,
        +tip: ?number,
        +rating: ?number,
        +createdAt: ?any,
        +payments: ?$ReadOnlyArray<?{|
          +paymentMethod: ?PaymentMethods
        |}>,
        +zone: ?{|
          +id: string,
          +province: ?string,
          +city: ?string,
          +district: ?string,
        |},
        +$fragmentRefs: TaxiOrderTable_data$ref,
      |}
    |}>,
    +pageInfo: {|
      +hasNextPage: boolean,
      +endCursor: ?string,
    |},
    +totalCount: number,
  |},
  +$refType: TaxiOrderList_data$ref,
|};
export type TaxiOrderList_data$data = TaxiOrderList_data;
export type TaxiOrderList_data$key = {
  +$data?: TaxiOrderList_data$data,
  +$fragmentRefs: TaxiOrderList_data$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": "phoneNumber",
  "args": null,
  "kind": "ScalarField",
  "name": "phone_number",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "count"
    },
    {
      "kind": "RootArgument",
      "name": "cursor"
    },
    {
      "kind": "RootArgument",
      "name": "q"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "entities"
        ]
      }
    ]
  },
  "name": "TaxiOrderList_data",
  "selections": [
    {
      "alias": "entities",
      "args": [
        {
          "kind": "Variable",
          "name": "q",
          "variableName": "q"
        }
      ],
      "concreteType": "TaxiConnection",
      "kind": "LinkedField",
      "name": "__TaxiOrderList_entities_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TaxiEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Taxi",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "passenger",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TaxiDriver",
                  "kind": "LinkedField",
                  "name": "driver",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": "taxiDriverDetail",
                      "args": null,
                      "concreteType": "TaxiDriverDetail",
                      "kind": "LinkedField",
                      "name": "taxi_driver_detail",
                      "plural": false,
                      "selections": [
                        {
                          "alias": "realName",
                          "args": null,
                          "kind": "ScalarField",
                          "name": "real_name",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TaxiVehicleDetail",
                  "kind": "LinkedField",
                  "name": "vehicle",
                  "plural": false,
                  "selections": [
                    {
                      "alias": "plateNumber",
                      "args": null,
                      "kind": "ScalarField",
                      "name": "plate_number",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                {
                  "alias": "otherFee",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "other_fee",
                  "storageKey": null
                },
                {
                  "alias": "taxiFee",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "taxi_fee",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "tip",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "rating",
                  "storageKey": null
                },
                {
                  "alias": "createdAt",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "created_at",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Payment",
                  "kind": "LinkedField",
                  "name": "payments",
                  "plural": true,
                  "selections": [
                    {
                      "alias": "paymentMethod",
                      "args": null,
                      "kind": "ScalarField",
                      "name": "payment_method",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Zone",
                  "kind": "LinkedField",
                  "name": "zone",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "province",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "city",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "district",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "TaxiOrderTable_data"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Relay",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e203cf36a2d12b12e506ca53827a3302';

module.exports = node;
