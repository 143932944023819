import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'antd';
import moment from 'moment';

const defaultProps = {
  shouldShowBanner: timeStr => {
    if (!timeStr) return true; // storage 中未记录
    return moment().isAfter(timeStr, 'days');
  }, // 每日一次
  storageKey: 'DURATION_TIP_BANNER_TIME',
};

const DurationTipBanner = ({ shouldShowBanner, storageKey, ...alertProps }) => {
  const lastShowTime = global.localStorage.getItem(storageKey);

  /* Alert close 有动画效果，导致布局过度抖动，使用状态控制避免该问题  */
  const [showBanner, setShowBanner] = useState(shouldShowBanner(lastShowTime));

  useEffect(() => {
    if (showBanner) {
      global.localStorage.setItem(storageKey, moment().format());
    }
  }, [showBanner, storageKey]);

  const handlerClose = useCallback(() => {
    setShowBanner(false);
  }, []);

  if (!showBanner) return null;

  return <Alert {...alertProps} onClose={handlerClose} />;
};

DurationTipBanner.propTypes = {
  shouldShowBanner: PropTypes.func,
  storageKey: PropTypes.string,
};
DurationTipBanner.defaultProps = defaultProps;

export default DurationTipBanner;
