/* eslint-disable no-console */
import { cloneElement, PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FilterOutlined, ReloadOutlined } from '@ant-design/icons';
import { Spin, message, Button } from 'antd';
import _ from 'lodash';

import { LoadMoreCursor } from '../InfiniteLoadMore';
import labelAnnotationsParser from '../../utils/labelAnnotationsParser';

import formatQ from './formatQ';
import {
  getValueType,
  transformFiltersValForLocationState,
  transformFilterValuesForForm,
  filterValuesToFormFieldData,
} from './transform';

export const DEFAULT_OPTIONS = {
  fetchNum: 25,
  // cursor: null,
  historyStateKey: 'SmartTable2',
  stringifyQ: true, // 新接口支持不对 q 进行序列化操作，默认为 true 兼容现有 Table
  defaultQ: {},
};

export const createSmartTable =
  (options = {}) =>
  WrappedComp => {
    const OPTIONS = {
      ...DEFAULT_OPTIONS,
      ...options,
      mapLocationStateToProps(state, ownProps) {
        const historyStateKey =
          ownProps.historyStateKey || OPTIONS.historyStateKey;
        const locationState = state?.router?.location?.state?.[historyStateKey];
        const filtersState = locationState?.filters;
        const sorterState = locationState?.sorter;
        const selectorState = locationState?.selector;

        const locationQ = formatQ(
          {
            sorter: sorterState,
            filters: filtersState,
          },
          OPTIONS.defaultQ,
        );

        const fullLabels = _.join(_.values(selectorState), ',');
        let selectors;
        try {
          const parser = labelAnnotationsParser();
          parser.feed(fullLabels);
          selectors = _.flatMap(parser.results); // why need flat map??
          // Sometimes, a grammar can parse a particular string in multiple different ways. nearley provides you with all the parsings.
          // visit https://nearley.js.org/docs/parser to get more details
        } catch (error) {
          selectors = undefined;
          if (!_.isEmpty(selectorState)) {
            console.error(error);
          }
        }

        const finalQ = _.pickBy(
          {
            ...locationQ,
            ...ownProps.q,
          },
          val => !_.isNil(val) && !_.isEqual(val, ''),
        );

        return {
          ...ownProps,
          historyStateKey,
          variables: {
            ...(ownProps.variables || {}),
            q: OPTIONS.stringifyQ ? JSON.stringify(finalQ) : finalQ,
            selectors,
          },
          filtersState,
          sorterState,
          selectorState,
        };
      },
    };

    class SmartTable2 extends PureComponent {
      static defaultProps = {
        historyStateKey: OPTIONS.historyStateKey,
      };

      static propTypes = {
        history: PropTypes.object,
        location: PropTypes.object,
      };

      static OPTIONS = OPTIONS;

      state = {
        refetching: false,
        loadingMore: false,
      };

      constructor(...args) {
        super(...args);
        this.smartTable = {
          loadMore: this._loadMore,
          refetchList: this._refetch,
          getRefetchBtn: this.getRefetchBtn,
          getClearFilterBtn: this.getClearFilterBtn,
          getColumnSorterOrder: this.getColumnSorterOrder,
          getTableChangeHandler: this.getTableChangeHandler,
          getColumnFilters: this.getColumnFilters,
          clearFiltersAndOrder: (...args) => {
            console.warn(
              `The Api 'clearFiltersAndOrder' will be deserted, 
              please use 'clearLocationState' to replace it`,
            );
            this._clearLocationState(...args);
          },
          changeFilterAndSorter: (...args) => {
            console.warn(
              `The Api 'changeFilterAndSorter' will be deserted, 
            please use 'changeLocationState' to replace it`,
            );
            this._changeLocationState(...args);
          },
          clearLocationState: this._clearLocationState,
          changeLocationState: this._changeLocationState,
          wrappedAdvancedSearchForm: this._wrappedAdvancedSearchForm,
          wrappedSmartSearchForm: this._wrappedSmartSearchForm,
        };
      }

      _loadMore = finished => {
        const { refetching } = this.state;

        if (refetching) {
          return;
        }
        const { relay } = this.props;
        relay.loadMore(OPTIONS.fetchNum, error => {
          error && message.error(error.message);
          finished();
        });
      };

      _refetch = refetchVariables => {
        const { refetching, loadingMore } = this.state;
        if (refetching || loadingMore) {
          return;
        }
        this.setState(
          {
            refetching: true,
          },
          () => {
            const { relay } = this.props;
            relay.refetchConnection(
              OPTIONS.fetchNum,
              error => {
                this.setState({
                  refetching: false,
                });
                error && message.error(error.message);
              },
              refetchVariables,
            ); //刷新 列表
          },
        );
      };

      /**
       * filters: Ransack filter
       * sorter: Ransack sorter
       * selector: Label selector [string]
       */
      _changeLocationState = (
        newFiltersState = {},
        newSorterState,
        newSelectorState = {},
      ) => {
        const {
          location,
          history,
          historyStateKey,
          filtersState,
          sorterState,
          selectorState,
        } = this.props;

        newSorterState = _.isEmpty(newSorterState) ? null : newSorterState;

        if (
          _.isEqual(filtersState, newFiltersState) &&
          _.isEqual(sorterState, newSorterState) &&
          _.isEqual(selectorState, newSelectorState)
        ) {
          return;
        }

        // 赋值该表格新的过滤和筛选状态
        const newLocation = _.mergeWith(
          location,
          {
            state: {
              [historyStateKey]: {
                filters: transformFiltersValForLocationState(newFiltersState),
                sorter: newSorterState,
                selector: newSelectorState,
              },
            },
          },
          (objValue, srcValue) => (_.isArray(srcValue) ? srcValue : undefined),
        );
        history.replace(newLocation);
      };

      _clearLocationState = () => {
        const { location, history, historyStateKey } = this.props;
        // push all
        const newLocation = _.merge(location, {
          state: {
            [historyStateKey]: null,
          },
        });

        history.replace(newLocation);
      };

      getTableChangeHandler = (options = {}) => {
        const { filterOperation = {} } = options;

        function getFilterOperation(field, value) {
          const customizeOperation = filterOperation[field];
          if (customizeOperation) {
            if (_.isFunction(customizeOperation)) {
              return customizeOperation(field, value);
            }
            return customizeOperation;
          }
          if (_.isArray(value)) {
            return 'in';
          }
          return 'eq';
        }

        return (pagination, filters, sorter) => {
          const newFilters = _.mapValues(filters, (value, field) => ({
            value,
            valueType: getValueType(value),
            _op: getFilterOperation(field, value),
          }));

          // push all
          this._changeLocationState(
            newFilters,
            sorter?.order
              ? {
                  field: sorter?.columnKey,
                  order: sorter?.order,
                }
              : {},
          );
        };
      };

      handleLoadingMore = loadingMore => this.setState({ loadingMore });

      /**
       * 获取滚屏加载触发器
       * @return {ReactElement || void} Waypoint || Spin || undefined
       */
      getLoadingMoreWayPoint = () => {
        const { relay } = this.props;

        if (!relay.hasMore()) return null;

        return (
          <LoadMoreCursor
            loadMore={this._loadMore}
            onLoadingMore={this.handleLoadingMore}
          >
            <Spin>
              <div style={{ height: '64px' }} />
            </Spin>
          </LoadMoreCursor>
        );
      };

      /**
       * 获取重新请求的按钮
       * @param {object} props 按钮的属性
       * @return {ReactElement} Button
       */
      getRefetchBtn = (props = {}) => (
        <Button
          icon={<ReloadOutlined />}
          loading={
            this.props.loading ||
            this.state.refetching ||
            this.state.loadingMore
          }
          onClick={() => {
            this._refetch();
          }}
          {...props}
        >
          重新加载
        </Button>
      );

      /**
       * 获取清空过滤器的按钮
       * @param {object} props 按钮的属性
       * @return {ReactElement} Button
       */
      getClearFilterBtn = (props = {}) => (
        <Button
          icon={<FilterOutlined />}
          loading={
            this.props.loading ||
            this.state.refetching ||
            this.state.loadingMore
          }
          onClick={this._clearLocationState}
          {...props}
        >
          清除过滤器及排序
        </Button>
      );

      _wrappedAdvancedSearchForm = formInstance => {
        return cloneElement(formInstance, {
          onReset: this._clearLocationState,
          initialValues: _.assign(
            {},
            transformFilterValuesForForm(this.props.filtersState),
            this.props.selectorState,
          ),
        });
      };

      /**
       * this function only work for antd v4 form
       * try to use `_wrappedAdvancedSearchForm` for legecy antd form
       *
       * @param {ReactElement} formInstance
       */
      _wrappedSmartSearchForm = formInstance => {
        return cloneElement(formInstance, {
          onReset: this._clearLocationState,
          fields: filterValuesToFormFieldData(
            this.props.filtersState,
            this.props.selectorState,
          ),
        });
      };

      /**
       * 获取列排序条件
       * @param {string} field 列的 key
       * @return {boolean} true: 按该列排序，false：未按该列排序
       */
      getColumnSorterOrder = field => {
        const { sorterState } = this.props;
        if (_.isEqual(field, sorterState?.field)) {
          return sorterState?.order;
        }
        return false;
      };

      /**
       * 获取列筛选条件
       * @param {string} field 列的 key
       * @return {object} { filteredValue: any, filtered: boolean }
       */
      getColumnFilters = field => {
        const { filtersState } = this.props;
        const filtersOpt = filtersState?.[field];
        const filteredValue = filtersOpt?.value || null;

        return {
          filteredValue,
          filtered: !!filteredValue,
        };
      };

      render() {
        const { loading, ...otherProps } = this.props;
        const { refetching, loadingMore } = this.state;
        return (
          <Fragment>
            <WrappedComp
              {...otherProps}
              smartTable={this.smartTable}
              loading={loading || refetching}
              loadingMore={loadingMore}
            />
            {this.getLoadingMoreWayPoint()}
          </Fragment>
        );
      }
    }

    return SmartTable2;
  };

export default createSmartTable;
