/**
 * Created by qiuyuzhou on 2017/6/12.
 */

import _ from 'lodash';
import { Select } from 'antd';

const provinces = [
  { name: '北京市', pinyin: 'bj' },
  { name: '广东省', pinyin: 'gd' },
  { name: '山东省', pinyin: 'sd' },
  { name: '江苏省', pinyin: 'js' },
  { name: '河南省', pinyin: 'hn' },
  { name: '上海市', pinyin: 'sh' },
  { name: '河北省', pinyin: 'hb' },
  { name: '浙江省', pinyin: 'zj' },
  { name: '陕西省', pinyin: 'sx' },
  { name: '湖南省', pinyin: 'hn' },
  { name: '重庆市', pinyin: 'cq' },
  { name: '福建省', pinyin: 'fj' },
  { name: '天津市', pinyin: 'tj' },
  { name: '云南省', pinyin: 'yn' },
  { name: '四川省', pinyin: 'sc' },
  { name: '广西壮族自治区', pinyin: 'gx' },
  { name: '安徽省', pinyin: 'ah' },
  { name: '海南省', pinyin: 'hn' },
  { name: '江西省', pinyin: 'jx' },
  { name: '湖北省', pinyin: 'hb' },
  { name: '山西省', pinyin: 'sx' },
  { name: '辽宁省', pinyin: 'ln' },
  { name: '黑龙江', pinyin: 'hlj' },
  { name: '内蒙古自治区', pinyin: 'nmg' },
  { name: '贵州省', pinyin: 'gz' },
  { name: '甘肃省', pinyin: 'gs' },
  { name: '青海省', pinyin: 'qh' },
  { name: '新疆维吾尔自治区', pinyin: 'xj' },
  { name: '西藏区', pinyin: 'xz' },
  { name: '吉林省', pinyin: 'jl' },
  { name: '宁夏回族自治区', pinyin: 'nx' },
];

const ProvinceSelect = props => {
  const options = _.sortBy(provinces, 'pinyin').map(p => {
    return (
      <Select.Option key={p.name} pinyin={p.pinyin}>
        {p.name}
      </Select.Option>
    );
  });
  return (
    <Select
      showSearch
      style={{ width: 200 }}
      placeholder="选择省"
      filterOption={(input, option) => {
        return (
          option.props.pinyin.indexOf(input.toLowerCase()) >= 0 ||
          option.props.children.indexOf(input.toLowerCase()) >= 0
        );
      }}
      {...props}
    >
      {options}
    </Select>
  );
};

export default ProvinceSelect;
