import { useCallback } from 'react';
import { useMutation } from 'react-relay/hooks';

const usePromiseMutation = (...useMutationArgs) => {
  const [commit, ...args] = useMutation(...useMutationArgs);
  const commitWithPromise = useCallback(
    async config =>
      new Promise((resolve, reject) =>
        commit({
          ...config,
          onCompleted: resolve,
          onError: e => reject(e),
        }),
      ),
    [commit],
  );
  return [commitWithPromise, ...args];
};

export default usePromiseMutation;
