import { lazy } from 'react';

import Section from '../../components/Section';
import { authorizedRouteWrapper } from '../../auth/authorization';

export default {
  path: '/route-carpool-v2',
  component: Section,
  name: '远途特惠（v2）',
  icon: 'sd-ride-o',
  resource_id: '/route-carpool-v2',
  leftNav: true,
  routes: [
    {
      path: 'trips',
      name: '行程列表',
      resource_id: '/route-carpool-v2/trips',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "RouteCarpoolTripListPage" */ '../../views/RouteCarpoolTripListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'trips/:id',
      resource_id: '/route-carpool-v2/trips/:id',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "RouteCarpoolTripDetailsPage" */ '../../views/RouteCarpoolTripDetailsPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'orders',
      name: '订单列表',
      resource_id: '/route-carpool-v2/orders',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "RouteCarpoolOrderListPage" */ '../../views/RouteCarpoolOrderListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'orders/:id',
      resource_id: '/route-carpool-v2/orders/:id',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "RouteCarpoolOrderDetailsPage" */ '../../views/RouteCarpoolOrderDetailsPage'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'u-routes',
      name: '无向线路列表',
      resource_id: '/route-carpool-v2/u-routes',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "RouteCarpoolURouteListPage" */ '../../views/RouteCarpoolURouteListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'routes',
      name: '线路列表',
      resource_id: '/route-carpool-v2/routes',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "RouteCarpoolRouteListPage" */ '../../views/RouteCarpoolRouteListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'u-routes/:id',
      resource_id: '/route-carpool-v2/u-routes/:id',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "RouteCarpoolURouteDetailsPage" */ '../../views/RouteCarpoolURouteDetailsPage'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'u-routes/:id/bind-carcap-unit',
      resource_id: '/route-carpool-v2/u-routes/:id/bind-carcap-unit',
      name: '绑定运力',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "RouteCarpoolURouteOnlinecarcapUnitBindingsPage" */ '../../views/RouteCarpoolURouteOnlinecarcapUnitBindingsPage'
            ),
        ),
      ),
    },
    {
      path: 'u-routes/:urouteId/prices-preview',
      resource_id: '/route-carpool-v2/u-routes/:urouteId/prices-preview',
      name: '预览价格',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "RouteCarpoolPricesPreviewPage" */ '../../views/RouteCarpoolPricesPreviewPage'
            ),
        ),
      ),
    },
    {
      path: 'routes/:id',
      resource_id: '/route-carpool-v2/routes/:id',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "RouteCarpoolRouteDetailsPage" */ '../../views/RouteCarpoolRouteDetailsPage'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'u-routes/:id/create-uroute-draft',
      resource_id: '/route-carpool-v2/u-routes/:id/create-uroute-draft',
      name: '创建草案',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "RouteCarpoolCreateURouteDraftPage" */ '../../views/RouteCarpoolCreateURouteDraftPage'
            ),
        ),
      ),
    },
    {
      path: 'u-routes/:urouteId/drafts/:draftId/update',
      resource_id:
        '/route-carpool-v2/u-routes/:urouteId/drafts/:draftId/update',
      name: '更新草案',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "RouteCarpoolUpdateDraftPage" */ '../../views/RouteCarpoolUpdateDraftPage'
            ),
        ),
      ),
    },

    {
      path: 'global-placer-cancel-violation-conf',
      name: '乘客取消违约默认配置',
      resource_id: '/route-carpool-v2/global-placer-cancel-violation-conf',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "RouteCarpoolGlobalPlacerCancelRulePage" */ '../../views/RouteCarpoolGlobalPlacerCancelRulePage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
  ],
};
