import {
  memo,
  Suspense,
  useCallback,
  useMemo,
  useContext,
  isValidElement,
  useEffect,
  useRef,
  cloneElement,
} from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import ProLayout, {
  DefaultFooter,
  RouteContext,
  WaterMark,
} from '@ant-design/pro-layout';
import { Spin, BackTop, Divider } from 'antd';
import _ from 'lodash';

import { changeSideMenuCollapsed } from '../../redux/actions';

import useCurClientRoles from '../../hooks/useCurClientRoles';

import { authorizedRoutes, isAuthorizedRoute } from '../../auth/authorization';

import logo from '../../assets/logo.png';

import ViewerMenu from '../../components/ViewerMenu';
import DurationTipBanner from '../../components/DurationTipBanner';
import AuthLink from '../../components/AuthLink';
import SDIcon from '../../components/SDIcon';
import Section from '../../components/Section';

// Allow menu.js config icon as string or ReactNode
// img icon:
// icon: 'http://demo.com/icon.png' -> <img />
// custom icon (iconfont):
// icon: 'sd-taxi' -> <SDIcon>
// "@ant-design/icons":
// icon: <SettingOutlined /> -> <SettingOutlined />
const getIcon = icon => {
  if (typeof icon === 'string') {
    if (icon.startsWith('http')) {
      return <img src={icon} alt="icon" />;
    }
    if (icon.startsWith('sd-')) {
      return <SDIcon type={icon} />;
    }

    if (process.env.NODE_ENV === 'development') {
      throw new Error(
        `invalid icon: "${icon}". if you want to use ant-design icon, please use a component like <CarOutlined /> than a string like "car".`,
      );
    }
  }

  if (isValidElement(icon)) {
    return icon;
  }

  return null;
};

/**
 * route data to menu data
 * @param {object} routes
 */
function formatter(routes = [], roles) {
  return routes.reduce((menus, route) => {
    if (route.leftNav && isAuthorizedRoute(route, roles)) {
      const { path, name, icon, routes } = route;
      menus.push({
        path,
        name,
        icon: getIcon(icon),
        children: formatter(routes, roles),
      });
    }
    return menus;
  }, []);
}

const getMenuData = (routes, roles) => formatter(routes, roles);

const LAYOUT_CONTENT_STYLE = {
  minHeight: 'calc(100vh - 94px)',
};

function MenuItemContent({ menuData }) {
  const { name, path: itemPath, icon } = menuData;
  const { isMobile, location, onCollapse } = useContext(RouteContext);
  return (
    <AuthLink
      to={itemPath}
      replace={itemPath === location.pathname}
      onClick={isMobile ? () => onCollapse(true) : undefined}
    >
      {icon}
      <span>{name}</span>
    </AuthLink>
  );
}

function ScrollToTop() {
  const history = useHistory();
  const location = useLocation();
  const $prePath = useRef(location.pathname);
  useEffect(() => {
    const unListen = history.listen((location, action) => {
      if ($prePath.current !== location.pathname) {
        window.scrollTo?.(0, 0);
        $prePath.current = location.pathname;
      } else if (action === 'PUSH') {
        // 相同路径 push 也回到顶部
        window.scrollTo?.(0, 0);
      }
    });
    return () => {
      unListen();
    };
  }, [history]);

  return null;
}

const DEFAULT_CONTENT_SKELETON = (
  <div
    style={{
      position: 'absolute',
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Spin size="large" />
  </div>
);

// see document in https://prolayout.ant.design/#api
function BasicLayout({ collapsed, match, route, location, toggleCollapse }) {
  const childRoutes = route?.routes;
  const roles = useCurClientRoles();
  const { keycloak } = useKeycloak();

  const matchPath = match.path;

  const menuData = useMemo(
    () => getMenuData(authorizedRoutes(childRoutes, roles), roles),
    [childRoutes, roles],
  );

  const menuDataRender = useCallback(() => menuData, [menuData]);

  const menuHeaderRender = useCallback(
    (logoDom, titleDom) => (
      <AuthLink to={matchPath}>
        {logoDom}
        {titleDom &&
          cloneElement(titleDom, {
            // 避免 collapsed 过程抖动
            style: { maxHeight: 22, overflow: 'hidden' },
          })}
      </AuthLink>
    ),
    [matchPath],
  );

  const menuItemRender = useCallback(menuData => {
    return <MenuItemContent menuData={menuData} />;
  }, []);

  const menuFooterRender = useCallback(
    () => (
      <div style={{ flex: 1 }}>
        <Divider style={{ margin: '8px 0' }} />
        <ViewerMenu collapsed={collapsed} />
      </div>
    ),
    [collapsed],
  );

  const footerRender = useCallback(
    () => (
      <DefaultFooter
        links={[]}
        copyright={`${new Date().getFullYear()} 深圳顺道出行科技有限公司`}
      />
    ),
    [],
  );

  return (
    <ProLayout
      logo={logo}
      title="顺道出行"
      breakpoint={false}
      fixedHeader={true}
      fixSiderbar={true}
      collapsed={collapsed}
      onCollapse={toggleCollapse}
      loading={false}
      menuHeaderRender={menuHeaderRender}
      // onMenuHeaderClick={onMenuHeaderClick}
      menuDataRender={menuDataRender}
      menuItemRender={menuItemRender}
      menuFooterRender={menuFooterRender}
      location={location}
      footerRender={footerRender}
      contentStyle={LAYOUT_CONTENT_STYLE}
    >
      <WaterMark
        content={keycloak?.tokenParsed?.email || keycloak?.tokenParsed?.sub}
        style={{ minHeight: '100%' }}
      >
        <DurationTipBanner
          type="warning"
          message="您登录的系统在后台有日志记录，若违反公司信息安全规定，违规获取敏感数据将会受到法律制裁。"
          showIcon
          closable
          banner
          // keycloak.tokenParsed.sub 是用户 ID
          storageKey={`${keycloak.tokenParsed.sub}_DURATION_TIP_BANNER_TIME`}
          style={{ margin: '-24px -40px 24px' }}
        />
        <Suspense fallback={DEFAULT_CONTENT_SKELETON}>
          <Section route={route} match={match} />
        </Suspense>
        <BackTop style={{ bottom: 70 }} />
        <ScrollToTop />
      </WaterMark>
    </ProLayout>
  );
}

const mapStateToProps = (state, owenerProps) => ({
  ...owenerProps,
  collapsed: state.collapsed,
});

const mapDispatchToProps = dispatch => ({
  toggleCollapse: collapsed => dispatch(changeSideMenuCollapsed(collapsed)),
});

export default _.flow([memo, connect(mapStateToProps, mapDispatchToProps)])(
  BasicLayout,
);
