import { lazy } from 'react';
import { ToolOutlined } from '@ant-design/icons';

import Section from '../../components/Section';
import { authorizedRouteWrapper } from '../../auth/authorization';

export default {
  path: 'tools',
  name: '工具',
  icon: <ToolOutlined />,
  component: Section,
  resource_id: '/tools',
  leftNav: true,
  routes: [
    {
      path: 'drawing',
      name: '绘制工具',
      resource_id: '/tools/drawing',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "GeoEditPage" */ '../../views/GeoEditPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'geojson-encode',
      name: '地理信息编码',
      resource_id: '/tools/geojson-encode',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "GeoJSONEncodePage" */ '../../views/GeoJSONEncodePage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
  ],
};
