import { useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { createFragmentContainer, QueryRenderer, graphql } from 'react-relay';
import { useRelayEnvironment } from 'react-relay/hooks';
import { Card, Tabs, message, Divider } from 'antd';

import useSDMutation from '../../hooks/useSDMutation';
import ModalContainer, {
  safelyOpenModalByRef,
} from '../../components/ModalContainer';
import ModalForm from '../../components/ModalForm';
import { decodeGraphQLGlobalID } from '../../utils/graphQLGlobalID';
import RelayError from '../../components/RelayError';
import LocalDateTime from '../../components/LocalDateTime';
import usePermission from '../../hooks/usePermission';
import usePermissionKeyCreator from '../../hooks/usePermissionKeyCreator';
import DescriptionList from '../../components/DescriptionList';
import descriptionListTitleRender from '../../utils/descriptionListTitleRender';
import TaxiDriverList from '../taxiDrivers/TaxiDriverList';
import TaxiVehicleList from '../TaxiVehicleListPage/TaxiVehicleList';
import TaxiOrderList from '../TaxiOrderListPage/TaxiOrderList';
import PageHeaderLayout from '../../layouts/PageHeaderLayout';

import TaxiCompanyForm from './TaxiCompanyForm';
import TaxiStaffList from './TaxiStaffList';

const TaxiDriverListWithRouter = withRouter(TaxiDriverList);
const TaxiVehicleListWithRouter = withRouter(TaxiVehicleList);
const TaxiOrderListWithRouter = withRouter(TaxiOrderList);
const TaxiStaffListWithRouter = withRouter(TaxiStaffList);
const { Description } = DescriptionList;

const updateTaxiCompanyMutation = graphql`
  mutation TaxiCompanyDetailsUpdateBaseInfoMutation(
    $input: UpdateCompanyInput!
  ) {
    updateCompany(input: $input) {
      error {
        errors
      }
      company {
        id
        city
        name
        province
        phone_number
      }
    }
  }
`;

const TaxiCompanyDetails = ({ company, loading, keycloak, relay }) => {
  const decodeCompanyId =
    company?.id && global.parseInt(decodeGraphQLGlobalID(company?.id));

  const $companyRef = useRef();

  const [editCompany, setEditCompany] = useState(null);

  const [updateTaxiCompany] = useSDMutation(updateTaxiCompanyMutation);

  const [createActionPK] = usePermissionKeyCreator();
  const permission = usePermission({
    editBaseInfo: createActionPK('editBaseInfo'),
    addStaff: createActionPK('addStaff'),
    editStaffPasswd: createActionPK('editStaffPasswd'),
    unlockStaff: createActionPK('unlockStaff'),
    lockStaff: createActionPK('lockStaff'),
    editStaffRole: createActionPK('editStaffRole'),
  });

  const companyModelHelper = useCallback(
    ({ visible, hide }) => {
      return (
        <ModalForm
          key={visible}
          title="编辑出租车公司信息"
          open={visible}
          onCancel={hide}
          onSubmit={async formVals => {
            const { id } = company;
            const variables = {
              input: {
                id: id,
                ...formVals,
              },
            };
            try {
              await updateTaxiCompany({ variables });
              message.success('成功修改出租车公司信息');
              hide();
            } catch (error) {
              message.error(error.message);
              throw error;
            } finally {
              setEditCompany(null);
            }
          }}
        >
          <TaxiCompanyForm initialValues={editCompany} />
        </ModalForm>
      );
    },
    [editCompany, company, updateTaxiCompany],
  );

  const tabItems = [
    {
      label: '出租车订单',
      key: 'order',
      children: (
        <TaxiOrderListWithRouter
          _inDetails={true}
          q={{
            company_not_null: 1,
            company_id_eq: decodeCompanyId,
          }}
        />
      ),
    },
    {
      label: '车辆',
      key: 'vehicle',
      children: (
        <TaxiVehicleListWithRouter
          q={{
            company_not_null: 1,
            company_id_eq: decodeCompanyId,
          }}
        />
      ),
    },
    {
      label: '司机',
      key: 'driver',
      children: (
        <TaxiDriverListWithRouter
          q={{
            company_not_null: 1,
            company_id_eq: decodeCompanyId,
          }}
        />
      ),
    },
    {
      label: '员工账号',
      key: 'staff',
      children: (
        <TaxiStaffListWithRouter
          q={{
            company_not_null: 1,
            company_id_eq: decodeCompanyId,
          }}
          addStaffAuth={permission.addStaff}
          editStaffPasswdAuth={permission.editStaffPasswd}
          unlockStaffAuth={permission.unlockStaff}
          lockStaffAuth={permission.lockStaff}
          editStaffRoleAuth={permission.editStaffRole}
        />
      ),
    },
  ];

  return (
    <PageHeaderLayout title="出租车公司详情">
      <Card loading={loading}>
        <DescriptionList
          title={descriptionListTitleRender('基本信息', [
            {
              permission: permission.editBaseInfo,
              name: '编辑',
              onClick: () => {
                setEditCompany(company);
                safelyOpenModalByRef($companyRef);
              },
            },
          ])}
        >
          <Description term="ID">
            {decodeGraphQLGlobalID(company?.id)}
          </Description>
          <Description term="名称">{company?.name}</Description>
          <Description term="省份">{company?.province}</Description>
          <Description term="城市">{company?.city}</Description>
          <Description term="客服电话">{company?.phone_number}</Description>
          <Description term="创建时间">
            {company && <LocalDateTime date={company?.created_at} />}
          </Description>
        </DescriptionList>

        <Divider />

        <Tabs items={tabItems} />
      </Card>
      {permission.editBaseInfo && (
        <ModalContainer ref={$companyRef}>{companyModelHelper}</ModalContainer>
      )}
    </PageHeaderLayout>
  );
};

TaxiCompanyDetails.defaultProps = {
  loading: false,
};

TaxiCompanyDetails.propTypes = {
  loading: PropTypes.bool,
};

const TaxiCompanyDetailsContainer = createFragmentContainer(
  TaxiCompanyDetails,
  {
    company: graphql`
      fragment TaxiCompanyDetails_company on Company {
        id
        name
        city
        province
        created_at
        phone_number
      }
    `,
  },
);

export default _props => {
  const environment = useRelayEnvironment();
  return (
    <QueryRenderer
      environment={environment}
      query={graphql`
        query TaxiCompanyDetailsQuery($id: ID!) {
          company: node(id: $id) {
            ...TaxiCompanyDetails_company
          }
        }
      `}
      variables={{
        id: _props.match.params.id,
      }}
      render={readyState => {
        const { error, props } = readyState;
        if (error) {
          return <RelayError error={error} />;
        } else if (props) {
          return (
            <TaxiCompanyDetailsContainer
              {..._props}
              loading={false}
              company={props.company}
            />
          );
        }
        return (
          <TaxiCompanyDetailsContainer
            {..._props}
            loading={true}
            company={null}
          />
        );
      }}
    />
  );
};
