import { useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import _ from 'lodash';

// import { Breadcrumbs } from './Breadcrumbs';
import { authorizedRoutes } from '../auth/authorization';
import useCurClientRoles from '../hooks/useCurClientRoles';

import NotFound from '../views/404';

const NOT_FOUND_ROUTE = {
  key: 'not-found',
  path: '*',
  component: NotFound,
  exact: true,
};

function Section({ route, match }) {
  const roles = useCurClientRoles();
  const allRoutes = route.routes;
  const routes = useMemo(
    () =>
      _.flow(
        routes => authorizedRoutes(routes, roles),
        routes =>
          _.filter(routes, ({ leftNav, path }) => leftNav || path === '/home'),
      )(allRoutes),
    [allRoutes, roles],
  );

  if (match.isExact && !_.isEmpty(routes)) {
    // 重定向到第一个下级导航路径
    return <Redirect to={_.head(routes)?.path} />;
  }

  return renderRoutes((allRoutes || []).concat(NOT_FOUND_ROUTE));
}

export default Section;
