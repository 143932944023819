/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type TaxiOrderDetail_data$ref = any;
export type TaxiOrderDetailsQueryVariables = {|
  id: string
|};
export type TaxiOrderDetailsQueryResponse = {|
  +data: ?{|
    +$fragmentRefs: TaxiOrderDetail_data$ref
  |}
|};
export type TaxiOrderDetailsQuery = {|
  variables: TaxiOrderDetailsQueryVariables,
  response: TaxiOrderDetailsQueryResponse,
|};
*/


/*
query TaxiOrderDetailsQuery(
  $id: ID!
) {
  data: node(id: $id) {
    __typename
    ...TaxiOrderDetail_data
    id
  }
}

fragment TaxiOrderDetail_data on Taxi {
  id
  destination
  origin
  rating
  feedback
  ...TaxiTimeline_data
  ...TaxiOrderPaymentList_data
}

fragment TaxiOrderPaymentList_data on Taxi {
  payments {
    id
    paymentMethod: payment_method
    amount
    state
    promotionAmount: promotion_amount
    rideBalance: ride_balance
  }
}

fragment TaxiTimeline_data on Taxi {
  driver {
    id
    phoneNumber: phone_number
  }
  status
  createdAt: created_at
  logStateChanges: log_state_changes {
    id
    createdAt: created_at
    event
    fromState: from_state
    toState: to_state
  }
  orderReassignReasons: order_reassign_reasons {
    driver {
      phoneNumber: phone_number
      id
    }
    reassignReason: reassign_reason {
      reason
      id
    }
    id
  }
  cancelReason: cancel_reason {
    cancelType: cancel_type
    reason
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": "phoneNumber",
  "args": null,
  "kind": "ScalarField",
  "name": "phone_number",
  "storageKey": null
},
v4 = {
  "alias": "createdAt",
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reason",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TaxiOrderDetailsQuery",
    "selections": [
      {
        "alias": "data",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TaxiOrderDetail_data"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TaxiOrderDetailsQuery",
    "selections": [
      {
        "alias": "data",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "destination",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "origin",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rating",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "feedback",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TaxiDriver",
                "kind": "LinkedField",
                "name": "driver",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": "logStateChanges",
                "args": null,
                "concreteType": "LogStateChange",
                "kind": "LinkedField",
                "name": "log_state_changes",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "event",
                    "storageKey": null
                  },
                  {
                    "alias": "fromState",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "from_state",
                    "storageKey": null
                  },
                  {
                    "alias": "toState",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "to_state",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": "orderReassignReasons",
                "args": null,
                "concreteType": "OrderReassignReason",
                "kind": "LinkedField",
                "name": "order_reassign_reasons",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Driver",
                    "kind": "LinkedField",
                    "name": "driver",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": "reassignReason",
                    "args": null,
                    "concreteType": "ReassignReason",
                    "kind": "LinkedField",
                    "name": "reassign_reason",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": "cancelReason",
                "args": null,
                "concreteType": "CancelReason",
                "kind": "LinkedField",
                "name": "cancel_reason",
                "plural": false,
                "selections": [
                  {
                    "alias": "cancelType",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cancel_type",
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Payment",
                "kind": "LinkedField",
                "name": "payments",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": "paymentMethod",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "payment_method",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "amount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "state",
                    "storageKey": null
                  },
                  {
                    "alias": "promotionAmount",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "promotion_amount",
                    "storageKey": null
                  },
                  {
                    "alias": "rideBalance",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ride_balance",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Taxi",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fd0e46b5fb8cf0c7af4c766471011903",
    "id": null,
    "metadata": {},
    "name": "TaxiOrderDetailsQuery",
    "operationKind": "query",
    "text": "query TaxiOrderDetailsQuery(\n  $id: ID!\n) {\n  data: node(id: $id) {\n    __typename\n    ...TaxiOrderDetail_data\n    id\n  }\n}\n\nfragment TaxiOrderDetail_data on Taxi {\n  id\n  destination\n  origin\n  rating\n  feedback\n  ...TaxiTimeline_data\n  ...TaxiOrderPaymentList_data\n}\n\nfragment TaxiOrderPaymentList_data on Taxi {\n  payments {\n    id\n    paymentMethod: payment_method\n    amount\n    state\n    promotionAmount: promotion_amount\n    rideBalance: ride_balance\n  }\n}\n\nfragment TaxiTimeline_data on Taxi {\n  driver {\n    id\n    phoneNumber: phone_number\n  }\n  status\n  createdAt: created_at\n  logStateChanges: log_state_changes {\n    id\n    createdAt: created_at\n    event\n    fromState: from_state\n    toState: to_state\n  }\n  orderReassignReasons: order_reassign_reasons {\n    driver {\n      phoneNumber: phone_number\n      id\n    }\n    reassignReason: reassign_reason {\n      reason\n      id\n    }\n    id\n  }\n  cancelReason: cancel_reason {\n    cancelType: cancel_type\n    reason\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '991890cd110948fd1fbea41e5a1b05b6';

module.exports = node;
