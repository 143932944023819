import Enum from 'enum';
import _ from 'lodash';

export const PAYMENT_METHOD = new Enum(
  {
    CASH: 'Cash',
    WECHATPAYMENT: 'WechatPayment',
    ALIPAYMETHOD: 'AlipayMethod',
    WITHDRAW: 'Withdraw',
    RIDEBALANCE: 'RideBalance',
    DIDI: 'Didi',
  },
  { freeze: true },
);

export const PAYMENT_METHOD_LIST = [
  {
    value: PAYMENT_METHOD.CASH.valueOf(),
    label: '现金',
  },
  {
    value: PAYMENT_METHOD.WECHATPAYMENT.valueOf(),
    label: '微信支付',
  },
  {
    value: PAYMENT_METHOD.ALIPAYMETHOD.valueOf(),
    label: '支付宝',
  },
  {
    value: PAYMENT_METHOD.WITHDRAW.valueOf(),
    label: '提现',
  },
  {
    value: PAYMENT_METHOD.RIDEBALANCE.valueOf(),
    label: '余额',
  },
  {
    value: PAYMENT_METHOD.DIDI.valueOf(),
    label: '滴滴',
  },
];

export const paymentMethod = _.fromPairs(
  PAYMENT_METHOD_LIST.map(({ value, label }) => [value, label]),
);
