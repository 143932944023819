import { PureComponent } from 'react';
import { Input } from 'antd';

function transformValue(value, len = Number.MAX_VALUE) {
  const reg = /\D/g;
  if (typeof value !== 'string') {
    value = String(value);
  }

  value = value.replace(reg, '');
  return value.slice(0, len);
}

class PhoneNumberInput extends PureComponent {
  state = {
    value: null,
  };
  static getDerivedStateFromProps(nextProps, curState) {
    let newState = {};
    if (nextProps.value !== curState.value) {
      newState.value = transformValue(nextProps.value, nextProps.maxLength);
    }

    return newState;
  }

  triggerChange = val => {
    const { onChange } = this.props;
    onChange && onChange(val);
  };

  handleOnChange = e => {
    const { maxLength } = this.props;
    const value = transformValue(e.target.value, maxLength);
    this.setState({
      value: e.target.value,
    });
    this.triggerChange(value);
  };

  render() {
    const { maxLength, ...inputProps } = this.props;
    const { value } = this.state;

    return (
      <Input {...inputProps} value={value} onChange={this.handleOnChange} />
    );
  }
}

export default PhoneNumberInput;
