import { Children, isValidElement, cloneElement } from 'react';
import PropTypes from 'prop-types';
// import classnames from 'classnames';
import styles from './index.module.less';

const AUTO_MARGIN_BOTTOM_SIZE_SMALL = 'sm';
const AUTO_MARGIN_BOTTOM_SIZE_NORMAL = 'md';
const AUTO_MARGIN_BOTTOM_SIZE_LARGE = 'lg';

const AutoMarginBottom = ({ children, size }) =>
  Children.map(children, (c, index) =>
    Children.count(children) !== index + 1 ? (
      isValidElement(c) ? ( // children maybe is null
        cloneElement(c, {
          className: styles[`margin-bottom-${size}`],
        })
      ) : (
        <span className={styles[`margin-bottom-${size}`]}>{c}</span>
      )
    ) : (
      c
    ),
  );

AutoMarginBottom.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf([
    AUTO_MARGIN_BOTTOM_SIZE_SMALL,
    AUTO_MARGIN_BOTTOM_SIZE_NORMAL,
    AUTO_MARGIN_BOTTOM_SIZE_LARGE,
  ]),
};

AutoMarginBottom.defaultProps = {
  size: AUTO_MARGIN_BOTTOM_SIZE_NORMAL,
};

Object.defineProperty(AutoMarginBottom, 'SIZE', {
  value: Object.create(null, {
    SMALL: { value: AUTO_MARGIN_BOTTOM_SIZE_SMALL, writable: false },
    NORMAL: { value: AUTO_MARGIN_BOTTOM_SIZE_NORMAL, writable: false },
    LARGE: { value: AUTO_MARGIN_BOTTOM_SIZE_LARGE, writable: false },
  }),
  writable: false,
});

export default AutoMarginBottom;
