import { lazy } from 'react';
import { ShopOutlined } from '@ant-design/icons';

import Section from '../../components/Section';
import { authorizedRouteWrapper } from '../../auth/authorization';

export default {
  path: 'store',
  name: '商城',
  icon: <ShopOutlined />,
  component: Section,
  resource_id: 'store',
  exact: false,
  leftNav: true,
  routes: [
    {
      path: 'merchants',
      name: '商家管理',
      resource_id: 'store_merchants',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "MerchantList" */ '../../views/MerchantListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'products',
      name: '商品管理',
      resource_id: 'store-products',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "ProductList" */ '../../views/ProductListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'exchangeOrders',
      name: '订单',
      resource_id: 'store_exchangeOrders',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "ExchangeOrderList" */ '../../views/ExchangeOrderListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'merchants/:id',
      resource_id: 'store_merchants_view_details',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "MerchantDetailsPage" */ '../../views/MerchantDetailsPage'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'products/:id',
      resource_id: 'store_products_view_details',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "ProductDetail" */ '../../views/ProductDetailsPage'
            ),
        ),
      ),
      exact: true,
    },
  ],
};
