/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type PaymentMethods = "AlipayMethod" | "Cash" | "Didi" | "RideBalance" | "WechatPayment" | "Withdraw" | "%future added value";
export type RideStatus = "accepted" | "arrived" | "at_dropoff" | "canceled" | "closed" | "completed" | "driver_canceled" | "expired" | "in_progress" | "origin_arrived" | "passenger_canceled" | "pending" | "requesting" | "suspended" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type TaxiOrderTable_data$ref: FragmentReference;
declare export opaque type TaxiOrderTable_data$fragmentType: TaxiOrderTable_data$ref;
export type TaxiOrderTable_data = $ReadOnlyArray<{|
  +id: string,
  +passenger: ?{|
    +id: string,
    +phoneNumber: ?string,
  |},
  +driver: ?{|
    +id: string,
    +phoneNumber: ?string,
    +taxiDriverDetail: ?{|
      +realName: ?string
    |},
  |},
  +vehicle: ?{|
    +plateNumber: string
  |},
  +status: ?RideStatus,
  +otherFee: ?number,
  +taxiFee: ?number,
  +tip: ?number,
  +rating: ?number,
  +createdAt: ?any,
  +payments: ?$ReadOnlyArray<?{|
    +paymentMethod: ?PaymentMethods
  |}>,
  +zone: ?{|
    +id: string,
    +province: ?string,
    +city: ?string,
    +district: ?string,
  |},
  +$refType: TaxiOrderTable_data$ref,
|}>;
export type TaxiOrderTable_data$data = TaxiOrderTable_data;
export type TaxiOrderTable_data$key = $ReadOnlyArray<{
  +$data?: TaxiOrderTable_data$data,
  +$fragmentRefs: TaxiOrderTable_data$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": "phoneNumber",
  "args": null,
  "kind": "ScalarField",
  "name": "phone_number",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "TaxiOrderTable_data",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "passenger",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TaxiDriver",
      "kind": "LinkedField",
      "name": "driver",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": "taxiDriverDetail",
          "args": null,
          "concreteType": "TaxiDriverDetail",
          "kind": "LinkedField",
          "name": "taxi_driver_detail",
          "plural": false,
          "selections": [
            {
              "alias": "realName",
              "args": null,
              "kind": "ScalarField",
              "name": "real_name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TaxiVehicleDetail",
      "kind": "LinkedField",
      "name": "vehicle",
      "plural": false,
      "selections": [
        {
          "alias": "plateNumber",
          "args": null,
          "kind": "ScalarField",
          "name": "plate_number",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": "otherFee",
      "args": null,
      "kind": "ScalarField",
      "name": "other_fee",
      "storageKey": null
    },
    {
      "alias": "taxiFee",
      "args": null,
      "kind": "ScalarField",
      "name": "taxi_fee",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tip",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rating",
      "storageKey": null
    },
    {
      "alias": "createdAt",
      "args": null,
      "kind": "ScalarField",
      "name": "created_at",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Payment",
      "kind": "LinkedField",
      "name": "payments",
      "plural": true,
      "selections": [
        {
          "alias": "paymentMethod",
          "args": null,
          "kind": "ScalarField",
          "name": "payment_method",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Zone",
      "kind": "LinkedField",
      "name": "zone",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "province",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "city",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "district",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Taxi",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '0e252b4b14a34c5f678ba73400f6e5eb';

module.exports = node;
