import { useRouteMatch } from 'react-router-dom';

import createPermissionKey from '../auth/createPermissionKey';

const usePermissionKeyCreator = () => {
  const { path } = useRouteMatch();
  const createActionPermission = permissionKey =>
    createPermissionKey(
      path,
      createPermissionKey.ACTION_TYPE.ACTION,
      permissionKey,
    );
  const createViewPermission = permissionKey =>
    createPermissionKey(
      path,
      createPermissionKey.ACTION_TYPE.VIEW,
      permissionKey,
    );
  return [createActionPermission, createViewPermission];
};

export default usePermissionKeyCreator;
