import { lazy } from 'react';
import { QrcodeOutlined } from '@ant-design/icons';

import Section from '../../components/Section';
import { authorizedRouteWrapper } from '../../auth/authorization';

export default {
  path: 'move-car-code',
  component: Section,
  name: '挪车码',
  icon: <QrcodeOutlined />,
  resource_id: '/move-car-code',
  leftNav: true,
  routes: [
    {
      path: 'batch-list',
      name: '批次管理',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "MoveCarCodeBatchListPage" */ '../../views/MoveCarCodeBatchListPage'
            ),
        ),
      ),
      resource_id: '/move-car-code/batch-list',
      exact: true,
      leftNav: true,
    },
    {
      path: 'code-list',
      name: '挪车码列表',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "MoveCarCodeListPage" */ '../../views/MoveCarCodeListPage'
            ),
        ),
      ),
      resource_id: '/move-car-code/code-list',
      exact: true,
      leftNav: true,
    },
    {
      path: 'virtual-phone-binding-list',
      name: '虚拟号绑定记录',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "MoveCarCodeVPBindingListPage" */ '../../views/MoveCarCodeVPBindingListPage'
            ),
        ),
      ),
      resource_id: '/move-car-code/virtual-phone-binding-list',
      exact: true,
      leftNav: true,
    },
  ],
};
