import { PureComponent } from 'react';
import _ from 'lodash';
import { Spin, Select } from 'antd';
import { useRelayEnvironment } from 'react-relay/hooks';
import { createRefetchContainer, graphql, QueryRenderer } from 'react-relay';

import RelayError from './RelayError';

/**
 * 向外传递前转换
 * @param {*} value
 */
function transformOut(value) {
  return { id: value.key, name: value.label };
}

/**
 * 转换成内部所需结构
 * @param {*} value
 */
function transformIn(value) {
  return { key: value.id, label: value.name };
}
class InputSearchTaxiCompany extends PureComponent {
  state = {
    refetching: false,
  };

  constructor(props) {
    super(props);
    this.onSearch = _.debounce(this.onSearch, 800);
  }

  onSearch = value => {
    value = value.trim();
    let q = null;
    if (value.length > 0) {
      q = JSON.stringify({
        name_cont: value,
      });
    }
    if (this.refetchDisposable) {
      this.refetchDisposable.dispose();
      this.refetchDisposable = null;
    }
    this.setState({ refetching: true });
    this.refetchDisposable = this.props.relay.refetch(
      { q },
      null,
      error => {
        if (error) {
          console.error(error);
        }
        this.refetchDisposable = null;
        this.setState({ refetching: false });
      },
      {
        force: false,
      },
    );
  };

  onChange = value => {
    if (this.props.mode === 'multiple') {
      this.props.onChange(value.map(transformOut));
    } else {
      this.props.onChange(transformOut(value));
    }
  };

  render() {
    const { data, mode = null, value } = this.props;
    let options;
    if (data && data.companies) {
      const companies = data.companies.edges.map(edge => edge.node);
      options = companies.map(company => (
        <Select.Option key={company.id}>{company.name}</Select.Option>
      ));
    } else {
      options = (
        <Select.Option key="company_null" value="disabled" disabled>
          找不到相关出租车公司，请确保存在相关出租车公司。
        </Select.Option>
      );
    }
    // transform value
    let _value;
    if (value) {
      if (mode === 'multiple') {
        _value = value.map(transformIn);
      } else {
        _value = transformIn(value);
      }
    } else {
      _value = {
        key: '',
        label: null,
      };
    }
    return (
      <Select
        mode={mode}
        id={this.props.id}
        labelInValue={true}
        showSearch
        value={_value}
        filterOption={false}
        onSearch={this.onSearch}
        onChange={this.onChange}
        notFoundContent={this.state.refetching ? <Spin size="small" /> : null}
      >
        {options}
      </Select>
    );
  }
}

const query = graphql`
  query InputSearchTaxiCompanyQuery($q: Json) {
    data: relay {
      ...InputSearchTaxiCompany_data
    }
  }
`;

const InputSearchTaxiCompanyContainer = createRefetchContainer(
  InputSearchTaxiCompany,
  {
    data: graphql`
      fragment InputSearchTaxiCompany_data on Relay {
        companies(first: 10, q: $q)
          @connection(key: "InputSearchTaxiCompany_companies") {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `,
  },
  query,
);

const InputSearchTaxiCompanyRoot = _props => {
  const relayEnvironment = useRelayEnvironment();
  return (
    <QueryRenderer
      environment={relayEnvironment}
      query={query}
      variables={{
        q: _props.q || null,
      }}
      render={readyState => {
        const { error, props } = readyState;
        if (error) {
          return <RelayError error={error} />;
        } else if (props) {
          return (
            <InputSearchTaxiCompanyContainer data={props.data} {..._props} />
          );
        }
        return (
          <InputSearchTaxiCompanyContainer
            loading={true}
            data={null}
            {..._props}
          />
        );
      }}
    />
  );
};

export default InputSearchTaxiCompanyRoot;
