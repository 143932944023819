import Config from '../config';

export function getCurClientRolesFromKeycloak(keycloak) {
  return keycloak?.resourceAccess?.[Config.keycloakClientId]?.roles;
}

export const getUsernameFromKeycloak = keycloak =>
  keycloak?.idTokenParsed?.preferred_username;

export const getEmailFromKeycloak = keycloak => keycloak?.tokenParsed?.email;

export const updateTokenWithPromise = (keycloak, retry = 5) =>
  new Promise((resolve, reject) => {
    keycloak
      .updateToken(retry)
      .success(refreshed => {
        if (refreshed) {
          return resolve(keycloak.token);
        }
      })
      .error(reject);
  });

export const isNotLoggedIn = keycloak => !keycloak?.authenticated;

export const isLoggedIn = keycloak => !isNotLoggedIn(keycloak);
