import { useEffect, useState, useCallback, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { Spin } from 'antd';

const LoadMoreCursor = ({
  children,
  scrollableAncestor,
  loadMore,
  onLoadingMore,
}) => {
  const [loadingMore, setLoadingMore] = useState(false);
  const $mounted = useRef(false);
  const { ref, inView } = useInView({
    root: scrollableAncestor,
  });

  useEffect(() => {
    $mounted.current = true;
    return () => {
      $mounted.current = false;
      onLoadingMore?.(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const executeLoadMore = useCallback(() => {
    setLoadingMore(true);
    const finished = () => {
      if ($mounted.current) {
        setLoadingMore(false);
      }
    };
    try {
      loadMore?.(finished);
    } catch (error) {
      finished();
    }
  }, [loadMore]);

  useEffect(() => {
    if (loadingMore) return;
    if (!inView) return;
    executeLoadMore();
  }, [executeLoadMore, inView, loadingMore]);

  useEffect(() => {
    onLoadingMore?.(loadingMore);
  }, [loadingMore, onLoadingMore]);

  let content;

  if (!children) {
    content = <Spin />;
  } else if (typeof children === 'function') {
    content = children(loadingMore);
  } else {
    content = children;
  }

  // 加载更多的状态时不监听锚点
  // 否则，数据加载完毕后会后立即触发 “executeLoadMore”
  return <div ref={loadingMore ? null : ref}>{content}</div>;
};

export default LoadMoreCursor;
