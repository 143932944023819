import createHOC from '../../utils/createHOC';

import ErrorBoundary from './ErrorBoundary';

const withErrorBoundary = createHOC(
  'withErrorBoundary',
  WrappedComponent => props => (
    <ErrorBoundary>
      <WrappedComponent {...props} />
    </ErrorBoundary>
  ),
);

export default withErrorBoundary;
