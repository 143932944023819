/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type TaxiCompanyDetails_company$ref = any;
export type TaxiCompanyDetailsQueryVariables = {|
  id: string
|};
export type TaxiCompanyDetailsQueryResponse = {|
  +company: ?{|
    +$fragmentRefs: TaxiCompanyDetails_company$ref
  |}
|};
export type TaxiCompanyDetailsQuery = {|
  variables: TaxiCompanyDetailsQueryVariables,
  response: TaxiCompanyDetailsQueryResponse,
|};
*/


/*
query TaxiCompanyDetailsQuery(
  $id: ID!
) {
  company: node(id: $id) {
    __typename
    ...TaxiCompanyDetails_company
    id
  }
}

fragment TaxiCompanyDetails_company on Company {
  id
  name
  city
  province
  created_at
  phone_number
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TaxiCompanyDetailsQuery",
    "selections": [
      {
        "alias": "company",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TaxiCompanyDetails_company"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TaxiCompanyDetailsQuery",
    "selections": [
      {
        "alias": "company",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "city",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "province",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "created_at",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "phone_number",
                "storageKey": null
              }
            ],
            "type": "Company",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4476c7747263a9e536a30d18fe641f09",
    "id": null,
    "metadata": {},
    "name": "TaxiCompanyDetailsQuery",
    "operationKind": "query",
    "text": "query TaxiCompanyDetailsQuery(\n  $id: ID!\n) {\n  company: node(id: $id) {\n    __typename\n    ...TaxiCompanyDetails_company\n    id\n  }\n}\n\nfragment TaxiCompanyDetails_company on Company {\n  id\n  name\n  city\n  province\n  created_at\n  phone_number\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5251542aea0cad6b99395a0647dc8ce8';

module.exports = node;
