import PropTypes from 'prop-types';
import _ from 'lodash';

export default function PhoneNumberFormatter({
  value,
  format,
  regexp,
  ...otherProps
}) {
  if (_.isString(value) || _.isNumber(value)) {
    const _val = value.toString();
    return <span {...otherProps}>{_val.replace(regexp, format)}</span>;
  }

  return 'xxx-xxx-xxxx';
}

PhoneNumberFormatter.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  format: PropTypes.string,
};

PhoneNumberFormatter.defaultProps = {
  format: '$1-$2-$3',
  regexp: /^(\d{1,3})(\d{4})(\d+)$/,
};
