import _ from 'lodash';

import RolePermissionMapping from './permission.config';
import { transformPermissionRegExp } from './utils';
import createPermissionChecker from './createPermissionChecker';

/**
 * 权限健与配置是否匹配
 *
 * @param {Array<string>} permission // conf
 * @param {string} source // permissionKey
 */
const isMatchPermission = (policy, permissionKey) =>
  transformPermissionRegExp(policy).test(permissionKey);

const { hasPermission } = createPermissionChecker(
  RolePermissionMapping,
  isMatchPermission,
);

/**
 * 校验是否有权限
 * @param {String || Array} permission
 * @param {String} mode
 */
const isAuthorized = (permissions, roles = [], mode = 'or') => {
  if (_.isEmpty(roles)) {
    return false;
  }

  let _permissions;
  // 方便后续处理做结构转换
  if (_.isString(permissions)) {
    _permissions = [permissions];
  } else if (Array.isArray(permissions)) {
    _permissions = permissions;
  } else if (process.env.NODE_ENV === 'development') {
    throw new TypeError('权限位输入异常');
  }

  if (mode === 'and') {
    return _.every(_permissions, p => hasPermission(roles, p));
  }

  return _.some(_permissions, p => hasPermission(roles, p));
};

export default isAuthorized;
