import { lazy } from 'react';

import Section from '../../components/Section';
import { authorizedRouteWrapper } from '../../auth/authorization';

export default {
  path: 'express-v2',
  component: Section,
  name: '快车（v2）',
  icon: 'sd-car-express-o',
  resource_id: '/express-v2',
  leftNav: true,
  routes: [
    {
      path: 'orders',
      name: '订单列表',
      resource_id: '/express-v2/orders',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "ExpressV2OrderListPage" */ '../../views/ExpressV2OrderListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'regions',
      name: '服务区域',
      resource_id: '/express-v2/regions',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "ExpressRegionListPage" */ '../../views/ExpressRegionListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'orders/:id',
      resource_id: '/express-v2/orders/:id',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "ExpressV2OrderDetailsPage" */ '../../views/ExpressV2OrderDetailsPage'
            ),
        ),
      ),
      exact: true,
      leftNav: false,
    },
    {
      path: 'regions/:id',
      resource_id: '/express-v2/regions/:id',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "ExpressRegionDetailsPage" */ '../../views/ExpressRegionDetailsPage'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'regions/:id/update-service/:key',
      resource_id: '/express-v2/regions/:id/update-service/:key',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "ExpressRegionServiceUpdatePage" */ '../../views/ExpressRegionServiceUpdatePage'
            ),
        ),
      ),
      exact: true,
    },
  ],
};
