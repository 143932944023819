import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { graphql, useFragment } from 'react-relay/hooks';
import { compile } from 'path-to-regexp';

import { Table } from 'antd';
import _ from 'lodash';

import { decodeGraphQLGlobalID } from '../../../utils/graphQLGlobalID';
import AuthLink from '../../../components/AuthLink';
import { paymentMethod } from '../../../common/PaymentMethod';
import LocalDateTime from '../../../components/LocalDateTime';
import PhoneNumberFormatter from '../../../components/PhoneNumberFormatter';
import {
  fillKeyByDataIndex,
  getColumnFilter,
  getColumnMerger,
} from '../../../utils/tableColumnHelper';

import { TaxiStatus } from './TaxiStatus';
import TaxiOrderDetailList from '../TaxiOrderDetail';

const fragment = graphql`
  fragment TaxiOrderTable_data on Taxi @relay(plural: true) {
    id
    passenger {
      id
      phoneNumber: phone_number
    }
    driver {
      id
      phoneNumber: phone_number
      taxiDriverDetail: taxi_driver_detail {
        realName: real_name
      }
    }
    vehicle {
      plateNumber: plate_number
    }
    status
    otherFee: other_fee
    taxiFee: taxi_fee
    tip
    rating
    createdAt: created_at
    payments {
      paymentMethod: payment_method
    }
    zone {
      id
      province
      city
      district
    }
  }
`;

const PAYMENT_FILTER_OPTIONS = _.toPairs(paymentMethod).map(item => {
  const [key, val] = item;
  return {
    text: val,
    value: key,
  };
});
const getTaxiDriverDetailsPagePath = compile('/taxi/taxi-drivers/:id', {
  encode: encodeURIComponent,
});
const userPassengerPath = compile('/user/passengers/:id', {
  encode: encodeURIComponent,
});

function paymentRender(value, record, index) {
  if (value?.length) {
    const lastPayment = value.find(item => item.state === 'completed');
    const lastPaymentMethod = lastPayment?.paymentMethod;
    return PAYMENT_FILTER_OPTIONS.find(item => item.value === lastPaymentMethod)
      ?.text;
  }
}

function statusRender(value, record, index) {
  return <TaxiStatus status={value} />;
}

function driverMobileLinkRender(value, record, index) {
  if (!value) return null;

  const phoneNumberPath = getTaxiDriverDetailsPagePath({
    id: record?.driver?.id,
  });
  return (
    value && (
      <AuthLink to={phoneNumberPath}>
        <PhoneNumberFormatter value={value} />
      </AuthLink>
    )
  );
}

function dateRender(value, record, index) {
  return <LocalDateTime date={value} />;
}

function priceRender(value, record, index) {
  return value && `¥ ${value}`;
}

function expandedRowRender(row) {
  return <TaxiOrderDetailList id={row.id} />;
}

function passengerLinkRender(value, record, index) {
  if (!value) return null;

  const phoneNumberPath = userPassengerPath({ id: record?.passenger?.id });
  return (
    <AuthLink to={phoneNumberPath}>
      <PhoneNumberFormatter value={value} />
    </AuthLink>
  );
}

const TaxiOrderTable = ({
  hidePassengerColumn,
  hideDriver,
  loading,
  entities,
  onChange,
  hideColumnKeys = [],
  extendsColProps = {},
}) => {
  const data = useFragment(fragment, entities);
  const finalColumns = useMemo(() => {
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        width: 90,
        render: decodeGraphQLGlobalID,
      },
      {
        title: '状态',
        dataIndex: 'status',
        width: 130,
        render: statusRender,
      },
      {
        title: '乘客手机号',
        dataIndex: ['passenger', 'phoneNumber'],
        width: 150,
        render: passengerLinkRender,
      },
      {
        title: '司机',
        children: [
          {
            title: '手机号',
            dataIndex: ['driver', 'phoneNumber'],
            width: 150,
            render: driverMobileLinkRender,
          },
          {
            title: '姓名',
            dataIndex: ['driver', 'taxiDriverDetail', 'realName'],
            width: 90,
          },
          {
            title: '车牌',
            dataIndex: ['vehicle', 'plateNumber'],
            width: 100,
          },
        ],
      },
      {
        title: '应付费用',
        children: [
          {
            title: '行程费',
            dataIndex: 'taxiFee',
            width: 100,
            render: priceRender,
          },
          {
            title: '小费',
            dataIndex: 'tip',
            width: 100,
            render: priceRender,
          },
          {
            title: '其他费用',
            dataIndex: 'otherFee',
            width: 110,
            render: priceRender,
          },
        ],
      },
      {
        title: '区域',
        children: [
          {
            title: '省份',
            dataIndex: ['zone', 'province'],
            width: 100,
          },
          {
            title: '城市',
            dataIndex: ['zone', 'city'],
            width: 100,
          },
          {
            title: '区／县',
            dataIndex: ['zone', 'district'],
            width: 100,
          },
        ],
      },
      {
        title: '支付方式',
        width: 120,
        dataIndex: 'payments',
        key: 'paymentMethod',
        render: paymentRender,
      },
      {
        title: '时间',
        dataIndex: 'createdAt',
        width: 130,
        render: dateRender,
      },
      {
        title: '评分',
        dataIndex: 'rating',
        width: 60,
      },
    ];

    const _hideColumnKeys = [...hideColumnKeys];
    if (hidePassengerColumn) {
      _hideColumnKeys.push('passenger.phoneNumber');
    }

    if (hideDriver) {
      _hideColumnKeys.push([
        'driver.phoneNumber',
        'driver.taxiDriverDetail.realName',
        'vehicle.plateNumber',
      ]);
    }

    return _.flow([
      fillKeyByDataIndex,
      getColumnFilter(_hideColumnKeys),
      getColumnMerger(extendsColProps),
    ])(columns);
  }, [extendsColProps, hideColumnKeys, hideDriver, hidePassengerColumn]);

  return (
    <Table
      loading={loading}
      columns={finalColumns}
      dataSource={data}
      rowKey="id"
      pagination={false}
      size="middle"
      expandable={{ expandedRowRender }}
      onChange={onChange}
    />
  );
};

TaxiOrderTable.propTypes = {
  ...Table.propTypes,
  extendsColProps: PropTypes.object,
  hideColumnKeys: PropTypes.arrayOf(PropTypes.string.isRequired),
};

export default TaxiOrderTable;
