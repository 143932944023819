import { lazy } from 'react';
import { ShoppingOutlined } from '@ant-design/icons';

import Section from '../../components/Section';
import { authorizedRouteWrapper } from '../../auth/authorization';

export default {
  path: 'xiaojian',
  name: '城际寄送',
  icon: <ShoppingOutlined />,
  component: Section,
  resource_id: '/xiaojian',
  leftNav: true,
  routes: [
    {
      path: 'orders',
      name: '小件订单',
      resource_id: '/xiaojian/orders',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "XiaojianOrderListPage" */ '../../views/XiaojianOrderListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'orders/:id',
      resource_id: '/xiaojian/orders/:id',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "XiaojianOrderDetailsPage" */ '../../views/XiaojianOrderDetailsPage'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'zones',
      name: '站点',
      resource_id: '/xiaojian/zones',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "XiaojianZoneListPage" */ '../../views/XiaojianZoneListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'zones/:id',
      resource_id: '/xiaojian/zones/:id',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "XiaojianZoneDetailPage" */ '../../views/XiaojianZoneDetailPage'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'routes',
      name: '线路',
      resource_id: '/xiaojian/routes',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "XiaojianRouteListPage" */ '../../views/XiaojianRouteListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'routes/:id',
      resource_id: '/xiaojian/routes/:id',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "XiaojianRouteDetailPage" */ '../../views/XiaojianRouteDetailPage'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'courier-groups',
      name: '配送组',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "XiaojianCourierGroupListPage" */ '../../views/XiaojianCourierGroupListPage'
            ),
        ),
      ),
      resource_id: '/xiaojian/courier-groups',
      exact: true,
      leftNav: true,
    },
    {
      path: 'courier-groups/:id',
      // name: '配送组详情',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "XiaojianCourierGroupDetailsPage" */ '../../views/XiaojianCourierGroupDetailsPage'
            ),
        ),
      ),
      resource_id: '/xiaojian/courier-groups/:id',
      exact: true,
    },
    {
      path: 'couriers',
      name: '配送员',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "XiaojianCourierListPage" */ '../../views/XiaojianCourierListPage'
            ),
        ),
      ),
      resource_id: '/xiaojian/couriers',
      exact: true,
      leftNav: true,
    },
    {
      path: 'couriers/:id',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "XiaojianCourierDetailsPage" */ '../../views/XiaojianCourierDetailsPage'
            ),
        ),
      ),
      resource_id: '/xiaojian/couriers/:id',
      exact: true,
    },
  ],
};
