import { LOCATION_CHANGE } from 'connected-react-router';
import { CHANGE_SIDE_MENU_COLLAPSED } from '../actions';

function collapsedReducer(state = false, action) {
  const { type } = action;
  if (type === CHANGE_SIDE_MENU_COLLAPSED) {
    return action.payload;
  } else if (type === LOCATION_CHANGE) {
    const { payload = {} } = action;
    if (
      payload.location.pathname === '/statistics/route-order-overview' ||
      payload.location.pathname === '/carpool/scheduling'
    ) {
      return true;
    }
  }
  return state;
}

export default collapsedReducer;
