import { useRef, useMemo, useCallback } from 'react';
import {
  graphql,
  createPaginationContainer,
  commitMutation,
} from 'react-relay';
import { PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import { Table, Button, message } from 'antd';
import { connect } from 'react-redux';
import { withKeycloak } from '@react-keycloak/web';
import _ from 'lodash';

import ModalContainer, {
  safelyOpenModalByRef,
} from '../../components/ModalContainer';
import ModalForm from '../../components/ModalForm';
import {
  createSmartTable,
  Toolbar,
  searchFormVals2Filters,
  TotalCount,
} from '../../components/SmartTable2';
import relayContainerCombiner from '../../utils/relayContainerCombiner';
import { createNodeLinkRender } from '../../utils/tableColumnRenders';
import createRelayRoot from '../../utils/createRelayRoot';
import TaxiCompanyForm from './TaxiCompanyForm';
import { isAuthorized } from '../../auth/authorization';
import { getCurClientRolesFromKeycloak } from '../../utils/keycloakUtils';
import { FullScreenPanel } from '../../layouts/BasicLayout';

import SearchForm from './SearchForm';

const { Column } = Table;
const TOOLBAR_BTN_STYLE = {
  marginRight: '8px',
};

function getOperation(field) {
  const fieldsOperations = [
    {
      _op: 'cont',
      fields: ['createdAt', 'name', 'city', 'phone_number'],
    },
  ];
  return (
    _.find(fieldsOperations, item => _.includes(item.fields, field))?._op ??
    'eq'
  );
}

const idNodeLinkRender = createNodeLinkRender(
  id => `/setting/taxi_companies/${id}`,
  'id',
);
const nodeLinkRender = createNodeLinkRender(
  id => `/setting/taxi_companies/${id}`,
);

const TaxiCompanyList = props => {
  const { data, loading, loadingMore, smartTable, relay, keycloak } = props;
  const entities = (data?.entities?.edges ?? []).map(edge => edge.node);
  const totalCount = data?.entities?.totalCount;

  const $companyRef = useRef();

  const permission = useMemo(() => {
    const roles = getCurClientRolesFromKeycloak(keycloak);

    return {
      addTaxiCompany: isAuthorized(
        'setting_taxiCompanies_action_addTaxiCompany',
        roles,
      ),
    };
  }, [keycloak]);

  const companyModelHelper = useCallback(
    ({ visible, hide }) => {
      return (
        <ModalForm
          key={visible}
          title="新建出租车公司"
          open={visible}
          onCancel={hide}
          onSubmit={async formVals => {
            const { environment } = relay;
            try {
              await createTaxiCompany(environment, formVals);
              message.success('成功创建出租车司机公司');
              hide();
              smartTable?.refetchList();
            } catch (error) {
              message.error(error.message);
              throw error;
            }
          }}
        >
          <TaxiCompanyForm />
        </ModalForm>
      );
    },
    [relay, smartTable],
  );

  const handleSearchFormFinish = useCallback(
    formValues => {
      const newFilters = searchFormVals2Filters(formValues, getOperation);
      smartTable.changeLocationState(newFilters);
    },
    [smartTable],
  );

  return (
    <FullScreenPanel>
      {smartTable.wrappedSmartSearchForm(
        <SearchForm onFinish={handleSearchFormFinish} />,
      )}
      <Toolbar>
        <Toolbar.Left>
          <Button
            icon={<ReloadOutlined />}
            style={TOOLBAR_BTN_STYLE}
            loading={loading}
            disabled={loadingMore}
            onClick={() => {
              smartTable.refetchList();
            }}
          >
            重新加载
          </Button>
          <Button
            style={TOOLBAR_BTN_STYLE}
            disabled={loading || loadingMore}
            onClick={smartTable.clearLocationState}
          >
            清除过滤器及排序
          </Button>
          {permission.addTaxiCompany && (
            <Button
              icon={<PlusOutlined />}
              type="primary"
              style={TOOLBAR_BTN_STYLE}
              loading={loading}
              onClick={() => {
                safelyOpenModalByRef($companyRef);
              }}
            >
              新建
            </Button>
          )}
        </Toolbar.Left>
        <Toolbar.Right>
          <TotalCount value={totalCount} />
        </Toolbar.Right>
      </Toolbar>
      <Table
        loading={loading}
        dataSource={entities}
        rowKey="id"
        pagination={false}
        onChange={smartTable.getTableChangeHandler()}
      >
        <Column
          title="ID"
          dataIndex="id"
          key="id"
          width={90}
          render={idNodeLinkRender}
          sorter={true}
          sortOrder={smartTable.getColumnSorterOrder('id')}
        />
        <Column
          title="名称"
          dataIndex="name"
          key="name"
          width={180}
          render={nodeLinkRender}
        />
        <Column title="城市" dataIndex="city" key="city" width={160} />
        <Column title="客服电话" dataIndex="phone_number" key="phone_number" />
      </Table>
      {permission.addTaxiCompany && (
        <ModalContainer ref={$companyRef}>{companyModelHelper}</ModalContainer>
      )}
    </FullScreenPanel>
  );
};

TaxiCompanyList.defaultProps = {
  loading: false,
  data: null,
};

const query = graphql`
  query TaxiCompanyListQuery($count: Int!, $cursor: String, $q: Json) {
    data: relay {
      ...TaxiCompanyList_data
    }
  }
`;

const HISTORY_STATE_KEY = 'TaxiCompanyList';

const SmartTaxiCompanyList = createSmartTable({
  historyStateKey: HISTORY_STATE_KEY,
  defaultQ: {
    s: 'id desc',
  },
})(TaxiCompanyList);

const TaxiCompanyListContainer = _.flow(
  relayContainerCombiner(
    createPaginationContainer,
    {
      data: graphql`
        fragment TaxiCompanyList_data on Relay {
          entities: companies(first: $count, after: $cursor, q: $q)
            @connection(key: "TaxiCompanyList_entities") {
            edges {
              node {
                id
                name
                province
                city
                phone_number
              }
            }
            pageInfo {
              hasNextPage
              endCursor
            }
            totalCount
          }
        }
      `,
    },
    {
      direction: 'forward',
      getConnectionFromProps(props) {
        return props.data && props.data.entities;
      },
      getFragmentVariables(prevVars, totalCount) {
        return {
          ...prevVars,
          count: totalCount,
        };
      },
      getVariables(props, { count, cursor }, fragmentVariables) {
        const { variables = {} } = props;
        return {
          ...fragmentVariables,
          count,
          ...variables,
          cursor,
        };
      },
      query,
    },
  ),
  withKeycloak,
  createRelayRoot({
    defaultVariables: {
      count: SmartTaxiCompanyList.OPTIONS.fetchNum,
    },
    query,
  }),
  connect(SmartTaxiCompanyList.OPTIONS.mapLocationStateToProps),
)(SmartTaxiCompanyList);

export default TaxiCompanyListContainer;

function createTaxiCompany(environment, values) {
  return new Promise(function (resolve, reject) {
    commitMutation(environment, {
      mutation: graphql`
        mutation TaxiCompanyListCreateCompanyMutation(
          $input: CreateCompanyInput!
        ) {
          createCompany(input: $input) {
            error {
              errors
            }
            company {
              id
              name
              city
              province
              phone_number
            }
          }
        }
      `,
      variables: {
        input: {
          ...values,
        },
      },
      onCompleted: response => {
        if (response.createCompany.error) {
          reject(new Error(response.createCompany.error.errors[0]));
        } else {
          resolve(response);
        }
      },
      onError: err => {
        reject(err);
      },
    });
  });
}
