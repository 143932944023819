import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import { Alert } from 'antd';

import LinkButton from '../LinkButton';
import ErrorInfo from '../ErrorInfo';

/**
 * If you want to use custom error boundary.
 * Please use `withErrorBoundary` and `ErrorBoundary` of `@sentry/react`
 *
 * @returns {ReactElement}
 */
const ErrorBoundary = ({ children }) => (
  <SentryErrorBoundary
    fallback={({ error, componentStack }) => {
      if (
        error.request &&
        /Loading (CSS ){0,}chunk .*? failed/.test(error.message)
        // window.location.hash !== '#retry'
      ) {
        // the chunk might no longer be available due to a recent redeployment of the page
        // mark the page to don't trigger reload infinitely
        // window.location.hash = '#retry';
        return (
          <Alert
            showIcon
            type="warning"
            message="加载资源失败"
            description={
              <span>
                网络异常或发布了新版本，是否尝试
                <LinkButton
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  刷新页面
                </LinkButton>
                ？
              </span>
            }
          />
        );
      }
      // workaround
      const err = new Error(error);
      err.stack = componentStack;
      return <ErrorInfo error={err} />;
    }}
  >
    {children}
  </SentryErrorBoundary>
);

export default ErrorBoundary;
