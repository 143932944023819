import { Component, createRef } from 'react';
import { compile } from 'path-to-regexp';
import { createPaginationContainer, graphql } from 'react-relay';
import { connect } from 'react-redux';
import { withKeycloak } from '@react-keycloak/web';
import { PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import { Table, Button, message } from 'antd';
import _ from 'lodash';

import ModalContainer, {
  safelyOpenModalByRef,
} from '../../components/ModalContainer';
import ModalForm from '../../components/ModalForm';
import { getCurClientRolesFromKeycloak } from '../../utils/keycloakUtils';
import relayContainerCombiner from '../../utils/relayContainerCombiner';
import createRelayRoot from '../../utils/createRelayRoot';
import { isAuthorized } from '../../auth/authorization';
import {
  searchFormVals2Filters,
  createSmartTable,
  TotalCount,
  Toolbar,
} from '../../components/SmartTable2';
import {
  createNodeLinkRender,
  createLocalDateTimeRender,
} from '../../utils/tableColumnRenders';
import { FullScreenPanel } from '../../layouts/BasicLayout';

import CreateTaxiDriverForm from './forms/CreateTaxiDriverForm';
import TaxiDriverSearchForm from './TaxiDriverSearchForm';

const { Column } = Table;
const TOOLBAR_BTN_STYLE = {
  marginRight: '8px',
};

function getOperation(field) {
  const fieldsOperations = [
    {
      _op: 'cont',
      fields: ['taxi_driver_detailComment'],
    },
    {
      _op: 'closed-open-interval',
      fields: ['created_at', 'current_sign_in_at'],
    },
  ];
  return (
    _.find(fieldsOperations, item => _.includes(item.fields, field))?._op ??
    'eq'
  );
}

const TAXI_DRIVER_DETAILS_PAGE_PATH = '/taxi/taxi-drivers/:id';
const getDetailsPagePath = compile(TAXI_DRIVER_DETAILS_PAGE_PATH, {
  encode: encodeURIComponent,
});

class TaxiDriverList extends Component {
  static defaultProps = {
    loading: false,
    data: null,
  };

  state = {};

  $createTaxiDriverRef = createRef();

  handleSearchFormFinish = formValues => {
    const newFilters = searchFormVals2Filters(formValues, getOperation);
    this.props.smartTable.changeLocationState(newFilters);
  };

  createTaxiDriver = ({ visible, hide }) => {
    return (
      <ModalForm
        key={visible}
        title="注册司机"
        open={visible}
        onCancel={hide}
        onSubmit={() => {
          message.info('系统维护中，暂时无法使用');
        }}
        width="600px"
      >
        <CreateTaxiDriverForm />
      </ModalForm>
    );
  };

  idNodeLinkRender = createNodeLinkRender(
    id => getDetailsPagePath({ id }),
    'id',
  );
  dateTimeRender = createLocalDateTimeRender();
  mobileNodeLinkRender = createNodeLinkRender(
    id => getDetailsPagePath({ id }),
    'mobile',
  );

  ratingRender = (data, row, index) => {
    return parseFloat(data).toFixed(1);
  };

  avatarRender = (data, row, index) => {
    if (data) {
      const imgURL = data.replace(/thumbnail$/, 'mini');
      return <img src={imgURL} alt="" width={32} />;
    }
    return '';
  };

  render() {
    const { data, loading, loadingMore, smartTable, history, keycloak } =
      this.props;
    const entities = (data?.entities?.edges ?? []).map(edge => edge.node);
    const totalCount = data?.entities?.totalCount;

    const roles = getCurClientRolesFromKeycloak(keycloak);
    const permission = {
      addTaxiDriver: isAuthorized(
        'user_taxiDrivers_action_addTaxiDriver',
        roles,
      ),
    };

    return (
      <FullScreenPanel>
        {smartTable.wrappedSmartSearchForm(
          <TaxiDriverSearchForm onFinish={this.handleSearchFormFinish} />,
        )}
        <Toolbar>
          <Toolbar.Left>
            <Button
              icon={<ReloadOutlined />}
              style={TOOLBAR_BTN_STYLE}
              loading={loading}
              disabled={loadingMore}
              onClick={() => {
                smartTable.refetchList();
              }}
            >
              重新加载
            </Button>
            <Button
              style={TOOLBAR_BTN_STYLE}
              disabled={loading || loadingMore}
              onClick={smartTable.clearLocationState}
            >
              清除过滤器及排序
            </Button>
            {permission.addTaxiDriver && (
              <Button
                icon={<PlusOutlined />}
                type="primary"
                style={TOOLBAR_BTN_STYLE}
                loading={loading}
                onClick={() => {
                  safelyOpenModalByRef(this.$createTaxiDriverRef);
                }}
              >
                注册司机
              </Button>
            )}
          </Toolbar.Left>
          <Toolbar.Right>
            <TotalCount value={totalCount} />
          </Toolbar.Right>
        </Toolbar>
        <Table
          loading={loading}
          dataSource={entities}
          rowKey="id"
          pagination={false}
          size="middle"
          onChange={smartTable.getTableChangeHandler()}
          onRow={record => {
            return {
              onDoubleClick: () => {
                history.push(getDetailsPagePath({ id: record.id }));
              },
            };
          }}
        >
          <Column
            title="ID"
            dataIndex="id"
            key="id"
            width={90}
            render={this.idNodeLinkRender}
            sorter={true}
            sortOrder={smartTable.getColumnSorterOrder('id')}
          />
          <Column
            title="手机号"
            dataIndex="phone_number"
            key="phone_number"
            width={180}
            render={this.mobileNodeLinkRender}
          />
          <Column
            title="司机头像"
            width={100}
            dataIndex={['avatar', 'thumbnail']}
            key="avatar.thumbnail"
            render={this.avatarRender}
          />
          <Column
            title="姓名"
            dataIndex={['taxi_driver_detail', 'real_name']}
            key="real_name"
            width={120}
          />
          <Column
            title="出租车公司"
            dataIndex={['company', 'name']}
            key="company.name"
            width={180}
          />
          <Column
            title="评分"
            dataIndex={['taxi_driver_detail', 'rating']}
            sorter={true}
            sortOrder={smartTable.getColumnSorterOrder(
              'taxi_driver_detail.rating',
            )}
            key="taxi_driver_detail.rating"
            width={80}
            render={this.ratingRender}
          />
          <Column
            title="创建时间"
            dataIndex="created_at"
            key="created_at"
            width={200}
            render={this.dateTimeRender}
            sorter={true}
            sortOrder={smartTable.getColumnSorterOrder('created_at')}
          />
          <Column
            title="最近登录时间"
            dataIndex="current_sign_in_at"
            key="current_sign_in_at"
            width={200}
            render={this.dateTimeRender}
            sorter={true}
            sortOrder={smartTable.getColumnSorterOrder('current_sign_in_at')}
          />
          <Column
            title="备注"
            dataIndex={('taxi_driver_detail', 'comment')}
            key="comment"
          />
        </Table>
        {permission.addTaxiDriver && (
          <ModalContainer ref={this.$createTaxiDriverRef}>
            {this.createTaxiDriver}
          </ModalContainer>
        )}
      </FullScreenPanel>
    );
  }
}

const query = graphql`
  query TaxiDriverListQuery($count: Int!, $cursor: String, $q: Json) {
    data: relay {
      ...TaxiDriverList_data
    }
  }
`;

const HISTORY_STATE_KEY = 'TaxiDriverList';

const SmartTaxiDriverList = createSmartTable({
  historyStateKey: HISTORY_STATE_KEY,
  defaultQ: {
    s: 'id desc',
  },
})(TaxiDriverList);

export default _.flow([
  relayContainerCombiner(
    createPaginationContainer,
    {
      data: graphql`
        fragment TaxiDriverList_data on Relay {
          entities: taxi_drivers(first: $count, after: $cursor, q: $q)
            @connection(key: "TaxiDriverList_entities") {
            edges {
              node {
                id
                company {
                  id
                  name
                }
                phone_number
                created_at
                current_sign_in_at
                taxi_driver_detail {
                  real_name
                  rating
                }
                avatar {
                  thumbnail
                }
              }
            }
            pageInfo {
              hasNextPage
              endCursor
            }
            totalCount
          }
        }
      `,
    },
    {
      direction: 'forward',
      getConnectionFromProps(props) {
        return props.data?.entities;
      },
      getFragmentVariables(prevVars, totalCount) {
        return {
          ...prevVars,
          count: totalCount,
        };
      },
      getVariables(props, { count, cursor }, fragmentVariables) {
        const { variables = {} } = props;
        return {
          ...fragmentVariables,
          count,
          ...variables,
          cursor,
        };
      },
      query,
    },
  ),
  withKeycloak,
  createRelayRoot({
    defaultVariables: {
      count: SmartTaxiDriverList.OPTIONS.fetchNum,
    },
    query,
  }),
  connect(SmartTaxiDriverList.OPTIONS.mapLocationStateToProps),
])(SmartTaxiDriverList);
