/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type TaxiCompanyList_data$ref = any;
export type TaxiCompanyListQueryVariables = {|
  count: number,
  cursor?: ?string,
  q?: ?any,
|};
export type TaxiCompanyListQueryResponse = {|
  +data: {|
    +$fragmentRefs: TaxiCompanyList_data$ref
  |}
|};
export type TaxiCompanyListQuery = {|
  variables: TaxiCompanyListQueryVariables,
  response: TaxiCompanyListQueryResponse,
|};
*/


/*
query TaxiCompanyListQuery(
  $count: Int!
  $cursor: String
  $q: Json
) {
  data: relay {
    ...TaxiCompanyList_data
  }
}

fragment TaxiCompanyList_data on Relay {
  entities: companies(first: $count, after: $cursor, q: $q) {
    edges {
      node {
        id
        name
        province
        city
        phone_number
        __typename
      }
      cursor
    }
    pageInfo {
      hasNextPage
      endCursor
    }
    totalCount
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "q"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Variable",
    "name": "q",
    "variableName": "q"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TaxiCompanyListQuery",
    "selections": [
      {
        "alias": "data",
        "args": null,
        "concreteType": "Relay",
        "kind": "LinkedField",
        "name": "relay",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TaxiCompanyList_data"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TaxiCompanyListQuery",
    "selections": [
      {
        "alias": "data",
        "args": null,
        "concreteType": "Relay",
        "kind": "LinkedField",
        "name": "relay",
        "plural": false,
        "selections": [
          {
            "alias": "entities",
            "args": (v1/*: any*/),
            "concreteType": "CompanyConnection",
            "kind": "LinkedField",
            "name": "companies",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CompanyEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Company",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "province",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "city",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "phone_number",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCount",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "entities",
            "args": (v1/*: any*/),
            "filters": [
              "q"
            ],
            "handle": "connection",
            "key": "TaxiCompanyList_entities",
            "kind": "LinkedHandle",
            "name": "companies"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "42b38d513b5244ca77b8e558662810ba",
    "id": null,
    "metadata": {},
    "name": "TaxiCompanyListQuery",
    "operationKind": "query",
    "text": "query TaxiCompanyListQuery(\n  $count: Int!\n  $cursor: String\n  $q: Json\n) {\n  data: relay {\n    ...TaxiCompanyList_data\n  }\n}\n\nfragment TaxiCompanyList_data on Relay {\n  entities: companies(first: $count, after: $cursor, q: $q) {\n    edges {\n      node {\n        id\n        name\n        province\n        city\n        phone_number\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n    totalCount\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '85ef3574f95ad2eb294d348ce2baa2bd';

module.exports = node;
