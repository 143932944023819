import { lazy } from 'react';
import _ from 'lodash';

import Section from '../../components/Section';
import { authorizedRouteWrapper } from '../../auth/authorization';

export default {
  path: 'express',
  component: Section,
  name: '快车',
  icon: 'sd-car-express-o',
  resource_id: 'express',
  leftNav: true,
  routes: [
    {
      path: 'orders',
      name: '快车订单',
      resource_id: 'express_orders',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "ExpressList" */ '../../views/ExpressListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'geofences',
      name: '服务围栏',
      resource_id: 'express_geofences',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "ExpressGeofenceListPage" */ '../../views/ExpressGeofenceListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'driver-groups',
      name: '司机组',
      resource_id: 'express_driver-groups',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "CSDriverGroupListPage" */ '../../views/CSDriverGroupListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'fares',
      name: '计价',
      resource_id: '/express/fares',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "ExpressFareListPage" */ '../../views/ExpressFareListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'hot-cities',
      name: '热门城市',
      resource_id: '/express/hot-cities',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "ExpressHotCityListPage" */ '../../views/ExpressHotCityListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'hot-places',
      name: '热门地点',
      resource_id: '/express/hot-places',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "ExpressHotPlaceListPage" */ '../../views/ExpressHotPlaceListPage'
            ),
        ),
      ),

      exact: true,
      leftNav: true,
    },
    {
      path: 'orders/:id',
      resource_id: _.snakeCase(`express_orders/:id`),
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "ExpressDetailsPage" */ '../../views/ExpressDetailsPage'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'hot-places/:id',
      resource_id: '/express/hot-places/:id',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "ExpressHotPlaceDetailPage" */ '../../views/ExpressHotPlaceDetailPage'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'driver-groups/:id',
      resource_id: _.snakeCase(`express_driver-groups/:id`),
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "CSDriverGroupDetailsPage" */ '../../views/CSDriverGroupDetailsPage'
            ),
        ),
      ),
      exact: true,
    },
  ],
};
