import { useCallback, useRef } from 'react';
import { useLocation, useHistory } from 'react-router';
import _ from 'lodash';

// import labelAnnotationsParser from '../../utils/labelAnnotationsParser';

import {
  transformFiltersValForLocationState,
  transformFilterValuesForForm,
} from './transform';
import searchFormVals2Filters from './searchFormVals2Filters';
import formatQ from './formatQ';

export function useLocationFilter(historyStateKey) {
  const location = useLocation();
  const filters = location?.state?.[historyStateKey]?.filters;
  return filters;
}

export function useLocationSorter(historyStateKey) {
  const location = useLocation();
  const sorter = location?.state?.[historyStateKey]?.sorter;
  return sorter;
}
export function useLocationSelector(historyStateKey) {
  const location = useLocation();
  const sorter = location?.state?.[historyStateKey]?.selector;
  return sorter;
}

export const useLocationQueryHandler = historyStateKey => {
  const location = useLocation();
  const history = useHistory();

  const filtersState = useLocationFilter(historyStateKey);
  const sorterState = useLocationSorter(historyStateKey);
  const selectorState = useLocationSelector(historyStateKey);

  const handler = useCallback(
    (filters, sorter, selector) => {
      const newFiltersState = _.isEmpty(filters) ? {} : filters;
      const newSorterState = _.isEmpty(sorter) ? null : sorter;
      const newSelectorState = _.isEmpty(selector) ? null : selector;

      if (
        _.isEqual(filtersState, newFiltersState) &&
        _.isEqual(sorterState, newSorterState) &&
        _.isEqual(selectorState, newSelectorState)
      ) {
        return;
      }

      // 赋值该表格新的过滤和筛选状态
      const newLocation = _.mergeWith(
        location,
        {
          state: {
            [historyStateKey]: {
              filters: transformFiltersValForLocationState(newFiltersState),
              sorter: newSorterState,
              selector: newSelectorState,
            },
          },
        },
        (objValue, srcValue) => (_.isArray(srcValue) ? srcValue : undefined),
      );
      history.replace(newLocation);
    },
    [
      filtersState,
      history,
      location,
      selectorState,
      sorterState,
      historyStateKey,
    ],
  );

  return handler;
};

export function useLocationQ(
  historyStateKey,
  defaultQ = {},
  overrideQ = {},
  stringifyQ = false,
) {
  const filtersState = useLocationFilter(historyStateKey);
  const sorterState = useLocationSorter(historyStateKey);

  const $defaultQ = useRef(defaultQ);
  // const selectorState = useLocationSelector(historyStateKey);

  const locationQ = formatQ(
    {
      sorter: sorterState,
      filters: filtersState,
    },
    $defaultQ.current,
  );

  const finalQ = _.pickBy(
    {
      ...locationQ,
      ...overrideQ,
    },
    val => !_.isNil(val) && !_.isEqual(val, ''),
  );

  return stringifyQ ? JSON.stringify(finalQ) : finalQ;
}

export function useLocationStateClearer(historyStateKey) {
  const location = useLocation();
  const history = useHistory();
  // push all

  return useCallback(() => {
    const newLocation = _.merge(location, {
      state: {
        [historyStateKey]: null,
      },
    });

    history.replace(newLocation);
  }, [historyStateKey, history, location]);
}

// about search form
export function useSearchFormSubmit(
  historyStateKey,
  getOperation = () => 'eq',
) {
  const locationsStateHandler = useLocationQueryHandler(historyStateKey);

  const handleSearchFormSubmit = useCallback(
    formVals => {
      const newFilters = searchFormVals2Filters(formVals, getOperation);
      locationsStateHandler(newFilters);
    },
    [getOperation, locationsStateHandler],
  );

  return handleSearchFormSubmit;
}

export function useFilterValues(historyStateKey) {
  const filtersState = useLocationFilter(historyStateKey);
  const selectorState = useLocationSelector(historyStateKey);

  return _.assign(
    {},
    transformFilterValuesForForm(filtersState),
    selectorState,
  );
}
