import { lazy } from 'react';

import Section from '../../components/Section';
import { authorizedRouteWrapper } from '../../auth/authorization';

// 运营监管相关页面
export default {
  path: 'supervisor',
  name: '司机与订单',
  icon: 'sd-order',
  component: Section,
  resource_id: '/supervisor',
  leftNav: true,
  routes: [
    {
      path: 'express',
      name: '实时订单',
      resource_id: '/supervisor/express',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "SupervisorExpressList" */ '../../views/supervisorExpress'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'intercity-rides',
      name: '预约订单',
      resource_id: '/supervisor/intercity-rides',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "SupervisorIntercityRideList" */ '../../views/supervisorIntercityRide'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
  ],
};
