/**
 * Created by qiuyuzhou on 2017/5/8.
 */
import { applyMiddleware, compose, createStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { createLogger } from 'redux-logger';
import { routerMiddleware } from 'connected-react-router';
import { createReduxEnhancer } from '@sentry/react';

import { fetchViewer } from './redux/actions';
import createRootReducer from './redux/reducers';
import { rootEpic } from './redux/epics';

export default function configureStore(history) {
  const middleware = [routerMiddleware(history)];

  if (process.env.NODE_ENV === 'development') {
    const reduxLogger = createLogger({});
    middleware.push(reduxLogger);
  }

  const epicMiddleware = createEpicMiddleware();
  middleware.push(epicMiddleware);

  const middlewareEnhancer = applyMiddleware(...middleware);
  const enhancers = [middlewareEnhancer];
  if (process.env.NODE_ENV === 'production') {
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/
    enhancers.push(createReduxEnhancer());
  }

  const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;

  const initialState = {
    account: {
      viewer: {
        entry: null,
        error: null,
        fetching: false,
      },
    },
  };

  const store = createStore(
    createRootReducer(history),
    initialState,
    composeEnhancers(...enhancers),
  );

  epicMiddleware.run(rootEpic);

  if (store.getState().account.tokens) {
    store.dispatch(fetchViewer());
  }
  return store;
}
