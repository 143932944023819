import { useCallback, useRef, useState, useMemo } from 'react';
import { graphql } from 'react-relay';
import { PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import _ from 'lodash';

import {
  useLocationStateClearer,
  useLocationQueryHandler,
  useSearchFormSubmit,
  useLocationSorter,
  useFilterValues,
  TotalCount,
  Toolbar,
} from '../../../components/SmartTable2';
import { getValueType } from '../../../components/SmartTable2/transform';
import { decodeGraphQLGlobalID } from '../../../utils/graphQLGlobalID';
import ModalForm from '../../../components/ModalForm';
import useSDMutation from '../../../hooks/useSDMutation';
import ModalContainer, {
  safelyOpenModalByRef,
} from '../../../components/ModalContainer';

import TaxiVehicleTable from './TaxiVehicleTable';
import TaxiVehicleForm from '../TaxiVehicleForm';
import SearchForm from './SearchForm';

const HISTORY_STATE_KEY = 'SmartTaxiVehicleTable';

const registerVehicleMutation = graphql`
  mutation SmartTaxiVehicleTableMutation(
    $input: CreateTaxiVehicleDetailInput!
  ) {
    createTaxiVehicleDetail(input: $input) {
      error {
        errors
      }
      vehicleDetail: vehicle_detail {
        ...TaxiVehicleTable_data
      }
    }
  }
`;

function getOperation(field) {
  const fieldsOperations = [
    {
      _op: 'closed-open-interval',
      fields: ['tradeExpireDate', 'registrationDate'],
    },
  ];
  return (
    _.find(fieldsOperations, item => _.includes(item.fields, field))?._op ??
    'eq'
  );
}

const SmartTaxiVehicleTable = ({
  data,
  loading,
  loadingMore,
  relay,
  historyStateKey: propHistoryStateKey,
  taxiDriverDetailAuth,
  addAuth,
  editAuth,
}) => {
  const entities = (data?.entities?.edges ?? []).map(edge => edge.node);
  const totalCount = data?.entities?.totalCount;
  const historyStateKey = propHistoryStateKey ?? HISTORY_STATE_KEY;

  const $registerVehicle = useRef(null);
  const [refetching, setRefetching] = useState(false);

  const searchFormValues = useFilterValues(historyStateKey);
  const sorterState = useLocationSorter(historyStateKey);
  const locationsStateHandler = useLocationQueryHandler(historyStateKey);

  const handleSearchFormReset = useLocationStateClearer(historyStateKey);
  const [registerVehicle] = useSDMutation(registerVehicleMutation);

  const handleSearchFormFinish = useSearchFormSubmit(
    historyStateKey,
    getOperation,
  );

  const searchFormFields = useMemo(
    () => _.map(searchFormValues, (value, key) => ({ name: key, value })),
    [searchFormValues],
  );

  const handleRefetchBtnClick = () => {
    if (refetching || loadingMore) {
      return;
    }
    setRefetching(true);
    relay.refetchConnection(25, error => {
      setRefetching(false);
      error && message.error(error.message);
    });
  };

  const handleTableChange = useMemo(() => {
    const getTableChangeHandler = (options = {}) => {
      const { filterOperation = {} } = options;

      function getFilterOperation(field, value) {
        const customizeOperation = filterOperation[field];
        if (customizeOperation) {
          if (_.isFunction(customizeOperation)) {
            return customizeOperation(field, value);
          }
          return customizeOperation;
        }
        if (_.isArray(value)) {
          return 'in';
        }
        return 'eq';
      }

      return (pagination, filters, sorter) => {
        const newFilters = _.mapValues(filters, (value, field) => ({
          value,
          valueType: getValueType(value),
          _op: getFilterOperation(field, value),
        }));
        locationsStateHandler(
          newFilters,
          sorter?.order
            ? {
                field: sorter?.columnKey,
                order: sorter?.order,
              }
            : {},
        );
      };
    };
    return getTableChangeHandler();
  }, [locationsStateHandler]);

  const getColumnSorterOrder = field => {
    if (_.isEqual(field, sorterState?.field)) {
      return sorterState?.order;
    }
    return false;
  };

  const idSortOrder = getColumnSorterOrder('id');
  const tradeExpressSortOrder = getColumnSorterOrder('tradeExpireDate');
  const registDateSortOrder = getColumnSorterOrder('registrationDate');

  const extendsColProps = useMemo(() => {
    return {
      id: {
        sorter: true,
        sortOrder: idSortOrder,
      },
      tradeExpireDate: {
        sorter: true,
        sortOrder: tradeExpressSortOrder,
      },
      registrationDate: {
        sorter: true,
        sortOrder: registDateSortOrder,
      },
    };
  }, [idSortOrder, tradeExpressSortOrder, registDateSortOrder]);

  const registerVehicleModal = useCallback(
    ({ visible, hide }) => {
      const handleFormFinish = async values => {
        const {
          company,
          plateNumber,
          registrationDate,
          tradeExpireDate,
          tradingCertificate,
          ...notNeedTransfromValue
        } = values;
        const inputValues = _.assign(
          {
            company_id: decodeGraphQLGlobalID(company.id),
            plate_number: plateNumber.province + plateNumber.number,
            registration_date: registrationDate,
            trading_certificate: !!tradingCertificate
              ? tradingCertificate
              : undefined,
            trade_expire_date: !!tradeExpireDate ? tradeExpireDate : undefined,
          },
          notNeedTransfromValue,
        );
        const variables = {
          input: {
            ...inputValues,
          },
        };
        try {
          await registerVehicle({
            variables,
          });
          message.success('成功注册车辆！');
          hide();
          setRefetching(true);
          relay.refetchConnection(1, error => {
            setRefetching(false);
            error && message.error(error.message);
          });
        } catch (error) {
          message.error(error.message);
        }
      };
      return (
        <ModalForm
          key={visible}
          title="注册车辆"
          open={visible}
          onCancel={hide}
        >
          {form => {
            return (
              <TaxiVehicleForm
                form={form}
                onFinish={handleFormFinish}
                initialValues={null}
              />
            );
          }}
        </ModalForm>
      );
    },
    [registerVehicle, relay],
  );

  const renderRegisterBtn = useCallback(() => {
    safelyOpenModalByRef($registerVehicle);
  }, []);

  return (
    <>
      <SearchForm
        onFinish={handleSearchFormFinish}
        fields={searchFormFields}
        onReset={handleSearchFormReset}
      />
      <Toolbar>
        <Toolbar.Left>
          <Button
            icon={<ReloadOutlined />}
            loading={loading}
            disabled={loadingMore}
            onClick={handleRefetchBtnClick}
          >
            重新加载
          </Button>
          <Button
            disabled={loading || loadingMore}
            onClick={handleSearchFormReset}
          >
            清除过滤器及排序
          </Button>
          {addAuth && (
            <Button
              icon={<PlusOutlined />}
              type="primary"
              loading={loading}
              onClick={renderRegisterBtn}
            >
              注册车辆
            </Button>
          )}
        </Toolbar.Left>
        <Toolbar.Right>
          <TotalCount value={totalCount} />
        </Toolbar.Right>
      </Toolbar>
      <TaxiVehicleTable
        entities={entities}
        loading={loading}
        onChange={handleTableChange}
        extendsColProps={extendsColProps}
        taxiDriverDetailAuth={taxiDriverDetailAuth}
        editAuth={editAuth}
      />
      {addAuth && (
        <ModalContainer ref={$registerVehicle}>
          {registerVehicleModal}
        </ModalContainer>
      )}
    </>
  );
};

export default SmartTaxiVehicleTable;
