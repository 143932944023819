import { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { Cascader } from 'antd';

import OriginAreaData from './OriginAreaData';

function handleDisabled(item, disabledValues = []) {
  const { code, children } = item;

  let newChildren;
  if (Array.isArray(children)) {
    newChildren = children.map(item => handleDisabled(item, disabledValues));
  }

  if (disabledValues.includes(code)) {
    return {
      ...item,
      disabled: true,
      children: newChildren,
    };
  } else {
    return {
      ...item,
      children: newChildren,
    };
  }
}

export const DEFAULT_DISABLED_VALUES = [
  '710000', // 台湾
  '810000', // 香港
  '820000', // 澳门
];

const fieldNames = { label: 'name', value: 'code' };
function AreaSelect({ disabledValues, ...cascaderProps }) {
  const options = useMemo(
    () =>
      OriginAreaData
        ? OriginAreaData.map(item => handleDisabled(item, disabledValues))
        : [],
    [disabledValues],
  );

  return (
    <Cascader
      expandTrigger="hover"
      placeholder="请选择区域"
      showSearch={true}
      {...cascaderProps}
      options={options}
      fieldNames={fieldNames}
    />
  );
}

AreaSelect.propTypes = {
  ...Cascader.propTypes,
  disabledValues: PropTypes.arrayOf(PropTypes.string.isRequired),
};

AreaSelect.defaultProps = {
  disabledValues: DEFAULT_DISABLED_VALUES,
};

export default memo(AreaSelect);
