import classNames from 'classnames';
import { Card } from 'antd';

import styles from './FullScreenPanel.module.less';

const defaultProps = {
  bordered: false,
};
const FullScreenPanel = ({ className, ...otherProps }) => (
  <Card
    {...otherProps}
    bordered
    className={classNames(styles['full-screen-panel'], className)}
  />
);

FullScreenPanel.propTypes = Card.propTypes;
FullScreenPanel.defaultProps = defaultProps;

export default FullScreenPanel;
