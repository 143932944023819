import { useMemo } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { getCurClientRolesFromKeycloak } from '../utils/keycloakUtils';

const useCurClientRoles = () => {
  const { keycloak } = useKeycloak();
  const roles = getCurClientRolesFromKeycloak(keycloak) || [];
  /**
   * why toStr and str-to-arr?
   *
   * see: https://github.com/facebook/react/issues/14476#issuecomment-471199055
   */
  const rolesStr = roles.sort().join(',');
  const memorizedRoles = useMemo(() => rolesStr.split(','), [rolesStr]);
  return memorizedRoles;
};

export default useCurClientRoles;
