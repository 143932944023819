import dataSource from './Area.json';

const getProvinceShortCode = code => code.slice(0, 2);
const getCityShortCode = code => code.slice(0, 4);
// 是否是直辖市

const allProvinces = [];
const allCities = [];
const allDistricts = [];
dataSource.forEach(item => {
  const [code] = item;
  if (code.length === 2) {
    allProvinces.push(item);
  } else if (code.length === 4) {
    allCities.push(item);
  } else if (code.length === 6) {
    allDistricts.push(item);
  }
});

export const treeData = [];
export const onlyProvinces = [];
export const provincesAndCities = [];

allProvinces.forEach(([code, name]) => {
  const fullCode = code.padEnd(6, '0');
  onlyProvinces.push({ code: fullCode, name });
  treeData.push({ code: fullCode, name, children: [] });
  provincesAndCities.push({ code: fullCode, name, children: [] });
});

// 直辖市处理方式参考高德
const specialCities = [
  ['1101', '北京城区'],
  ['1201', '天津城区'],
  ['3101', '上海城区'],
  ['5001', '重庆城区'],
  ['5002', '重庆郊县'],
];
allCities.concat(specialCities).forEach(([code, name]) => {
  const fullCode = code.padEnd(6, '0');
  const belongsProvinceShortCode = getProvinceShortCode(code);
  const isBelongsProvince = province =>
    getProvinceShortCode(province.code) === belongsProvinceShortCode;

  provincesAndCities
    .find(isBelongsProvince)
    .children.push({ code: fullCode, name });
  treeData
    .find(isBelongsProvince)
    .children.push({ code: fullCode, name, children: [] });
});

allDistricts.forEach(([code, name]) => {
  const fullCode = code.padEnd(6, '0');
  const belongsProvinceShortCode = getProvinceShortCode(code);
  const isBelongsProvince = province =>
    getProvinceShortCode(province.code) === belongsProvinceShortCode;
  const belongsCityShortCode = getCityShortCode(code);
  const isBelongsCity = city =>
    getCityShortCode(city.code) === belongsCityShortCode;

  const belongsProvince = treeData.find(isBelongsProvince);
  const belongsCity = belongsProvince.children.find(isBelongsCity);
  const treeNode = {
    code: fullCode,
    name,
  };
  if (belongsCity) {
    belongsCity.children.push(treeNode);
  } else {
    /**
     * 补充 “省级直属县级行政单位”,包括以下城市以及直辖市下的行政单位。
     *
     * 济源市,仙桃市,潜江市,天门市,神农架林区,
     * 五指山市,琼海市,文昌市,万宁市,东方市,定安县,屯昌县,澄迈县,临高县,白沙黎族自治县,昌江黎族自治县,乐东黎族自治县,陵水黎族自治县,保亭黎族苗族自治县,琼中黎族苗族自治县,
     * 石河子市,阿拉尔市,图木舒克市,五家渠市,北屯市,铁门关市,双河市,可克达拉市,昆玉市,胡杨河市
     */
    const specialCityNode = {
      code: belongsCityShortCode.padEnd(6, '0'), // 观测发现，非直辖市中，“省级直属县级行政单位” 行政编码均为 XX90XX
      name: '省级直辖行政单位',
      children: [treeNode],
    };
    belongsProvince.children.push(specialCityNode);
  }
});

export const provinces = onlyProvinces; // 兼容

export const allData = treeData; // 兼容

export default treeData;
