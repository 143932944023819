import { useState } from 'react';
import { graphql, createRefetchContainer } from 'react-relay';
import { Button } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';

import PageHeaderLayout from '../../layouts/PageHeaderLayout';
import AutoMarginBottom from '../../components/AutoMarginBottom';
import WithdrawalConfigWechatPayCard from './WithdrawalConfigWechatPayCard';
import WithdrawalConfigAlipayCard from './WithdrawalConfigAlipayCard';
import WithdrawalConfigBankCard from './WithdrawalConfigBankCard';
import WithdrawalConfigRewardCard from './WithdrawalConfigRewardCard';

import createRelayRoot from '../../utils/createRelayRoot';

const WithdrawalConfigPage = ({ loading, data, relay }) => {
  const [refetching, setRefetching] = useState(false);
  const isFetching = !!loading || refetching;

  const handleRefetchBtnClick = () => {
    if (isFetching) return;
    setRefetching(true);

    relay.refetch(undefined, undefined, () => {
      setRefetching(false);
    });
  };

  return (
    <PageHeaderLayout
      title="提现配置"
      action={
        <Button onClick={handleRefetchBtnClick} disabled={loading}>
          <ReloadOutlined />
        </Button>
      }
    >
      <AutoMarginBottom>
        <div>
          <WithdrawalConfigWechatPayCard
            data={data}
            relay={relay}
            loading={isFetching}
          />
        </div>
        <div>
          <WithdrawalConfigAlipayCard
            data={data}
            relay={relay}
            loading={isFetching}
          />
        </div>
        <div>
          <WithdrawalConfigBankCard
            data={data}
            relay={relay}
            loading={isFetching}
          />
        </div>
        <div>
          <WithdrawalConfigRewardCard
            data={data}
            relay={relay}
            loading={isFetching}
          />
        </div>
      </AutoMarginBottom>
    </PageHeaderLayout>
  );
};

const query = graphql`
  query WithdrawalConfigPageQuery {
    data: relay {
      config: global_config {
        ...WithdrawalConfigPage_data
      }
    }
  }
`;

const WithdrawalConfigContainer = createRefetchContainer(
  WithdrawalConfigPage,
  {
    data: graphql`
      fragment WithdrawalConfigPage_data on GlobalConfig {
        id

        wechat_payout
        auto_process_wechat_payout
        wechat_title
        wechat_minimum_payout_amount
        wechat_maximum_payout_amount

        alipay_payout
        auto_process_alipay_payout
        alipay_title
        alipay_minimum_payout_amount
        alipay_maximum_payout_amount

        bank_payout
        auto_process_bank_payout
        bank_title
        bank_minimum_payout_amount
        bank_maximum_payout_amount

        reward_wechat_minimum_payout_amount
        reward_wechat_maximum_payout_amount
      }
    `,
  },
  query,
);

export default createRelayRoot({
  query,
  dataMapping: [['data', props => props.data.config]],
})(WithdrawalConfigContainer);
