import { graphql } from 'react-relay';
import {
  commitMutationWithPromise,
  checkMutationResponse,
} from '../../utils/commitMutationWithPromise';

export default async function updateWithdrawalConfigMutation(env, variables) {
  const response = await commitMutationWithPromise(env, {
    mutation: graphql`
      mutation updateWithdrawalConfigMutation($input: GlobalConfigInput!) {
        setGlobalConfig(input: $input) {
          global_config {
            id
            ...WithdrawalConfigPage_data
          }
          error {
            errors
          }
        }
      }
    `,
    variables,
  });

  return checkMutationResponse(response, ['setGlobalConfig'])[
    'setGlobalConfig'
  ];
}
