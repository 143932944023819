import PropTypes from 'prop-types';
import { memo } from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { Spin, Rate, Row, Col } from 'antd';

import createRelayRoot from '../../utils/createRelayRoot';

import TaxiOrderPaymentList from './TaxiOrderPaymentList';
import TaxiTimeline from './TaxiTimeline';

const query = graphql`
  query TaxiOrderDetailsQuery($id: ID!) {
    data: node(id: $id) {
      ...TaxiOrderDetail_data
    }
  }
`;

const TaxiOrderDetails = props => {
  const { data, loading } = props;
  const rating = data?.rating;
  const payments = data?.payments ?? [];

  return (
    <Spin spinning={loading}>
      <Row gutter={16}>
        <Col span={12}>
          <section style={{ padding: '8px 0' }}>
            <div>
              <span className="ride-more-info-addr-label">上车点：</span>
              <span>{data?.origin}</span>
            </div>
            <div>
              <span className="ride-more-info-addr-label">目的地：</span>
              <span>{data?.destination}</span>
            </div>
          </section>
          {rating && (
            <section style={{ padding: '8px 0' }}>
              <Rate disabled value={rating} />
              <span>{data?.feedback}</span>
            </section>
          )}
          {payments && payments.length > 0 && (
            <section style={{ padding: '8px 0' }}>
              <TaxiOrderPaymentList payments={payments} />
            </section>
          )}
        </Col>
        <Col span={12} style={{ padding: '0 16px' }}>
          {data && <TaxiTimeline ride={data} />}
        </Col>
      </Row>
    </Spin>
  );
};

TaxiOrderDetails.propTypes = {
  data: PropTypes.object,
};

const TaxiOrderDetailsContainer = createFragmentContainer(TaxiOrderDetails, {
  data: graphql`
    fragment TaxiOrderDetail_data on Taxi {
      id
      destination
      origin
      rating
      feedback
      ...TaxiTimeline_data
      ...TaxiOrderPaymentList_data
    }
  `,
});

const TaxiOrderDetailList = createRelayRoot({
  query,
})(TaxiOrderDetailsContainer);

export default memo(function (props) {
  const variables = {
    id: props?.id,
  };
  return <TaxiOrderDetailList variables={variables} />;
});
