import { useContext } from 'react';
import { RouteContext } from '@ant-design/pro-layout';

import './FooterBar.module.less';

function FooterBar({ children }) {
  const { isMobile, collapsed } = useContext(RouteContext);
  return (
    <div
      styleName="footer-bar"
      style={
        isMobile
          ? null
          : { width: collapsed ? 'calc(100% - 59px)' : 'calc(100% - 256px)' }
      }
    >
      {children}
    </div>
  );
}

export default FooterBar;
