import { lazy } from 'react';
import _ from 'lodash';
import { NotificationOutlined } from '@ant-design/icons';

import Section from '../../components/Section';
import { decodeGraphQLGlobalID } from '../../utils/graphQLGlobalID';

import { authorizedRouteWrapper } from '../../auth/authorization';

export default {
  path: 'operating',
  name: '运营',
  icon: <NotificationOutlined />,
  component: Section,
  resource_id: 'operating',
  leftNav: true,
  routes: [
    {
      path: 'promotions',
      name: '领券活动',
      resource_id: 'operating_promotions',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "PromotionActivityListPage" */ '../../views/PromotionActivityListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'invisitation-activity',
      name: '邀新活动（奖励金）',
      resource_id: 'operating_invisitation-activity',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "invitationActivities" */ '../../views/invitationActivities'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'voucher_distribution',
      name: '定向送券',
      resource_id: 'operating_voucherDistribution',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "VoucherDistributionList" */ '../../views/voucherDistributions/VoucherDistributionList'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'vouchers',
      name: '优惠券',
      resource_id: 'operating_vouchers',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "VoucherListPage" */ '../../views/VoucherListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'app-dynamic-display-confs/materials/:materialType/:id',
      resource_id: 'operating_app-dynamic-display-confs_meterial_view_details',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "MaterialDetails" */ '../../views/appDynamicDisplayConfs/MaterialDetails'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'app-dynamic-display-confs',
      name: '动态展示位',
      resource_id: 'operating_app-dynamic-display-confs',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "appDynamicDisplayConfs" */ '../../views/appDynamicDisplayConfs'
            ),
        ),
      ),
      exact: false,
      leftNav: true,
    },
    {
      path: 'advertisements',
      name: '弹窗广告',
      resource_id: '/operating/advertisements',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "AdvertisementPanel" */ '../../views/advertisements/AdvertisementPanel'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'push-notification',
      name: 'APP 推送',
      resource_id: 'operating_push-notification',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "PushNotificationPage" */ '../../views/PushNotification'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'recharge',
      name: '余额充值（公司）',
      resource_id: 'operating_recharge',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "RechargeLogList" */ '../../views/RechargeListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'oc_recharge',
      name: '余额充值（运营中心）',
      resource_id: 'operating_ocRecharge',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "OperationCenterRechargeLogList" */ '../../views/operationCenterRecharge'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'send-verification-code',
      name: '发送短信验证码',
      resource_id: 'operating_send-verification-code',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "SendVerificationCodePage" */ '../../views/SendVerificationCodePage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'passenger-disablement',
      name: '禁用乘客',
      resource_id: 'operating_passenger-disablement',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "DisablementListPage" */ '../../views/DisablementListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'blacklist',
      name: '黑名单',
      resource_id: '/operating/blacklist',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "PassengerDriverBlacklistListPage" */ '../../views/PassengerDriverBlacklistListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'driver-punishments',
      name: '司机处治',
      resource_id: 'operating_driver-punishments',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "DriverPunishmentListPage" */ '../../views/DriverPunishmentListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'didi-work-order',
      name: '滴滴工单',
      resource_id: _.snakeCase('operating/didi-work-order'),
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "DidiWorkOrderListPage" */ '../../views/DidiWorkOrderListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'query-orderId',
      name: '按商户号查询订单',
      resource_id: '/operating/queryOrderId',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "QueryOrderIdPage" */ '../../views/QueryOrderIdPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'push-notification/:id',
      resource_id: 'operating_push-notification_view_details',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "PushNotificationDetailsPage" */ '../../views/PushNotification/NotificationDetails'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'invisitation-activity/:activityId/voucher-confs/:voucherConfId',
      resource_id: 'operating_invisitation-activity_view_vouchers_details',
      name: '优惠券配置详情 - 奖励金邀新活动',
      breadcrumbName: ({ params: { voucherConfId } }) =>
        `优惠券配置详情  /  ${decodeGraphQLGlobalID(
          decodeURIComponent(voucherConfId),
        )}`,
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "InvitationActivityVoucherConfDetails" */ '../../views/invitationActivities/InvitationActivityVoucherConfDetails'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'invisitation-activity/:activityId',
      resource_id: 'operating_invisitation-activity_view_details',
      name: '奖励金邀新活动详情',
      breadcrumbName: ({ params: { activityId } }) =>
        decodeGraphQLGlobalID(decodeURIComponent(activityId)),
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "InvitationActivityDetailsPage" */ '../../views/InvitationActivityDetailsPage'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'advertisements/:id',
      resource_id: '/operating/advertisements/:id',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "AdvertisementDetails" */ '../../views/advertisements/AdvertisementDetails'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'vouchers/:id',
      resource_id: 'operating_vouchers_view_details',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "VoucherDetailsPage" */ '../../views/VoucherDetailsPage'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'promotions/:promotionId/vouchers/:id',
      resource_id: 'operating_promotions_view_vouchers_details',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "PromotionVoucherDetailsPage" */ '../../views/PromotionVoucherDetailsPage'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'promotions/:id',
      resource_id: 'operating_promotions_view_details',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "PromotionActivityDetailsPage" */ '../../views/PromotionActivityDetailsPage'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'voucher_distribution/:id',
      resource_id: 'operating_voucherDistributionList_view_details',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "VoucherDistributionDetail" */ '../../views/voucherDistributions/VoucherDistributionDetail'
            ),
        ),
      ),
      exact: true,
    },
  ],
};
