import { PureComponent } from 'react';
import _ from 'lodash';
import { Spin, Select } from 'antd';
import { useRelayEnvironment } from 'react-relay/hooks';
import { createRefetchContainer, graphql, QueryRenderer } from 'react-relay';

import RelayError from './RelayError';

/**
 * 向外传递前转换
 * @param {*} value
 */
function transformOut(value) {
  return { id: value.key, plate_number: value.label };
}

/**
 * 转换成内部所需结构
 * @param {*} value
 */
function transformIn(value) {
  return { key: value.id, label: value.plate_number };
}
class InputSearchTaxiVehicle extends PureComponent {
  state = {
    refetching: false,
  };

  constructor(props) {
    super(props);
    this.onSearch = _.debounce(this.onSearch, 800);
  }

  onSearch = value => {
    value = value.trim();
    const { q } = this.props;
    const newq = !!q ? { ...q } : {};
    if (value.length > 0) {
      newq.plate_number_cont = value;
    }
    if (this.refetchDisposable) {
      this.refetchDisposable.dispose();
      this.refetchDisposable = null;
    }
    this.setState({ refetching: true }, () => {
      this.refetchDisposable = this.props.relay.refetch(
        { q: JSON.stringify(newq) },
        null,
        error => {
          if (error) {
            console.error(error);
          }
          this.refetchDisposable = null;
          this.setState({ refetching: false });
        },
        {
          force: false,
        },
      );
    });
  };

  onChange = value => {
    if (this.props.mode === 'multiple') {
      this.props.onChange(value.map(transformOut));
    } else {
      this.props.onChange(transformOut(value));
    }
  };

  render() {
    const { data, mode = null, value } = this.props;
    let options;
    if (data && data.taxi_vehicles) {
      const taxi_vehicles = data.taxi_vehicles.edges.map(edge => edge.node);
      options = taxi_vehicles.map(taxi_vehicle => (
        <Select.Option key={taxi_vehicle.id}>
          {taxi_vehicle.plate_number}
        </Select.Option>
      ));
    } else {
      options = (
        <Select.Option key="taxi_vehicle_null" value="disabled" disabled>
          找不到相关车辆
        </Select.Option>
      );
    }
    // transform value
    let _value;
    if (value) {
      if (mode === 'multiple') {
        _value = value.map(transformIn);
      } else {
        _value = transformIn(value);
      }
    } else {
      _value = {
        key: '',
        label: null,
      };
    }
    return (
      <Select
        mode={mode}
        id={this.props.id}
        labelInValue={true}
        showSearch
        value={_value}
        filterOption={false}
        onSearch={this.onSearch}
        onChange={this.onChange}
        notFoundContent={this.state.refetching ? <Spin size="small" /> : null}
      >
        {options}
      </Select>
    );
  }
}

const query = graphql`
  query InputSearchTaxiVehicleQuery($q: Json) {
    data: relay {
      ...InputSearchTaxiVehicle_data
    }
  }
`;

const InputSearchTaxiVehicleContainer = createRefetchContainer(
  InputSearchTaxiVehicle,
  {
    data: graphql`
      fragment InputSearchTaxiVehicle_data on Relay {
        taxi_vehicles(first: 10, q: $q)
          @connection(key: "InputSearchTaxiVehicle_taxi_vehicles") {
          edges {
            node {
              id
              plate_number
            }
          }
        }
      }
    `,
  },
  query,
);

const InputSearchTaxiVehicleRoot = _props => {
  const relayEnvironment = useRelayEnvironment();
  return (
    <QueryRenderer
      environment={relayEnvironment}
      query={query}
      variables={{ q: JSON.stringify(_props.q) }}
      render={readyState => {
        const { error, props } = readyState;
        if (error) {
          return <RelayError error={error} />;
        }
        return (
          <InputSearchTaxiVehicleContainer
            {..._props}
            loading={!props}
            data={props ? props.data : null}
          />
        );
      }}
    />
  );
};

export default InputSearchTaxiVehicleRoot;
