import _ from 'lodash';

import OriginAreaData from './OriginAreaData';

export const getNameByAddressCode = addressCode => {
  const core = (areaList, addressCode, level) => {
    const parentCode = addressCode?.slice(0, 2 * (level + 1)).padEnd(6, '0');
    const area = _.find(areaList, ({ code }) => code === parentCode);

    if (!area) return '';

    if (parentCode === addressCode) return area.name;

    return core(area?.children, addressCode, level + 1);
  };

  return core(OriginAreaData, addressCode, 0);
};

export const getFullNameByAddressCode = (addressCode, split = '') => {
  const core = (areaList, addressCode, parentName, level) => {
    const parentCode = addressCode?.slice(0, 2 * (level + 1)).padEnd(6, '0');
    const area = _.find(areaList, ({ code }) => code === parentCode);

    if (!area) return parentName;

    const fullName =
      parentName === '' ? area.name : `${parentName}${split}${area.name}`;
    if (parentCode === addressCode) return fullName;

    return core(area?.children, addressCode, fullName, level + 1);
  };

  return core(OriginAreaData, addressCode, '', 0);
};
