import { Component } from 'react';

export default class ModalContainer extends Component {
  state = {
    visible: false,
    loading: false,
  };

  show = () => {
    this.setState({ visible: true });
  };

  hide = () => {
    this.setState({ visible: false });
  };

  setLoading = (loading = true) => {
    this.setState({ loading });
  };

  render() {
    const { visible, loading } = this.state;
    const { children } = this.props;

    return children({
      visible,
      loading,
      show: this.show,
      hide: this.hide,
      setLoading: this.setLoading,
    });
  }
}
