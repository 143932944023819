import {
  optionToRequestInstanceMiddleware,
  checkResponseStatusMiddleware,
} from './middlewares';

/**
 * 组合中间件并按顺序执行
 * 参考 koa-compose
 *
 * @param {Array<Function>} middlewares
 */
const compose = middlewares => {
  if (!Array.isArray(middlewares))
    throw new TypeError('Middleware stack must be an array!');
  for (const fn of middlewares) {
    if (typeof fn !== 'function')
      throw new TypeError('Middleware must be composed of functions!');
  }

  return (context, next) => {
    // let index = -1;

    function dispatch(i, newContext = context) {
      // if (i <= index)
      //   return Promise.reject(new Error('next() called multiple times'));

      // index = i;

      let fn = middlewares[i];

      if (i === middlewares.length) fn = next;

      if (!fn) return Promise.resolve();

      try {
        return Promise.resolve(
          fn(newContext, (_newContext = newContext) =>
            dispatch(i + 1, _newContext),
          ),
        );
      } catch (err) {
        return Promise.reject(err);
      }
    }

    return dispatch(0);
  };
};

const createNetworkLayer = (middlewares = []) => {
  const flows = compose([
    optionToRequestInstanceMiddleware,
    ...middlewares,
    checkResponseStatusMiddleware,
  ]);
  return req => flows(req, fetch);
};

export default createNetworkLayer;
