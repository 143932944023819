import { PureComponent } from 'react';
import { DatePicker } from 'antd';

let warning = false;
if (!warning && process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line no-console
  console.warn(
    '该组件实现方式是一种 trick 行为，建议不再使用该组件，日期时间的处理建议在最终提交的地方进行处理',
  );
  warning = true;
}

export default class MyDatePicker extends PureComponent {
  static propTypes = {
    ...DatePicker.propTypes,
  };

  handleDatePickerChange = _moment => {
    const { onChange, showTime } = this.props;
    onChange &&
      onChange(!!showTime ? _moment : _moment && _moment.startOf('day'));
  };
  render() {
    return (
      <DatePicker {...this.props} onChange={this.handleDatePickerChange} />
    );
  }
}
