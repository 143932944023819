import { lazy } from 'react';
import { CalculatorOutlined } from '@ant-design/icons';

import Section from '../../components/Section';
import { authorizedRouteWrapper } from '../../auth/authorization';

export default {
  path: 'cs-plutus',
  name: '结算',
  icon: <CalculatorOutlined />,
  component: Section,
  resource_id: '/cs-plutus',
  leftNav: true,
  routes: [
    {
      path: 'account',
      name: '账户',
      resource_id: '/cs-plutus/account',
      leftNav: true,
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "CapacitySupplierIndividualAccountingPage" */ '../../views/CapacitySupplierIndividualAccountingPage'
            ),
        ),
      ),
    },
    {
      path: 'payout-applications',
      name: '提现申请',
      resource_id: '/cs-plutus/payout-applications',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "PayoutApplicationListForCSPage" */ '../../views/PayoutApplicationListForCSPage'
            ),
        ),
      ),
      leftNav: true,
      exact: true,
    },
    {
      path: 'driver-payoff-batch',
      name: '司机批量转账',
      resource_id: '/cs-plutus/driver-payoff-batch',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "DriverPayoffBatchListPage" */ '../../views/DriverPayoffBatchListPage'
            ),
        ),
      ),
      leftNav: true,
      exact: true,
    },
    {
      path: 'driver-payoff-batch/:id',
      resource_id: '/cs-plutus/driver-payoff-batch/:id',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "DriverPayoffBatchDetailsPage" */ '../../views/DriverPayoffBatchDetailsPage'
            ),
        ),
      ),
      exact: true,
    },
  ],
};
