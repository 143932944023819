import NumberFormatter from '../NumberFormatter';

import styles from './TotalCount.module.less';

const TotalCount = ({ title, value, suffix, ...otherProps }) => {
  return (
    <div {...otherProps} styleName="total-count">
      <span styleName="total-count-title">{title || '总数'}：</span>
      <NumberFormatter
        className={styles['total-count-value']}
        value={value}
        suffix={suffix}
      />
    </div>
  );
};

export default TotalCount;
