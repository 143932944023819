import { memo, useMemo } from 'react';
import { createPaginationContainer, graphql } from 'react-relay';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import _ from 'lodash';

import createRelayRoot from '../../utils/createRelayRoot';
import { paymentMethod } from '../../common/PaymentMethod';
import { decodeGraphQLGlobalID } from '../../utils/graphQLGlobalID';
import { withInfiniteConnection } from '../../components/InfiniteLoadMore';

import { useLocationQ } from './util';
import SmartTaxiOrdersTable from './SmartTaxiOrdersTable/SmartTaxiOrdersTable';
import { statusNames } from './SmartTaxiOrdersTable/TaxiStatus';

const HISTORY_STATE_KEY = 'SmartTaxiOrdersTable';

const PAYMENT_FILTER_OPTIONS = _.toPairs(paymentMethod).map(item => {
  const [key, val] = item;
  return {
    text: val,
    value: key,
  };
});

const query = graphql`
  query TaxiOrderListQuery($count: Int!, $cursor: String, $q: Json) {
    data: relay {
      ...TaxiOrderList_data
    }
  }
`;

function exportDataHandler(data) {
  const HEADERS = [
    'ID',
    '状态',
    '乘客手机号',
    '司机手机号',
    '司机姓名',
    '车牌号',
    '行程费',
    '小费',
    '其他费用',
    '省份',
    '城市',
    '区／县',
    '支付方式',
    '时间',
    '评分',
  ];

  const CSVDATA = [HEADERS];
  return CSVDATA.concat(
    _.map(data, node => {
      let id = decodeGraphQLGlobalID(node.id);
      let status = statusNames.find(item => item.value === node.status).text;
      let createdAt = moment
        .utc(node.createdAt)
        .locale('zh-CN')
        .local()
        .format('l HH:mm');

      return [
        id,
        status,
        node?.passenger?.phoneNumber || '',
        node?.driver?.phoneNumber || '',
        node?.driver?.taxiDriverDetail?.realName || '',
        node?.vehicle?.plateNumber || '',
        node?.taxiFee || 0,
        node?.tip || 0,
        node?.otherFee || 0,
        node?.zone?.province || '',
        node?.zone?.city || '',
        node?.zone?.district || '',
        paymentRender(node.payments) || '',
        createdAt,
        node?.rating || '',
      ];
    }),
  );
}

const paymentRender = (value, record, index) => {
  if (value?.length) {
    const lastPayment = value.find(item => item.state === 'completed');
    const lastPaymentMethod = lastPayment?.paymentMethod;
    return PAYMENT_FILTER_OPTIONS.find(item => item.value === lastPaymentMethod)
      ?.text;
  }
};

const AllTaxiOrdersTable = props => {
  const { data, loading, loadingMore, isExportAuth, ...tableProps } = props;
  const entities = (data?.entities?.edges ?? []).map(edge => edge.node);
  const totalCount = data?.entities?.totalCount;

  return (
    <SmartTaxiOrdersTable
      {...tableProps}
      loading={loading}
      entities={entities}
      totalCount={totalCount}
      primaryActions={
        isExportAuth && (
          <Button
            icon={<DownloadOutlined />}
            disabled={(loading || loadingMore) && entities}
          >
            <CSVLink
              data={exportDataHandler(entities)}
              filename={`TaxiOrders_${moment().format('YYYY/MM/DD')}.csv`}
              style={{ color: 'inherit', marginLeft: 8 }}
            >
              导出
            </CSVLink>
          </Button>
        )
      }
    />
  );
};

const TaxiOrderList = createPaginationContainer(
  withInfiniteConnection(AllTaxiOrdersTable),
  {
    data: graphql`
      fragment TaxiOrderList_data on Relay {
        entities: taxis(first: $count, after: $cursor, q: $q)
          @connection(key: "TaxiOrderList_entities") {
          edges {
            node {
              id
              passenger {
                id
                phoneNumber: phone_number
              }
              driver {
                id
                phoneNumber: phone_number
                taxiDriverDetail: taxi_driver_detail {
                  realName: real_name
                }
              }
              vehicle {
                plateNumber: plate_number
              }
              status
              otherFee: other_fee
              taxiFee: taxi_fee
              tip
              rating
              createdAt: created_at
              payments {
                paymentMethod: payment_method
              }
              zone {
                id
                province
                city
                district
              }
              ...TaxiOrderTable_data
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
          totalCount
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps(props) {
      return props.data?.entities;
    },
    getFragmentVariables(prevVars, totalCount) {
      return {
        ...prevVars,
        count: totalCount,
      };
    },
    getVariables(props, { count, cursor }, fragmentVariables) {
      const { variables = {} } = props;
      return {
        ...fragmentVariables,
        count,
        ...variables,
        cursor,
      };
    },
    query,
  },
);

const TaxiOrderListRoot = createRelayRoot({
  defaultVariables: {
    count: 25,
  },
  query,
})(TaxiOrderList);

export default memo(function ({
  isExportAuth,
  historyStateKey: propHistoryStateKey,
  q: propsQ,
  variables: propsVar,
  ...otherProps
}) {
  const historyStateKey = propHistoryStateKey || HISTORY_STATE_KEY;

  const qStr = JSON.stringify(_.merge(useLocationQ(historyStateKey), propsQ));
  const variables = useMemo(
    () => _.merge({ q: qStr }, propsVar),
    [propsVar, qStr],
  );

  return (
    <TaxiOrderListRoot
      isExportAuth={isExportAuth || historyStateKey}
      variables={variables}
      {...otherProps}
    />
  );
});
