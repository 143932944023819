export const TaxiCompanyRoleList = [
  {
    value: 'taxi_company_owner',
    label: '出租车公司管理人员',
    description: '出租车公司的管理人员，包含部分修改权限',
    order: 100,
  },
  {
    value: 'taxi_company_staff',
    label: '出租车公司客服人员',
    description: '出租车公司的客服人员，只有部分查看权限',
    order: 101,
  },
];

// 公司内部角色
export const innerRoleList = [
  {
    value: 'operator',
    label: '运营人员',
    description: '一般运营人员，只有部分查看权限，无商城管理权限',
    order: 12,
  },
  {
    value: 'admin',
    label: '超级管理员',
    description: '顺道出行管理后台的系统超级管理员',
    order: 1,
  },
  {
    value: 'employee',
    label: '内部员工',
    description: '公司内部员工',
    order: 99,
  },
  {
    value: 'accountant',
    label: '财务',
    description: '财务人员',
    order: 10,
  },
  {
    value: 'accountant_intern',
    label: '实习财务',
    description: '实习财务人员，只包含财务人员的查看权限',
    order: 11,
  },
  {
    value: 'store_operator',
    label: '商城管理员',
    description: '商城的运营人员，只有商城的管理权限',
    order: 20,
  },
  {
    value: 'senior_operator',
    label: '高级运营',
    description: '拥有更高权限的运营人员，无商城管理权限',
    order: 10,
  },
  {
    value: 'sub_admin',
    label: '普通系统管理员',
    description: '拥有大多数权限，但不可操作普通管理员和超级管理帐号',
    order: 2,
  },
  {
    value: 'driver_censor',
    label: '司机审核员',
    description: '该角色只包含查看和审核司机提交权限',
    order: 13,
  },
  {
    value: 'recharge_commissioner',
    label: '充值专员',
    description: '负责充值的人员，只包含充值中心内权限',
    order: 13,
  },
  {
    value: 'customer_service_staff',
    label: '客服人员',
    description: '客服人员',
    order: 98,
  },
];

export const commonRoleList = [
  {
    value: 'passenger',
    label: '乘客',
    description: '乘客',
    order: Number.MAX_SAFE_INTEGER,
  },
  {
    value: 'driver',
    label: '司机',
    description: '司机',
    order: Number.MAX_SAFE_INTEGER,
  },
];

export const OperationCenterRoleList = [
  {
    value: 'operation_center_staff',
    label: '运营中心管理员',
    description: '运营中心管理员',
    order: 101,
  },
  {
    value: 'operation_center_dispatcher',
    label: '运营中心调度员',
    description: '运营中心调度员',
    order: 102,
  },
  {
    value: 'operation_center_supervisor',
    label: '运营中心监管员',
    description: '运营中心监管员',
    order: 103,
  },
];

export const OperationCenterGroupRoleList = [
  {
    value: 'operation_center_group_manager',
    label: '区域管理中心人员',
    description: '区域管理中心人员',
    order: 100,
  },
];

export const CAPACITY_SUPPLIER_ROLE_LIST = [
  {
    value: 'capacity_supplier_manager',
    label: '运力提供商管理人员',
    description: '运力提供商管理人员',
    order: 100,
  },
  {
    value: 'capacity_supplier_staff',
    label: '运力提供商员工',
    description: '运力提供商员工',
    order: 101,
  },
];

export const ALL_ROLE_LIST = [
  ...commonRoleList,
  ...innerRoleList,
  ...TaxiCompanyRoleList,
  ...OperationCenterRoleList,
  ...OperationCenterGroupRoleList,
  ...CAPACITY_SUPPLIER_ROLE_LIST,
];

export default ALL_ROLE_LIST;

export function roleKeyMappingRole(rolekey) {
  return ALL_ROLE_LIST.find(item => item.value === rolekey.valueOf()) || null;
}
