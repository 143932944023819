/**
 * Created by qiuyuzhou on 2017/5/13.
 */
import PropTypes from 'prop-types';

import moment from 'moment';

const defaultProps = {
  format: 'l HH:mm',
  inputFormat: null,
  lang: 'zh-CN',
};

const propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  format: PropTypes.string,
  inputFormat: PropTypes.string,
  lang: PropTypes.string,
};

const LocalDateTime = props => {
  const { date, value = date } = props;

  if (!value) return null;

  const { inputFormat, lang, format } = props;
  const v = moment.utc(value, inputFormat).locale(lang).local().format(format);
  return <time>{v}</time>;
};

LocalDateTime.defaultProps = defaultProps;
LocalDateTime.propTypes = propTypes;

export default LocalDateTime;
