import { lazy } from 'react';
import { SettingOutlined } from '@ant-design/icons';

import Section from '../../components/Section';
import { authorizedRouteWrapper } from '../../auth/authorization';

import TaxiCompanyList from '../../views/taxiCompanies/TaxiCompanyList';
import TaxiCompanyDetails from '../../views/taxiCompanies/TaxiCompanyDetails';
import WithdrawalConfigPage from '../../views/WithdrawalConfigPage';

export default {
  path: 'setting',
  name: '设置',
  icon: <SettingOutlined />,
  component: Section,
  resource_id: 'setting',
  leftNav: true,
  routes: [
    {
      path: 'partners',
      name: '合作商管理',
      resource_id: '/setting/partners',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "PartnerListPage" */ '../../views/PartnerListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'partners/:id',
      resource_id: '/setting/partners/:id',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "PartnerDetailsPage" */ '../../views/PartnerDetailsPage'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'partners/:partnerId/rolesMapping/:mappingId',
      resource_id: '/setting/partners/:partnerId/rolesMapping/:mappingId',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "PartnerStaffRoleMappingDetailsPage" */ '../../views/PartnerStaffRoleMappingDetailsPage'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'operation-center-groups',
      name: '区域管理中心管理',
      resource_id: 'setting-operationCenterGroups',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "OperationCenterGroupListPage" */ '../../views/OperationCenterGroupListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'operation-centers',
      name: '线路运营中心管理',
      resource_id: 'setting_operation-centers',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "OperationCenterListPage" */ '../../views/OperationCenterListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'capacity-suppliers',
      name: '运力提供商管理',
      resource_id: '/setting/capacity-supplier',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "CapacitySupplierListPage" */ '../../views/CapacitySupplierListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'taxi_companies',
      name: '出租车公司管理',
      resource_id: 'setting_taxiCompanies',
      component: authorizedRouteWrapper(TaxiCompanyList),
      exact: true,
      leftNav: true,
    },
    {
      path: 'geofences',
      name: '预设地理围栏',
      resource_id: '/setting/fences',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "ExpressGeofenceListPage" */ '../../views/GeoFencesListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'rule',
      name: '区域调度控制',
      resource_id: '/setting/rule',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "ExpressGeofenceListPage" */ '../../views/GeoFenceRuleListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'workflows',
      name: '通用流程',
      resource_id: '/setting/workflows',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "WorkflowListPage" */ '../../views/WorkflowListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'workflows/:id',
      resource_id: '/setting/workflows/:id',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "WorkflowDetailsPage" */ '../../views/WorkflowDetailsPage'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'employees',
      name: '后台帐号',
      resource_id: '/setting/employees',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "AccountListPage" */ '../../views/AccountListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'ratings',
      name: '评价管理',
      resource_id: 'setting_ratings',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "RatingCommentListPage" */ '../../views/RatingCommentListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'cancel-reason',
      name: '取消原因',
      resource_id: '/setting/cancel-reason',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "CancelReasonListPage" */ '../../views/CancelReasonListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'reassign-reason',
      name: '改派原因',
      resource_id: '/setting/reassign-reason',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "ReassignReasonListPage" */ '../../views/ReassignReasonListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'prepayment-conditions',
      name: '预付规则',
      resource_id: '/setting/prepayment-conditions',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "PrepaymentConditionListPage" */ '../../views/PrepaymentConditionListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'withdrawal-config',
      name: '提现配置',
      resource_id: '/setting/withdrawal-config',
      component: authorizedRouteWrapper(WithdrawalConfigPage),
      exact: true,
      leftNav: true,
    },
    {
      path: 'commissions',
      name: '抽成配置',
      resource_id: 'setting_commissions',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "CommissionListPage" */ '../../views/CommissionListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'new-commissions',
      name: '抽成配置（ V2 ）',
      resource_id: '/setting/new-commissions',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "CommissionListPage" */ '../../views/NewCommissionListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'bundle-configurations',
      name: 'bundle配置',
      resource_id: '/setting/bundle-configurations',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "BundleConfigurationListPage" */ '../../views/BundleConfigurationListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'app-setting',
      name: 'APP 配置',
      resource_id: 'setting_app-setting',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "APPSettingPage" */ '../../views/APPSettingPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'admin-setting',
      name: '系统配置',
      resource_id: 'setting_admin-setting',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "AdminSettingPage" */ '../../views/AdminSettingPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'tags',
      name: '标签管理',
      resource_id: '/setting/tags',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "TagListPage" */ '../../views/TagListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'audit_logs',
      name: '系统操作记录',
      resource_id: 'setting_auditLogs',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "AuditLogListPage" */ '../../views/AuditLogListPage'
            ),
        ),
      ),
      leftNav: true,
      exact: true,
    },
    {
      path: 'send-verification-code-histories',
      name: '验证码发送记录',
      resource_id: 'setting_send-verification-code-histories',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "SendVerificationCodeHistoriesListPage" */ '../../views/SendVerificationCodeHistoriesListPage'
            ),
        ),
      ),
      leftNav: true,
      exact: true,
    },
    {
      path: 'phone-recording',
      name: '虚拟号通话记录',
      resource_id: '/setting/phone-recording',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "PhoneRecordListPage" */ '../../views/PhoneRecordListPage'
            ),
        ),
      ),
      leftNav: true,
      exact: true,
    },
    {
      path: 'taxi_companies/:id',
      resource_id: 'setting_taxiCompanies_view_details',
      component: authorizedRouteWrapper(TaxiCompanyDetails),
      exact: true,
    },
    {
      path: 'capacity-suppliers/:id',
      resource_id: '/setting/capacity-suppliers/:id',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "CapacitySupplierDetailsPage" */ '../../views/CapacitySupplierDetailsPage'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'assetCS/:id',
      resource_id: '/setting/capacity-suppliers/:id', // 重定向至此 path
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "CapacitySupplierDetailsPage" */ '../../views/AssetCSDetailsPage'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'capacity-suppliers/:csId/migrate-capacity',
      resource_id: '/setting/capacity-suppliers/:csId/migrate-capacity',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "MigrateCapacityBetweenCSPage" */ '../../views/MigrateCapacityBetweenCSPage'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'capacity-suppliers/:capacitySupplierId/driver-groups/:id',
      resource_id:
        '/setting/capacity-suppliers/:capacitySupplierId/driver-groups/:id',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "CSDriverGroupDetailsPage" */ '../../views/CSDriverGroupDetailsPage'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'capacity-suppliers/:capacitySupplierId/intercity-ride-trend',
      resource_id:
        '/setting/capacity-suppliers/:capacitySupplierId/intercity-ride-trend',
      breadcrumbName: () => '城际订单趋势',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "CSIntercityRideTrendPage" */ '../../views/CSIntercityRideTrendPage'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'operation-centers/:operationCenterId/intercity-ride-trend',
      resource_id: 'setting_operation-centers_view_intercity-ride-trend',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "OCIntercityRideTrendPage" */ '../../views/OCIntercityRideTrendPage'
            ),
        ),
      ),
      breadcrumbName: () => '城际订单趋势',
      exact: true,
    },
    {
      path: 'operation-centers/:id',
      resource_id: 'setting_operation-centers_view_details',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "OperationCenterDetailsPage" */ '../../views/OperationCenterDetailsPage'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'operation-center-groups/:id',
      resource_id: '/setting/operationCenterGroups-view-details/:id',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "OperationCenterGroupDetail" */ '../../views/OperationCenterGroupDetailsPage'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'prepayment-conditions/:id',
      resource_id: '/setting/prepayment-conditions/:id',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "PrepaymentConditionDetailsPage" */ '../../views/PrepaymentConditionDetailsPage'
            ),
        ),
      ),
      exact: true,
    },
  ],
};
