import PropTypes from 'prop-types';
import { Form, Input } from 'antd';

import PhoneNumberInput from '../../components/PhoneNumberInput';
import MyDatePicker, { QuickRanges } from '../../components/MyDatePicker';
import { SmartTableSearchForm } from '../../components/SmartTable2';

const { MyRangePicker } = MyDatePicker;

const TaxiDriverSearchForm = props => {
  return (
    <SmartTableSearchForm collapsesize={6} {...props}>
      <Form.Item label="ID" name="id">
        <Input />
      </Form.Item>
      <Form.Item label="手机号" name="phone_number">
        <PhoneNumberInput />
      </Form.Item>
      <Form.Item label="姓名" name="taxi_driver_detailReal_name">
        <Input />
      </Form.Item>
      <Form.Item label="出租车公司" name="companyName">
        <Input />
      </Form.Item>
      <Form.Item label="创建时间" name="created_at">
        <MyRangePicker
          showTime={true}
          style={{ width: '100%' }}
          format="l HH:mm:ss"
          ranges={QuickRanges}
        />
      </Form.Item>
      <Form.Item label="最近登录时间" name="current_sign_in_at">
        <MyRangePicker
          showTime={true}
          style={{ width: '100%' }}
          format="l HH:mm:ss"
          ranges={QuickRanges}
        />
      </Form.Item>
      <Form.Item label="备注" name="taxi_driver_detailComment">
        <Input />
      </Form.Item>
    </SmartTableSearchForm>
  );
};

TaxiDriverSearchForm.propTypes = {
  onFinish: PropTypes.func,
};

export default TaxiDriverSearchForm;
