import { Children, cloneElement } from 'react';
import classNames from 'classnames';
import { Row } from 'antd';
import styles from './index.module.less';

export default ({
  className,
  title,
  col = 3,
  layout = 'horizontal',
  gutter = 32,
  children,
  size = 'large',
  ...restProps
}) => {
  const clsString = classNames(
    styles['description-list'],
    styles[layout],
    className,
    {
      [styles.small]: size === 'small',
      [styles.large]: size === 'large',
    },
  );
  const column = col > 4 ? 4 : col;
  return (
    <div className={clsString} {...restProps}>
      {title ? <div className={styles.title}>{title}</div> : null}
      <Row gutter={gutter} type="flex">
        {Children.map(
          children,
          child => child && cloneElement(child, { column }),
        )}
      </Row>
    </div>
  );
};
