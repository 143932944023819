import _ from 'lodash';
import moment from 'moment';
// import moment from 'moment-relativism';

// const ranges = [
//   { label: '今天', from: 'now/d', to: 'now/d' },
//   { label: '昨天', from: 'now-1d/d', to: 'now-1d/d' },
//   { label: '前天', from: 'now-2d/d', to: 'now-2d/d' },
//   { label: '本周', from: 'now/w', to: 'now/w' },
//   { label: '上周', from: 'now-1w/w', to: 'now-1w/w' },
//   { label: '本月', from: 'now/M', to: 'now/M' },
//   { label: '上个月', from: 'now-1M/M', to: 'now-1M/M' },
//   { label: '最后 2 天', from: 'now-2d', to: 'now' },
//   { label: '最后 7 天', from: 'now-7d', to: 'now' },
//   { label: '最后 30 天', from: 'now-30d', to: 'now' },
// ];
//
// const QuickRangesList = _.fromPairs(
//   ranges.map(range => [
//     range.label,
//     () => [
//       moment.relativism(range.from.replace('/', '|')),
//       moment.relativism(range.to),
//     ],
//   ])
// );

const QuickRangesList = [
  {
    label: '今天',
    value: () => {
      const today = moment().startOf('day');
      const tomorrow = moment(today).add(1, 'day');
      return [today, tomorrow];
    },
  },
  {
    label: '昨天',
    value: () => {
      const today = moment().startOf('day');
      const yesterday = moment(today).subtract(1, 'day');
      return [yesterday, today];
    },
  },
  {
    label: '前天',
    value: () => {
      const yesterday = moment().subtract(1, 'day').startOf('day');
      const theDayBeforeYesterDay = moment(yesterday).subtract(1, 'day');
      return [theDayBeforeYesterDay, yesterday];
    },
  },
  {
    label: '本周',
    value: () => {
      const thisWeekStart = moment().startOf('week');
      const nextWeekStart = moment(thisWeekStart).add(1, 'week');
      return [thisWeekStart, nextWeekStart];
    },
  },
  {
    label: '上周',
    value: () => {
      const thisWeekStart = moment().startOf('week');
      const lastWeekStart = moment(thisWeekStart).subtract(1, 'week');
      return [lastWeekStart, thisWeekStart];
    },
  },
  {
    label: '本月',
    value: () => {
      const thisMonth = moment().startOf('month');
      const nextMonth = moment(thisMonth).add(1, 'month');
      return [thisMonth, nextMonth];
    },
  },
  {
    label: '上个月',
    value: () => {
      const thisMonth = moment().startOf('month');
      const lastMonth = moment(thisMonth).subtract(1, 'month');
      return [lastMonth, thisMonth];
    },
  },
  /**
   * 最后 N (天/S/H)，在大多数系统中为，当前时间点往前推移，
   * 不清楚为什么以下快捷时间未遵循该设计
   */
  ...[2, 7, 30].map(day => ({
    label: `最后 ${day} 天`,
    value: () => {
      const tomorrow = moment().add(1, 'day').startOf('day');
      return [moment(tomorrow).subtract(day, 'day'), tomorrow];
    },
  })),
];

export default _.fromPairs(
  _.map(QuickRangesList, ({ label, value }) => [label, value]),
);
