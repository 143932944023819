/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type WithdrawalConfigPage_data$ref = any;
export type WithdrawalConfigPageQueryVariables = {||};
export type WithdrawalConfigPageQueryResponse = {|
  +data: {|
    +config: ?{|
      +$fragmentRefs: WithdrawalConfigPage_data$ref
    |}
  |}
|};
export type WithdrawalConfigPageQuery = {|
  variables: WithdrawalConfigPageQueryVariables,
  response: WithdrawalConfigPageQueryResponse,
|};
*/


/*
query WithdrawalConfigPageQuery {
  data: relay {
    config: global_config {
      ...WithdrawalConfigPage_data
      id
    }
  }
}

fragment WithdrawalConfigPage_data on GlobalConfig {
  id
  wechat_payout
  auto_process_wechat_payout
  wechat_title
  wechat_minimum_payout_amount
  wechat_maximum_payout_amount
  alipay_payout
  auto_process_alipay_payout
  alipay_title
  alipay_minimum_payout_amount
  alipay_maximum_payout_amount
  bank_payout
  auto_process_bank_payout
  bank_title
  bank_minimum_payout_amount
  bank_maximum_payout_amount
  reward_wechat_minimum_payout_amount
  reward_wechat_maximum_payout_amount
}
*/

const node/*: ConcreteRequest*/ = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "WithdrawalConfigPageQuery",
    "selections": [
      {
        "alias": "data",
        "args": null,
        "concreteType": "Relay",
        "kind": "LinkedField",
        "name": "relay",
        "plural": false,
        "selections": [
          {
            "alias": "config",
            "args": null,
            "concreteType": "GlobalConfig",
            "kind": "LinkedField",
            "name": "global_config",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "WithdrawalConfigPage_data"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "WithdrawalConfigPageQuery",
    "selections": [
      {
        "alias": "data",
        "args": null,
        "concreteType": "Relay",
        "kind": "LinkedField",
        "name": "relay",
        "plural": false,
        "selections": [
          {
            "alias": "config",
            "args": null,
            "concreteType": "GlobalConfig",
            "kind": "LinkedField",
            "name": "global_config",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "wechat_payout",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "auto_process_wechat_payout",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "wechat_title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "wechat_minimum_payout_amount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "wechat_maximum_payout_amount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "alipay_payout",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "auto_process_alipay_payout",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "alipay_title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "alipay_minimum_payout_amount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "alipay_maximum_payout_amount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bank_payout",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "auto_process_bank_payout",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bank_title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bank_minimum_payout_amount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bank_maximum_payout_amount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "reward_wechat_minimum_payout_amount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "reward_wechat_maximum_payout_amount",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f26a73286d27f4068675ad390d152859",
    "id": null,
    "metadata": {},
    "name": "WithdrawalConfigPageQuery",
    "operationKind": "query",
    "text": "query WithdrawalConfigPageQuery {\n  data: relay {\n    config: global_config {\n      ...WithdrawalConfigPage_data\n      id\n    }\n  }\n}\n\nfragment WithdrawalConfigPage_data on GlobalConfig {\n  id\n  wechat_payout\n  auto_process_wechat_payout\n  wechat_title\n  wechat_minimum_payout_amount\n  wechat_maximum_payout_amount\n  alipay_payout\n  auto_process_alipay_payout\n  alipay_title\n  alipay_minimum_payout_amount\n  alipay_maximum_payout_amount\n  bank_payout\n  auto_process_bank_payout\n  bank_title\n  bank_minimum_payout_amount\n  bank_maximum_payout_amount\n  reward_wechat_minimum_payout_amount\n  reward_wechat_maximum_payout_amount\n}\n"
  }
};
// prettier-ignore
(node/*: any*/).hash = '1b1766d37d52b21de1092de3683b776b';

module.exports = node;
