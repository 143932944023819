import { Radio, Form } from 'antd';

import { TaxiCompanyRoleList } from '../../common/roles';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const ChgStaffRoleForm = props => {
  const { initialValues } = props;

  const initialValueRole = initialValues?.roles?.filter(
    item => item.role === 'taxi_company_owner',
  ).length
    ? 'taxi_company_owner'
    : 'taxi_company_staff';

  return (
    <Form {...formItemLayout} {...props} initialValues={initialValueRole}>
      <Form.Item
        label="角色"
        name="role"
        rules={[{ required: true, message: '角色不能为空' }]}
      >
        <RadioGroup>
          {TaxiCompanyRoleList.map(item => (
            <RadioButton key={item.value} value={item.value}>
              {item.label}
            </RadioButton>
          ))}
        </RadioGroup>
      </Form.Item>
    </Form>
  );
};

export default ChgStaffRoleForm;
