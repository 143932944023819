/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type InputSearchTaxiVehicle_data$ref = any;
export type InputSearchTaxiVehicleQueryVariables = {|
  q?: ?any
|};
export type InputSearchTaxiVehicleQueryResponse = {|
  +data: {|
    +$fragmentRefs: InputSearchTaxiVehicle_data$ref
  |}
|};
export type InputSearchTaxiVehicleQuery = {|
  variables: InputSearchTaxiVehicleQueryVariables,
  response: InputSearchTaxiVehicleQueryResponse,
|};
*/


/*
query InputSearchTaxiVehicleQuery(
  $q: Json
) {
  data: relay {
    ...InputSearchTaxiVehicle_data
  }
}

fragment InputSearchTaxiVehicle_data on Relay {
  taxi_vehicles(first: 10, q: $q) {
    edges {
      node {
        id
        plate_number
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "q"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  },
  {
    "kind": "Variable",
    "name": "q",
    "variableName": "q"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InputSearchTaxiVehicleQuery",
    "selections": [
      {
        "alias": "data",
        "args": null,
        "concreteType": "Relay",
        "kind": "LinkedField",
        "name": "relay",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "InputSearchTaxiVehicle_data"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InputSearchTaxiVehicleQuery",
    "selections": [
      {
        "alias": "data",
        "args": null,
        "concreteType": "Relay",
        "kind": "LinkedField",
        "name": "relay",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "TaxiVehicleDetailConnection",
            "kind": "LinkedField",
            "name": "taxi_vehicles",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TaxiVehicleDetailEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TaxiVehicleDetail",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "plate_number",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v1/*: any*/),
            "filters": [
              "q"
            ],
            "handle": "connection",
            "key": "InputSearchTaxiVehicle_taxi_vehicles",
            "kind": "LinkedHandle",
            "name": "taxi_vehicles"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ce44571086e76d3c823d90735e6bfa9d",
    "id": null,
    "metadata": {},
    "name": "InputSearchTaxiVehicleQuery",
    "operationKind": "query",
    "text": "query InputSearchTaxiVehicleQuery(\n  $q: Json\n) {\n  data: relay {\n    ...InputSearchTaxiVehicle_data\n  }\n}\n\nfragment InputSearchTaxiVehicle_data on Relay {\n  taxi_vehicles(first: 10, q: $q) {\n    edges {\n      node {\n        id\n        plate_number\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '704274a957cc379cf6e2d6d4efc8d3c7';

module.exports = node;
