import { lazy } from 'react';
import { InsuranceOutlined } from '@ant-design/icons';

import Section from '../../components/Section';
import { authorizedRouteWrapper } from '../../auth/authorization';

export default {
  path: 'insurance',
  component: Section,
  name: '保险',
  icon: <InsuranceOutlined />,
  resource_id: '/insurance',
  leftNav: true,
  routes: [
    {
      path: 'orderinsur-order',
      name: '订单险采购记录',
      resource_id: '/insurance/orderinsur-order',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "OrderInsuranceListPage" */ '../../views/OrderInsuranceListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'orderinsur-payments',
      name: '订单险售出记录',
      resource_id: '/insurance/orderinsur-payment',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "OrderInsurancePaymentListPage" */ '../../views/OrderInsurancePaymentListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
  ],
};
