import { Divider } from 'antd';
import propTypes from 'prop-types';

export function insertDivider(arr = [], type = 'vertical') {
  const newArr = [];
  const len = arr.length;

  arr.forEach((item, index) => {
    newArr.push(item);
    if (index !== len - 1) {
      newArr.push(<Divider key={`divider-${index}`} type={type} />);
    }
  });

  return newArr;
}

insertDivider.propTypes = {
  type: propTypes.oneOf(['vertical', 'horizontal']),
};
