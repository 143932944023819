import _ from 'lodash';

const searchFormVals2Filters = (formVals, getOperation = () => 'eq') => {
  return _.mapValues(formVals, (value, field) => ({
    // the value change from `undefined` to `null` to override the previous value.
    // and it will be used for the `fields` property of antd v4 Form component.
    value: value ?? null,
    _op: getOperation(field),
  }));
};

export default searchFormVals2Filters;
