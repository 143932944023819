/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type TaxiCompanyDetails_company$ref: FragmentReference;
declare export opaque type TaxiCompanyDetails_company$fragmentType: TaxiCompanyDetails_company$ref;
export type TaxiCompanyDetails_company = {|
  +id: string,
  +name: ?string,
  +city: ?string,
  +province: ?string,
  +created_at: ?any,
  +phone_number: ?string,
  +$refType: TaxiCompanyDetails_company$ref,
|};
export type TaxiCompanyDetails_company$data = TaxiCompanyDetails_company;
export type TaxiCompanyDetails_company$key = {
  +$data?: TaxiCompanyDetails_company$data,
  +$fragmentRefs: TaxiCompanyDetails_company$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaxiCompanyDetails_company",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "city",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "province",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created_at",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phone_number",
      "storageKey": null
    }
  ],
  "type": "Company",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'bab7f0db62c5218e21b7ebca26293063';

module.exports = node;
