import { PureComponent } from 'react';
import { Provider, connect } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { RelayEnvironmentProvider } from 'react-relay/hooks';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import { renderRoutes } from 'react-router-config';
import { Redirect, Switch } from 'react-router-dom';
import {
  ReactKeycloakProvider as KeycloakProvider,
  useKeycloak,
} from '@react-keycloak/web';
// import { withProfiler } from '@sentry/react';
import _ from 'lodash';

import { fetchViewer, fetchViewerFulfilled } from './redux/actions';
import {
  getCurClientRolesFromKeycloak,
  isNotLoggedIn,
} from './utils/keycloakUtils';
import history from './history';
import { store } from './reduxStore';
import { viewerSelector } from './redux/selectors';
import keycloak from './configureKeycloak';
import { environment as relayEnv } from './configureRelay';
import { MAIN_CONSOLE_ROUTES } from './common/router';
// import { transformRoutes } from './common/router/utils';
import LogoLoading from './components/LogoLoading';
import { withErrorBoundary } from './components/ErrorBoundary';

let RenderRoutes = ({ viewer }) => {
  const { keycloak } = useKeycloak();
  if (isNotLoggedIn(keycloak) || !viewer) {
    return null;
  }
  const roles = getCurClientRolesFromKeycloak(keycloak);

  const isOperationCenterStaffRole = !_.isEmpty(
    _.intersection(roles, [
      'operation_center_staff',
      'operation_center_dispatcher',
    ]),
  );

  return (
    <>
      <Switch>
        {viewer?.operationCenter && isOperationCenterStaffRole && (
          <Redirect
            from="/home"
            to="/statistics/route-order-overview"
            extra={true}
          />
        )}
        {viewer?.capacitySupplier && (
          <Redirect
            from="/home"
            to="/statistics/capacity-supplier"
            extra={true}
          />
        )}
      </Switch>
      {renderRoutes(MAIN_CONSOLE_ROUTES)}
    </>
  );
};
// TODO: upgrade redux to v7.1 and replace it by useSelector
RenderRoutes = connect(state => ({ viewer: viewerSelector(state) }))(
  RenderRoutes,
);
class App extends PureComponent {
  __KEYCLOAK__ = keycloak;

  onKeycloakEvent = (event, error) => {
    switch (event) {
      case 'onReady':
        if (isNotLoggedIn(this.__KEYCLOAK__)) {
          this.__KEYCLOAK__.login(); // 跳转到登录页
        }
        break;
      case 'onAuthSuccess':
        // permission 无效临时解决方案 start
        store.dispatch(
          fetchViewerFulfilled({
            roles: getCurClientRolesFromKeycloak(this.__KEYCLOAK__),
          }),
        );
        // permission 无效临时解决方案 end
        store.dispatch(fetchViewer());
        break;
      case 'onAuthLogout':
      case 'onAuthError':
      case 'onAuthRefreshError':
        this.__KEYCLOAK__.login(); // 跳转到登录页
        break;
      default:
        break;
    }
  };

  render() {
    return (
      <KeycloakProvider
        authClient={this.__KEYCLOAK__}
        onEvent={this.onKeycloakEvent}
        isLoadingCheck={isNotLoggedIn}
        LoadingComponent={
          <div
            style={{
              width: '100vw',
              height: '90vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <LogoLoading />
          </div>
        }
      >
        <RelayEnvironmentProvider environment={relayEnv}>
          <Provider store={store}>
            <ConfigProvider locale={zhCN}>
              <ConnectedRouter history={history}>
                <RenderRoutes />
              </ConnectedRouter>
            </ConfigProvider>
          </Provider>
        </RelayEnvironmentProvider>
      </KeycloakProvider>
    );
  }
}

// export default withErrorBoundary(withProfiler(App));
export default withErrorBoundary(App);
