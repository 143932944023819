import PropTypes from 'prop-types';
import { Form, Input } from 'antd';

import { SmartTableSearchForm } from '../../components/SmartTable2';

const SearchForm = props => {
  return (
    <SmartTableSearchForm collapsesize={6} {...props}>
      <Form.Item label="ID" name="id">
        <Input />
      </Form.Item>
      <Form.Item label="名称" name="name">
        <Input />
      </Form.Item>
      <Form.Item label="城市" name="city">
        <Input />
      </Form.Item>
      <Form.Item label="客服电话" name="phone_number">
        <Input />
      </Form.Item>
    </SmartTableSearchForm>
  );
};
SearchForm.propTypes = {
  onFinish: PropTypes.func,
};

export default SearchForm;
