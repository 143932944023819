import { lazy } from 'react';
import { DashboardOutlined } from '@ant-design/icons';

import Section from '../../components/Section';
import { authorizedRouteWrapper } from '../../auth/authorization';

export default {
  path: 'statistics',
  name: '仪表盘',
  icon: <DashboardOutlined />,
  component: Section,
  resource_id: '/statistics',
  leftNav: true,
  routes: [
    {
      path: 'route-order-overview',
      name: '调度中心',
      resource_id: '/statistics/route-order-overview',
      leftNav: true,
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "RouteOrderOverviewPage" */ '../../views/RouteOrderOverviewPage'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'capacity-supplier',
      name: '运力提供商流水',
      resource_id: '/statistics/capacity-supplier',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "CapacitySupplierInfo" */ '../../views/CapacitySupplierDetailsPage/CapacitySupplierInfoPage'
            ),
        ),
      ),
      leftNav: true,
      exact: true,
    },
    {
      path: 'capacity-supplier/:capacitySupplierId/intercity-ride-trend',
      resource_id:
        '/statistics/capacity-supplier/:capacitySupplierId/intercity-ride-trend',
      breadcrumbName: () => '城际订单趋势',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "CSIntercityRideTrendPage" */ '../../views/CSIntercityRideTrendPage'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'operation-center-individual-account',
      name: '账户',
      resource_id: '/statistics/operation-center-individual-account',
      leftNav: true,
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "OperationCenterIndividualAccountingPage" */ '../../views/OperationCenterIndividualAccountingPage'
            ),
        ),
      ),
      exact: true,
    },
  ],
};
