import { useState } from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';

import createHOC from '../../utils/createHOC';

import LoadMoreCursor from './LoadMoreCursor';

const withInfiniteLoadMore = Comp => {
  const propTypes = {
    hasMore: PropTypes.func.isRequired,
    loadMore: PropTypes.func.isRequired,
    scrollableAncestor: PropTypes.any, // dom
  };

  const defaultProps = {};

  const InfiniteLoadMoreHOC = ({
    hasMore,
    loadMore: propLoadMore,
    scrollableAncestor, // default is window.document
    ...restProps
  }) => {
    const [loadingMore, setLoadingMore] = useState(false);

    return (
      <>
        <Comp {...restProps} loadingMore={loadingMore} />
        {hasMore() && (
          <LoadMoreCursor
            loadMore={propLoadMore}
            scrollableAncestor={scrollableAncestor}
            onLoadingMore={setLoadingMore}
          >
            <div style={{ textAlign: 'center', padding: 12 }}>
              <Spin />
            </div>
          </LoadMoreCursor>
        )}
      </>
    );
  };

  InfiniteLoadMoreHOC.propTypes = propTypes;
  InfiniteLoadMoreHOC.defaultProps = defaultProps;

  return InfiniteLoadMoreHOC;
};

export default createHOC('withInfiniteLoadMore', withInfiniteLoadMore);
