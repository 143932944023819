import { Input, Form } from 'antd';
import moment from 'moment';

import {
  getFormRequiredValidator,
  getPhoneNumberValidator,
  createCustomValidator,
} from '../../../utils/formFieldValidator';
import PhoneNumberInput from '../../../components/PhoneNumberInput';
import { decodeGraphQLGlobalID } from '../../../utils/graphQLGlobalID';

import InputSearchTaxiCompany from '../../../components/InputSearchTaxiCompany';
import InputSearchTaxiVehicle from '../../../components/InputSearchTaxiVehicle';
import MyDatePicker from '../../../components/MyDatePicker';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};
const DatePicker_style = {
  width: '100%',
};

const disableDate = curDate =>
  moment().startOf('day').add(1, 'd').isSameOrBefore(curDate);

const CreateTaxiDriverForm = ({ form }) => {
  const { getFieldValue } = form;

  return (
    <Form {...formItemLayout}>
      <Form.Item
        label="手机号"
        name="phone_number"
        rules={[
          getFormRequiredValidator('请输入手机号'),
          getPhoneNumberValidator(),
        ]}
      >
        <PhoneNumberInput />
      </Form.Item>

      <Form.Item
        label="姓名"
        name="taxi_driver_detailReal_name"
        rules={[getFormRequiredValidator('请输入姓名')]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="出租车公司"
        name="company"
        rules={[
          getFormRequiredValidator('请选择出租车公司'),
          createCustomValidator(value => {
            if (value && value.id) return true;
          }, '请选择出租车公司'),
        ]}
      >
        <InputSearchTaxiCompany />
      </Form.Item>

      {getFieldValue('company') && (
        <Form.Item
          label="出租车"
          name="vehicle"
          rules={[
            getFormRequiredValidator('请选择出租车'),
            createCustomValidator(value => {
              if (value && value.id) return true;
            }, '请选择出租车'),
          ]}
        >
          <InputSearchTaxiVehicle
            q={{
              company_not_null: true,
              company_id_eq: +decodeGraphQLGlobalID(
                getFieldValue('company').id,
              ),
            }}
          />
        </Form.Item>
      )}

      <Form.Item
        label="身份证"
        name="taxi_driver_detailIdentity"
        rules={[getFormRequiredValidator('请输入身份证')]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="驾驶证"
        name="taxi_driver_detailDriver_license"
        rules={[getFormRequiredValidator('请输入驾驶证')]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="首次获取驾照日期"
        name="taxi_driver_detailFirst_acquisition_date"
        rules={[getFormRequiredValidator('请输入首次获取驾照日期', 'object')]}
      >
        <MyDatePicker style={DatePicker_style} disabledDate={disableDate} />
      </Form.Item>

      <Form.Item
        label="从业资格证"
        name="taxi_driver_detailCertificate_number"
        rules={[getFormRequiredValidator('请输入从业资格证')]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="从业资格截止日期"
        name="taxi_driver_detail.certificate_expire_date"
        rules={[getFormRequiredValidator('清输入从业资格截止日期', 'object')]}
      >
        <MyDatePicker style={DatePicker_style} />
      </Form.Item>

      <Form.Item label="上岗证号" name="taxi_driver_detailWork_license">
        <Input />
      </Form.Item>
    </Form>
  );
};

export default CreateTaxiDriverForm;
