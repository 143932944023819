/**
 * Created by qiuyuzhou on 2017/5/7.
 */

import { viewerRolesSelector, viewerSelector } from '../redux/selectors';
import { store } from '../reduxStore';

import isAuthorizedRoute from './isAuthorizedRoute';

import _isAuthorized from './isAuthorized';
export { default as isAuthorizedRoute } from './isAuthorizedRoute';

/**
 * 获取当前登录对象的所有角色
 */
const getViewerRoles = () => viewerRolesSelector(store.getState());

/**
 * 校验是否是 出租车公司
 */
export const isCompany = () => !!viewerSelector(store.getState()).company;

export const authorizedRoutes = (routes, roles) =>
  routes.filter(route => isAuthorizedRoute(route, roles));

export {
  default as withAuthRoute,
  default as authorizedRouteWrapper, // 兼容，后续需要去掉
} from './withAuthRoute';

/**
 * 校验是否有权限
 * @param {String || Array} permission
 * @param {String} mode
 */
export const isAuthorized = (
  permissions,
  roles = getViewerRoles(), // 兼容，后续需要去掉
  mode = 'or',
) => _isAuthorized(permissions, roles, mode);
