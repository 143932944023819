import { lazy } from 'react';
import { UserOutlined } from '@ant-design/icons';

import Section from '../../components/Section';
import { authorizedRouteWrapper } from '../../auth/authorization';

export default {
  path: 'user',
  component: Section,
  name: '用户',
  icon: <UserOutlined />,
  resource_id: 'user',
  leftNav: true,
  routes: [
    {
      path: 'passengers',
      name: '乘客',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "PassengerListPage" */ '../../views/PassengerListPage'
            ),
        ),
      ),
      resource_id: 'user_passengers',
      exact: true,
      leftNav: true,
    },
    // {
    //   path: 'drivers',
    //   name: '司机',
    //   component: authorizedRouteWrapper(
    //     lazy(() =>
    //       import(
    //         /* webpackChunkName: "DriverListPage" */ '../../views/DriverListPage'
    //       ),
    //     ),
    //   ),
    //   resource_id: 'user_drivers',
    //   exact: true,
    //   leftNav: true,
    // },
    {
      path: 'driver-accounts',
      name: '司机帐号列表',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "DriverAccountListPage" */ '../../views/DriverAccountListPage'
            ),
        ),
      ),
      resource_id: '/user/driver-accounts',
      exact: true,
      leftNav: true,
    },
    {
      path: 'passengers/:id',
      resource_id: 'user_passengers_view_details',
      name: '乘客详情',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "PassengerDetailsPage" */ '../../views/PassengerDetailsPage'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'drivers/:id',
      resource_id: '/asset/drivers/:id', // 重定向至该 path
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "DriverDetailsPage" */ '../../views/DriverDetailsPage'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'driver-accounts/:id',
      resource_id: '/user/driver-accounts/:id',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "DriverAccountDetailsPage" */ '../../views/DriverAccountDetailsPage'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'drivers/:driverId/intercity-ride-trend',
      resource_id: 'user_drivers_view_intercity-ride-trend',
      breadcrumbName: () => `城际订单趋势`,
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "DriverIntercityRideTrendPage" */ '../../views/DriverIntercityRideTrendPage'
            ),
        ),
      ),
      exact: true,
    },
  ],
};
