import PropTypes from 'prop-types';

import { paymentMethod } from '../common/PaymentMethod';

export default function PaymentMethod({ method }) {
  const methodType = method && paymentMethod[method];
  return <span>{methodType || method}</span>;
}

PaymentMethod.propTypes = {
  method: PropTypes.string,
};
