/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type WithdrawalConfigPage_data$ref: FragmentReference;
declare export opaque type WithdrawalConfigPage_data$fragmentType: WithdrawalConfigPage_data$ref;
export type WithdrawalConfigPage_data = {|
  +id: string,
  +wechat_payout: ?boolean,
  +auto_process_wechat_payout: ?boolean,
  +wechat_title: ?string,
  +wechat_minimum_payout_amount: ?any,
  +wechat_maximum_payout_amount: ?any,
  +alipay_payout: ?boolean,
  +auto_process_alipay_payout: ?boolean,
  +alipay_title: ?string,
  +alipay_minimum_payout_amount: ?any,
  +alipay_maximum_payout_amount: ?any,
  +bank_payout: ?boolean,
  +auto_process_bank_payout: ?boolean,
  +bank_title: ?string,
  +bank_minimum_payout_amount: ?any,
  +bank_maximum_payout_amount: ?any,
  +reward_wechat_minimum_payout_amount: ?any,
  +reward_wechat_maximum_payout_amount: ?any,
  +$refType: WithdrawalConfigPage_data$ref,
|};
export type WithdrawalConfigPage_data$data = WithdrawalConfigPage_data;
export type WithdrawalConfigPage_data$key = {
  +$data?: WithdrawalConfigPage_data$data,
  +$fragmentRefs: WithdrawalConfigPage_data$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WithdrawalConfigPage_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wechat_payout",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "auto_process_wechat_payout",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wechat_title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wechat_minimum_payout_amount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wechat_maximum_payout_amount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "alipay_payout",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "auto_process_alipay_payout",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "alipay_title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "alipay_minimum_payout_amount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "alipay_maximum_payout_amount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bank_payout",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "auto_process_bank_payout",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bank_title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bank_minimum_payout_amount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bank_maximum_payout_amount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reward_wechat_minimum_payout_amount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reward_wechat_maximum_payout_amount",
      "storageKey": null
    }
  ],
  "type": "GlobalConfig",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '76d7baec1858f24d831b80376665adea';

module.exports = node;
