import { lazy } from 'react';
import { CalculatorOutlined } from '@ant-design/icons';

import Section from '../../components/Section';
import { authorizedRouteWrapper } from '../../auth/authorization';

export default {
  path: 'plutus',
  name: '清结算',
  icon: <CalculatorOutlined />,
  component: Section,
  resource_id: 'plutus',
  leftNav: true,
  routes: [
    // 账户相关 Start
    {
      path: 'overview',
      name: '账户总览',
      resource_id: 'plutus_overview',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "AccountingOverviews" */ '../../views/accountingOverviews'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'ledgers',
      name: '静态账户',
      resource_id: 'plutus_ledgers',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "LedgerList" */ '../../views/ledgers/LedgerList'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'platform_revenue_commission',
      name: '平台抽成账户',
      resource_id: 'plutus_platformRevenueCommission',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "AccountingPlatformCommissionDescendantsList" */ '../../views/accountingPlatformCommissionDescendants/AccountingPlatformCommissionDescendantsList'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'partners',
      name: '合作商账户',
      resource_id: '/plutus/partners',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "AccountingPartnerListPage" */ '../../views/AccountingPartnerListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'operation-center-accounts',
      name: '线路运营中心账户',
      resource_id: '/plutus/operation-center-accounts',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "AccountingOperationCenterListPage" */ '../../views/AccountingOperationCenterListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'capacity-supplier-accounts',
      name: '运力提供商账户',
      resource_id: '/plutus/capacity-supplier-accounts',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "AccountingCapacitySupplierListPage" */ '../../views/AccountingCapacitySupplierListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'company_accounts',
      name: '出租车公司账户',
      resource_id: 'plutus_CompanyAccounts',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "AccountingCompanyLedgerList" */ '../../views/accountingCompanyLedger/AccountingCompanyLedgerList'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'driver-accounts',
      name: '司机账户',
      resource_id: 'plutus_drivrAccounts',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "AccountingDriverListPage" */ '../../views/AccountingDriverListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    // 账户相关 End
    // 申请、审批 Start
    {
      path: 'partner-invoices',
      name: '合作商发票审核',
      resource_id: '/plutus/partner-invoices',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "PartnerInvoiceVerificationListPage" */ '../../views/PartnerInvoiceVerificationListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'partner-withdrawal-approvals',
      name: '合作商提现审核',
      resource_id: '/plutus/partner-withdrawal-approvals',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "PartnerWithdrawalApprovalListPage" */ '../../views/PartnerWithdrawalApprovalListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'partner-withdrawal-remittances',
      name: '合作商打款审核',
      resource_id: '/plutus/partner-withdrawal-remittances',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "PartnerWithdrawalRemittanceListPage" */ '../../views/PartnerWithdrawalRemittanceListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'partner-payoff-batch',
      name: '合作商批量转账',
      resource_id: '/plutus/driver-payoff',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "DriverPayoffBatchListPage" */ '../../views/PartnerPayoffBatchListPage'
            ),
        ),
      ),
      leftNav: true,
      exact: true,
    },
    {
      path: 'cs-payout-applications',
      name: '运力提供商提现申请',
      resource_id: 'plutus_cs-payout-applications',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "CSPayoutApplicationListPage" */ '../../views/CSPayoutApplicationListPage'
            ),
        ),
      ),
      leftNav: true,
      exact: true,
    },
    {
      path: 'invoice-applications',
      name: '发票申请（v2）',
      resource_id: '/plutus/invoice-applications',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "InvoiceApplicationListPage" */ '../../views/InvoiceApplicationListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'invoice-applications/:id',
      resource_id: '/plutus/invoice-applications/:id',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "InvoiceApplicationDetailsPage" */ '../../views/InvoiceApplicationDetailsPage'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'receipts',
      name: '发票申请记录',
      resource_id: '/plutus/receipts',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "ReceiptApplicationListPage" */ '../../views/ReceiptApplicationListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    // 申请、审批 End
    // 查询记录相关 Start
    {
      path: 'payment-records/v1/:id',
      resource_id: 'plutus/payment-records/v1/:id',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "PaymentV1DetailsPage" */ '../../views/PaymentV1DetailsPage'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'payment-records/v2/:id',
      resource_id: 'plutus/payment-records/v2/:id',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "PaymentRecordDetailsPage" */ '../../views/PaymentRecordDetailsPage'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'payment-records',
      name: '支付记录',
      resource_id: 'plutus/payments-records',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "PaymentListPage" */ '../../views/PaymentListPage'
            ),
        ),
      ),
      // exact: false 时 'payment-records/v1/:id', 'payment-records/v2/:id', 必须在 'payment-records' 之前
      exact: false,
      leftNav: true,
    },
    {
      path: 'refunds',
      name: '退款记录',
      resource_id: 'plutus/refunds',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "RefundListPage" */ '../../views/RefundListPage'
            ),
        ),
      ),
      leftNav: true,
    },
    {
      path: 'trades',
      name: '交易记录',
      resource_id: 'plutus/trades',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "TradeListPage" */ '../../views/TradeListPage'
            ),
        ),
      ),
      leftNav: true,
    },
    {
      path: 'withdrawal-record',
      name: '司机提现记录',
      resource_id: '/plutus/withdrawal-record',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "WithdrawalRecordListPage" */ '../../views/WithdrawalRecordListPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'partner-invoices/:id',
      resource_id: '/plutus/partner-invoices/:id',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "PartnerInvoiceVerificationDetailsPage" */ '../../views/PartnerInvoiceVerificationDetailsPage'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'partner-withdrawal-approvals/:id',
      resource_id: '/plutus/partner-withdrawal-approvals/:id',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "PartnerWithdrawalApprovalDetailsPage" */ '../../views/PartnerWithdrawalApprovalDetailsPage'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'partner-withdrawal-remittances/:id',
      resource_id: '/plutus/partner-withdrawal-remittances/:id',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "PartnerWithdrawalRemittanceDetailsPage" */ '../../views/PartnerWithdrawalRemittanceDetailsPage'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'reward-withdrawals',
      name: '奖励金提现记录',
      resource_id: '/plutus/reward-withdrawals',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "RewardWithdrawalList" */ '../../views/rewardWithdrawals/RewardWithdrawalList'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    // 查询记录相关 End
    // 以下为详情页 TODO：调整管理
    {
      path: 'ledgers/:id',
      resource_id: 'plutus_ledgers_view_details',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "LedgerDetail" */ '../../views/ledgers/LedgerDetail'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'driver-accounts/:id',
      resource_id: 'plutus_driverAccounts_view_details',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "AccountingAccountDetailsPage" */ '../../views/AccountingAccountDetailsPage'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'company_accounts/:id',
      resource_id: 'plutus_companyAccounts_view_details',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "AccountingCompanyLedgerDetail" */ '../../views/accountingCompanyLedger/AccountingCompanyLedgerDetail'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'partners/:partnerId/ledgers/:id',
      resource_id: '/plutus/partners/:partnerId/ledgers/:id',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "AccountingAccountDetailsPage" */ '../../views/AccountingAccountDetailsPage'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'partners/:partnerId/invoice-amount',
      resource_id: '/plutus/partners/:partnerId/invoice-amount',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "PartnerInvoiceAmountDetailsPage" */ '../../views/PartnerInvoiceAmountDetailsPage'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'operation-center-accounts/:id',
      resource_id: 'plutus_operationCenterAccounts_view_details',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "AccountingAccountDetailsPage" */ '../../views/AccountingAccountDetailsPage'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'capacity-supplier-accounts/:id',
      resource_id: 'plutus_capacitySupplierAccounts_view_details',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "AccountingAccountDetailsPage" */ '../../views/AccountingAccountDetailsPage'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'platform_revenue_commission/:id',
      resource_id: 'plutus_platformRevenueCommission_view_detail',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "AccountingPlatformCommissionDescendantsDetail" */ '../../views/accountingPlatformCommissionDescendants/AccountingPlatformCommissionDescendantsDetail'
            ),
        ),
      ),
      exact: true,
    },

    {
      path: 'withdrawal-record/:channel/:id',
      resource_id: '/plutus/withdrawal-record/:channel/:id',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "WithdrawalRecordDetailPage" */ '../../views/WithdrawalRecordDetailPage'
            ),
        ),
      ),
      exact: true,
    },

    {
      path: 'overview/:ancestorId/:descendantId',
      resource_id: 'plutus_overview_view_descendantDetail',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "DescendantAccountDetail" */ '../../views/accountingOverviews/DescendantAccountDetail'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'overview/:ancestorId',
      resource_id: 'plutus_overview_view_ancestorDetail',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "AncestorAccountDetail" */ '../../views/accountingOverviews/AncestorAccountDetail'
            ),
        ),
      ),
      exact: true,
    },
    {
      path: 'reward-withdrawals/:id',
      resource_id: '/plutus/reward-withdrawals/:id',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "RewardWithdrawalDetail" */ '../../views/rewardWithdrawals/RewardWithdrawalDetail'
            ),
        ),
      ),
      exact: true,
    },
  ],
};
