import PropTypes from 'prop-types';

import NumberFormatter from './NumberFormatter';

const RMB_CODE = 'CNY'; // 人民币
const DOLLAR_CODE = 'USD'; // 美元

const AMOUNT_PREFIXER_MAP = {
  [RMB_CODE]: '¥',
  [DOLLAR_CODE]: '$',
};

function AmountFormatter(props) {
  const { amountCode, ...otherProps } = props;
  return (
    <NumberFormatter
      {...otherProps}
      prefix={`${AMOUNT_PREFIXER_MAP[amountCode]}`}
    />
  );
}

AmountFormatter.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  amountCode: PropTypes.string.isRequired,
};

AmountFormatter.defaultProps = {
  amountCode: RMB_CODE,
};

export default AmountFormatter;
