import { useRef, useState } from 'react';
import { createPaginationContainer, graphql } from 'react-relay';
import { PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import { Table, Button, message, Popconfirm } from 'antd';
import { connect } from 'react-redux';
import _ from 'lodash';

import ModalContainer, {
  safelyOpenModalByRef,
} from '../../components/ModalContainer';
import {
  createSmartTable,
  Toolbar,
  TotalCount,
} from '../../components/SmartTable2';
import ModalForm from '../../components/ModalForm';
import relayContainerCombiner from '../../utils/relayContainerCombiner';
import createRelayRoot from '../../utils/createRelayRoot';
import LinkButton from '../../components/LinkButton';
import { decodeGraphQLGlobalID } from '../../utils/graphQLGlobalID';
import LocalDateTime from '../../components/LocalDateTime';
import { insertDivider } from '../../utils/dividerUtil';
import ActiveState from '../../components/ActiveState';
import { roleKeyMappingRole } from '../../common/roles';

import StaffCreateForm from './StaffCreateForm';
import StaffUpdateForm from './StaffUpdateForm';
import ChgStaffRoleForm from './ChgStaffRoleForm';

const { Column } = Table;
const TOOLBAR_BTN_STYLE = {
  marginRight: '8px',
};

const TaxiStaffList = props => {
  const {
    data,
    loading,
    loadingMore,
    smartTable,
    addStaffAuth,
    editStaffPasswdAuth,
    unlockStaffAuth,
    lockStaffAuth,
    editStaffRoleAuth,
  } = props;
  const entities = (data?.entities?.edges ?? []).map(edge => edge.node);
  const totalCount = data?.entities?.totalCount;

  const $createRef = useRef(null);
  const $editRef = useRef(null);
  const $roleRef = useRef(null);

  const [editStaffRole, setEditStaffRole] = useState(null);

  const staffModelHelper = ({ visible, hide }) => {
    return (
      <ModalForm
        key={visible}
        title="新建员工账号"
        open={visible}
        onCancel={hide}
        onSubmit={() => {
          message.info('系统维护中，暂时无法使用');
        }}
      >
        <StaffCreateForm />
      </ModalForm>
    );
  };

  const staffEditModelHelper = ({ visible, hide }) => {
    return (
      <ModalForm
        key={visible}
        title="修改员工账号密码"
        open={visible}
        onCancel={hide}
        onSubmit={() => {
          message.info('系统维护中，暂时无法使用');
        }}
      >
        <StaffUpdateForm />
      </ModalForm>
    );
  };

  const chgStaffRoleModelHelper = ({ visible, hide }) => {
    return (
      <ModalForm
        key={visible}
        title="修改员工角色"
        open={visible}
        onCancel={hide}
        onSubmit={() => {
          message.info('系统维护中，暂时无法使用');
        }}
      >
        <ChgStaffRoleForm initialValues={editStaffRole} />
      </ModalForm>
    );
  };

  const dateRender = (date, row, index) => {
    return <LocalDateTime date={date} />;
  };

  const lockedRender = (blocked_at, record, index) => (
    <ActiveState isActive={!blocked_at} />
  );

  const roleRender = (values, record, index) => {
    return values
      .filter(item =>
        ['taxi_company_staff', 'taxi_company_owner'].includes(item.role),
      )
      .map(item => roleKeyMappingRole(item.role).label);
  };

  const actionsRender = (id, record) => {
    const actions = [];
    if (editStaffRoleAuth) {
      const editRoleButton = (
        <LinkButton
          key="editRoleButton"
          onClick={() => {
            setEditStaffRole(record);
            safelyOpenModalByRef($roleRef);
          }}
        >
          修改角色
        </LinkButton>
      );
      actions.push(editRoleButton);
    }
    if (editStaffPasswdAuth) {
      const editPasswdButton = (
        <LinkButton
          key="editPasswdButton"
          onClick={() => {
            safelyOpenModalByRef($editRef);
          }}
        >
          修改密码
        </LinkButton>
      );
      actions.push(editPasswdButton);
    }
    if (record.blocked_at) {
      if (unlockStaffAuth) {
        const unlockStaffButton = (
          <Popconfirm
            key="unlockStaff"
            title="确认启用?"
            onConfirm={() => {
              message.info('系统维护中，暂时无法使用');
            }}
          >
            <LinkButton>启用</LinkButton>
          </Popconfirm>
        );
        actions.push(unlockStaffButton);
      }
    } else {
      if (lockStaffAuth) {
        const lockStaffButton = (
          <Popconfirm
            key="lockStaff"
            title="确认禁用?"
            onConfirm={() => {
              message.info('系统维护中，暂时无法使用');
            }}
          >
            <LinkButton>禁用</LinkButton>
          </Popconfirm>
        );
        actions.push(lockStaffButton);
      }
    }

    return insertDivider(actions);
  };

  return (
    <>
      <Toolbar>
        <Toolbar.Left>
          <Button
            icon={<ReloadOutlined />}
            style={TOOLBAR_BTN_STYLE}
            loading={loading}
            disabled={loadingMore}
            onClick={() => {
              smartTable.refetchList();
            }}
          >
            重新加载
          </Button>
          <Button
            style={TOOLBAR_BTN_STYLE}
            disabled={loading || loadingMore}
            onClick={smartTable.clearLocationState}
          >
            清除过滤器及排序
          </Button>
          {addStaffAuth && (
            <Button
              icon={<PlusOutlined />}
              type="primary"
              style={TOOLBAR_BTN_STYLE}
              loading={loading}
              onClick={() => {
                safelyOpenModalByRef($createRef);
              }}
            >
              新建
            </Button>
          )}
        </Toolbar.Left>
        <Toolbar.Right>
          <TotalCount value={totalCount} />
        </Toolbar.Right>
      </Toolbar>
      <Table
        loading={loading}
        dataSource={entities}
        rowKey="id"
        pagination={false}
        onChange={smartTable.getTableChangeHandler()}
      >
        <Column
          title="ID"
          dataIndex="id"
          key="id"
          render={decodeGraphQLGlobalID}
          sorter={true}
          sortOrder={smartTable.getColumnSorterOrder('id')}
        />
        <Column title="员工账号" dataIndex="username" key="username" />
        <Column
          title="角色"
          dataIndex="roles"
          key="roles"
          render={roleRender}
        />
        <Column
          title="创建时间"
          dataIndex="created_at"
          key="created_at"
          render={dateRender}
        />
        <Column
          title="更新时间"
          dataIndex="updated_at"
          key="updated_at"
          render={dateRender}
        />
        <Column
          title="禁用"
          dataIndex="blocked_at"
          key="blocked_at"
          render={lockedRender}
        />
        {(editStaffRoleAuth ||
          lockStaffAuth ||
          unlockStaffAuth ||
          editStaffPasswdAuth) && (
          <Column
            title="操作"
            dataIndex="id"
            key="action"
            render={actionsRender}
          />
        )}
      </Table>
      {addStaffAuth && (
        <ModalContainer ref={$createRef}>{staffModelHelper}</ModalContainer>
      )}
      {editStaffPasswdAuth && (
        <ModalContainer ref={$editRef}>{staffEditModelHelper}</ModalContainer>
      )}
      {editStaffRoleAuth && (
        <ModalContainer ref={$roleRef}>
          {chgStaffRoleModelHelper}
        </ModalContainer>
      )}
    </>
  );
};

TaxiStaffList.defaultProps = {
  loading: false,
  data: null,
};

const query = graphql`
  query TaxiStaffListQuery($count: Int!, $cursor: String, $q: Json) {
    data: relay {
      ...TaxiStaffList_data
    }
  }
`;

const HISTORY_STATE_KEY = 'SmartTaxiStaffList';

const SmartTaxiStaffList = createSmartTable({
  historyStateKey: HISTORY_STATE_KEY,
  defaultQ: {
    s: 'id desc',
  },
})(TaxiStaffList);

const TaxiStaffListContainer = _.flow(
  relayContainerCombiner(
    createPaginationContainer,
    {
      data: graphql`
        fragment TaxiStaffList_data on Relay {
          entities: taxi_company_staffs(first: $count, after: $cursor, q: $q)
            @connection(key: "TaxiStaffList_entities") {
            edges {
              node {
                id
                username
                phone_number
                created_at
                updated_at
                blocked_at
                roles {
                  role
                }
                company {
                  id
                  name
                }
              }
            }
            pageInfo {
              hasNextPage
              endCursor
            }
            totalCount
          }
        }
      `,
    },
    {
      direction: 'forward',
      getConnectionFromProps(props) {
        return props.data && props.data.entities;
      },
      getFragmentVariables(prevVars, totalCount) {
        return {
          ...prevVars,
          count: totalCount,
        };
      },
      getVariables(props, { count, cursor }, fragmentVariables) {
        const { variables = {} } = props;
        return {
          ...fragmentVariables,
          count,
          ...variables,
          cursor,
        };
      },
      query,
    },
  ),
  createRelayRoot({
    defaultVariables: {
      count: SmartTaxiStaffList.OPTIONS.fetchNum,
    },
    query,
  }),
  connect(SmartTaxiStaffList.OPTIONS.mapLocationStateToProps),
)(SmartTaxiStaffList);

export default TaxiStaffListContainer;
