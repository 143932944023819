/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type accountQueryVariables = {||};
export type accountQueryResponse = {|
  +viewer: ?{|
    +id: string,
    +username: string,
    +capacitySupplier: ?{|
      +id: string,
      +name: ?string,
    |},
    +operationCenter: ?{|
      +id: string,
      +name: ?string,
    |},
    +operationCenterGroup: ?{|
      +id: string,
      +name: ?string,
    |},
  |}
|};
export type accountQuery = {|
  variables: accountQueryVariables,
  response: accountQueryResponse,
|};
*/


/*
query accountQuery {
  viewer: adminViewer {
    id
    username
    capacitySupplier {
      id
      name
    }
    operationCenter {
      id
      name
    }
    operationCenterGroup {
      id
      name
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": "viewer",
    "args": null,
    "concreteType": "AdminUser",
    "kind": "LinkedField",
    "name": "adminViewer",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "username",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CapacitySupplier",
        "kind": "LinkedField",
        "name": "capacitySupplier",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "OperationCenter",
        "kind": "LinkedField",
        "name": "operationCenter",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "OperationCenterGroup",
        "kind": "LinkedField",
        "name": "operationCenterGroup",
        "plural": false,
        "selections": (v1/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "accountQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "accountQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "3b7e77f9600891437803486917f1341d",
    "id": null,
    "metadata": {},
    "name": "accountQuery",
    "operationKind": "query",
    "text": "query accountQuery {\n  viewer: adminViewer {\n    id\n    username\n    capacitySupplier {\n      id\n      name\n    }\n    operationCenter {\n      id\n      name\n    }\n    operationCenterGroup {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6f9c70151ccfaf2eed20fed85f536acf';

module.exports = node;
