import { lazy } from 'react';
import { BarChartOutlined } from '@ant-design/icons';

import Section from '../../components/Section';
import { authorizedRouteWrapper } from '../../auth/authorization';

export default {
  path: 'bi',
  component: Section,
  name: '数据中心',
  icon: <BarChartOutlined />,
  resource_id: '/bi',
  leftNav: true,
  routes: [
    {
      path: 'order-stats',
      name: '订单统计',
      resource_id: '/bi/order-stats',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "StatsOrderPage" */ '../../views/StatsOrderPage'
            ),
        ),
      ),
      exact: true,
      leftNav: true,
    },
    {
      path: 'export-biz-order',
      name: '导出订单数据',
      resource_id: '/bi/export-biz-order',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "OrderDownloadPage" */ '../../views/OrderDownloadPage'
            ),
        ),
      ),
      leftNav: true,
      exact: true,
    },
    {
      path: 'export-biz-trips',
      name: '导出行程数据',
      resource_id: '/bi/export-biz-trips',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "ExportTripPage" */ '../../views/ExportTripPage'
            ),
        ),
      ),
      leftNav: true,
      exact: true,
    },
    {
      path: 'export-order-insurance',
      name: '导出订单险数据',
      resource_id: '/bi/export-order-insurance',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "DriverPayoffBatchListPage" */ '../../views/ExportOrderInsurancePage'
            ),
        ),
      ),
      leftNav: true,
      exact: true,
    },
    {
      path: 'export-exchanged-order',
      name: '导出换单申请',
      resource_id: '/bi/export-exchanged-order',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "DriverPayoffBatchListPage" */ '../../views/ExportExchangedOrderPage'
            ),
        ),
      ),
      leftNav: true,
      exact: true,
    },
    {
      path: 'export-expressV2-payInfo',
      name: '导出优惠券和余额',
      resource_id: '/bi/export-expressV2-payInfo',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "DriverPayoffBatchListPage" */ '../../views/ExportExpressV2PayInfoPage'
            ),
        ),
      ),
      leftNav: true,
      exact: true,
    },
    {
      path: 'export-passenger-phone',
      name: '导出乘客手机号',
      resource_id: '/bi/export-passenger-phone',
      component: authorizedRouteWrapper(
        lazy(
          () =>
            import(
              /* webpackChunkName: "DriverPayoffBatchListPage" */ '../../views/ExportPassengerPhonePage'
            ),
        ),
      ),
      leftNav: true,
      exact: true,
    },
  ],
};
