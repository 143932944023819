/**
 * Created by qiuyuzhou on 2017/5/10.
 */
import { Alert } from 'antd';

const RelayError = props => {
  const { error } = props;
  return (
    <Alert
      message="Relay Network Error"
      description={<code>{error?.stack}</code>}
      type="error"
      showIcon
    />
  );
};

export default RelayError;
