import _ from 'lodash';

const PERMISSION = {};

PERMISSION.EMPLOYEE = [];
PERMISSION.ADMIN = [
  '*',
  '!driver-payoff-batch',
  '!carpool_scheduling', // 调度中心
  '!/statistics/capacity-supplier', // 运力提供商流水 dashboard
  '!/statistics/operation-center-individual-account', // dashboard 线路运营中心账户
  // '!statistics_capacitySupplierIndividualAccount', // dashboard 运力提供商账户
  '!/supervisor', //运营中心监管人员
  '!express_driver-groups', //快车 运力提供商司机组
  `!${_.snakeCase(`express/driver-groups/:id`)}`, //快车 运力提供商司机组详情
  '!/cs-plutus*',
  '!customer-service-center_*',
  '!/operation-center-console*',
];
PERMISSION.SUB_ADMIN = [
  ...PERMISSION.ADMIN,
  '!/taxi/taxi-orders_action_export',
  '!carpool_intercityRides_action_export',
  '!/plutus/operation-center-accounts_action_export',
  '!express_orders_action_export',
  '!*_view_passengerIdentity',
  '!*_view_passengerName',
  '!/plutus/receipts_action_*',
  '!plutus_payout-applications_action_close',
];

PERMISSION.SENIOR_OPERATOR = [
  '!/taxi/taxi-orders_action_export',
  '!carpool_intercityRides_action_export',
  '!carpool_intercityRides_action_refundPenalty',
  '!/plutus/operation-center-accounts_action_export',
  '!express_orders_action_export',
  '!operating_invisitation-activity_action_exportInvitationActivityVoucherOrders',
  '!operating_invisitation-activity_action_exportRewardInvitationRecords',
  '*_view_passengerPhone',
  '*_view_intercityRideId',
  '/statistics/analysis',
  '/statistics/route-order-overview_*',
  '*_action_eraseBlacklist',
  'user_passengers_view_*',
  'user_passengers_action_editRewardAccount',
  'user_passengers_action_editDisablement',
  '!/user/driver-accounts',
  '!/user/driver-accounts/:id',
  '/asset/drivers*',
  '/asset/vehicles*',
  '/asset/integrity-rules*',
  '/asset/driver-certificates-query*',
  '/asset/vehicle-certificates-query*',
  '!/asset/driver-certificates-query_action_exportData',
  '!/asset/vehicle-certificates-query_action_exportData',
  '!/asset/drivers/:id_action_editMetadatum',
  '!/asset/drivers/:id_action_editBankcard',
  '!/asset/drivers/:id_view_domainEvents',
  '!/asset/drivers/:id_view_driverCapacityUnitInfo',
  '!/asset/vehicles/:id_view_domainEvents',
  '/recruitment/driver-posts*',
  'carpool_*',
  '/carpool**',
  '!carpool_routes_action_bindCommission',
  '!/carpool/zones/:id_view_labels',
  '!/carpool/zones/:id_view_annotations',
  '!/carpool/zones/:id_action_labels',
  '!/carpool/zones/:id_action_annotations',
  '!carpool_scheduling_*',
  '!carpool_createIntercityRides_*',
  'express_*',
  '/express*',
  '!/express-v2/regions_action*',
  '!/express-v2/regions/:id_action*',
  '!express_driver-groups*',
  '/xiaojian*',
  '/taxi*',
  '!/taxi/taxi-vehicles_action_addTaxiVehicle',
  '/move-car-code/batch-list_view_*',
  '/move-car-code/code-list_view_*',
  '/move-car-code/virtual-phone-binding-list_view_*',
  'locations_*',
  'operating_*',
  '/operating*',
  `/operating/blacklist_action_exportBlacklist`,
  'store_*',
  '!store_exchangeOrders_view_passengerPhone',
  'setting_taxiCompanies_*',
  'setting_commissions_*',
  'setting_bonuses_*',
  '/setting/capacity-suppliers*',
  '/setting/partners*',
  '!/setting/partners_action*',
  '!/setting/partners/:id_action*',
  '!/setting/partners/:partnerId/rolesMapping/:mappingId_action*',
  '!/setting/capacity-suppliers/:id_action_bindExpressCommissionRule',
  '!/setting/capacity-suppliers/:id_action_bindRouteCarpoolCommissionRule',
  'setting_operation-centers_*',
  '/setting/phone-recording',
  '!*_action_downloadOrderSummaries',
  'setting_operationCenterGroups*',
  '/setting/tags*',
  `${_.snakeCase(
    `setting_capacity-suppliers/:capacitySupplierId/driver-groups/:id`,
  )}_*`,
  `!${_.snakeCase(
    `setting_capacity-suppliers/:capacitySupplierId/driver-groups/:id`,
  )}_action_bindCommission`,
  `/setting/prepayment-conditions_*`,
  '/bi/order-stats',
  '/bi/export-expressV2-payInfo',
  '/bi/export-passenger-phone',
  '/tools/drawing',

  '/route-carpool-v2/u-routes_*',
  '/route-carpool-v2/u-routes/:id_*',
  '/route-carpool-v2/u-routes/:id/bind-carcap-unit',
  '/route-carpool-v2/u-routes/:urouteId/prices-preview',
  '/route-carpool-v2/u-routes/:id/create-uroute-draft',
  '/route-carpool-v2/u-routes/:urouteId/drafts/:draftId/update',
  '/route-carpool-v2/routes_view_*',
  '/route-carpool-v2/routes/:id_view_*',
  '/route-carpool-v2/trips_view_*',
  '/route-carpool-v2/trips/:id_view_*',
  '/route-carpool-v2/orders_view_*',
  '/route-carpool-v2/orders/:id_view_*',
  '/route-carpool-v2/global-placer-cancel-violation-conf_view_*',
];
PERMISSION.OPERATOR = [
  ...PERMISSION.SENIOR_OPERATOR,
  '!*_action_eraseBlacklist',
  '!user-passengers_action_editDisablement',
  '!user_passengers_action_editRewardAccount',
  '!user_passengers_action_editDisablement',
  '!/asset/drivers/:id_action_editPhoneNumber',
  '!/asset/drivers/:id_action_confirmPersonLeave',
  '!/asset/integrity-rules/:id/editing',
  '!/recruitment/driver-posts/create-post',
  '!/recruitment/driver-posts/:id/edit',
  '!/recruitment/driver-posts_action_active',
  // '!/recruitment/applications/:id_action_approve',
  '!carpool_intercityRides_view_fixedCommission',
  '!carpool_intercityRides_action_closeOrder',
  '!carpool_intercityRides_action_waiveServiceFee',
  '!carpool_routes_view_bonus',
  '!carpool_routes_action_editBonus',
  '!express_orders_action_refund',
  '!express_orders_action_freeCharge',
  `!${_.snakeCase('express_orders')}_action_payInCash`,
  `!${_.snakeCase(`express/orders/:id`)}_action_refund`,
  `!${_.snakeCase(`express/orders/:id`)}_action_freeCharge`,
  `!${_.snakeCase('express_orders/:id')}_action_payInCash`,
  `!${_.snakeCase('express_orders/:id')}_view_fixedCommission`,
  '!express_areas_view_commission',
  '!express_areas_action_bindBonus',
  '!express_areas_action_bindCommission',
  '!express_geofences_view_labels',
  '!express_geofences_view_annotations',
  '!/express/hot-cities_action*',
  '!/xiaojian/courier-groups_action_add',
  '!/xiaojian/courier-groups/:id_action_editCutRate',
  '!/taxi/hot-cities_action*',
  '!*_action_eraseBlacklist',
  '!operating_*_action_*_switch*',
  '!operating_*_action_switchActivity',
  '!operating_recharge_*',
  '!operating_voucherDistributionList_action_*',
  '!operating_passenger-disablement_action_*',
  `!/operating/blacklist_action_exportBlacklist`,
  '!store_*',
  '!setting_commissions_*',
  '!setting_bonuses_*',
  '!setting_operationCenterGroups*',
  '!/setting/tags_action_*',
  '!/bi/export-expressV2-payInfo',
  '!/bi/export-passenger-phone',
  '!/route-carpool-v2/u-routes/:urouteId/prices-preview',
  '!/route-carpool-v2/u-routes/:id/create-uroute-draft',
  '!/route-carpool-v2/u-routes/:urouteId/drafts/:draftId/update',
  '!/route-carpool-v2/orders/:id_action_*',
];
PERMISSION.STORE_OPERATOR = ['store_*'];

PERMISSION.ACCOUNTANT = [
  'plutus_*',
  '!plutus_payout-applications_action_close',
  '/plutus*',
  '/bi/export-biz-order',
  '/bi/export-biz-trips',
  '/bi/export-order-insurance',
];
PERMISSION.ACCOUNTANT_INTERN = [
  ...PERMISSION.ACCOUNTANT,
  '!plutus_*_action_*',
  '!/plutus*_action_*',
];

PERMISSION.RECHARGE_COMMISSIONER = [
  'operating_recharge_*',
  'operating_ocRecharge_*',
];

PERMISSION.DRIVER_CENSOR = [
  '/asset/vehicle-switches*',
  '/asset/paper-updates*',
  '/recruitment/applications*',
  '/xiaojian/couriers', // 兼容
  '/xiaojian/couriers_action_addCourier',
];

PERMISSION.TAXI_COMPANY_STAFF = [
  'user_taxiDrivers_view_*',
  '!user_taxiDrivers_view_details_bankInfo',
  '!user_taxiDrivers_view_details_accountInfo',
  '!user_taxiDrivers_view_details_wechat',
  '!user_taxiDrivers_view_details_alipay',
  '!user_taxiDrivers_view_details_identitycard',
  '/taxi/taxi-orders_view_*',
  '/taxi/taxi-vehicles_view_*',
  '/taxi/taxi-areas_view_*',
  '/taxi/online-drivers_view_*',
];
PERMISSION.TAXI_COMPANY_OWNER = [
  ...PERMISSION.TAXI_COMPANY_STAFF,
  'user_taxiDrivers_action_editTags',
  'user_taxiDrivers_action_editDesc',
];

PERMISSION.OPERATION_CENTER_STAFF = [
  '!*_view_passengerPhone',
  '!*_view_intercityRideId',
  '!*_view_blacklist',
  '/statistics/analysis',
  '/statistics/operation-center-individual-account_view_*',
  '/statistics/operation-center-individual-account_action_downloadOrderSummaries',
  '/statistics/route-order-overview_*',
  'carpool_scheduling_*',
  'carpool_createIntercityRides_*',
  'carpool_intercityRideGroups_*',
  'carpool_intercityRides',
  'carpool_intercityRides_view_details',
  'carpool_intercityRides_view_viewTraceRecord',
  '!carpool_intercityRides_view_allData',
  'carpool_intercityRides_view_callRecord',
  'carpool_intercityRides_view_fixedCommission',
  '!carpool_intercityRides_action_payInCash',
  'carpool_intercityRides_action_contactPassenger',
  'carpool_intercityRides_action_updatePrice',
  'carpool_intercityRides_action_updatePassengers',
  'carpool_intercityRides_action_reassignOrder',
  'carpool_intercityRides_action_cancelOrder',
  'carpool_routes_view_details',
  'carpool_routes_view_baseInfo',
  'carpool_routes_view_baseInfo',
  'carpool_routes_view_stops',
  'carpool_routes_view_driverGroupList',
  'carpool_routes_view_driverGroupDetails',
  'carpool_routes_view_carpoolConf',
  'carpool_routes_view_driverList',
  'carpool_routes_view_chauffeurConf',
  'carpool_routes_view_intercityCarpoolConf',
  'carpool_routes_view_configCalendar',
  'carpool_routes_view_fenceMap',
  'carpool_routes_view_prepaymentConf',
  'carpool_routes_view_cancelPenaltyConf',
  'carpool_routes_view_subStopList',
  'carpool_routes_action_editSubStopConf',
  'express_orders',
  `!${_.snakeCase('express_orders')}_view_didiInfo`,
  `!${_.snakeCase('express_orders')}_action_payInCash`,
  _.snakeCase('express_orders/:id'),
  `${_.snakeCase('express_orders/:id')}_view_virtualPhoneNumbers`,
  `${_.snakeCase('express_orders/:id')}_view_callRecord`,
  `${_.snakeCase('express_orders/:id')}_view_fixedCommission`,
  `!${_.snakeCase('express_orders/:id')}_view_didiInfo`,
  `!${_.snakeCase('express_orders/:id')}_view_workOrder`,
  `!${_.snakeCase('express_orders/:id')}_view_auditLogs`,
  `!${_.snakeCase('express_orders/:id')}_action_contactPassenger`,
  `!${_.snakeCase('express_orders/:id')}_action_payInCash`,
  'express_geofences',
  '/express/fares*',
  'locations',
  'operating_activities',
  'operating_activities_view_details',
  'operating_activities_view_vouchers_details',
  'operating_promotions',
  'operating_promotions_view_details',
  'operating_promotions_view_vouchers_details',
  '/tools/drawing',
  '/operation-center-console*',
];

PERMISSION.OPERATION_CENTER_DISPATCHER = [
  ...PERMISSION.OPERATION_CENTER_STAFF,
  '!/asset/drivers/:id_view_rangeStatistic',
  '!/statistics/operation-center-individual-account',
  '!operating_activities',
  '!operating_activities_view_details',
  '!operating_activities_view_vouchers_details',
  '!operating_promotions',
  '!operating_promotions_view_details',
  '!operating_promotions_view_vouchers_details',
  '!carpool_intercityRides_view_callRecord',
  '!carpool_intercityRides_view_fixedCommission',
  'carpool_routes_action_editAutoNotifyConf',
  'carpool_routes_action_editTags',
  `!${_.snakeCase('express_orders/:id')}_view_callRecord`,
  `!${_.snakeCase('express_orders/:id')}_view_virtualPhoneNumbers`,
  `!${_.snakeCase('express_orders/:id')}_view_fixedCommission`,
];

PERMISSION.OPERATION_CENTER_SUPERVISOR = ['/supervisor*'];

PERMISSION.CAPACITY_SUPPLIER_STAFF = [
  '/asset/drivers_views_*',
  '/asset/drivers/:id_view_papers',
  '/asset/drivers/:id_view_routes',
  '/asset/drivers/:id_view_intercityRides',
  '/asset/drivers/:id_view_express',
  '/asset/drivers/:id_view_geofence',
  '/asset/drivers/:id_view_rangeStatistic',
  '/asset/drivers/:id_view_punishment',
  '/asset/drivers/:id_view_accountInfo',
  '/asset/drivers/:id_action_bindVehicle',
  '/asset/vehicles/:id_view_*',
  '!/asset/vehicles/:id_view_domainEvents',
  '/asset/vehicles/:id_action_unbindDriver',
  '/asset/vehicles/:id_action_editStatus',
  '/asset/driver-certificates-query_view_*',
  '/asset/vehicle-certificates-query_view_*',
  '!*_view_blacklist',
  'operating_driver-punishments', // 运力提供商能看到其下司机的处治记录，全局列表和司机详情里两处
  '/statistics/capacity-supplier',
  '/statistics/capacity-supplier/:capacitySupplierId/intercity-ride-trend',
  'express_orders',
  `${_.snakeCase('express_orders/:id')}_view_workOrder`,
  `${_.snakeCase('express_orders/:id')}_view_auditLogs`,
  `${_.snakeCase('express_orders/:id')}_view_didiInfo`,
  `${_.snakeCase('express_orders/:id')}_view_virtualPhoneNumbers`,
  `${_.snakeCase('express_orders/:id')}_view_callRecord`,
  'express_orders*_action_close',
  'express_geofences',
  'express_driver-groups',
  _.snakeCase(`express_driver-groups/:id`),
  '/tools/drawing',
];

PERMISSION.CAPACITY_SUPPLIER_MANAGER = [
  ...PERMISSION.CAPACITY_SUPPLIER_STAFF,
  `${_.snakeCase('express_orders/:id')}_view_fixedCommission`,
  '/asset/drivers/:id_view_driverTrack',
  '/asset/drivers/:id_action_confirmPersonLeave',
  'driver-payoff-batch_*',
  '/cs-plutus*',
];

PERMISSION.OPERATION_CENTER_GROUP_MANAGER = ['/statistics/analysis'];

PERMISSION.CUSTOMER_SERVICE_STAFF = [
  '/asset/drivers_view_*',
  '/asset/drivers_action_bindRoute',
  '/asset/drivers/:id_view_identityNo',
  '/asset/drivers/:id_view_routes',
  '/asset/drivers/:id_view_intercityRides',
  '/asset/drivers/:id_view_driverGroups',
  '/asset/drivers/:id_view_express',
  '/asset/drivers/:id_view_geofence',
  '/asset/drivers/:id_view_rangeStatistic',
  '/asset/drivers/:id_view_accountInfo',
  '/asset/drivers/:id_view_punishment',
  '/asset/drivers/:id_view_driverTrack',
  '/asset/drivers/:id_action_editCS',
  '/asset/drivers/:id_action_editCoopType',
  '/asset/drivers/:id_action_editPapers',
  '/asset/drivers/:id_action_bindVehicle',
  '/asset/drivers/:id_action_bindRoute',
  '/asset/drivers/:id_action_editDriverGroups',

  '/asset/vehicles_view_*',
  '/asset/vehicles/:id',
  '/asset/vehicles/:id_action_editStatus',
  '/asset/vehicles/:id_action_unbindDriver',
  '/asset/vehicles/:id_action_editPapers',

  'carpool_intercityRides_view_details',
  'carpool_intercityRides_view_callRecord',
  'carpool_intercityRides_view_viewTraceRecord',
  'carpool_intercityRides_view_chatRecord',
  'carpool_intercityRides_view_penaltyRule',
  'carpool_intercityRides_action_contactPassenger',
  'carpool_intercityRides_action_waivePenalty',

  `${_.snakeCase('express/orders')}_view_details`,
  `${_.snakeCase('express/orders/:id')}_action_contactPassenger`,
  `${_.snakeCase('express/orders/:id')}_action_contactWorkOrder`,
  `${_.snakeCase('express/orders/:id')}_view_workOrder`,
  `${_.snakeCase('express/orders/:id')}_view_didiInfo`,
  `${_.snakeCase('express/orders/:id')}_view_virtualPhoneNumbers`,
  `${_.snakeCase('express/orders/:id')}_view_callRecord`,

  '/express-v2/orders_view_*',
  '/express-v2/orders/:id_view_*',

  '/xiaojian/orders_view_*',
  '/xiaojian/orders/:id_action_closeOrder',
  '/xiaojian/orders/:id_action_reassignOrder',
  '/xiaojian/orders/:id_action_modifyPrice',
  '/xiaojian/orders/:id_action_freeCharge',

  '/route-carpool-v2/orders/:id_action_*',
];

export default _.mapKeys(PERMISSION, (val, key) => _.snakeCase(key));
