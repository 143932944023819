export class SDNetworkError extends Error {
  constructor(msg) {
    super('[SDNetworkError]: ' + msg);
    this.name = SDNetworkError.name;
  }

  static isSDNetworkError = error =>
    error instanceof SDNetworkError || error.name === SDNetworkError.name;
}

// error.name 兼用babel 转换后 instanceof 失效的问题
export const isSDNetworkError = SDNetworkError.isSDNetworkError;

export default SDNetworkError;
