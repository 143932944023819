import moment from 'moment';

export { validatePasswd } from './passwordValidator';

export function validateNumberInRange(
  value,
  min = Number.MIN_VALUE,
  max = Number.MAX_VALUE,
  equalMin = false,
  equalMax = false,
) {
  if (min > max) {
    return false;
  }
  if (
    (value > min && value < max) ||
    (equalMin && value === min) ||
    (equalMax && value === max)
  ) {
    return true;
  }

  return false;
}

export function createNumberRangeValidator(min, max, equalMin, equalMax) {
  return value => validateNumberInRange(value, min, max, equalMin, equalMax);
}

export const isPdfUri = uri => {
  if (!validateURL(uri)) return false;
  return /\.pdf$/.test(new URL(uri).pathname);
};

export const isImageUri = uri => {
  if (!validateURL(uri)) return false;
  return /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(new URL(uri).pathname);
};

// email regexp from https://emailregex.com/
export const EMAIL_REGEXP =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function validateEMail(email) {
  return EMAIL_REGEXP.test(email);
}

const ACCOUNT_REGEXP = /^[a-zA-Z][a-zA-Z0-9_]{3,19}$/;

export function validateAccount(account) {
  return ACCOUNT_REGEXP.test(account);
}

// const PHONE_NUMBER_REGEXP = /(^1\d{10}$)|(^8888\d{7}$)/;
const PHONE_NUMBER_REGEXP = /^1\d{10}$/;
const TEST_PHONE_NUMBER_REGEXP = /^8888\d{7}$/;

export function validatePhone(value) {
  return PHONE_NUMBER_REGEXP.test(value);
}

export function isTestPhoneNumber(value) {
  return TEST_PHONE_NUMBER_REGEXP.test(value);
}

/**
 * 是否是可用的手机号码
 * 包括 1 开头的常规手机号
 * 或 8888 开头的测试手机号
 *
 * @param {string} value
 */
export function isUseablePhoneNumber(value) {
  return validatePhone(value) || isTestPhoneNumber(value);
}

const URL_REGEXP = new RegExp(
  '^(http|https|ftp)\\://([a-zA-Z0-9\\.\\-]+(\\:[a-zA-Z0-9\\.&%\\$\\-]+)*@)*((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])|localhost|([a-zA-Z0-9\\-]+\\.)*[a-zA-Z0-9\\-]+\\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|tech|[a-zA-Z]{2}))(\\:[0-9]+)*(/($|[a-zA-Z0-9\\.\\,\\?\\’\\\\+&%\\$#\\=~_\\-]+))*$',
);

export function validateURL(value) {
  return URL_REGEXP.test(value);
}

const SDCX_URL_REGEXP = /^sdcx:\/\//;

export function validateSDCXURL(value) {
  return SDCX_URL_REGEXP.test(value);
}

const BANK_CARD_REGEXP = /^\d{16,19}$/;

export function validateBankCardNumber(value) {
  return BANK_CARD_REGEXP.test(value);
}

/**
 * 校验校验码
 * 据 GB 11643-1999 中有关公民身份号码的规定，最后一位校验校验码，由前 17位计算得到
 * http://c.gb688.cn/bzgk/gb/showGb?type=online&hcno=080D6FBF2BB468F9007657F26D60013E
 */
const computeValidateCode = identity => {
  const w = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]; // 加权因子
  let sum = 0;
  for (let i = 0; i < 17; ++i) {
    sum += global.parseInt(identity[i], 10) * w[i];
  }
  return '10X98765432'.charAt(sum % 11);
};

/**
 * 验证身份证号
 * @param {string} identity
 */
export function validateIdentity(identity) {
  /**
   * 110105 19491231 002 x
   * addressCode validateCode queueCode validateCode
   */
  const _identity = identity.toString();
  // 校验长度
  if (_identity.length !== 18) {
    return false;
  }

  // 校验顺序码
  const queueCode = _identity.slice(14, 17);
  if (!/\d{3}/.test(queueCode)) {
    return false;
  }

  // 校验出生日期是否合法
  const birth = _identity.slice(6, 14);
  const m = new moment(birth, 'YYYYMMDD');
  if (!m.isValid() || !m.isBefore(moment())) {
    return false;
  }
  // 校验 address code
  const addressCode = _identity.slice(0, 6);

  if (!/\d{6}/.test(addressCode)) {
    return false;
  }

  // for (let province of OriginAreaData) {
  //   for (let city of province.children) {
  //     for (let district of city.children) {
  //       if (district.value.toString() === addressCode) {
  //         return true;
  //       }
  //     }
  //   }
  // }

  const validateCode = _identity.slice(17, 18);
  if (computeValidateCode(_identity) !== validateCode) {
    return false;
  }

  return true;
}
